import * as Dialog from '@radix-ui/react-dialog';
import { useFetcher } from 'react-router-dom';
import ActionAlert from '../../../base/design-system/ActionAlert';
import { useEffect } from 'react';

function CheckPasswordModal({
    objectId,
    dismissPasswordModal,
}: {
    objectId: string;
    dismissPasswordModal: () => void;
}) {
    const fetcher = useFetcher();

    useEffect(() => {
        if (fetcher.data?.success) {
            dismissPasswordModal();
        }
    }, [fetcher.data]);

    return (
        <Dialog.Root open={true}>
            <Dialog.Portal>
                <Dialog.Overlay className="fixed inset-0 bg-black/60 animate-dialog-overlay-show" />
                <Dialog.Content className="w-[90vw] max-w-[450px] max-h-[85vh] fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 p-6 bg-white dark:bg-gray-700 rounded-md focus:outline-none animate-dialog-content-show">
                    <Dialog.Title asChild>
                        <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                            This content is password protected.
                        </h3>
                    </Dialog.Title>
                    <div className="text-sm py-4 md:py-5">
                        <fetcher.Form method="post">
                            <div>
                                <label
                                    htmlFor="password-input"
                                    className="block mb-3 text-base font-medium text-gray-900 dark:text-white"
                                >
                                    Please enter the password:
                                </label>
                                <input
                                    type="password"
                                    name="password"
                                    id="password-input"
                                    className="block w-full p-2.5 mb-3 bg-gray-50 focus:outline-none border rounded-lg border-gray-400 focus:ring-1 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700/20 dark:border-gray-500 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:placeholder-gray-400"
                                    placeholder="Enter password"
                                    required
                                />
                                <input
                                    type="hidden"
                                    name="object_uuid"
                                    value={objectId}
                                />
                            </div>
                            <button
                                type="submit"
                                name="intent"
                                value="check_password"
                                className="w-full mb-3 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                            >
                                Submit
                            </button>
                        </fetcher.Form>
                        <ActionAlert response={fetcher.data} />
                    </div>
                </Dialog.Content>
            </Dialog.Portal>
        </Dialog.Root>
    );
}

export default CheckPasswordModal;
