import { Form, Link, useLoaderData } from 'react-router-dom';
import * as Tabs from '@radix-ui/react-tabs';
import { formatDate } from '../../../../utils';
import {
    CreditCard,
    CircleDollarSign,
    ExternalLink,
    Gift,
    Info,
    ShoppingCart,
    HelpCircle,
} from 'lucide-react';

interface Purchase {
    created_ts: number;
    provider: string;
    type: string;
    amount: number;
    quantity: number;
    invoice_url: string;
}

interface Gift {
    created_ts: number;
    is_admin_gift: boolean;
    credits_gifted: number;
    note: string | null;
    sender_username: string | null;
    recipient_username: string | null;
    user_is_sender: boolean;
    user_is_recipient: boolean;
}

interface PurchaseData {
    purchases: Purchase[];
    gifts: Gift[];
    api_credits: number;
}

function getSenderUsername(gift: Gift) {
    if (gift.is_admin_gift === true) {
        return 'EP Admin';
    } else if (gift.sender_username === null) {
        return 'User not found';
    } else if (gift.user_is_sender) {
        return 'You';
    } else {
        return gift.sender_username;
    }
}

function getRecipientUsername(gift: Gift) {
    if (gift.recipient_username === null) {
        return 'User not found';
    } else if (gift.user_is_recipient) {
        return 'You';
    } else {
        return gift.recipient_username;
    }
}

function Credits() {
    const purchaseData = useLoaderData() as PurchaseData;

    function formatPrice(cents: number) {
        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
        });
        return formatter.format(cents / 100);
    }

    function formatCreditBalance(balance: number) {
        const formatter = new Intl.NumberFormat('en-US');
        return formatter.format(balance);
    }

    const linkStyles =
        'text-blue-500 hover:text-blue-600 hover:underline dark:text-primary-dark-text-accent dark:hover:text-primary-dark-text-accent';

    return (
        <>
            {/* Header */}
            <div className="space-y-4">
                <h1 className="font-bold text-4xl pb-2">Credits</h1>
                <p className="text-xl text-gray-700 dark:text-gray-300">
                    Credits are required to cover costs of API calls to language
                    models made using your Expected Parrot API key.
                </p>
            </div>

            <div className="mt-6 bg-green-50 dark:bg-green-900/20 p-5 rounded-lg border border-green-200 dark:border-green-900">
                <div className="flex items-center gap-2">
                    <CircleDollarSign className="text-green-600" size={24} />
                    <h4 className="text-xl font-semibold text-green-800 dark:text-green-400">
                        Current Balance:
                    </h4>
                    <span className="text-xl font-bold text-green-800 dark:text-green-400">
                        {formatCreditBalance(purchaseData.api_credits)} credits
                    </span>
                </div>
            </div>

            {/* Credits Information Section */}
            <div className="border border-gray-200 dark:border-gray-800 rounded-xl p-6 shadow-sm bg-white dark:bg-gray-900">
                <div className="flex items-center gap-3 mb-4">
                    <div className="w-10 h-10 rounded-full bg-blue-100 dark:bg-blue-900 flex items-center justify-center text-blue-600 dark:text-blue-400">
                        <Info size={20} />
                    </div>
                    <h3 className="font-bold text-2xl">About Credits</h3>
                </div>

                <div className="space-y-8">
                    <p className="text-gray-700 dark:text-gray-300">
                        Credits are deducted from your balance based on token
                        rates set by individual service providers. See details
                        about{' '}
                        <Link
                            to="/models"
                            target="_blank"
                            className={linkStyles}
                        >
                            model pricing
                        </Link>{' '}
                        and how{' '}
                        <Link
                            to="https://docs.expectedparrot.com/en/latest/credits.html"
                            target="_blank"
                            className={linkStyles}
                        >
                            credits
                        </Link>{' '}
                        are calculated.
                    </p>

                    <div className="bg-blue-50 dark:bg-blue-900/20 p-4 rounded-lg mt-6 border-l-4 border-blue-500">
                        <p>
                            <b>Note:</b> Running surveys using your own keys
                            from service providers does <u>not</u> require
                            credits.
                        </p>
                    </div>
                </div>
            </div>

            {/* Purchase Credits Section */}
            <div className="border border-gray-200 dark:border-gray-800 rounded-xl p-6 shadow-sm bg-white dark:bg-gray-900">
                <div className="flex items-center gap-3 mb-4">
                    <div className="w-10 h-10 rounded-full bg-green-100 dark:bg-green-900 flex items-center justify-center text-green-600 dark:text-green-400">
                        <CreditCard size={20} />
                    </div>
                    <h3 className="font-bold text-2xl">Purchase Credits</h3>
                </div>

                <p className="mb-6 text-gray-700 dark:text-gray-300">
                    $1.00 = 100 credits. Payments are processed through Stripe.
                    You may be charged processing fees.
                </p>

                <div className="bg-gray-50 dark:bg-gray-800 p-6 rounded-lg mb-4">
                    <Form method="post" className="space-y-6">
                        <div>
                            <label
                                htmlFor="credit-amount"
                                className="block text-md font-bold mb-2"
                            >
                                Number of credits (must be at least 100):
                            </label>
                            <input
                                type="number"
                                id="credit-amount"
                                name="credit_amount"
                                min="100"
                                max="99999999"
                                required
                                className="block p-2.5 w-full sm:w-56 bg-white focus:outline-none border rounded-md border-gray-400 focus:ring-1 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700/20 dark:border-gray-500 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:placeholder-gray-400"
                            />
                        </div>
                        <div>
                            <label
                                htmlFor="extra-invoice-notes"
                                className="block text-md font-bold mb-2"
                            >
                                Invoice notes (optional)
                            </label>
                            <textarea
                                id="extra-invoice-notes"
                                name="extra_invoice_notes"
                                rows={2}
                                className="block resize-none w-full sm:w-80 p-2 border border-gray-400 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700/20 dark:border-gray-500 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:placeholder-gray-400"
                            />
                        </div>
                        <button
                            type="submit"
                            className="px-6 py-3 bg-green-600 hover:bg-green-700 rounded-lg text-white font-semibold flex items-center gap-2"
                        >
                            <ShoppingCart size={18} />
                            Purchase Credits
                        </button>
                    </Form>
                </div>
            </div>

            {/* Purchase History Section */}
            <div className="border border-gray-200 dark:border-gray-800 rounded-xl p-6 shadow-sm bg-white dark:bg-gray-900">
                <div className="flex items-center gap-3 mb-4">
                    <div className="w-10 h-10 rounded-full bg-purple-100 dark:bg-purple-900 flex items-center justify-center text-purple-600 dark:text-purple-400">
                        <ShoppingCart size={20} />
                    </div>
                    <h3 className="font-bold text-2xl">Purchase History</h3>
                </div>

                <Tabs.Root defaultValue="purchases">
                    <Tabs.List
                        className="flex gap-x-2 mb-6 border-b border-gray-200 dark:border-gray-700"
                        aria-label="Purchase history tabs"
                    >
                        <Tabs.Trigger
                            className="p-4 border-b-2 rounded-t-lg text-sm font-medium text-center data-[state=active]:text-blue-600 data-[state=active]:border-blue-600 data-[state=inactive]:border-transparent dark:data-[state=active]:text-primary-dark-text-accent dark:data-[state=active]:border-primary-dark-text-accent flex items-center gap-2"
                            value="purchases"
                        >
                            <ShoppingCart size={16} />
                            Purchases
                        </Tabs.Trigger>
                        <Tabs.Trigger
                            className="p-4 border-b-2 rounded-t-lg text-sm font-medium text-center data-[state=active]:text-blue-600 data-[state=active]:border-blue-600 data-[state=inactive]:border-transparent dark:data-[state=active]:text-primary-dark-text-accent dark:data-[state=active]:border-primary-dark-text-accent flex items-center gap-2"
                            value="gifting"
                        >
                            <Gift size={16} />
                            Gifts
                        </Tabs.Trigger>
                    </Tabs.List>

                    <Tabs.Content value="purchases" className="w-full">
                        <div className="overflow-x-auto w-full">
                            <table className="min-w-full border-collapse">
                                <thead className="bg-gray-50 dark:bg-gray-800">
                                    <tr>
                                        <th className="py-3 px-4 text-left font-semibold border-b dark:border-gray-700">
                                            Date
                                        </th>
                                        <th className="py-3 px-4 text-left font-semibold border-b dark:border-gray-700">
                                            Type
                                        </th>
                                        <th className="py-3 px-4 text-center font-semibold border-b dark:border-gray-700">
                                            Amount
                                        </th>
                                        <th className="py-3 px-4 text-center font-semibold border-b dark:border-gray-700">
                                            Quantity
                                        </th>
                                        <th className="py-3 px-4 text-center font-semibold border-b dark:border-gray-700">
                                            Provider
                                        </th>
                                        <th className="py-3 px-4 text-center font-semibold border-b dark:border-gray-700">
                                            Invoice
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {purchaseData.purchases.length === 0 ? (
                                        <tr>
                                            <td
                                                colSpan={6}
                                                className="py-4 text-center text-gray-500 italic"
                                            >
                                                No purchase history found
                                            </td>
                                        </tr>
                                    ) : (
                                        purchaseData.purchases.map(
                                            (purchase, index) => (
                                                <tr
                                                    key={index}
                                                    className={`${
                                                        index % 2 === 0
                                                            ? 'bg-white dark:bg-gray-900'
                                                            : 'bg-gray-50 dark:bg-gray-800'
                                                    }`}
                                                >
                                                    <td className="py-3 px-4 border-b dark:border-gray-700">
                                                        {formatDate(
                                                            purchase.created_ts,
                                                            'MMMM D, YYYY'
                                                        )}
                                                    </td>
                                                    <td className="py-3 px-4 border-b dark:border-gray-700">
                                                        {purchase.type}
                                                    </td>
                                                    <td className="py-3 px-4 text-center border-b dark:border-gray-700">
                                                        {formatPrice(
                                                            purchase.amount
                                                        )}
                                                    </td>
                                                    <td className="py-3 px-4 text-center border-b dark:border-gray-700">
                                                        {purchase.quantity}
                                                    </td>
                                                    <td className="py-3 px-4 text-center border-b dark:border-gray-700">
                                                        {purchase.provider}
                                                    </td>
                                                    <td className="py-3 px-4 text-center border-b dark:border-gray-700">
                                                        <a
                                                            href={
                                                                purchase.invoice_url
                                                            }
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            className="flex items-center justify-center gap-1 text-blue-500 hover:text-blue-600"
                                                        >
                                                            View{' '}
                                                            <ExternalLink
                                                                size={14}
                                                            />
                                                        </a>
                                                    </td>
                                                </tr>
                                            )
                                        )
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </Tabs.Content>

                    <Tabs.Content value="gifting" className="w-full">
                        <div className="overflow-x-auto w-full">
                            <table className="min-w-full border-collapse">
                                <thead className="bg-gray-50 dark:bg-gray-800">
                                    <tr>
                                        <th className="py-3 px-4 text-left font-semibold border-b dark:border-gray-700">
                                            Date
                                        </th>
                                        <th className="py-3 px-4 text-left font-semibold border-b dark:border-gray-700">
                                            Sender
                                        </th>
                                        <th className="py-3 px-4 text-left font-semibold border-b dark:border-gray-700">
                                            Recipient
                                        </th>
                                        <th className="py-3 px-4 text-left font-semibold border-b dark:border-gray-700">
                                            Note
                                        </th>
                                        <th className="py-3 px-4 text-center font-semibold border-b dark:border-gray-700">
                                            Credits Gifted
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {purchaseData.gifts.length === 0 ? (
                                        <tr>
                                            <td
                                                colSpan={5}
                                                className="py-4 text-center text-gray-500 italic"
                                            >
                                                No gift history found
                                            </td>
                                        </tr>
                                    ) : (
                                        purchaseData.gifts.map(
                                            (gift, index) => (
                                                <tr
                                                    key={index}
                                                    className={`${
                                                        index % 2 === 0
                                                            ? 'bg-white dark:bg-gray-900'
                                                            : 'bg-gray-50 dark:bg-gray-800'
                                                    }`}
                                                >
                                                    <td className="py-3 px-4 border-b dark:border-gray-700">
                                                        {formatDate(
                                                            gift.created_ts,
                                                            'MMMM D, YYYY'
                                                        )}
                                                    </td>
                                                    <td className="py-3 px-4 border-b dark:border-gray-700">
                                                        {getSenderUsername(
                                                            gift
                                                        )}
                                                    </td>
                                                    <td className="py-3 px-4 border-b dark:border-gray-700">
                                                        {getRecipientUsername(
                                                            gift
                                                        )}
                                                    </td>
                                                    <td className="py-3 px-4 border-b dark:border-gray-700">
                                                        {gift.note || '—'}
                                                    </td>
                                                    <td className="py-3 px-4 text-center border-b dark:border-gray-700">
                                                        {gift.credits_gifted}
                                                    </td>
                                                </tr>
                                            )
                                        )
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </Tabs.Content>
                </Tabs.Root>
            </div>

            {/* Footer */}
            {/* <div className="border border-gray-200 dark:border-gray-800 rounded-xl p-6 shadow-sm bg-blue-50 dark:bg-blue-900/20">
                <h3 className="font-bold text-2xl mb-4">
                    Need help with credits?
                </h3>
                <div className="grid md:grid-cols-2 gap-4">
                    <a
                        href="https://docs.expectedparrot.com/en/latest/index.html"
                        className="flex items-center justify-center gap-2 px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition"
                    >
                        Read the docs <ExternalLink size={18} />
                    </a>
                    <Link
                        to="/support"
                        className="flex items-center justify-center gap-2 px-6 py-3 bg-blue-200 text-blue-800 dark:bg-blue-700 dark:text-white rounded-lg hover:bg-blue-300 dark:hover:bg-blue-600 transition"
                    >
                        Contact support <HelpCircle size={18} />
                    </Link>
                </div>
            </div> */}
        </>
    );
}

export default Credits;
