import { useRef, useEffect } from 'react';

function Notebook({
    notebookContent,
    openInColabLink,
}: {
    notebookContent: string;
    openInColabLink: string | null;
}) {
    const iframeRef = useRef<HTMLIFrameElement>(null);

    useEffect(() => {
        const iframe = iframeRef.current;
        if (!iframe) return;

        // Listen for messages from iframe
        // These messages are sent from the calculateHeight function in index.html.j2
        const handleMessage = (event: MessageEvent) => {
            if (event.data.height) {
                // console.log('Received height:', event.data.height);
                iframe.style.height = `${event.data.height}px`;
            }
        };
        window.addEventListener('message', handleMessage);

        // Cleanup
        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, [notebookContent]);

    return (
        <div>
            {openInColabLink && (
                <a
                    href={openInColabLink}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <img
                        src="https://colab.research.google.com/assets/colab-badge.svg"
                        alt="Open In Colab"
                    />
                </a>
            )}
            <iframe
                ref={iframeRef}
                srcDoc={notebookContent}
                title="Notebook content"
                // The iframe needs to be full height so that the user does
                // not see flashing content when the iframe is resized
                className="w-full bg-white h-screen"
            />
            {/* <div
                className="bg-white"
                dangerouslySetInnerHTML={{
                    __html: notebookContent,
                }}
            /> */}
        </div>
    );
}

export default Notebook;
