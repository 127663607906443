import { Link } from 'react-router-dom';
import { ArrowRight } from 'lucide-react';

function CreateIndex() {
    const linkStyles =
        'text-blue-500 hover:text-blue-600 hover:underline dark:text-primary-dark-text-accent dark:hover:text-primary-dark-text-accent';

    // Base styles that will be shared by all buttons
    const baseStyles =
        'group self-stretch w-full flex flex-col gap-3 text-left border focus:outline-none focus:ring-4 rounded-lg px-5 py-2.5';

    // Dark mode styles that will be shared by all buttons
    const darkModeStyles =
        'dark:text-primary-dark-text dark:border-gray-600 dark:hover:border-gray-600';

    // Specific styles for each button type
    const surveyBtnStyles = `${baseStyles} ${darkModeStyles} bg-teal-200 hover:bg-teal-300 focus:ring-teal-100 dark:bg-teal-800/40 dark:hover:bg-teal-800/60 dark:focus:ring-teal-900`;

    const agentListBtnStyles = `${baseStyles} ${darkModeStyles} bg-pink-200 hover:bg-pink-300 focus:ring-blue-100 dark:bg-blue-800/40 dark:hover:bg-blue-800/60 dark:focus:ring-blue-900`;

    const scenarioListBtnStyles = `${baseStyles} ${darkModeStyles} bg-sky-200 hover:bg-sky-300 focus:ring-purple-100 dark:bg-purple-800/40 dark:hover:bg-purple-800/60 dark:focus:ring-purple-900`;

    const projectBtnStyles = `${baseStyles} ${darkModeStyles} bg-amber-200 hover:bg-amber-300 focus:ring-amber-100 dark:bg-amber-800/40 dark:hover:bg-amber-800/60 dark:focus:ring-amber-900`;

    return (
        <>
            <div className="flex justify-between items-center space-y-2 sm:space-y-0">
                <h1 className="font-bold text-3xl pb-2">Create</h1>
            </div>
            <p>
                Watch a{' '}
                <a
                    href="https://app.arcade.software/share/MbB0C3UDuZE6JLgB68FL"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={`inline-flex items-center ${linkStyles}`}
                >
                    quick demo
                </a>
                . Learn more about{' '}
                <a
                    href="https://docs.expectedparrot.com/en/latest/survey_builder.html"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={`inline-flex items-center ${linkStyles}`}
                >
                    how it works
                </a>
                .
            </p>

            <div className="p-8 space-y-4 ml-0 border-solid border-2 border-gray-300 dark:border-primary-dark-border rounded-lg text-sm shadow-md w-full sm:w-4/5 md:w-3/4 lg:w-2/3">
                <Link className={surveyBtnStyles} to="/create/survey">
                    <p className="flex items-center gap-1 font-medium text-lg">
                        Survey
                        <ArrowRight className="w-4 h-4 opacity-0 group-hover:opacity-100 group-hover:translate-x-1 transition-all motion-reduce:transition-none duration-150" />
                    </p>
                    Create a survey with our interactive builder
                </Link>
                <Link className={agentListBtnStyles} to="/create/agent-list">
                    <p className="flex items-center gap-1 font-medium text-lg">
                        Agent List
                        <ArrowRight className="w-4 h-4 opacity-0 group-hover:opacity-100 group-hover:translate-x-1 transition-all motion-reduce:transition-none duration-150" />
                    </p>
                    Create agents with traits and personas to answer your survey
                    (or upload a CSV file)
                </Link>
                <Link
                    className={scenarioListBtnStyles}
                    to="/create/scenario-list"
                >
                    <p className="flex items-center gap-1 font-medium text-lg">
                        Scenario List
                        <ArrowRight className="w-4 h-4 opacity-0 group-hover:opacity-100 group-hover:translate-x-1 transition-all motion-reduce:transition-none duration-150" />
                    </p>
                    Create scenarios for content to add to your questions (or
                    upload a CSV file)
                </Link>
                <Link className={projectBtnStyles} to="/create/project">
                    <p className="flex items-center gap-1 font-medium text-lg">
                        Project
                        <ArrowRight className="w-4 h-4 opacity-0 group-hover:opacity-100 group-hover:translate-x-1 transition-all motion-reduce:transition-none duration-150" />
                    </p>
                    Add a survey to a project to begin collecting responses
                </Link>
            </div>
        </>
    );
}

export default CreateIndex;
