import KeyValueTable from './KeyValueTable';

function Agent({
    traits,
}: {
    traits: { id: number; key: string; value: any }[];
}) {
    return (
        <KeyValueTable
            title="Agent traits"
            keyColName="Trait"
            valueColName="Value"
            data={traits}
        />
    );
}

export default Agent;
