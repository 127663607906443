import { useFetcher } from 'react-router-dom';
import { Check, X } from 'lucide-react';
import Toggle from '../../../base/Toggle';
import { formatDate } from '../../../../utils';
import { SharedKey } from './types';

function EnabledBadge({ enabled }: { enabled: boolean }) {
    switch (enabled) {
        case true:
            return (
                <div className="flex items-center gap-2 px-2 py-1 w-24 rounded-md text-sm font-semibold rounded-lg bg-green-100 text-green-800 dark:bg-green-400/15 dark:text-green-400 transition-all duration-200">
                    <Check className="w-4 h-4" />
                    Enabled
                </div>
            );
        case false:
            return (
                <div className="flex items-center gap-2 px-2 py-1 w-24 rounded-md text-sm font-semibold rounded-lg bg-red-100 text-red-800 dark:bg-red-400/15 dark:text-red-400 transition-all duration-200">
                    <X className="w-4 h-4" />
                    Disabled
                </div>
            );
        default:
            return null;
    }
}

function SharedKeyStatusToggle({
    shareId,
    defaultValue,
    isEpKey,
}: {
    shareId: string;
    defaultValue: boolean;
    isEpKey: boolean;
}) {
    const fetcher = useFetcher();

    const checked = fetcher.formData
        ? fetcher.formData.get('is_enabled_by_user') === 'true'
        : defaultValue;

    function handleToggle(newValue: boolean) {
        fetcher.submit(
            {
                intent: 'update_share',
                share_uuid: shareId,
                is_enabled_by_user: newValue,
                is_ep_key: isEpKey,
            },
            {
                method: 'post',
            }
        );
    }

    return (
        <Toggle
            id={`shared-key-toggle-${shareId}`}
            checked={checked}
            onCheckedChange={handleToggle}
            name="is_enabled_by_user"
        />
    );
}

function SharedKeysTable({ shared_keys }: { shared_keys: SharedKey[] }) {
    function formatPrice(usd: number) {
        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
        });
        return formatter.format(usd);
    }

    return (
        <div className="mb-8 overflow-x-auto">
            <div className="relative">
                <table className="min-w-full text-sm relative">
                    <thead className="bg-white dark:bg-primary-dark-bg sticky border-b dark:border-gray-100/20 top-0">
                        <tr>
                            <th className="py-2 px-4 text-left w-2/5 font-semibold whitespace-nowrap">
                                Key
                            </th>
                            <th className="py-2 px-4 text-left w-1/5 font-semibold whitespace-nowrap">
                                Monthly usage
                            </th>
                            <th className="py-2 px-4 text-left w-1/5 font-semibold whitespace-nowrap">
                                Enabled by owner
                            </th>
                            <th className="py-2 px-4 text-left w-1/5 font-semibold whitespace-nowrap">
                                Enabled by you
                            </th>
                        </tr>
                    </thead>
                    <tbody className="align-top">
                        {shared_keys.map((key, index) => (
                            <tr
                                key={key.id}
                                className={`border-y dark:border-gray-100/20 dark:text-secondary-dark-text ${
                                    index % 2 === 0
                                        ? 'bg-white dark:bg-gray-800'
                                        : 'bg-gray-50 dark:bg-gray-700'
                                }`}
                            >
                                <td className="py-2 px-4 text-left">
                                    <div className="flex flex-col">
                                        <span className="font-medium">
                                            {key.name}
                                            {key.paired_key_name &&
                                                `, ${key.paired_key_name}`}
                                        </span>
                                        <span className="text-gray-500 dark:text-gray-400">
                                            Shared by {key.owner_username} on{' '}
                                            {formatDate(
                                                key.created_ts,
                                                'MMMM D, YYYY'
                                            )}
                                        </span>
                                    </div>
                                </td>
                                <td className="py-2 px-4 text-left whitespace-nowrap">
                                    {formatPrice(key.current_month_usage_usd)} /{' '}
                                    {formatPrice(key.monthly_spend_limit_usd)}
                                </td>
                                <td className="py-2 px-4 text-left">
                                    <EnabledBadge
                                        enabled={key.is_enabled_by_owner}
                                    />
                                </td>
                                <td className="py-2 px-4 text-left flex items-center gap-4">
                                    <SharedKeyStatusToggle
                                        shareId={key.id}
                                        defaultValue={key.is_enabled_by_user}
                                        isEpKey={key.is_ep_key}
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default SharedKeysTable;
