import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
    ArrowRight,
    ExternalLink,
    Copy,
    Check,
    Clock,
    Settings,
    Key,
    BookOpen,
    Code,
    ChevronRight,
} from 'lucide-react';

const GettingStartedIndex = () => {
    const linkStyles =
        'text-blue-500 hover:text-blue-600 hover:underline dark:text-primary-dark-text-accent dark:hover:text-primary-dark-text-accent';

    const [copiedText, setCopiedText] = useState('');

    const copyToClipboard = (text) => {
        setCopiedText(text);
        setTimeout(() => setCopiedText(''), 2000);
    };

    const homeSettingsImageURL = new URL(
        '../../../public/assets/getting-started/settings-view.png',
        import.meta.url
    ).pathname;

    const homeKeysImageURL = new URL(
        '../../../public/assets/getting-started/keys-view.png',
        import.meta.url
    ).pathname;

    return (
        <div className="max-w-4xl mx-auto space-y-12 pb-16">
            {/* Introduction */}
            <div className="space-y-4">
                <h1 className="font-bold text-4xl md:text-5xl pb-2 text-blue-600">
                    Design, conduct & replicate research with AI
                </h1>

                <p className="text-xl text-gray-700 dark:text-gray-300">
                    Expected Parrot tools make it easy to design, conduct and
                    replicate social science and market research with AI agents
                    and language models. Follow the steps below to get started
                    in just a few minutes. For more details on each step, please
                    see our{' '}
                    <a
                        href="https://docs.expectedparrot.com"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="inline-flex items-center gap-1 text-blue-500 hover:text-blue-700"
                    >
                        documentation <ExternalLink size={16} />
                    </a>{' '}
                    page.
                </p>
            </div>

            {/* Step 1 */}
            <div
                id="step-1"
                className="border border-gray-200 dark:border-gray-800 rounded-xl p-6 shadow-sm bg-white dark:bg-gray-900"
            >
                <div className="flex items-center gap-3 mb-4">
                    <div className="w-10 h-10 rounded-full bg-blue-100 dark:bg-blue-900 flex items-center justify-center text-blue-600 dark:text-blue-400">
                        <Code size={20} />
                    </div>
                    <h3 className="font-bold text-2xl">Step 1: Install EDSL</h3>
                </div>

                <p className="mb-4">
                    Open your terminal and run the following command to install
                    the EDSL package:
                </p>

                <div className="relative bg-gray-100 dark:bg-gray-800 rounded-lg p-4 font-mono text-sm mb-4">
                    pip install edsl
                    <button
                        onClick={() => copyToClipboard('pip install edsl')}
                        className="absolute top-2 right-2 p-1 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
                        aria-label="Copy to clipboard"
                    >
                        {copiedText === 'pip install edsl' ? (
                            <Check size={18} />
                        ) : (
                            <Copy size={18} />
                        )}
                    </button>
                </div>

                <p className="mb-4">
                    For faster installation using{' '}
                    <code className="bg-gray-100 dark:bg-gray-800 px-1 py-0.5 rounded">
                        uv
                    </code>
                    :
                </p>

                <div className="relative bg-gray-100 dark:bg-gray-800 rounded-lg p-4 font-mono text-sm mb-4">
                    !pip install uv && uv pip install edsl
                    <button
                        onClick={() =>
                            copyToClipboard(
                                '!pip install uv && uv pip install edsl'
                            )
                        }
                        className="absolute top-2 right-2 p-1 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
                        aria-label="Copy to clipboard"
                    >
                        {copiedText ===
                        '!pip install uv && uv pip install edsl' ? (
                            <Check size={18} />
                        ) : (
                            <Copy size={18} />
                        )}
                    </button>
                </div>

                <p className="mb-4">To update an existing installation:</p>

                <div className="relative bg-gray-100 dark:bg-gray-800 rounded-lg p-4 font-mono text-sm mb-4">
                    pip install --upgrade edsl
                    <button
                        onClick={() =>
                            copyToClipboard('pip install --upgrade edsl')
                        }
                        className="absolute top-2 right-2 p-1 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
                        aria-label="Copy to clipboard"
                    >
                        {copiedText === 'pip install --upgrade edsl' ? (
                            <Check size={18} />
                        ) : (
                            <Copy size={18} />
                        )}
                    </button>
                </div>

                <div className="flex flex-wrap gap-4 mt-6">
                    <a
                        href="https://docs.expectedparrot.com/en/latest/colab_setup.html"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="inline-flex items-center gap-1 px-4 py-2 bg-orange-100 dark:bg-orange-800 rounded-lg hover:bg-orange-200 dark:hover:bg-orange-700 transition"
                    >
                        Google Colab setup <ExternalLink size={16} />
                    </a>
                    <a
                        href="https://github.com/expectedparrot/edsl"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="inline-flex items-center gap-1 px-4 py-2 bg-yellow-100 dark:bg-yellow-800 rounded-lg hover:bg-yellow-200 dark:hover:bg-yellow-700 transition"
                    >
                        Source code on GitHub <ExternalLink size={16} />
                    </a>
                </div>
            </div>

            {/* Step 2 */}
            <div
                id="step-2"
                className="border border-gray-200 dark:border-gray-800 rounded-xl p-6 shadow-sm bg-white dark:bg-gray-900"
            >
                <div className="flex items-center gap-3 mb-4">
                    <div className="w-10 h-10 rounded-full bg-green-100 dark:bg-green-900 flex items-center justify-center text-green-600 dark:text-green-400">
                        <Settings size={20} />
                    </div>
                    <h3 className="font-bold text-2xl">
                        Step 2: Create an account
                    </h3>
                </div>
                <p className="mb-4">
                    Sign up for a free account to access special features for
                    conducting research at the Expected Parrot server.
                </p>
                <p className="bg-green-50 dark:bg-green-900/20 p-4 rounded-lg mb-6 border-l-4 border-green-500">
                    Your account comes with <strong>$25 in credits</strong> for
                    API calls to available models.
                </p>
                <Link
                    to="/login"
                    className="inline-flex items-center gap-2 px-6 py-3 bg-green-600 text-white rounded-lg hover:bg-blue-700 transition"
                >
                    Create account <ArrowRight size={18} />
                </Link>
            </div>

            {/* Step 3 */}
            <div
                id="step-3"
                className="border border-gray-200 dark:border-gray-800 rounded-xl p-6 shadow-sm bg-white dark:bg-gray-900"
            >
                <div className="flex items-center gap-3 mb-4">
                    <div className="w-10 h-10 rounded-full bg-purple-100 dark:bg-purple-900 flex items-center justify-center text-purple-600 dark:text-purple-400">
                        <Settings size={20} />
                    </div>
                    <h3 className="font-bold text-2xl">
                        Step 3: Choose where to conduct your research
                    </h3>
                </div>
                <p className="mb-4">
                    You can run surveys remotely at the Expected Parrot server
                    or locally on your own machine.
                </p>
                <div className="bg-purple-50 dark:bg-purple-900/20 p-4 rounded-lg mb-6 border-l-4 border-purple-500">
                    <p>
                        <b>Activate remote inference</b> at your{' '}
                        <b>
                            <Link to="/home/settings" className={linkStyles}>
                                Settings
                            </Link>{' '}
                        </b>
                        page:
                    </p>
                    <br></br>
                    <img
                        src={homeSettingsImageURL}
                        alt="Settings page..."
                        className="w-3/4 border-2 border-gray-200 rounded-lg shadow-lg"
                    />
                    <br></br>
                    <p className="pb-2">This allows you to:</p>
                    <ul className="list-disc pl-5 space-y-1">
                        <li>Run surveys at the Expected Parrot server.</li>
                        <li>
                            Use your Expected Parrot API key to access all
                            available models.
                        </li>
                        <li>
                            Automatically store and retrieve responses from the
                            Universal Remote Cache.
                        </li>
                    </ul>
                </div>
                <Link
                    to="/getting-started/coop-remote-cache"
                    className="inline-flex items-center gap-1 text-blue-600 hover:text-blue-700 dark:text-blue-400 dark:hover:text-blue-500"
                >
                    Learn more about remote features <ChevronRight size={16} />
                </Link>
            </div>

            {/* Step 4 */}
            <div
                id="step-4"
                className="border border-gray-200 dark:border-gray-800 rounded-xl p-6 shadow-sm bg-white dark:bg-gray-900"
            >
                <div className="flex items-center gap-3 mb-4">
                    <div className="w-10 h-10 rounded-full bg-amber-100 dark:bg-amber-900 flex items-center justify-center text-amber-600 dark:text-amber-400">
                        <Key size={20} />
                    </div>
                    <h3 className="font-bold text-2xl">
                        Step 4: Manage API keys
                    </h3>
                </div>
                <p className="mb-4">
                    You can use your own API keys for LLMs and/or an Expected
                    Parrot API key that provides access to all available models.
                </p>
                <div className="bg-yellow-50 dark:bg-yellow-900/20 p-4 rounded-lg mb-6 border-l-4 border-yellow-500">
                    <p className="pb-2">
                        Manage keys at your{' '}
                        <b>
                            <Link to="/home/keys" className={linkStyles}>
                                Keys
                            </Link>{' '}
                        </b>
                        page:
                    </p>
                    <img
                        src={homeKeysImageURL}
                        alt="Settings page..."
                        className="w-3/4 border-2 border-gray-200 rounded-lg shadow-lg"
                    />
                    <br></br>
                    <ul className="list-disc pl-5 space-y-1">
                        <li>
                            Use your Expected Parrot API key to access all
                            available models.
                        </li>
                        <li>
                            Add your own keys from Anthropic, OpenAI and other
                            service providers.
                        </li>
                        <li>
                            Share keys with your team, track usage, set spending
                            limits and easily switch between providers at any
                            time.
                        </li>
                    </ul>
                </div>
                <Link
                    to="/getting-started/edsl-api-keys"
                    className="inline-flex items-center gap-1 text-blue-600 hover:text-blue-700 dark:text-blue-400 dark:hover:text-blue-500"
                >
                    Learn more about managing keys <ChevronRight size={16} />
                </Link>
            </div>

            {/* Step 5 */}
            <div
                id="step-5"
                className="border border-gray-200 dark:border-gray-800 rounded-xl p-6 shadow-sm bg-white dark:bg-gray-900"
            >
                <div className="flex items-center gap-3 mb-4">
                    <div className="w-10 h-10 rounded-full bg-indigo-100 dark:bg-indigo-900 flex items-center justify-center text-indigo-600 dark:text-indigo-400">
                        <BookOpen size={20} />
                    </div>
                    <h3 className="font-bold text-2xl">
                        Step 5: Run the starter tutorial
                    </h3>
                </div>

                <p className="mb-6">
                    Download and run the starter tutorial notebook to try
                    example code. You can also explore demo notebooks for a
                    variety of use cases at our documentation page and easily
                    modify them for your purposes.
                </p>
                <p className="font-italic pb-4">
                    Have a use case that you don't see covered? Get in touch and
                    we'll create one for you!
                </p>
                {/* <div className="bg-gray-100 dark:bg-gray-800 p-6 rounded-lg mb-6 font-mono text-sm overflow-auto">
                    <div className="text-gray-600 dark:text-gray-400">
                        # Example: Running a simple survey
                    </div>
                    <div className="mt-2">
                        <span className="text-green-700 dark:text-green-400">
                            from
                        </span>{' '}
                        edsl{' '}
                        <span className="text-green-700 dark:text-green-400">
                            import
                        </span>{' '}
                        QuestionMultipleChoice
                        <br></br>
                        <br></br>q = QuestionMultipleChoice (<br></br>
                        &nbsp;&nbsp;&nbsp;&nbsp;question_name ={' '}
                        <span className="text-red-700 dark:text-red-400">
                            "example"
                        </span>
                        ,<br></br>
                        &nbsp;&nbsp;&nbsp;&nbsp;question_text ={' '}
                        <span className="text-red-700 dark:text-red-400">
                            "What is the capital of France?"
                        </span>
                        ,<br></br>
                        &nbsp;&nbsp;&nbsp;&nbsp;question_options = [
                        <span className="text-red-700 dark:text-red-400">
                            "London"
                        </span>
                        ,{' '}
                        <span className="text-red-700 dark:text-red-400">
                            "Paris"
                        </span>
                        ,{' '}
                        <span className="text-red-700 dark:text-red-400">
                            "Boston"
                        </span>
                        ]<br></br>)<br></br>
                        <br></br>
                        results = q.
                        <span className="text-blue-600 dark:text-blue-400">
                            run
                        </span>
                        ()
                    </div>
                </div> */}

                <div className="grid md:grid-cols-2 gap-4 mb-6">
                    <a
                        href="https://www.expectedparrot.com/content/RobinHorton/starter-tutorial"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="flex items-center justify-center gap-2 px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition"
                    >
                        View starter tutorial <ArrowRight size={18} />
                    </a>
                    <a
                        href="https://docs.expectedparrot.com/en/latest/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="flex items-center justify-center gap-2 px-6 py-3 bg-blue-200 text-blue-800 dark:bg-blue-700 dark:text-white rounded-lg hover:bg-blue-300 dark:hover:bg-blue-600 transition"
                    >
                        Read our docs <ExternalLink size={18} />
                    </a>
                </div>
            </div>

            {/* What's Next Section */}
            <div className="border border-gray-200 dark:border-gray-800 rounded-xl p-6 shadow-sm bg-blue-50 dark:bg-blue-900/20">
                <h3 className="font-bold text-2xl mb-4">What's next?</h3>

                <div className="grid md:grid-cols-3 gap-4">
                    <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow-sm">
                        <h4 className="font-semibold text-lg mb-2">
                            Explore use cases
                        </h4>
                        <p className="text-sm text-gray-600 dark:text-gray-400 mb-3">
                            Discover how others are using Expected Parrot for
                            research
                        </p>
                        <Link
                            to="/getting-started/edsl-use-cases"
                            className="text-blue-600 dark:text-blue-400 text-sm flex items-center"
                        >
                            See examples{' '}
                            <ChevronRight size={14} className="ml-1" />
                        </Link>
                    </div>

                    <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow-sm">
                        <h4 className="font-semibold text-lg mb-2">
                            Join the community
                        </h4>
                        <p className="text-sm text-gray-600 dark:text-gray-400 mb-3">
                            Connect with other researchers using Expected Parrot
                        </p>
                        <a
                            href="https://discord.com/invite/mxAYkjfy9m"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-blue-600 dark:text-blue-400 text-sm flex items-center"
                        >
                            Join our Discord{' '}
                            <ChevronRight size={14} className="ml-1" />
                        </a>
                    </div>

                    <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow-sm">
                        <h4 className="font-semibold text-lg mb-2">
                            Advanced features
                        </h4>
                        <p className="text-sm text-gray-600 dark:text-gray-400 mb-3">
                            Learn about custom agents, no-code tools, hybrid
                            human/AI surveys & more
                        </p>
                        <a
                            href="https://docs.expectedparrot.com/en/latest/"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-blue-600 dark:text-blue-400 text-sm flex items-center"
                        >
                            Read our docs{' '}
                            <ChevronRight size={14} className="ml-1" />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GettingStartedIndex;
