import { formatDate } from '../../../../utils';
import Pagination from '../../../base/Pagination';
import { CacheLog } from './types';

interface CacheLogTableProps {
    cacheLogs: CacheLog[];
    cacheLogsCount: number;
    currentPage: number;
    totalPages: number;
    pageSize: number;
    showOwnerEmail: boolean;
}

function CacheLogTable({
    cacheLogs,
    cacheLogsCount,
    currentPage,
    totalPages,
    pageSize,
    showOwnerEmail,
}: CacheLogTableProps) {
    const startIndex = (currentPage - 1) * pageSize + 1;
    const endIndex = Math.min(startIndex + pageSize - 1, cacheLogsCount);

    return (
        <div className="space-y-4">
            <h2 className="font-bold text-2xl">History</h2>
            {cacheLogsCount === 0 ? (
                <p>Showing 0 logs</p>
            ) : (
                <p>
                    Showing {startIndex}-{endIndex} of {cacheLogsCount} logs
                </p>
            )}
            <Pagination
                style="grayscale"
                currentPage={currentPage}
                totalPages={totalPages}
            />
            <div className="mb-8 overflow-x-auto">
                <div className="relative">
                    <table className="min-w-full text-sm relative">
                        <thead className="bg-white dark:bg-primary-dark-bg sticky border-b dark:border-gray-100/20 top-0">
                            <tr>
                                <th className="py-2 px-4 text-left w-1/5 font-semibold">
                                    Date
                                </th>
                                {showOwnerEmail && (
                                    <th className="py-2 px-4 text-left w-1/5 font-semibold">
                                        Owner
                                    </th>
                                )}
                                <th className="py-2 px-4 text-left w-2/5 font-semibold">
                                    Description
                                </th>
                                <th className="py-2 px-4 text-center w-1/5 font-semibold">
                                    Number of Cache Entries
                                </th>
                            </tr>
                        </thead>
                        <tbody className="align-top">
                            {cacheLogs.map((log, index) => (
                                <tr
                                    key={index}
                                    className={`border-y dark:border-gray-100/20 dark:text-secondary-dark-text ${
                                        index % 2 === 0
                                            ? 'bg-white dark:bg-gray-800'
                                            : 'bg-gray-50 dark:bg-gray-700'
                                    }`}
                                >
                                    <td className="py-2 px-4">
                                        {formatDate(
                                            log.created_ts,
                                            'MMMM D, YYYY'
                                        )}
                                    </td>
                                    {showOwnerEmail && (
                                        <td className="py-2 px-4">
                                            {log.owner_email}
                                        </td>
                                    )}
                                    <td className="py-2 px-4">
                                        {log.description ||
                                            'No description provided'}
                                    </td>
                                    <td className="py-2 px-4 text-center">
                                        {log.cache_entry_count}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default CacheLogTable;
