import { useState, useEffect, useRef } from 'react';
import { Link, useFetcher, useOutletContext } from 'react-router-dom';
import {
    ArrowDown,
    Award,
    BarChart,
    Bot,
    CheckCircle,
    ChevronLeft,
    ChevronRight,
    Copy,
    Send,
    ChevronDown,
    Share2,
    Star,
    Tag,
    Users,
} from 'lucide-react';
import LogosGrid from '../base/LogosGrid';
import HeroLogosGrid from './HeroLogosGrid';
import { useCopyToClipboard } from '../../hooks/useCopyToClipboard';

function EmailSignupForm() {
    const [email, setEmail] = useState<string>('');
    const [submissionSuccessful, setSubmissionSuccessful] =
        useState<boolean>(false);

    const fetcher = useFetcher();

    useEffect(() => {
        if (fetcher.data?.success) {
            setSubmissionSuccessful(true);
            const timer = setTimeout(() => {
                setEmail('');
                setSubmissionSuccessful(false);
            }, 3000);

            return () => clearTimeout(timer);
        }
    }, [fetcher.data]);

    return (
        <div className="mt-32 mb-24">
            <div className="bg-green-50 dark:bg-gray-800 rounded-lg p-8 shadow-md">
                <div className="flex flex-col items-center justify-center">
                    <h3 className="text-2xl sm:text-3xl font-bold text-green-700 dark:text-green-500 mb-4 text-center">
                        Stay up to date with our research tools
                    </h3>
                    <p className="text-lg text-gray-600 dark:text-gray-300 mb-6 text-center max-w-3xl">
                        Sign up for updates about new features, models,
                        tutorials and research opportunities.
                    </p>
                    <fetcher.Form
                        method="post"
                        className="w-full max-w-2xl flex flex-col sm:flex-row gap-4"
                    >
                        <input
                            type="email"
                            placeholder="Enter your email address"
                            className="flex-grow py-3 px-4 rounded-lg border border-gray-300 dark:border-gray-700 dark:bg-gray-700 dark:text-white focus:outline-none focus:ring-2 focus:ring-green-500"
                            name="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                        <button
                            type="submit"
                            name="intent"
                            value="subscribe"
                            className="bg-green-700 hover:bg-green-800 text-white font-semibold py-3 px-6 rounded-lg flex items-center justify-center gap-2 transition-colors"
                        >
                            <span>Subscribe</span>
                            <Send className="h-5 w-5" />
                        </button>
                    </fetcher.Form>
                    {submissionSuccessful && (
                        <p className="mt-4 text-green-600 dark:text-green-400 font-medium">
                            Thank you! You've been subscribed to our newsletter.
                        </p>
                    )}
                </div>
            </div>
        </div>
    );
}

function TestimonialsCarousel() {
    const [currentQuote, setCurrentQuote] = useState<number>(0);
    const [canScroll, setCanScroll] = useState<boolean>(false);
    const quoteContainerRef = useRef<HTMLDivElement>(null);
    const isDarkMode = useOutletContext();

    const prevQuote = () => {
        setCurrentQuote(
            (prev) => (prev - 1 + testimonials.length) % testimonials.length
        );
    };

    const nextQuote = () => {
        setCurrentQuote((prev) => (prev + 1) % testimonials.length);
    };

    // Check if quote is scrollable after render and whenever quote changes
    useEffect(() => {
        if (quoteContainerRef.current) {
            const container = quoteContainerRef.current;
            // Check if the content height is greater than the container height
            setCanScroll(container.scrollHeight > container.clientHeight);
        }
    }, [currentQuote]);

    // Auto-rotate quotes every 8 seconds
    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentQuote((prev) => (prev + 1) % testimonials.length);
        }, 8000);

        return () => clearInterval(interval);
    }, []);

    // Testimonial quotes
    const testimonials = [
        {
            quote: 'Expected Parrot has been invaluable for our exploration of silicon sampling approaches in experimental research. The cached results feature saves us significant time and computing costs, and ensures perfect reproducibility across experimental iterations. My team can easily build upon previous experiments without redundant work, allowing us to focus on interpreting results rather than managing computational overhead.',
            author: 'Thomas Graeber',
            title: 'Assistant Professor, Harvard Business School',
        },
        {
            quote: "I build agents that explore knowledge and information spaces, demonstrating how generative AI can augment humans to do this better in a variety of fields (finance, pharmaceuticals, education, market research).  Expected Parrot's libraries allow me to not only conjure agents from existing data, but also interact with a wide variety of variants to optimize for characteristics that are best able to explore spaces. The libraries are easy to use and incredibly robust to areas that aren't directly survey/social science research-oriented.",
            author: 'Marisa Boston',
            title: 'CEO, simthetic.ai',
        },
        {
            quote: "While working on my research, I came across the edsl Python package, and it has been a game-changer. It saved me a tremendous amount of time in running my study, streamlining processes that would have otherwise been much more complex. What impressed me the most, though, is the ethos behind Expected Parrot—-both the edsl library and the broader product are built in close collaboration with the community. Every voice is heard, and innovative ideas are genuinely welcomed. I'll definitely be using edsl for my future studies!",
            author: 'Julian Ustiyanovych',
            title: 'Senior Engineer, Anaconda',
        },
        {
            quote: "Expected Parrot is a game-changer for AI-driven research. It's Python-friendly, makes it easy to set up surveys and experiments with AI agents (and more), and allows you to tap into multiple LLMs at once. It's also open-source, adaptable, and a must-have for anyone looking to streamline their workflow.",
            author: 'Sophia Kazinnik',
            title: 'Researcher, Stanford University',
        },
        {
            quote: 'I was new to this field of study and previously I had only experience working with LLMs through API calls. While I was struggling to construct experiments with AI Agents, my PI suggested I check out EDSL. After I started incorporating it, my workflows became so much smoother and I started saving up my cognitive abilities for the actual experiment instead of worrying about the guardrails and the boilerplates. The best part about EDSL over any other tool personally is their support through Discord. I was able to communicate my requirements and get future updates!',
            author: 'Arul Murugan',
            title: 'Researcher, UC Berkeley',
        },
        {
            quote: "Advances in AI models have outstripped advances in infrastructure for using AI models. EDSL is S-tier AI infrastructure that allows you to interact with models at higher scale and scope than you could with other tools. It's a one-way door: once you get used to it you'll find yourself reaching for it all the time and the old approaches will feel clunky and painful.",
            author: '',
            title: 'Researcher',
        },
    ];

    // Animation style for the scroll indicator
    const pulseAnimation = {
        animation: 'pulseAnimation 1.5s infinite ease-in-out',
    };

    // Define the keyframes animation in a style tag that will be added to the component
    const keyframesStyle = `
        @keyframes pulseAnimation {
            0% { transform: translateY(0); }
            50% { transform: translateY(3px); }
            100% { transform: translateY(0); }
        }
    `;

    // Custom scrollbar styles (inline)
    const scrollbarStyle = {
        scrollbarWidth: 'thin',
        scrollbarColor: '#ccc #f1f1f1',
    };

    // Dark mode scrollbar styles
    const darkScrollbarStyle = {
        scrollbarWidth: 'thin',
        scrollbarColor: '#555 #333',
    };

    return (
        <>
            {/* Add the keyframes animation */}
            <style>{keyframesStyle}</style>

            <div className="bg-white dark:bg-gray-800 rounded-xl p-6 shadow-md border border-gray-100 dark:border-gray-700">
                {/* Stars */}
                <div className="flex items-center gap-2 mb-3">
                    {[...Array(5)].map((_, i) => (
                        <Star
                            key={i}
                            className="h-5 w-5 fill-yellow-400 text-yellow-400"
                        />
                    ))}
                </div>
                {/* Quote Content */}
                <div className="min-h-[220px] relative">
                    {testimonials.map((testimonial, index) => (
                        <div
                            key={index}
                            className={`absolute top-0 left-0 w-full transition-opacity duration-500 ${
                                index === currentQuote
                                    ? 'opacity-100 z-10'
                                    : 'opacity-0 z-0'
                            }`}
                        >
                            {/* Quote container */}
                            <div className="relative mb-4">
                                <div
                                    ref={quoteContainerRef}
                                    className="max-h-36 overflow-y-auto pr-2"
                                    style={
                                        isDarkMode
                                            ? darkScrollbarStyle
                                            : scrollbarStyle
                                    }
                                >
                                    <p className="text-gray-700 dark:text-gray-300 text-lg italic">
                                        "{testimonial.quote}"
                                        {/* Add padding space for gradient fade */}
                                        {canScroll && (
                                            <span className="block h-12"></span>
                                        )}
                                    </p>
                                </div>

                                {/* Fade effect with scroll indicator */}
                                {canScroll && (
                                    <div
                                        className="absolute bottom-0 left-0 right-0 h-12 pointer-events-none flex justify-center items-end pb-1"
                                        style={{
                                            background: isDarkMode
                                                ? 'linear-gradient(to top, rgba(31, 41, 55, 1) 0%, rgba(31, 41, 55, 0) 100%)'
                                                : 'linear-gradient(to top, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%)',
                                        }}
                                    ></div>
                                )}
                            </div>

                            {/* Author and controls section */}
                            <div className="border-t border-gray-100 dark:border-gray-700 pt-4 mt-2">
                                <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center w-full">
                                    <div className="mb-2 sm:mb-0 flex-grow min-w-0">
                                        <p className="font-medium text-gray-900 dark:text-white truncate">
                                            {testimonial.author}
                                        </p>
                                        <p className="text-sm text-gray-500 dark:text-gray-400 truncate max-w-xs">
                                            {testimonial.title}
                                        </p>
                                    </div>
                                    <div className="flex gap-1 flex-shrink-0">
                                        <button
                                            onClick={prevQuote}
                                            className="bg-gray-100 dark:bg-gray-700 rounded-full p-1 hover:bg-gray-200 dark:hover:bg-gray-600 transition-colors"
                                            aria-label="Previous testimonial"
                                        >
                                            <ChevronLeft className="h-4 w-4 text-gray-600 dark:text-gray-300" />
                                        </button>
                                        <button
                                            onClick={nextQuote}
                                            className="bg-gray-100 dark:bg-gray-700 rounded-full p-1 hover:bg-gray-200 dark:hover:bg-gray-600 transition-colors"
                                            aria-label="Next testimonial"
                                        >
                                            <ChevronRight className="h-4 w-4 text-gray-600 dark:text-gray-300" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
}

function AppIndex() {
    const isDarkMode = useOutletContext();
    const [scrolled, setScrolled] = useState(false);

    const [pipCopied, copyPip] = useCopyToClipboard(2000);
    const [codeCopied, copyCode] = useCopyToClipboard(2000);

    const videoURL1 = new URL(
        '../../../public/assets/get_started_smaller.mp4',
        import.meta.url
    ).pathname;

    const videoURL2 = new URL(
        '../../../public/assets/docs_scroll.mp4',
        import.meta.url
    ).pathname;

    const videoURL3 = new URL(
        '../../../public/assets/models_page.mp4',
        import.meta.url
    ).pathname;

    const videoURL4 = new URL(
        '../../../public/assets/docs_scroll.mp4',
        import.meta.url
    ).pathname;

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            if (scrollPosition > 100) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const scrollToLogosGrid = () => {
        const logosSection = document.getElementById('logos-grid-section');
        if (logosSection) {
            logosSection.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <div className="relative w-full overflow-auto">
            {/* Back to top button */}
            <div
                className={`fixed bottom-8 right-8 z-50 transition-opacity duration-300 ${
                    scrolled ? 'opacity-100' : 'opacity-0'
                }`}
            >
                <button
                    onClick={() =>
                        window.scrollTo({ top: 0, behavior: 'smooth' })
                    }
                    className="bg-green-700 hover:bg-green-800 text-white rounded-full p-3 shadow-lg"
                    aria-label="Back to top"
                >
                    <ChevronDown className="h-6 w-6 transform rotate-180" />
                </button>
            </div>

            {/* Hero Section with Background Pattern */}
            <div className="relative overflow-hidden">
                <div className="max-w-7xl mx-auto px-4 sm:px-10 pt-28 pb-16">
                    <div className="flex flex-col md:flex-row gap-10 items-center">
                        {/* Left Side Content */}
                        <div className="md:w-3/5">
                            <h1 className="text-4xl sm:text-6xl font-bold text-gray-900 dark:text-white leading-tight">
                                <span className="text-green-700 dark:text-green-500">
                                    AI-powered
                                </span>{' '}
                                research made easy
                            </h1>

                            <div className="mt-8 space-y-6">
                                {/* Benefits as bullet points */}
                                <div className="flex items-start gap-3">
                                    <CheckCircle className="h-6 w-6 text-green-700 dark:text-green-500 flex-shrink-0 mt-1" />
                                    <p className="text-lg text-gray-700 dark:text-gray-300">
                                        Design and launch experiments with many
                                        AI agents and models at once
                                    </p>
                                </div>
                                <div className="flex items-start gap-3">
                                    <CheckCircle className="h-6 w-6 text-green-700 dark:text-green-500 flex-shrink-0 mt-1" />
                                    <p className="text-lg text-gray-700 dark:text-gray-300">
                                        Collaborate seamlessly at our integrated
                                        research-focused platform
                                    </p>
                                </div>
                                <div className="flex items-start gap-3">
                                    <CheckCircle className="h-6 w-6 text-green-700 dark:text-green-500 flex-shrink-0 mt-1" />
                                    <p className="text-lg text-gray-700 dark:text-gray-300">
                                        Replicate verified results at no cost
                                    </p>
                                </div>
                            </div>

                            {/* Credit Badge */}
                            <div className="mt-8 inline-flex items-center bg-orange-100 dark:bg-orange-900/30 border border-orange-200 dark:border-orange-700/30 text-orange-800 dark:text-orange-300 px-4 py-2 rounded-lg">
                                <Award className="h-5 w-5 mr-2" />
                                <span className="font-medium">
                                    New users get $25 in API credits
                                </span>
                            </div>

                            {/* CTA Buttons */}
                            <div className="mt-8 flex flex-col sm:flex-row gap-4 sm:gap-6">
                                <Link
                                    to="/login"
                                    className="flex items-center justify-center bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 dark:focus:ring-green-800 border-2 border-green-700 hover:border-green-800 rounded-lg px-8 py-3 text-lg text-white font-semibold whitespace-nowrap transition-all"
                                >
                                    Create account
                                </Link>
                                <Link
                                    to="/getting-started"
                                    className="flex items-center justify-center border-2 border-green-700 hover:border-green-800 rounded-lg px-8 py-3 text-lg text-green-700 hover:text-green-800 dark:text-green-500 dark:hover:text-green-400 font-semibold whitespace-nowrap transition-all"
                                >
                                    Learn more
                                </Link>
                            </div>
                        </div>

                        {/* Right Side: Quotes Carousel and Logos */}
                        <div className="md:w-2/5 space-y-8">
                            {/* Testimonials Carousel */}
                            <TestimonialsCarousel />
                            {/* Partner Logos */}
                            <div className="bg-white dark:bg-gray-800 rounded-xl p-6 shadow-md border border-gray-100 dark:border-gray-700">
                                <div className="flex justify-between items-center mb-4">
                                    <div className="inline-block bg-green-100 dark:bg-green-900/30 text-green-800 dark:text-green-300 font-medium px-4 py-1 rounded-full text-sm">
                                        <span>
                                            Accelerating research at top
                                            institutions
                                        </span>
                                    </div>
                                    <button
                                        onClick={scrollToLogosGrid}
                                        className="flex items-center gap-1 text-sm text-green-700 dark:text-green-500 hover:underline"
                                    >
                                        <span>See all</span>
                                        <ArrowDown className="h-3 w-3" />
                                    </button>
                                </div>
                                <div className="h-54 overflow-hidden">
                                    {/* Using HeroLogosGrid component */}
                                    <HeroLogosGrid />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="max-w-7xl mx-auto px-4 sm:px-10 pt-16">
                {/* <div className="sm:ml-20"> */}
                {/* First Section - Getting Started */}
                <div id="getting-started" className="mt-32 relative">
                    <div className="absolute -left-8 h-full w-1 bg-gradient-to-b from-orange-500 to-orange-600 rounded-full opacity-50"></div>
                    <p className="text-2xl sm:text-4xl font-bold text-orange-600 dark:text-orange-400 mb-8">
                        {' '}
                        Start conducting research in minutes.
                    </p>
                    {/* Instruction cards with consistent styling */}
                    <div className="space-y-12">
                        {/* First row - Install package */}
                        <div className="bg-white dark:bg-gray-800 rounded-xl p-6 shadow-md border border-gray-100 dark:border-gray-700">
                            <div className="flex flex-col sm:flex-row sm:items-center sm:gap-12">
                                <div className="flex-1 sm:text-left">
                                    <div className="flex items-center gap-2 mb-4">
                                        <span className="flex items-center justify-center w-8 h-8 rounded-full bg-orange-100 dark:bg-orange-900/30 text-orange-600 dark:text-orange-400 font-bold">
                                            1
                                        </span>
                                        <h3 className="text-xl font-semibold text-gray-800 dark:text-gray-200">
                                            Install the package
                                        </h3>
                                    </div>
                                    <p className="text-base sm:text-lg text-gray-600 dark:text-gray-300 mt-2">
                                        Open your terminal and run the following
                                        command to{' '}
                                        <Link
                                            to="/getting-started/edsl-installation"
                                            className="underline text-orange-600 dark:text-orange-400 hover:text-orange-700"
                                        >
                                            install
                                        </Link>{' '}
                                        the package:
                                    </p>
                                </div>
                                <div className="mt-8 sm:mt-0 sm:flex-1">
                                    <div
                                        className="bg-gray-100 dark:bg-gray-700 p-4 rounded-lg relative cursor-pointer group transition-all hover:bg-gray-200 dark:hover:bg-gray-600"
                                        onClick={() =>
                                            copyPip('pip install edsl').catch(
                                                (error) => {
                                                    console.error(
                                                        'Failed to copy!',
                                                        error
                                                    );
                                                }
                                            )
                                        }
                                        title="Click to copy"
                                    >
                                        <code className="bg-white dark:bg-gray-800 p-3 rounded-lg block text-sm font-mono">
                                            pip install edsl
                                        </code>
                                        <div className="absolute top-3 right-3">
                                            {pipCopied ? (
                                                <span className="px-2 py-1 bg-green-100 dark:bg-green-800 text-green-700 dark:text-green-300 text-xs font-medium rounded-md animate-fade-in">
                                                    Copied!
                                                </span>
                                            ) : (
                                                <span className="text-gray-400 dark:text-gray-500 group-hover:text-gray-600 dark:group-hover:text-gray-300 transition-colors">
                                                    <Copy className="h-5 w-5" />
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Second row - Run code in notebook */}
                        <div className="bg-white dark:bg-gray-800 rounded-xl p-6 shadow-md border border-gray-100 dark:border-gray-700">
                            <div className="flex flex-col sm:flex-row sm:items-center sm:gap-12">
                                <div className="flex-1 sm:text-left">
                                    <div className="flex items-center gap-2 mb-4">
                                        <span className="flex items-center justify-center w-8 h-8 rounded-full bg-orange-100 dark:bg-orange-900/30 text-orange-600 dark:text-orange-400 font-bold">
                                            2
                                        </span>
                                        <h3 className="text-xl font-semibold text-gray-800 dark:text-gray-200">
                                            Run example code
                                        </h3>
                                    </div>
                                    <p className="text-base sm:text-lg text-gray-600 dark:text-gray-300 mt-2">
                                        Download and run the{' '}
                                        <a
                                            href="https://docs.expectedparrot.com/en/latest/starter_tutorial.html"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-orange-600 dark:text-orange-400 underline hover:text-orange-700"
                                        >
                                            starter tutorial
                                        </a>{' '}
                                        or any example code in a notebook:
                                    </p>
                                </div>
                                <div className="mt-8 sm:mt-0 sm:flex-1">
                                    <div
                                        className="bg-gray-100 dark:bg-gray-700 p-4 rounded-lg relative cursor-pointer group transition-all hover:bg-gray-200 dark:hover:bg-gray-600"
                                        onClick={() =>
                                            copyCode(
                                                `from edsl import QuestionMultipleChoice
q = QuestionMultipleChoice(
    question_name = "example",
    question_text = "What is the capital of France?",
    question_options = ["London", "Paris", "Boston"]
)

results = q.run()`
                                            ).catch((error) => {
                                                console.error(
                                                    'Failed to copy!',
                                                    error
                                                );
                                            })
                                        }
                                        title="Click to copy"
                                    >
                                        <code className="p-3 rounded-lg block text text-sm font-mono">
                                            <span className="text-green-700 dark:text-green-400">
                                                from
                                            </span>{' '}
                                            edsl{' '}
                                            <span className="text-green-700 dark:text-green-400">
                                                import
                                            </span>{' '}
                                            QuestionMultipleChoice
                                            <br></br>
                                            <br></br>q = QuestionMultipleChoice
                                            (<br></br>
                                            &nbsp;&nbsp;&nbsp;&nbsp;question_name
                                            ={' '}
                                            <span className="text-red-700 dark:text-red-400">
                                                "example"
                                            </span>
                                            ,<br></br>
                                            &nbsp;&nbsp;&nbsp;&nbsp;question_text
                                            ={' '}
                                            <span className="text-red-700 dark:text-red-400">
                                                "What is the capital of France?"
                                            </span>
                                            ,<br></br>
                                            &nbsp;&nbsp;&nbsp;&nbsp;question_options
                                            = [
                                            <span className="text-red-700 dark:text-red-400">
                                                "London"
                                            </span>
                                            ,{' '}
                                            <span className="text-red-700 dark:text-red-400">
                                                "Paris"
                                            </span>
                                            ,{' '}
                                            <span className="text-red-700 dark:text-red-400">
                                                "Boston"
                                            </span>
                                            ]<br></br>)<br></br>
                                            <br></br>
                                            results = q.
                                            <span className="text-blue-600 dark:text-blue-400">
                                                run
                                            </span>
                                            ()
                                        </code>
                                        <div className="absolute top-3 right-3">
                                            {codeCopied ? (
                                                <span className="px-2 py-1 bg-green-100 dark:bg-green-800 text-green-700 dark:text-green-300 text-xs font-medium rounded-md animate-fade-in">
                                                    Copied!
                                                </span>
                                            ) : (
                                                <span className="text-gray-400 dark:text-gray-500 group-hover:text-gray-600 dark:group-hover:text-gray-300 transition-colors">
                                                    <Copy className="h-5 w-5" />
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-col sm:flex-row sm:items-center sm:gap-12">
                                <div className="flex-1 sm:text-left mt-4">
                                    <p className="text-base sm:text-lg text-gray-600 dark:text-gray-300 mt-2">
                                        You can also use our free GPT to{' '}
                                        <a
                                            href="https://chatgpt.com/g/g-67d17648ea5481919004bfc0bb1c2a8e-expectedparrot"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="underline text-orange-600 dark:text-orange-400 hover:text-orange-700"
                                        >
                                            generate EDSL code →
                                        </a>
                                    </p>
                                </div>
                                <div className="mt-8 sm:mt-8 sm:flex-1">
                                    <a
                                        href="https://chatgpt.com/g/g-67d17648ea5481919004bfc0bb1c2a8e-expectedparrot"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="block overflow-hidden rounded-lg shadow-lg hover:shadow-xl transition-shadow"
                                    >
                                        <img
                                            src={
                                                new URL(
                                                    '../../../public/assets/edsl_gpt.png',
                                                    import.meta.url
                                                ).pathname
                                            }
                                            alt="GPT for generating EDSL code"
                                            className="w-full max-h-64 object-contain rounded-lg transform hover:scale-[1.02] transition-transform"
                                        />
                                    </a>
                                </div>
                            </div>
                        </div>

                        {/* Third row - See results */}
                        <div className="bg-white dark:bg-gray-800 rounded-xl p-6 shadow-md border border-gray-100 dark:border-gray-700">
                            <div className="flex flex-col sm:flex-row sm:items-center sm:gap-12">
                                <div className="flex-1 sm:text-left">
                                    <div className="flex items-center gap-2 mb-4">
                                        <span className="flex items-center justify-center w-8 h-8 rounded-full bg-orange-100 dark:bg-orange-900/30 text-orange-600 dark:text-orange-400 font-bold">
                                            3
                                        </span>
                                        <h3 className="text-xl font-semibold text-gray-800 dark:text-gray-200">
                                            View results
                                        </h3>
                                    </div>
                                    <p className="text-base sm:text-lg text-gray-600 dark:text-gray-300 mt-2">
                                        Results will automatically appear in
                                        your account, where you can analyze and
                                        share them.{' '}
                                        <a
                                            href="https://www.expectedparrot.com/content/46593595-8f16-4e9d-aa5e-6da519a98a10"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="underline text-orange-600 dark:text-orange-400 hover:text-orange-700"
                                        >
                                            View example results →
                                        </a>
                                    </p>
                                </div>
                                <div className="mt-8 sm:mt-0 sm:flex-1">
                                    <a
                                        href="https://www.expectedparrot.com/content/46593595-8f16-4e9d-aa5e-6da519a98a10"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="block overflow-hidden rounded-lg shadow-lg hover:shadow-xl transition-shadow"
                                    >
                                        <img
                                            src={
                                                new URL(
                                                    '../../../public/assets/example_question.png',
                                                    import.meta.url
                                                ).pathname
                                            }
                                            alt="Example results dashboard"
                                            className="w-full max-h-64 object-contain rounded-lg transform hover:scale-[1.02] transition-transform"
                                        />
                                    </a>
                                </div>
                            </div>
                        </div>

                        {/* Fourth row - watch a video */}
                        <div className="bg-white dark:bg-gray-800 rounded-xl p-6 shadow-md border border-gray-100 dark:border-gray-700">
                            <div className="flex flex-col sm:flex-row sm:items-center sm:gap-12">
                                <div className="flex-1 sm:text-left">
                                    <div className="flex items-center gap-2 mb-4">
                                        <span className="flex items-center justify-center w-8 h-8 rounded-full bg-orange-100 dark:bg-orange-900/30 text-orange-600 dark:text-orange-400 font-bold">
                                            4
                                        </span>
                                        <h3 className="text-xl font-semibold text-gray-800 dark:text-gray-200">
                                            See it in action
                                        </h3>
                                    </div>
                                    <p className="text-base sm:text-lg text-gray-600 dark:text-gray-300 mt-2">
                                        Watch this quick tutorial video that
                                        demonstrates all the steps above:
                                    </p>
                                </div>
                                <div className="mt-8 sm:mt-0 sm:flex-1">
                                    <div className="relative rounded-lg overflow-hidden shadow-lg">
                                        <video
                                            controls
                                            className="w-full max-h-64 object-contain"
                                        >
                                            <source
                                                src={videoURL1}
                                                type="video/mp4"
                                            />
                                            Your browser does not support the
                                            video tag.
                                        </video>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Second Video Section - Reproduce Results */}
                <div id="reproduce-results" className="mt-32 relative">
                    <div className="absolute -left-8 h-full w-1 bg-gradient-to-b from-purple-500 to-purple-600 rounded-full opacity-50"></div>

                    <div className="bg-white dark:bg-gray-800 rounded-xl p-8 shadow-md border border-gray-100 dark:border-gray-700">
                        <div className="flex flex-col sm:flex-row sm:items-center sm:gap-12">
                            <div className="flex-1 sm:text-left">
                                <h2 className="text-2xl sm:text-4xl font-bold text-purple-600 dark:text-purple-400 mb-4">
                                    Replicate results at no cost
                                </h2>
                                <p className="text-base sm:text-lg text-gray-600 dark:text-gray-300 mt-4">
                                    LLM responses are stored automatically,
                                    making it easy for anyone to re-run your
                                    experiments and share results at no cost.{' '}
                                    <Link
                                        to="/getting-started/coop-remote-cache"
                                        className="underline text-purple-600 dark:text-purple-400 hover:text-purple-700"
                                    >
                                        Learn more about our caching system →
                                    </Link>
                                </p>

                                <div className="mt-6 flex flex-wrap gap-4">
                                    <div className="flex items-center gap-2 bg-purple-50 dark:bg-purple-900/20 px-3 py-2 rounded-lg">
                                        <CheckCircle className="h-5 w-5 text-purple-600 dark:text-purple-400" />
                                        <span className="text-gray-700 dark:text-gray-300">
                                            Automatic caching
                                        </span>
                                    </div>
                                    <div className="flex items-center gap-2 bg-purple-50 dark:bg-purple-900/20 px-3 py-2 rounded-lg">
                                        <CheckCircle className="h-5 w-5 text-purple-600 dark:text-purple-400" />
                                        <span className="text-gray-700 dark:text-gray-300">
                                            Share results easily
                                        </span>
                                    </div>
                                    <div className="flex items-center gap-2 bg-purple-50 dark:bg-purple-900/20 px-3 py-2 rounded-lg">
                                        <CheckCircle className="h-5 w-5 text-purple-600 dark:text-purple-400" />
                                        <span className="text-gray-700 dark:text-gray-300">
                                            Reduce API costs
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-8 sm:mt-0 sm:flex-1">
                                <img
                                    src={
                                        new URL(
                                            '../../../public/assets/getting-started/coop-my-content.png',
                                            import.meta.url
                                        ).pathname
                                    }
                                    alt="Content sharing interface"
                                    className="w-full rounded-lg shadow-lg hover:shadow-xl transition-shadow"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Third Video Section - Access Models */}
                <div id="access-models" className="mt-32 relative">
                    <div className="absolute -left-8 h-full w-1 bg-gradient-to-b from-pink-500 to-pink-600 rounded-full opacity-50"></div>

                    <div className="bg-white dark:bg-gray-800 rounded-xl p-8 shadow-md border border-gray-100 dark:border-gray-700">
                        <div className="flex flex-col sm:flex-row sm:items-center sm:gap-12">
                            <div className="flex-1 sm:text-left">
                                <h2 className="text-2xl sm:text-4xl font-bold text-pink-600 dark:text-pink-400 mb-4">
                                    Access hundreds of models at once
                                </h2>
                                <p className="text-base sm:text-lg text-gray-600 dark:text-gray-300 mt-4">
                                    Our tools work with{' '}
                                    <Link
                                        to="/models"
                                        className="underline text-pink-600 dark:text-pink-400 hover:text-pink-700"
                                    >
                                        many popular LLMs
                                    </Link>{' '}
                                    from Anthropic, Azure, Bedrock, DeepInfra,
                                    Deepseek, Google, Mistral, OpenAI,
                                    Perplexity, Together, Xai and other service
                                    providers.
                                </p>

                                <p className="text-base sm:text-lg text-gray-600 dark:text-gray-300 mt-4">
                                    Use your{' '}
                                    <span className="font-bold">
                                        Expected Parrot API key
                                    </span>{' '}
                                    to access all of them at once and easily
                                    compare responses.
                                </p>
                            </div>
                            <div className="mt-8 sm:mt-0 sm:flex-1">
                                <div className="relative rounded-lg overflow-hidden shadow-lg">
                                    <video
                                        controls
                                        className="w-full max-h-64 object-contain"
                                    >
                                        <source
                                            src={videoURL3}
                                            type="video/mp4"
                                        />
                                        Your browser does not support the video
                                        tag.
                                    </video>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Fourth Video Section - Tutorials */}
                <div id="tutorials" className="mt-32 relative">
                    <div className="absolute -left-8 h-full w-1 bg-gradient-to-b from-blue-500 to-blue-600 rounded-full opacity-50"></div>

                    <div className="bg-white dark:bg-gray-800 rounded-xl p-8 shadow-md border border-gray-100 dark:border-gray-700">
                        <div className="flex flex-col sm:flex-row sm:items-center sm:gap-12">
                            <div className="flex-1 sm:text-left">
                                <h2 className="text-2xl sm:text-4xl font-bold text-blue-600 dark:text-blue-400 mb-4">
                                    Explore tutorials and demos
                                </h2>
                                <p className="text-base sm:text-lg text-gray-600 dark:text-gray-300 mt-4">
                                    Check out our{' '}
                                    <a
                                        href="https://docs.expectedparrot.com/en/latest/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="underline text-blue-600 dark:text-blue-400 hover:text-blue-700"
                                    >
                                        documentation
                                    </a>{' '}
                                    for example code and reusable notebooks
                                    covering many use cases including:
                                </p>

                                <div className="mt-6 grid grid-cols-1 gap-4">
                                    <div className="flex items-center gap-3 p-3 bg-gray-50 dark:bg-gray-700/30 rounded-lg">
                                        <Tag className="h-5 w-5 text-blue-600 dark:text-blue-400 mt-0.5 flex-shrink-0" />
                                        <span className="text-gray-700 dark:text-gray-300">
                                            Data labeling and classification
                                        </span>
                                    </div>
                                    <div className="flex items-center gap-3 p-3 bg-gray-50 dark:bg-gray-700/30 rounded-lg">
                                        <Users className="h-5 w-5 text-blue-600 dark:text-blue-400 mt-0.5 flex-shrink-0" />
                                        <span className="text-gray-700 dark:text-gray-300">
                                            Multi-agent interactions and
                                            simulations
                                        </span>
                                    </div>
                                    <div className="flex items-center gap-3 p-3 bg-gray-50 dark:bg-gray-700/30 rounded-lg">
                                        <BarChart className="h-5 w-5 text-blue-600 dark:text-blue-400 mt-0.5 flex-shrink-0" />
                                        <span className="text-gray-700 dark:text-gray-300">
                                            Market research and sentiment
                                            analysis
                                        </span>
                                    </div>
                                    <div className="flex items-center gap-3 p-3 bg-gray-50 dark:bg-gray-700/30 rounded-lg">
                                        <Bot className="h-5 w-5 text-blue-600 dark:text-blue-400 mt-0.5 flex-shrink-0" />
                                        <span className="text-gray-700 dark:text-gray-300">
                                            Hybrid human/AI experimental designs
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-8 sm:mt-0 sm:flex-1">
                                <div className="relative rounded-lg overflow-hidden shadow-lg aspect-video">
                                    <video
                                        controls
                                        className="w-full h-full object-cover"
                                    >
                                        <source
                                            src={videoURL4}
                                            type="video/mp4"
                                        />
                                        Your browser does not support the video
                                        tag.
                                    </video>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Fifth Video Section - Community */}
                <div id="community" className="mt-32 relative">
                    <div className="absolute -left-8 h-full w-1 bg-gradient-to-b from-gray-500 to-gray-600 rounded-full opacity-50"></div>

                    <div className="bg-white dark:bg-gray-800 rounded-xl p-8 shadow-md border border-gray-100 dark:border-gray-700">
                        <div className="flex flex-col sm:flex-row sm:items-center sm:gap-12">
                            <div className="flex-1 sm:text-left">
                                <h2 className="text-2xl sm:text-4xl font-bold text-gray-700 dark:text-gray-300 mb-4">
                                    Join a community of researchers
                                </h2>
                                <p className="text-base sm:text-lg text-gray-600 dark:text-gray-300 mt-4">
                                    Connect with an active community of AI
                                    researchers on{' '}
                                    <a
                                        href="https://discord.com/invite/mxAYkjfy9m"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="underline text-gray-700 dark:text-gray-400 hover:text-gray-900"
                                    >
                                        Discord
                                    </a>
                                    , where you can:
                                </p>

                                <div className="mt-6 grid grid-cols-1 sm:grid-cols-2 gap-4">
                                    <div className="flex items-center gap-3 p-3 bg-gray-50 dark:bg-gray-700/30 rounded-lg">
                                        <Users className="h-5 w-5 text-gray-700 dark:text-gray-400" />
                                        <span className="text-gray-700 dark:text-gray-300">
                                            Connect with researchers
                                        </span>
                                    </div>
                                    <div className="flex items-center gap-3 p-3 bg-gray-50 dark:bg-gray-700/30 rounded-lg">
                                        <Share2 className="h-5 w-5 text-gray-700 dark:text-gray-400" />
                                        <span className="text-gray-700 dark:text-gray-300">
                                            Share your project findings
                                        </span>
                                    </div>
                                    <div className="flex items-center gap-3 p-3 bg-gray-50 dark:bg-gray-700/30 rounded-lg">
                                        <CheckCircle className="h-5 w-5 text-gray-700 dark:text-gray-400" />
                                        <span className="text-gray-700 dark:text-gray-300">
                                            Get implementation help
                                        </span>
                                    </div>
                                    <div className="flex items-center gap-3 p-3 bg-gray-50 dark:bg-gray-700/30 rounded-lg">
                                        <Award className="h-5 w-5 text-gray-700 dark:text-gray-400" />
                                        <span className="text-gray-700 dark:text-gray-300">
                                            Discover best practices
                                        </span>
                                    </div>
                                </div>

                                <div className="mt-6">
                                    <a
                                        href="https://discord.com/invite/mxAYkjfy9m"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="inline-flex items-center justify-center bg-gray-700 hover:bg-gray-800 focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-800 rounded-lg px-5 py-2.5 text-white font-medium whitespace-nowrap transition-all"
                                    >
                                        Join our Discord
                                    </a>
                                </div>
                            </div>
                            <div className="mt-8 sm:mt-0 sm:flex-1">
                                <div className="flex justify-center">
                                    <img
                                        src={
                                            new URL(
                                                '../../../public/assets/discord.png',
                                                import.meta.url
                                            ).pathname
                                        }
                                        alt="Discord Community Screenshot"
                                        className="w-1/2 rounded-lg shadow-lg"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Email Sign-up Section */}
                <EmailSignupForm />

                {/* ID for scrolling */}
                <div id="logos-grid-section">
                    <LogosGrid />
                </div>
            </div>

            <div className="max-w-7xl mx-auto px-4 sm:px-10 mt-32 mb-24">
                <div className="bg-green-50 dark:bg-gray-800 rounded-lg p-8 shadow-md">
                    <div className="flex flex-col items-center justify-center">
                        <h3 className="text-2xl sm:text-3xl font-bold text-green-700 dark:text-green-500 mb-4 text-center">
                            Create an account
                        </h3>
                        <p className="text-lg text-gray-600 dark:text-gray-300 mb-6 text-center max-w-3xl">
                            Your account comes with $25 in API credits.
                        </p>
                        <div className="w-full max-w-2xl flex justify-center">
                            <Link
                                to="/login"
                                className="bg-green-700 hover:bg-green-800 text-white font-semibold py-3 px-6 rounded-lg flex items-center justify-center gap-2 transition-colors"
                            >
                                <span>Create account</span>
                                <Send className="h-5 w-5" />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AppIndex;
