import LogosGrid from '../base/LogosGrid';

function About() {
    return (
        <div className="relative w-full overflow-auto">
            <div className="max-w-7xl mx-auto px-4 sm:px-10 pt-20">
                <div className="sm:ml-20">
                    <h1 className="text-3xl sm:text-5xl font-bold text-green-700 dark:text-primary-dark-text">
                        We're building tools for designing, conducting and
                        reproducing research with AI
                    </h1>
                    <p className="text-lg sm:text-xl text-gray-500 dark:text-primary-dark-text mt-8">
                        Our work draws on recent{' '}
                        <a
                            href="https://docs.expectedparrot.com/en/latest/papers.html"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-green-600 dark:text-primary-dark-text"
                        >
                            research
                        </a>{' '}
                        demonstrating that AI can be used to simulate surveys,
                        experiments and empirical studies. We believe that this
                        breakthrough has far-ranging applications for the social
                        sciences and market research. Our goal is to make
                        conducting AI-powered research easy.
                    </p>
                    <p className="text-lg sm:text-xl text-gray-500 dark:text-primary-dark-text mt-8">
                        We're a remote team backed by{' '}
                        <a
                            href="https://www.bloomberg.com/company/values/tech-at-bloomberg/bloomberg-beta/"
                            className="text-green-600 dark:text-primary-dark-text"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Bloomberg Beta
                        </a>
                        .
                    </p>
                    <p className="text-lg sm:text-xl text-gray-500 dark:text-primary-dark-text mt-8">
                        Interested in working with us? Send us a message on{' '}
                        <a
                            className="text-green-600 dark:text-primary-dark-text"
                            href="https://discord.com/invite/mxAYkjfy9m"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Discord
                        </a>{' '}
                        or email us at{' '}
                        <a
                            className="text-green-600 dark:text-primary-dark-text"
                            href="mailto:info@expectedparrot.com"
                        >
                            info@expectedparrot.com
                        </a>
                        .
                    </p>
                    <br></br>

                    <LogosGrid />

                    {/* Add more content to test scrolling */}
                    <div className="h-32 mt-8">
                        {/* Spacer to ensure we have enough content to scroll */}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default About;
