import * as Dialog from '@radix-ui/react-dialog';
import * as VisuallyHidden from '@radix-ui/react-visually-hidden';
import { useFetcher } from 'react-router-dom';
import { X } from 'lucide-react';
import ActionAlert from '../../../../../base/design-system/ActionAlert';
import UpdateSpendingLimitPopover from './UpdateSpendingLimitPopover';
import SharedKeyToggle from './SharedKeyToggle';
import UnshareKeyPopover from './UnshareKeyPopover';

const PRETTY_KEY_NAMES = {
    ANTHROPIC_API_KEY: 'Anthropic API key',
    AWS_SECRET_ACCESS_KEY: 'AWS Bedrock API key',
    AZURE_ENDPOINT_URL_AND_KEY: 'Azure API key',
    DEEP_INFRA_API_KEY: 'Deep Infra API key',
    DEEPSEEK_API_KEY: 'DeepSeek API key',
    GOOGLE_API_KEY: 'Google API key',
    GROQ_API_KEY: 'Groq API key',
    MISTRAL_API_KEY: 'Mistral API key',
    OPENAI_API_KEY: 'OpenAI API key',
    PERPLEXITY_API_KEY: 'Perplexity API key',
    TOGETHER_API_KEY: 'Together API key',
    EXPECTED_PARROT_API_KEY: 'Expected Parrot API key',
};

function ShareKeyForm({ keyId, isEpKey }: { keyId: string; isEpKey: boolean }) {
    const fetcher = useFetcher();

    return (
        <div>
            <fetcher.Form method="post">
                <div>
                    <label
                        htmlFor={`share-input-${keyId}`}
                        className="block mb-3 text-base font-medium text-gray-900 dark:text-white"
                    >
                        Username or email
                    </label>
                    <input
                        type="text"
                        name="username_or_email"
                        id={`share-input-${keyId}`}
                        className="block w-full p-2.5 mb-3 bg-gray-50 focus:outline-none border rounded-lg border-gray-400 focus:ring-1 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700/20 dark:border-gray-500 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:placeholder-gray-400"
                        placeholder="name@company.com"
                        required
                    />
                    <label
                        htmlFor={`spend-limit-${keyId}`}
                        className="block mb-3 text-base font-medium text-gray-900 dark:text-white"
                    >
                        Monthly spending limit (USD)
                    </label>
                    <input
                        type="number"
                        name="monthly_spend_limit_usd"
                        id={`spend-limit-${keyId}`}
                        min={0}
                        className="block w-full p-2.5 mb-3 bg-gray-50 focus:outline-none border rounded-lg border-gray-400 focus:ring-1 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700/20 dark:border-gray-500 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:placeholder-gray-400"
                        placeholder="100"
                        required
                    />
                    <input type="hidden" name="key_uuid" value={keyId} />
                    <input
                        type="hidden"
                        name="is_ep_key"
                        value={isEpKey.toString()}
                    />
                </div>
                <button
                    type="submit"
                    name="intent"
                    value="share_key"
                    className="w-full mb-3 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                    Share
                </button>
            </fetcher.Form>
            <ActionAlert response={fetcher.data} />
        </div>
    );
}

function getPrettyKeyName(keyName: string) {
    return PRETTY_KEY_NAMES[keyName] || keyName;
}

function ShareKeyModal({
    keyId,
    keyName,
    sharedWith,
    isEpKey,
    children,
}: {
    keyId: string;
    keyName: string;
    sharedWith: {
        id: string;
        username: string;
        monthly_spend_limit_usd: number;
        current_month_usage_usd: number;
        is_enabled_by_owner: boolean;
    }[];
    isEpKey: boolean;
    children: React.ReactNode;
}) {
    function formatPrice(usd: number) {
        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
        });
        return formatter.format(usd);
    }

    return (
        <Dialog.Root>
            <Dialog.Trigger asChild>{children}</Dialog.Trigger>
            <Dialog.Portal>
                <Dialog.Overlay className="fixed inset-0 bg-black/60 animate-dialog-overlay-show" />
                <Dialog.Content
                    aria-describedby={undefined}
                    className="w-[90vw] max-w-[550px] max-h-[85vh] overflow-y-auto fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 p-6 bg-white dark:bg-gray-700 rounded-md focus:outline-none animate-dialog-content-show"
                >
                    <Dialog.Title asChild>
                        <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                            Grant access to your {getPrettyKeyName(keyName)}
                        </h3>
                    </Dialog.Title>
                    <div className="text-sm py-4 md:py-5">
                        <ShareKeyForm keyId={keyId} isEpKey={isEpKey} />
                        <p className="block mt-4 mb-2 text-base font-medium text-gray-900 dark:text-white">
                            People with access
                        </p>
                        {sharedWith.length === 0 ? (
                            <p className="border-t border-gray-200 dark:border-gray-600 py-3">
                                You haven't granted anyone access to this key
                                yet!
                            </p>
                        ) : (
                            <ul>
                                {sharedWith.map((share) => (
                                    <li key={share.id}>
                                        <div className="flex justify-between border-t py-3 border-gray-200 dark:border-gray-600">
                                            <div>
                                                <div className="font-medium">
                                                    {share.username}
                                                </div>
                                                <div className="text-sm text-gray-500 dark:text-gray-400">
                                                    Monthly usage:{' '}
                                                    {formatPrice(
                                                        share.current_month_usage_usd
                                                    )}{' '}
                                                    /{' '}
                                                    {formatPrice(
                                                        share.monthly_spend_limit_usd
                                                    )}
                                                </div>
                                            </div>
                                            <div className="flex items-center gap-x-2">
                                                <SharedKeyToggle
                                                    shareId={share.id}
                                                    defaultValue={
                                                        share.is_enabled_by_owner
                                                    }
                                                    isEpKey={isEpKey}
                                                />
                                                <UpdateSpendingLimitPopover
                                                    shareId={share.id}
                                                    currentLimit={
                                                        share.monthly_spend_limit_usd
                                                    }
                                                    isEpKey={isEpKey}
                                                />
                                                <UnshareKeyPopover
                                                    shareId={share.id}
                                                    isEpKey={isEpKey}
                                                />
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>

                    <Dialog.Close asChild>
                        <button
                            className="absolute top-2.5 right-2.5 h-6 w-6 justify-center align-center rounded-full"
                            aria-label="Close"
                        >
                            <X className="inline w-4 h-4" strokeWidth="2" />
                        </button>
                    </Dialog.Close>
                </Dialog.Content>
            </Dialog.Portal>
        </Dialog.Root>
    );
}

export default ShareKeyModal;
