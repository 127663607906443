import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
    ArrowRight,
    ExternalLink,
    ChevronLeft,
    ChevronRight,
    Book,
    FileText,
    MessageCircle,
} from 'lucide-react';

const EDSLUseCases = () => {
    const linkStyles =
        'text-blue-500 hover:text-blue-600 hover:underline dark:text-primary-dark-text-accent dark:hover:text-primary-dark-text-accent';

    const dataLabelingImage = new URL(
        '../../../../../public/assets/edsl_data_labeling.png',
        import.meta.url
    ).pathname;

    const conceptInductionImage = new URL(
        '../../../../../public/assets/edsl_concept_induction.png',
        import.meta.url
    ).pathname;

    const modelsScoringImage = new URL(
        '../../../../../public/assets/models_scoring_models.png',
        import.meta.url
    ).pathname;

    const courseEvaluationsImage = new URL(
        '../../../../../public/assets/course_evaluations.png',
        import.meta.url
    ).pathname;

    const messyDatasetImage = new URL(
        '../../../../../public/assets/messy_dataset.png',
        import.meta.url
    ).pathname;

    const jobPostingsImage = new URL(
        '../../../../../public/assets/job_postings.png',
        import.meta.url
    ).pathname;

    const npsImage = new URL(
        '../../../../../public/assets/nps.png',
        import.meta.url
    ).pathname;

    const screenshots = [
        {
            title: 'Data Labeling',
            description: 'Use AI agents to efficiently label complex datasets',
            imageSrc: dataLabelingImage,
            url: 'https://docs.expectedparrot.com/en/latest/notebooks/data_labeling_example.html',
        },
        {
            title: 'Concept Induction',
            description:
                'Extract and identify implicit concepts from unstructured text',
            imageSrc: conceptInductionImage,
            url: 'https://docs.expectedparrot.com/en/latest/notebooks/concept_induction.html',
        },
        {
            title: 'Content Analysis',
            description:
                'Automate qualitative and quantitative analyses of textual content',
            imageSrc: courseEvaluationsImage,
            url: 'https://docs.expectedparrot.com/en/latest/notebooks/analyze_evaluations.html',
        },
        {
            title: 'Feedback Simulation',
            description:
                'Simulate feedback from target audiences using AI agents',
            imageSrc: npsImage,
            url: 'https://docs.expectedparrot.com/en/latest/notebooks/yoga_studio_name_survey.html',
        },
        {
            title: 'Model Evaluations',
            description: 'Compare responses from multiple language models',
            imageSrc: modelsScoringImage,
            url: 'https://docs.expectedparrot.com/en/latest/notebooks/models_scoring_models.html',
        },
        {
            title: 'Data Cleaning',
            description: 'Clean and validate datasets with AI-powered checks',
            imageSrc: messyDatasetImage,
            url: 'https://docs.expectedparrot.com/en/latest/notebooks/data_cleaning.html',
        },
        {
            title: 'Agent Specialization',
            description: 'Design expert agents to perform specialized tasks',
            imageSrc: jobPostingsImage,
            url: 'https://docs.expectedparrot.com/en/latest/notebooks/data_labeling_agent.html',
        },
    ];

    // Carousel state
    const [activeSlide, setActiveSlide] = useState(0);

    // Auto-advance carousel every 5 seconds
    useEffect(() => {
        const interval = setInterval(() => {
            setActiveSlide((prev) => (prev + 1) % screenshots.length);
        }, 5000);
        return () => clearInterval(interval);
    }, [screenshots.length]);

    // Navigation functions
    const nextSlide = () => {
        setActiveSlide((prev) => (prev + 1) % screenshots.length);
    };

    const prevSlide = () => {
        setActiveSlide((prev) =>
            prev === 0 ? screenshots.length - 1 : prev - 1
        );
    };

    // Go to specific slide
    const goToSlide = (index) => {
        setActiveSlide(index);
    };

    return (
        <div className="max-w-4xl mx-auto space-y-12 pb-16 px-4 sm:px-6">
            {/* Introduction */}
            <div className="space-y-4">
                <h1 className="font-bold text-4xl md:text-5xl pb-2 text-blue-600">
                    EDSL Use Cases
                </h1>
                <p className="text-xl text-gray-700 dark:text-gray-300">
                    Explore the versatility of EDSL for AI-powered research
                    through a variety of use cases. Each example includes a
                    downloadable notebook with code to help you get started.
                </p>

                <div className="bg-blue-50 dark:bg-blue-900/20 p-6 rounded-lg border-l-4 border-blue-500">
                    <p className="text-lg text-gray-700 dark:text-gray-300">
                        For additional applications and resources, please see
                        our{' '}
                        <a
                            href="https://docs.expectedparrot.com/en/latest/"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="inline-flex items-center gap-1 text-blue-500 hover:text-blue-700"
                        >
                            documentation <ExternalLink size={16} />
                        </a>
                        .
                    </p>
                </div>

                <div className="bg-green-50 dark:bg-green-900/20 p-4 rounded-lg mb-6 border-l-4 border-green-500">
                    <p>
                        <b>Tip:</b> If you are interested in a use case not
                        mentioned, please{' '}
                        <a
                            href="mailto:info@expectedparrot.com"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="inline-flex items-center gap-1 text-blue-500 hover:text-blue-700"
                        >
                            get in touch <ExternalLink size={16} />
                        </a>{' '}
                        and we'll create a notebook for you!
                    </p>
                </div>
            </div>

            {/* Featured Use Cases Section */}
            <div className="border border-gray-200 dark:border-gray-800 rounded-xl p-6 shadow-sm bg-white dark:bg-gray-900">
                <div className="flex items-center gap-3 mb-6">
                    <div className="w-10 h-10 rounded-full bg-blue-100 dark:bg-blue-900 flex items-center justify-center text-blue-600 dark:text-blue-400">
                        <Book size={20} />
                    </div>
                    <h3 className="font-bold text-2xl">Featured Use Cases</h3>
                </div>

                <div className="relative bg-gray-50 dark:bg-gray-800 p-4 sm:p-6 rounded-xl">
                    <div className="relative w-full overflow-hidden rounded-lg">
                        {/* Carousel Content */}
                        <div className="relative mx-auto">
                            {screenshots.map((item, index) => (
                                <div
                                    key={index}
                                    className={`transition-opacity duration-500 ${
                                        index === activeSlide
                                            ? 'opacity-100 block'
                                            : 'opacity-0 hidden'
                                    }`}
                                >
                                    <div className="text-center mb-4">
                                        <h3 className="text-2xl font-bold mb-2">
                                            {item.title}
                                        </h3>
                                        <p className="text-lg text-gray-700 dark:text-gray-300">
                                            {item.description}
                                        </p>
                                    </div>
                                    <a
                                        href={item.url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="block"
                                    >
                                        <div className="w-full aspect-video mx-auto overflow-hidden rounded-lg shadow-md border border-gray-200 dark:border-gray-700 hover:shadow-lg transition-shadow duration-300">
                                            <img
                                                src={item.imageSrc}
                                                alt={`Screenshot of ${item.title}`}
                                                className="w-full h-full object-cover object-center"
                                            />
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>

                        {/* Navigation Arrows */}
                        <button
                            onClick={prevSlide}
                            className="absolute left-2 top-1/2 -translate-y-1/2 bg-white dark:bg-gray-700 p-2 rounded-full shadow-md hover:bg-gray-100 dark:hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
                            aria-label="Previous slide"
                        >
                            <ChevronLeft size={24} />
                        </button>

                        <button
                            onClick={nextSlide}
                            className="absolute right-2 top-1/2 -translate-y-1/2 bg-white dark:bg-gray-700 p-2 rounded-full shadow-md hover:bg-gray-100 dark:hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
                            aria-label="Next slide"
                        >
                            <ChevronRight size={24} />
                        </button>
                    </div>

                    {/* Dot Navigation */}
                    <div className="flex justify-center mt-4">
                        {screenshots.map((_, index) => (
                            <button
                                key={index}
                                onClick={() => goToSlide(index)}
                                className={`mx-1 h-3 w-3 rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                                    index === activeSlide
                                        ? 'bg-blue-600 dark:bg-blue-500'
                                        : 'bg-gray-300 dark:bg-gray-600 hover:bg-gray-400 dark:hover:bg-gray-500'
                                }`}
                                aria-label={`Go to slide ${index + 1}`}
                            />
                        ))}
                    </div>
                </div>

                <div className="mt-6 flex justify-center">
                    <a
                        href="https://docs.expectedparrot.com/en/latest/notebooks/index.html"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="inline-flex items-center gap-2 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition"
                    >
                        View all notebooks <ExternalLink size={16} />
                    </a>
                </div>
            </div>

            {/* All Use Cases Section */}
            <div className="border border-gray-200 dark:border-gray-800 rounded-xl p-6 shadow-sm bg-white dark:bg-gray-900">
                <div className="flex items-center gap-3 mb-6">
                    <div className="w-10 h-10 rounded-full bg-purple-100 dark:bg-purple-900 flex items-center justify-center text-purple-600 dark:text-purple-400">
                        <FileText size={20} />
                    </div>
                    <h3 className="font-bold text-2xl">All Use Cases</h3>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                    {screenshots.map((item, index) => (
                        <div
                            key={index}
                            className="bg-gray-50 dark:bg-gray-800 p-4 rounded-lg border border-gray-200 dark:border-gray-700 shadow-sm"
                        >
                            <h3 className="font-bold text-xl mb-2">
                                {item.title}
                            </h3>
                            <p className="mb-3 text-gray-700 dark:text-gray-300">
                                {item.description}
                            </p>
                            <a
                                href={item.url}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="group block"
                            >
                                <div className="overflow-hidden rounded-lg border border-gray-200 dark:border-gray-700 transition-all hover:shadow-md hover:border-blue-300 dark:hover:border-blue-700 aspect-video mb-3">
                                    <img
                                        src={item.imageSrc}
                                        alt={`Screenshot of ${item.title}`}
                                        className="w-full h-full object-cover object-center transition-transform duration-300 group-hover:scale-105"
                                    />
                                </div>
                                <div className="flex justify-center">
                                    <span className="inline-flex items-center gap-1 text-blue-600 hover:text-blue-700">
                                        View notebook <ExternalLink size={14} />
                                    </span>
                                </div>
                            </a>
                        </div>
                    ))}
                </div>
            </div>

            {/* Additional Resources */}
            <div className="border border-gray-200 dark:border-gray-800 rounded-xl p-6 shadow-sm bg-blue-50 dark:bg-blue-900/20">
                <h3 className="font-bold text-2xl mb-4">Learn more</h3>

                <div className="grid md:grid-cols-2 gap-4">
                    <a
                        href="https://docs.expectedparrot.com/en/latest/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="flex items-center justify-center gap-2 px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition"
                    >
                        Documentation <ExternalLink size={18} />
                    </a>
                    <a
                        href="https://discord.com/invite/mxAYkjfy9m"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="flex items-center justify-center gap-2 px-6 py-3 bg-blue-200 text-blue-800 dark:bg-blue-700 dark:text-white rounded-lg hover:bg-blue-300 dark:hover:bg-blue-600 transition"
                    >
                        Join our Discord <MessageCircle size={18} />
                    </a>
                </div>
            </div>
        </div>
    );
};

export default EDSLUseCases;
