import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
    ArrowRight,
    ExternalLink,
    Copy,
    Check,
    Key,
    Shield,
    FileText,
    Code,
    ChevronRight,
} from 'lucide-react';

const EDSLAPIKeys = () => {
    const linkStyles =
        'text-blue-500 hover:text-blue-600 hover:underline dark:text-primary-dark-text-accent dark:hover:text-primary-dark-text-accent';

    const [copiedText, setCopiedText] = useState('');

    const copyToClipboard = (text) => {
        setCopiedText(text);
        setTimeout(() => setCopiedText(''), 2000);
    };

    const homeKeysImageURL = new URL(
        '../../../../../public/assets/getting-started/keys-view.png',
        import.meta.url
    ).pathname;

    return (
        <div className="max-w-4xl mx-auto space-y-12 pb-16 px-4 sm:px-6">
            {/* Introduction */}
            <div className="space-y-4">
                <h1 className="font-bold text-4xl md:text-5xl pb-2 text-blue-600">
                    Manage API Keys
                </h1>
                <p className="text-xl text-gray-700 dark:text-gray-300">
                    API keys are required to access language models. You can
                    choose whether to use your own keys from service providers
                    (Anthropic, Google, OpenAI, etc.) or use an Expected Parrot
                    key to access all{' '}
                    <Link to="/models" className={linkStyles}>
                        available models
                    </Link>
                    . Your Expected Parrot key also lets you post and share
                    content at{' '}
                    <Link
                        to="/getting-started/coop-how-it-works"
                        className={linkStyles}
                    >
                        Coop
                    </Link>
                    .
                </p>

                <p className="text-xl text-gray-700 dark:text-gray-300">
                    There are three methods for storing and managing your keys:
                </p>
                <div className="text-lg bg-green-50 dark:bg-green-900/20 p-4 rounded-lg mb-4 border-l-4 border-green-500">
                    <p>
                        <b>1. Manage keys at your account.</b>{' '}
                        <span className="italic">Recommended</span>.
                    </p>
                </div>
                <div className="text-lg bg-blue-50 dark:bg-blue-900/20 p-4 rounded-lg mb-2 border-l-4 border-blue-500">
                    <p>
                        <b>
                            2. Store keys in a{' '}
                            <code className="bg-gray-100 dark:bg-gray-800 px-1 py-0.5 rounded">
                                .env
                            </code>{' '}
                            file on your computer.
                        </b>
                    </p>
                </div>
                <div className="text-lg bg-red-50 dark:bg-red-900/20 p-4 rounded-lg mb-2 border-l-4 border-red-500">
                    <p>
                        <b>3. Include keys directly in your code.</b>{' '}
                        <span className="italic">
                            Not recommended for security reasons
                        </span>
                        .
                    </p>
                </div>
            </div>

            {/* Method 1 */}
            <div className="border border-gray-200 dark:border-gray-800 rounded-xl p-6 shadow-sm bg-white dark:bg-gray-900">
                <div className="flex items-center gap-3 mb-4">
                    <div className="w-10 h-10 rounded-full bg-green-100 dark:bg-green-900 flex items-center justify-center text-green-600 dark:text-green-400">
                        <Key size={20} />
                    </div>
                    <h3 className="font-bold text-2xl">
                        1. Manage keys at your account{' '}
                        <span className="text-green-600 text-lg italic">
                            (recommended)
                        </span>
                    </h3>
                </div>

                <p className="mb-4">
                    This method provides a secure way to store your keys and the
                    most flexibility in choosing how to use them.
                </p>

                <p className="mb-4">
                    Navigate to the{' '}
                    <Link to="/home/keys" className={linkStyles}>
                        Keys
                    </Link>{' '}
                    page of your account. Your Expected Parrot key is
                    automatically stored and available to use. This key allows
                    you to run surveys with all available models at the Expected
                    Parrot server and to post and retrieve content at Coop.
                </p>

                <p className="mb-4">
                    If you have your own keys that you want to use for running
                    surveys you can select the option to add them:
                </p>

                <div className="mb-6 flex justify-center">
                    <img
                        src={homeKeysImageURL}
                        alt="Screenshot of the Keys page"
                        className="w-full max-w-2xl border-2 border-gray-200 rounded-lg shadow-lg"
                    />
                </div>

                <p className="mb-4">
                    This makes your keys available when running surveys at the
                    Expected Parrot server. You can also grant access to other
                    users and set usage limits. This lets other users to make
                    API calls using your keys without sharing the keys directly.
                </p>

                <div className="bg-yellow-50 dark:bg-yellow-900/20 p-4 rounded-lg mb-4 border-l-4 border-yellow-500">
                    <p>
                        Note that when{' '}
                        <Link
                            to="/getting-started/coop-remote-inference"
                            className={linkStyles}
                        >
                            remote inference
                        </Link>{' '}
                        is deactivated you must provide your own keys for models
                        and store them in a{' '}
                        <code className="bg-gray-100 dark:bg-gray-800 px-1 py-0.5 rounded">
                            .env
                        </code>{' '}
                        file (see the next option for details).
                    </p>
                </div>
                <Link
                    to="/home/keys"
                    className="inline-flex items-center gap-2 px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 transition"
                >
                    Go to Keys page <ArrowRight size={18} />
                </Link>
            </div>

            {/* Method 2 */}
            <div className="border border-gray-200 dark:border-gray-800 rounded-xl p-6 shadow-sm bg-white dark:bg-gray-900">
                <div className="flex items-center gap-3 mb-4">
                    <div className="w-10 h-10 rounded-full bg-blue-100 dark:bg-blue-900 flex items-center justify-center text-blue-600 dark:text-blue-400">
                        <FileText size={20} />
                    </div>
                    <h3 className="font-bold text-2xl">
                        2. Store keys in a local file{' '}
                        <span className="text-blue-600 text-lg italic">
                            (alternative method)
                        </span>
                    </h3>
                </div>

                <p className="mb-4">
                    This method allows you to store keys on your computer to
                    make them available when running surveys locally or
                    remotely:
                </p>

                <p className="mb-2">
                    1. Navigate to your EDSL working directory (replace with
                    your actual path):{' '}
                    <code className="bg-gray-100 dark:bg-gray-800 px-1 py-0.5 rounded">
                        $ cd /path/to/edsl
                    </code>
                </p>

                <p className="mb-2">
                    2. Create a file named{' '}
                    <code className="bg-gray-100 dark:bg-gray-800 px-1 py-0.5 rounded">
                        .env
                    </code>
                </p>

                <p className="mb-2">
                    3. Copy your keys to the file in the following format:{' '}
                </p>

                <div className="relative bg-gray-100 dark:bg-gray-800 rounded-lg p-4 font-mono text-sm mb-4 overflow-x-auto">
                    <pre className="whitespace-pre-wrap break-all">
                        ANTHROPIC_API_KEY ={' '}
                        <span className="text-red-700 dark:text-lime-500">
                            'your_key_here'
                        </span>
                        <br />
                        DEEP_INFRA_API_KEY ={' '}
                        <span className="text-red-700 dark:text-lime-500">
                            'your_key_here'
                        </span>
                        <br />
                        DEEPSEEK_API_KEY ={' '}
                        <span className="text-red-700 dark:text-lime-500">
                            'your_key_here'
                        </span>
                        <br />
                        GOOGLE_API_KEY ={' '}
                        <span className="text-red-700 dark:text-lime-500">
                            'your_key_here'
                        </span>
                        <br />
                        GROQ_API_KEY ={' '}
                        <span className="text-red-700 dark:text-lime-500">
                            'your_key_here'
                        </span>
                        <br />
                        MISTRAL_API_KEY ={' '}
                        <span className="text-red-700 dark:text-lime-500">
                            'your_key_here'
                        </span>
                        <br />
                        OPENAI_API_KEY ={' '}
                        <span className="text-red-700 dark:text-lime-500">
                            'your_key_here'
                        </span>
                        <br />
                        PERPLEXITY_API_KEY ={' '}
                        <span className="text-red-700 dark:text-lime-500">
                            'your_key_here'
                        </span>
                        <br />
                        TOGETHER_API_KEY ={' '}
                        <span className="text-red-700 dark:text-lime-500">
                            'your_key_here'
                        </span>
                        <br />
                        XAI_API_KEY ={' '}
                        <span className="text-red-700 dark:text-lime-500">
                            'your_key_here'
                        </span>
                        <br />
                        <br />
                        AWS_ACCESS_KEY_ID ={' '}
                        <span className="text-red-700 dark:text-lime-500">
                            'your_key_here'
                        </span>
                        <br />
                        AWS_SECRET_ACCESS_KEY ={' '}
                        <span className="text-red-700 dark:text-lime-500">
                            'your_key_here'
                        </span>
                        <br />
                        <br />
                        AZURE_ENDPOINT_URL_AND_KEY ={' '}
                        <span className="text-red-700 dark:text-lime-500">
                            https://model_1_link:api_key_1,https://model_2_link:api_key_2
                        </span>
                    </pre>
                    <button
                        onClick={() =>
                            copyToClipboard(`ANTHROPIC_API_KEY = 'your_key_here'
DEEP_INFRA_API_KEY = 'your_key_here'
DEEPSEEK_API_KEY = 'your_key_here'
GOOGLE_API_KEY = 'your_key_here'
GROQ_API_KEY = 'your_key_here'
MISTRAL_API_KEY = 'your_key_here'
OPENAI_API_KEY = 'your_key_here'
PERPLEXITY_API_KEY = 'your_key_here'
TOGETHER_API_KEY = 'your_key_here'
XAI_API_KEY = 'your_key_here'

AWS_ACCESS_KEY_ID = 'your_key_here'
AWS_SECRET_ACCESS_KEY = 'your_key_here'

AZURE_ENDPOINT_URL_AND_KEY = https://model_1_link:api_key_1,https://model_2_link:api_key_2`)
                        }
                        className="absolute top-2 right-2 p-1 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
                        aria-label="Copy to clipboard"
                    >
                        {copiedText.startsWith('ANTHROPIC_API_KEY') ? (
                            <Check size={18} />
                        ) : (
                            <Copy size={18} />
                        )}
                    </button>
                </div>

                <p className="mb-4">
                    To post and retrieve content at Coop you must also store
                    your Expected Parrot API key:
                </p>

                <div className="relative bg-gray-100 dark:bg-gray-800 rounded-lg p-4 font-mono text-sm mb-4 overflow-x-auto">
                    <pre className="whitespace-pre-wrap break-all">
                        EXPECTED_PARROT_API_KEY ={' '}
                        <span className="text-red-700 dark:text-lime-500">
                            'your_key_here'
                        </span>
                    </pre>
                    <button
                        onClick={() =>
                            copyToClipboard(
                                `EXPECTED_PARROT_API_KEY = 'your_key_here'`
                            )
                        }
                        className="absolute top-2 right-2 p-1 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
                        aria-label="Copy to clipboard"
                    >
                        {copiedText ===
                        `EXPECTED_PARROT_API_KEY = 'your_key_here'` ? (
                            <Check size={18} />
                        ) : (
                            <Copy size={18} />
                        )}
                    </button>
                </div>

                <p className="mb-4">
                    Your key can be found at the{' '}
                    <Link to="/home/settings" className={linkStyles}>
                        Settings
                    </Link>{' '}
                    page of your account where you can reset it at any time.
                    This key allows you to access all{' '}
                    <Link to="/models" className={linkStyles}>
                        available models
                    </Link>{' '}
                    at once and run surveys at the Expected Parrot server. It
                    also allows you to post content generated locally to{' '}
                    <Link
                        to="/getting-started/coop-how-it-works"
                        className={linkStyles}
                    >
                        Coop
                    </Link>
                    , and to interact with other content that is public or
                    shared with you.
                </p>

                <div className="bg-green-50 dark:bg-green-900/20 p-4 rounded-lg mb-4 border-l-4 border-green-500">
                    <p>
                        <b>Tip:</b> See special instructions for{' '}
                        <a
                            href="https://docs.expectedparrot.com/en/latest/colab_setup.html"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="inline-flex items-center gap-1 text-blue-500 hover:text-blue-700"
                        >
                            using EDSL in Colab <ExternalLink size={16} />
                        </a>{' '}
                        and{' '}
                        <a
                            href="https://docs.expectedparrot.com/en/latest/edsl_with_cloud_providers.html"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="inline-flex items-center gap-1 text-blue-500 hover:text-blue-700"
                        >
                            storing API keys for Azure and Bedrock{' '}
                            <ExternalLink size={16} />
                        </a>
                        .
                    </p>
                </div>
            </div>

            {/* Method 3 */}
            <div className="border border-gray-200 dark:border-gray-800 rounded-xl p-6 shadow-sm bg-white dark:bg-gray-900">
                <div className="flex items-center gap-3 mb-4">
                    <div className="w-10 h-10 rounded-full bg-red-100 dark:bg-red-900 flex items-center justify-center text-red-600 dark:text-red-400">
                        <Shield size={20} />
                    </div>
                    <h3 className="font-bold text-2xl">
                        3. Include keys in your code{' '}
                        <span className="text-red-600 text-lg italic">
                            (not recommended)
                        </span>
                    </h3>
                </div>

                <p className="mb-4">
                    This method is not recommended for security reasons. If you
                    include your keys in your code, they can be seen by anyone
                    who has access to your code.
                </p>

                <p className="mb-4">
                    To use this method, store any keys that you want to use as
                    strings in your code in the following format:
                </p>

                <div className="relative bg-gray-100 dark:bg-gray-800 rounded-lg p-4 font-mono text-sm mb-4 overflow-x-auto">
                    <pre className="whitespace-pre-wrap break-all">
                        import os
                        <br />
                        <br />
                        os.environ['EXPECTED_PARROT_API_KEY'] ={' '}
                        <span className="text-red-700 dark:text-lime-500">
                            'your_key_here'
                        </span>
                        <br />
                        <br />
                        os.environ['ANTHROPIC_API_KEY'] ={' '}
                        <span className="text-red-700 dark:text-lime-500">
                            'your_key_here'
                        </span>
                        <br />
                        os.environ['DEEP_INFRA_API_KEY'] ={' '}
                        <span className="text-red-700 dark:text-lime-500">
                            'your_key_here'
                        </span>
                        <br />
                        os.environ['DEEPSEEK_API_KEY'] ={' '}
                        <span className="text-red-700 dark:text-lime-500">
                            'your_key_here'
                        </span>
                        <br />
                        os.environ['GOOGLE_API_KEY'] ={' '}
                        <span className="text-red-700 dark:text-lime-500">
                            'your_key_here'
                        </span>
                        <br />
                        os.environ['GROQ_API_KEY'] ={' '}
                        <span className="text-red-700 dark:text-lime-500">
                            'your_key_here'
                        </span>
                        <br />
                        os.environ['MISTRAL_API_KEY'] ={' '}
                        <span className="text-red-700 dark:text-lime-500">
                            'your_key_here'
                        </span>
                        <br />
                        os.environ['OPENAI_API_KEY'] ={' '}
                        <span className="text-red-700 dark:text-lime-500">
                            'your_key_here'
                        </span>
                        <br />
                        os.environ['PERPLEXITY_API_KEY'] ={' '}
                        <span className="text-red-700 dark:text-lime-500">
                            'your_key_here'
                        </span>
                        <br />
                        os.environ['TOGETHER_API_KEY'] ={' '}
                        <span className="text-red-700 dark:text-lime-500">
                            'your_key_here'
                        </span>
                        <br />
                        os.environ['XAI_API_KEY'] ={' '}
                        <span className="text-red-700 dark:text-lime-500">
                            'your_key_here'
                        </span>
                        <br />
                        <br />
                        os.environ['AWS_ACCESS_KEY_ID'] ={' '}
                        <span className="text-red-700 dark:text-lime-500">
                            'your_key_here'
                        </span>
                        <br />
                        os.environ['AWS_SECRET_ACCESS_KEY'] ={' '}
                        <span className="text-red-700 dark:text-lime-500">
                            'your_key_here'
                        </span>
                        <br />
                        os.environ['AZURE_ENDPOINT_URL_AND_KEY'] ={' '}
                        <span className="text-red-700 dark:text-lime-500">
                            https://model_1_link:api_key_1,https://model_2_link:api_key_2
                        </span>
                    </pre>
                    <button
                        onClick={() =>
                            copyToClipboard(`import os

os.environ['EXPECTED_PARROT_API_KEY'] = 'your_key_here'

os.environ['ANTHROPIC_API_KEY'] = 'your_key_here'
os.environ['DEEP_INFRA_API_KEY'] = 'your_key_here'
os.environ['DEEPSEEK_API_KEY'] = 'your_key_here'
os.environ['GOOGLE_API_KEY'] = 'your_key_here'
os.environ['GROQ_API_KEY'] = 'your_key_here'
os.environ['MISTRAL_API_KEY'] = 'your_key_here'
os.environ['OPENAI_API_KEY'] = 'your_key_here'
os.environ['PERPLEXITY_API_KEY'] = 'your_key_here'
os.environ['TOGETHER_API_KEY'] = 'your_key_here'
os.environ['XAI_API_KEY'] = 'your_key_here'

os.environ['AWS_ACCESS_KEY_ID'] = 'your_key_here'
os.environ['AWS_SECRET_ACCESS_KEY'] = 'your_key_here'
os.environ['AZURE_ENDPOINT_URL_AND_KEY'] = https://model_1_link:api_key_1,https://model_2_link:api_key_2`)
                        }
                        className="absolute top-2 right-2 p-1 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
                        aria-label="Copy to clipboard"
                    >
                        {copiedText.startsWith('import os') ? (
                            <Check size={18} />
                        ) : (
                            <Copy size={18} />
                        )}
                    </button>
                </div>

                <div className="bg-red-50 dark:bg-red-900/20 p-4 rounded-lg mb-4 border-l-4 border-red-500">
                    <p>
                        Note that your keys will not persist across sessions and
                        you will need to provide your keys each time you start a
                        new session.
                    </p>
                </div>
            </div>

            {/* Additional Resources Section */}
            <div className="border border-gray-200 dark:border-gray-800 rounded-xl p-6 shadow-sm bg-blue-50 dark:bg-blue-900/20">
                <h3 className="font-bold text-2xl mb-4">Learn more</h3>

                <div className="grid md:grid-cols-2 gap-4">
                    <a
                        href="https://docs.expectedparrot.com/en/latest/edsl_with_cloud_providers.html"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="flex items-center justify-center gap-2 px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition"
                    >
                        Cloud provider setup <ExternalLink size={18} />
                    </a>
                    <Link
                        to="/getting-started/coop-remote-inference"
                        className="flex items-center justify-center gap-2 px-6 py-3 bg-blue-200 text-blue-800 dark:bg-blue-700 dark:text-white rounded-lg hover:bg-blue-300 dark:hover:bg-blue-600 transition"
                    >
                        Remote inference <ChevronRight size={18} />
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default EDSLAPIKeys;
