import { useFormContext, Controller } from 'react-hook-form';
import { AlertCircle } from 'lucide-react';
import {
    Question as QuestionType,
    BudgetQuestion,
    CheckBoxQuestion,
    FreeTextQuestion,
    LikertFiveQuestion,
    LinearScaleQuestion,
    ListQuestion,
    MultipleChoiceQuestion,
    NumericalQuestion,
    RankQuestion,
    TopKQuestion,
    YesNoQuestion,
} from './types';

function ErrorMessage({ error }: { error?: string }) {
    if (!error) return null;
    return (
        <p className="flex items-center gap-x-2 text-red-500 dark:text-pink-400 text-sm mt-1">
            <AlertCircle className="w-5" />
            {error}
        </p>
    );
}

function BudgetQuestion({ question }: { question: BudgetQuestion }) {
    const {
        control,
        formState: { errors },
    } = useFormContext();

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'decimal',
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
    });

    return (
        <div className="mb-6">
            <h2 className="text-xl font-semibold mb-3">
                {question.question_text}
            </h2>
            <Controller
                name={question.question_name}
                control={control}
                defaultValue={{}}
                render={({ field }) => {
                    const total = Number(
                        Object.values(field.value).reduce(
                            (sum: number, value: string) =>
                                sum + (parseFloat(value) || 0),
                            0
                        )
                    );

                    return (
                        <div className="space-y-3">
                            {question.question_options.map((option, index) => (
                                <div key={index} className="flex flex-col">
                                    <div className="flex items-center">
                                        <label
                                            htmlFor={`${question.question_name}-${index}`}
                                            className="w-1/3"
                                        >
                                            {option}
                                        </label>
                                        <input
                                            type="text"
                                            className="w-2/3 p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                            id={`${question.question_name}-${index}`}
                                            value={field.value[option] || ''}
                                            ref={
                                                option ===
                                                question.question_options[0]
                                                    ? field.ref
                                                    : undefined
                                            }
                                            onChange={(e) => {
                                                const updatedValue = {
                                                    ...field.value,
                                                    [option]: e.target.value,
                                                };
                                                field.onChange(updatedValue);
                                            }}
                                        />
                                    </div>
                                </div>
                            ))}
                            <div className="flex items-center font-semibold">
                                <span className="w-1/3">Total</span>
                                <span className="w-2/3 p-2">
                                    {formatter.format(total)}
                                </span>
                            </div>
                        </div>
                    );
                }}
            />
            <ErrorMessage
                error={errors[question.question_name]?.message as string}
            />
        </div>
    );
}

function CheckBoxQuestion({ question }: { question: CheckBoxQuestion }) {
    const {
        control,
        formState: { errors },
    } = useFormContext();

    return (
        <div className="mb-6">
            <h2 className="text-xl font-semibold mb-3">
                {question.question_text}
            </h2>
            <Controller
                name={question.question_name}
                control={control}
                defaultValue={[]}
                render={({ field }) => (
                    <div className="space-y-1">
                        {question.question_options.map((option, index) => (
                            <div key={index} className="flex items-center">
                                <input
                                    type="checkbox"
                                    id={`${question.question_name}-${index}`}
                                    checked={field.value.includes(option)}
                                    ref={
                                        option === question.question_options[0]
                                            ? field.ref
                                            : undefined
                                    }
                                    onChange={(e) => {
                                        const updatedValue = e.target.checked
                                            ? [...field.value, option]
                                            : field.value.filter(
                                                  (item: string) =>
                                                      item !== option
                                              );
                                        field.onChange(updatedValue);
                                    }}
                                    className="w-4 h-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                                />
                                <label
                                    htmlFor={`${question.question_name}-${index}`}
                                    className="grow ml-2 py-1"
                                >
                                    {option}
                                </label>
                            </div>
                        ))}
                    </div>
                )}
            />
            <ErrorMessage
                error={errors[question.question_name]?.message as string}
            />
        </div>
    );
}

function FreeTextQuestion({ question }: { question: FreeTextQuestion }) {
    const {
        control,
        formState: { errors },
    } = useFormContext();

    return (
        <div className="mb-6">
            <h2 className="text-xl font-semibold mb-3">
                {question.question_text}
            </h2>
            <Controller
                name={question.question_name}
                control={control}
                defaultValue=""
                render={({ field }) => (
                    <textarea
                        {...field}
                        className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                        rows={4}
                    />
                )}
            />
            <ErrorMessage
                error={errors[question.question_name]?.message as string}
            />
        </div>
    );
}

function LikertFiveQuestion({ question }: { question: LikertFiveQuestion }) {
    const {
        control,
        formState: { errors },
    } = useFormContext();

    return (
        <div className="mb-6">
            <h2 className="text-xl font-semibold mb-3">
                {question.question_text}
            </h2>
            <Controller
                name={question.question_name}
                control={control}
                defaultValue=""
                render={({ field }) => (
                    <div className="space-y-1">
                        {question.question_options.map((option, index) => (
                            <label key={index} className="flex items-center">
                                <input
                                    type="radio"
                                    ref={
                                        option === question.question_options[0]
                                            ? field.ref
                                            : undefined
                                    }
                                    onChange={field.onChange}
                                    name={field.name}
                                    value={option}
                                    checked={field.value === option}
                                    className="w-4 h-4 text-blue-600 border-gray-300 focus:ring-blue-500"
                                />
                                <span className="ml-2 py-1">{option}</span>
                            </label>
                        ))}
                    </div>
                )}
            />
            <ErrorMessage
                error={errors[question.question_name]?.message as string}
            />
        </div>
    );
}

function LinearScaleQuestion({ question }: { question: LinearScaleQuestion }) {
    const {
        control,
        formState: { errors },
    } = useFormContext();

    function mapOptionsToLabels(
        options: number[],
        labels: { [key: number]: string }
    ) {
        return options.map((option) => {
            if (labels !== null && option in labels) {
                return {
                    option: option.toString(),
                    label: `${option} - ${labels[option]}`,
                };
            }
            return {
                option: option.toString(),
                label: option.toString(),
            };
        });
    }

    const formattedOptions = mapOptionsToLabels(
        question.question_options,
        question.option_labels
    );

    return (
        <div className="mb-6">
            <h2 className="text-xl font-semibold mb-3">
                {question.question_text}
            </h2>
            <Controller
                name={question.question_name}
                control={control}
                defaultValue=""
                render={({ field }) => (
                    <div className="space-y-1">
                        {formattedOptions.map(({ option, label }, index) => (
                            <label key={index} className="flex items-center">
                                <input
                                    type="radio"
                                    ref={
                                        option ===
                                        question.question_options[0].toString()
                                            ? field.ref
                                            : undefined
                                    }
                                    onChange={field.onChange}
                                    name={field.name}
                                    value={option}
                                    checked={field.value === option}
                                    className="w-4 h-4 text-blue-600 border-gray-300 focus:ring-blue-500"
                                />
                                <span className="ml-2 py-1">{label}</span>
                            </label>
                        ))}
                    </div>
                )}
            />
            <ErrorMessage
                error={errors[question.question_name]?.message as string}
            />
        </div>
    );
}

function ListQuestion({ question }: { question: ListQuestion }) {
    const {
        control,
        formState: { errors },
    } = useFormContext();

    return (
        <div className="mb-6 space-y-4">
            <h2 className="text-xl font-semibold mb-3">
                {question.question_text}
            </h2>
            <p>Please enter one item per line.</p>
            <Controller
                name={question.question_name}
                control={control}
                defaultValue=""
                render={({ field }) => (
                    <textarea
                        {...field}
                        className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                        rows={4}
                    />
                )}
            />
            <ErrorMessage
                error={errors[question.question_name]?.message as string}
            />
        </div>
    );
}

function MultipleChoiceQuestion({
    question,
}: {
    question: MultipleChoiceQuestion;
}) {
    const {
        control,
        formState: { errors },
    } = useFormContext();

    return (
        <div className="mb-6">
            <h2 className="text-xl font-semibold mb-3">
                {question.question_text}
            </h2>
            <Controller
                name={question.question_name}
                control={control}
                defaultValue=""
                render={({ field }) => (
                    <div className="space-y-1">
                        {question.question_options.map((option, index) => (
                            <label key={index} className="flex items-center">
                                <input
                                    type="radio"
                                    ref={
                                        option === question.question_options[0]
                                            ? field.ref
                                            : undefined
                                    }
                                    onChange={field.onChange}
                                    name={field.name}
                                    value={option}
                                    checked={field.value === option}
                                    className="w-4 h-4 text-blue-600 border-gray-300 focus:ring-blue-500"
                                />
                                <span className="ml-2 py-1">{option}</span>
                            </label>
                        ))}
                    </div>
                )}
            />
            <ErrorMessage
                error={errors[question.question_name]?.message as string}
            />
        </div>
    );
}

function NumericalQuestion({ question }: { question: NumericalQuestion }) {
    const {
        control,
        formState: { errors },
    } = useFormContext();

    return (
        <div className="mb-6">
            <h2 className="text-xl font-semibold mb-3">
                {question.question_text}
            </h2>
            <Controller
                name={question.question_name}
                control={control}
                defaultValue=""
                render={({ field }) => (
                    <input
                        {...field}
                        className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                )}
            />
            <ErrorMessage
                error={errors[question.question_name]?.message as string}
            />
        </div>
    );
}

function RankQuestion({ question }: { question: RankQuestion }) {
    const {
        control,
        formState: { errors },
    } = useFormContext();

    return (
        <div className="mb-6">
            <h2 className="text-xl font-semibold mb-3">
                {question.question_text}
            </h2>
            <div className="space-y-3">
                <Controller
                    name={question.question_name}
                    control={control}
                    defaultValue={{}}
                    render={({ field }) => (
                        <>
                            {question.question_options.map((option, index) => (
                                <div key={index} className="flex items-center">
                                    <label className="w-2/3">{option}</label>
                                    <select
                                        className="w-1/3 p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        ref={
                                            option ===
                                            question.question_options[0]
                                                ? field.ref
                                                : undefined
                                        }
                                        value={field.value[option] || ''}
                                        onChange={(e) => {
                                            const newRank = e.target.value;
                                            const updatedRankings = {
                                                ...field.value,
                                            };

                                            if (newRank === '') {
                                                delete updatedRankings[option];
                                            } else {
                                                const rankInt =
                                                    parseInt(newRank);
                                                const optionWithSameRank =
                                                    Object.keys(
                                                        updatedRankings
                                                    ).find(
                                                        (key) =>
                                                            updatedRankings[
                                                                key
                                                            ] === rankInt &&
                                                            key !== option
                                                    );

                                                if (optionWithSameRank) {
                                                    delete updatedRankings[
                                                        optionWithSameRank
                                                    ];
                                                }

                                                updatedRankings[option] =
                                                    rankInt;
                                            }

                                            field.onChange(updatedRankings);
                                        }}
                                    >
                                        <option value="">Select rank</option>
                                        {[
                                            ...Array(question.num_selections),
                                        ].map((_, i) => (
                                            <option key={i + 1} value={i + 1}>
                                                {i + 1}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            ))}
                        </>
                    )}
                />
            </div>
            <ErrorMessage
                error={errors[question.question_name]?.message as string}
            />
        </div>
    );
}

function TopKQuestion({ question }: { question: TopKQuestion }) {
    const {
        control,
        formState: { errors },
    } = useFormContext();

    return (
        <div className="mb-6">
            <h2 className="text-xl font-semibold mb-3">
                {question.question_text}
            </h2>
            <Controller
                name={question.question_name}
                control={control}
                defaultValue={[]}
                render={({ field }) => (
                    <div className="space-y-1">
                        {question.question_options.map((option, index) => (
                            <div key={index} className="flex items-center">
                                <input
                                    type="checkbox"
                                    id={`${question.question_name}-${index}`}
                                    checked={field.value.includes(option)}
                                    ref={
                                        option === question.question_options[0]
                                            ? field.ref
                                            : undefined
                                    }
                                    onChange={(e) => {
                                        const updatedValue = e.target.checked
                                            ? [...field.value, option]
                                            : field.value.filter(
                                                  (item: string) =>
                                                      item !== option
                                              );
                                        field.onChange(updatedValue);
                                    }}
                                    className="w-4 h-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                                />
                                <label
                                    htmlFor={`${question.question_name}-${index}`}
                                    className="grow ml-2 py-1"
                                >
                                    {option}
                                </label>
                            </div>
                        ))}
                    </div>
                )}
            />
            <ErrorMessage
                error={errors[question.question_name]?.message as string}
            />
        </div>
    );
}

function YesNoQuestion({ question }: { question: YesNoQuestion }) {
    const {
        control,
        formState: { errors },
    } = useFormContext();

    return (
        <div className="mb-6">
            <h2 className="text-xl font-semibold mb-3">
                {question.question_text}
            </h2>
            <Controller
                name={question.question_name}
                control={control}
                defaultValue=""
                render={({ field }) => (
                    <div className="space-y-1">
                        {question.question_options.map((option, index) => (
                            <label key={index} className="flex items-center">
                                <input
                                    type="radio"
                                    ref={
                                        option === question.question_options[0]
                                            ? field.ref
                                            : undefined
                                    }
                                    onChange={field.onChange}
                                    name={field.name}
                                    value={option}
                                    checked={field.value === option}
                                    className="w-4 h-4 text-blue-600 border-gray-300 focus:ring-blue-500"
                                />
                                <span className="ml-2 py-1">{option}</span>
                            </label>
                        ))}
                    </div>
                )}
            />
            <ErrorMessage
                error={errors[question.question_name]?.message as string}
            />
        </div>
    );
}

export function Question({ question }: { question: QuestionType }) {
    switch (question.question_type) {
        case 'budget':
            return <BudgetQuestion question={question} />;
        case 'checkbox':
            return <CheckBoxQuestion question={question} />;
        case 'free_text':
            return <FreeTextQuestion question={question} />;
        case 'likert_five':
            return <LikertFiveQuestion question={question} />;
        case 'linear_scale':
            return <LinearScaleQuestion question={question} />;
        case 'list':
            return <ListQuestion question={question} />;
        case 'multiple_choice':
            return <MultipleChoiceQuestion question={question} />;
        case 'numerical':
            return <NumericalQuestion question={question} />;
        case 'rank':
            return <RankQuestion question={question} />;
        case 'top_k':
            return <TopKQuestion question={question} />;
        case 'yes_no':
            return <YesNoQuestion question={question} />;
        default:
            return "We don't have a component for this question type yet!";
    }
}
