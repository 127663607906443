import { useFetcher } from 'react-router-dom';
import * as Popover from '@radix-ui/react-popover';
import { Edit2 } from 'lucide-react';
import { useState, useEffect } from 'react';

function UpdateRateLimitForm({
    keyId,
    service,
    currentRpm,
    currentTpm,
    handleOpenChange,
}: {
    keyId: string;
    service: string;
    currentRpm: number | null;
    currentTpm: number | null;
    handleOpenChange: (open: boolean) => void;
}) {
    const fetcher = useFetcher();

    useEffect(() => {
        if (fetcher.data?.success === true) {
            handleOpenChange(false);
        }
    }, [fetcher.data]);

    return (
        <fetcher.Form method="post">
            <input type="hidden" name="key_uuid" value={keyId} />
            <input type="hidden" name="service" value={service} />
            <input
                type="number"
                name="rpm"
                defaultValue={currentRpm || ''}
                placeholder="100"
                className="block w-full p-2.5 mb-3 bg-gray-50 focus:outline-none border rounded-lg border-gray-400 focus:ring-1 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700/20 dark:border-gray-500 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:placeholder-gray-400"
            />
            <input
                type="number"
                name="tpm"
                defaultValue={currentTpm || ''}
                placeholder="100"
                className="block w-full p-2.5 mb-3 bg-gray-50 focus:outline-none border rounded-lg border-gray-400 focus:ring-1 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700/20 dark:border-gray-500 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:placeholder-gray-400"
            />
            <div className="flex justify-between gap-x-2">
                <Popover.Close asChild>
                    <button className="hover:bg-gray-300/20 border border-gray-300 dark:border-2 dark:border-gray-500 font-semibold text-sm px-3 py-2 rounded-md">
                        Cancel
                    </button>
                </Popover.Close>
                <button
                    type="submit"
                    name="intent"
                    value="update_key_rate_limit"
                    className="bg-blue-600 hover:bg-blue-700 border-2 border-blue-600 hover:border-blue-700 rounded-md px-3 py-2 text-white font-semibold text-sm"
                    onClick={(event) => event.stopPropagation()}
                >
                    Update
                </button>
            </div>
        </fetcher.Form>
    );
}

function UpdateRateLimitPopover({
    keyId,
    service,
    rpm,
    tpm,
}: {
    keyId: string;
    service: string;
    rpm: number | null;
    tpm: number | null;
}) {
    const [open, setOpen] = useState(false);

    return (
        <Popover.Root open={open} onOpenChange={setOpen}>
            <Popover.Trigger asChild>
                <button className="text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-lg text-sm px-3 py-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">
                    <Edit2 className="w-4 h-4" />
                </button>
            </Popover.Trigger>
            <Popover.Portal>
                <Popover.Content
                    className="w-48 bg-white dark:bg-gray-700 rounded-lg shadow z-20"
                    sideOffset={5}
                >
                    <div className="flex flex-col gap-y-4 p-4 text-sm">
                        <div className="text-gray-900 dark:text-white">
                            Update rate limits:
                        </div>
                        <UpdateRateLimitForm
                            keyId={keyId}
                            service={service}
                            currentRpm={rpm}
                            currentTpm={tpm}
                            handleOpenChange={setOpen}
                        />
                    </div>
                </Popover.Content>
            </Popover.Portal>
        </Popover.Root>
    );
}

export default UpdateRateLimitPopover;
