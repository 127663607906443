import { Search, Send } from 'lucide-react';
import { useState } from 'react';
import ModelPricingTable from './ModelPricingTable';
import { ModelRecord } from '../../../types';
import ReportPricingIssueModal from './ReportPricingIssueModal';
import FilterDropdown from './FilterDropdown';
import ColumnDropdown from './ColumnDropdown';

const columns = [
    {
        id: 'service',
        label: 'Service',
        default: true,
        group: 'General',
        adminOnly: false,
        recordField: 'inference_service',
    },
    {
        id: 'model',
        label: 'Model',
        default: true,
        group: 'General',
        adminOnly: false,
        recordField: 'model',
    },
    {
        id: 'textSupport',
        label: 'Text Support',
        default: true,
        group: 'General',
        adminOnly: false,
        recordField: 'works_with_text',
    },
    {
        id: 'imageSupport',
        label: 'Image Support',
        default: true,
        group: 'General',
        adminOnly: false,
        recordField: 'works_with_images',
    },
    {
        id: 'inputPrice',
        label: 'Input Price',
        default: true,
        group: 'Pricing',
        adminOnly: false,
        recordField: 'input_price_per_1M_tokens',
    },
    {
        id: 'outputPrice',
        label: 'Output Price',
        default: true,
        group: 'Pricing',
        adminOnly: false,
        recordField: 'output_price_per_1M_tokens',
    },
    {
        id: 'pricingDataUrl',
        label: 'Pricing Data URL',
        default: true,
        group: 'Pricing',
        adminOnly: false,
        recordField: 'pricing_data_url',
    },
    {
        id: 'capitalOfFrance',
        label: 'Capital of France',
        default: false,
        group: 'Test Questions',
        adminOnly: false,
        recordField: 'answer_capital_of_france',
    },
    {
        id: 'imageDescription',
        label: 'Logo Description',
        default: false,
        group: 'Test Questions',
        adminOnly: false,
        recordField: 'answer_image_description',
    },
    {
        id: 'capitalOfFranceExceptions',
        label: 'Capital of France',
        default: false,
        group: 'Exceptions',
        adminOnly: true,
        recordField: 'exceptions_capital_of_france',
    },
    {
        id: 'imageDescriptionExceptions',
        label: 'Logo Description',
        default: false,
        group: 'Exceptions',
        adminOnly: true,
        recordField: 'exceptions_image_description',
    },
];

function ModelPricingTableWrapper({
    modelRecords,
    lastUpdatedTimeFromNow,
    isAdmin,
}: {
    modelRecords: ModelRecord[];
    lastUpdatedTimeFromNow: string;
    isAdmin: boolean;
}) {
    const [visibleColumns, setVisibleColumns] = useState(
        columns.reduce(
            (acc, column) => ({
                ...acc,
                [column.id]: column.default,
            }),
            {}
        )
    );

    const [filters, setFilters] = useState({
        textSupport: [],
        imageSupport: [],
        services: [],
    });

    const [searchQuery, setSearchQuery] = useState('');

    const [sortConfig, setSortConfig] = useState<{
        key: string | null;
        direction: 'asc' | 'desc';
    }>({
        key: null,
        direction: 'asc',
    });

    const filteredRecords = modelRecords.filter((record) => {
        const textMatch =
            // If no text support filter is applied, show all models
            filters.textSupport.length === 0 ||
            // If the model works with text, show it
            (filters.textSupport.includes('works') && record.works_with_text) ||
            // If the model doesn't work with text, hide it
            (filters.textSupport.includes('doesnt-work') &&
                !record.works_with_text);

        const imageMatch =
            // If no image support filter is applied, show all models
            filters.imageSupport.length === 0 ||
            // If the model works with images, show it
            (filters.imageSupport.includes('works') &&
                record.works_with_images) ||
            // If the model doesn't work with images, hide it
            (filters.imageSupport.includes('doesnt-work') &&
                !record.works_with_images);

        const serviceMatch =
            // If no service filter is applied, show all models
            filters.services.length === 0 ||
            // If the model is from the selected service, show it
            filters.services.includes(record.inference_service);

        const searchMatch =
            searchQuery === '' ||
            record.model.toLowerCase().includes(searchQuery.toLowerCase()) ||
            record.inference_service
                .toLowerCase()
                .includes(searchQuery.toLowerCase());

        return textMatch && imageMatch && serviceMatch && searchMatch;
    });

    const sortedRecords = [...filteredRecords].sort((a, b) => {
        if (!sortConfig.key) return 0;

        let aValue, bValue;
        switch (sortConfig.key) {
            case 'service':
                aValue = a.inference_service;
                bValue = b.inference_service;
                break;
            case 'model':
                aValue = a.model;
                bValue = b.model;
                break;
            case 'textSupport':
                aValue = a.works_with_text;
                bValue = b.works_with_text;
                break;
            case 'imageSupport':
                aValue = a.works_with_images;
                bValue = b.works_with_images;
                break;
            case 'inputPrice':
                aValue = a.input_price_per_1M_tokens;
                bValue = b.input_price_per_1M_tokens;
                break;
            case 'outputPrice':
                aValue = a.output_price_per_1M_tokens;
                bValue = b.output_price_per_1M_tokens;
                break;
            default:
                return 0;
        }

        // Handle boolean values for text/image support
        if (typeof aValue === 'boolean') {
            return sortConfig.direction === 'asc'
                ? Number(bValue) - Number(aValue)
                : Number(aValue) - Number(bValue);
        }

        // Handle null values for price fields
        if (sortConfig.key.includes('Price')) {
            const aNum = aValue === null ? -1 : (aValue as number);
            const bNum = bValue === null ? -1 : (bValue as number);
            return sortConfig.direction === 'asc' ? aNum - bNum : bNum - aNum;
        }

        // Handle string fields
        const aStr = String(aValue || '').toLowerCase();
        const bStr = String(bValue || '').toLowerCase();
        return sortConfig.direction === 'asc'
            ? aStr.localeCompare(bStr)
            : bStr.localeCompare(aStr);
    });

    const handleSort = (key: string) => {
        setSortConfig((current) => ({
            key,
            direction:
                current.key === key && current.direction === 'asc'
                    ? 'desc'
                    : 'asc',
        }));
    };

    const handleFilterChange = (
        filterType: string,
        value: string | string[]
    ) => {
        setFilters((prev) => ({
            ...prev,
            [filterType]: value,
        }));
    };

    const handleColumnChange = (columnId: string, checked: boolean) => {
        setVisibleColumns((prev) => ({
            ...prev,
            [columnId]: checked,
        }));
    };

    // Create service options for the dropdown
    const serviceOptions = Array.from(
        new Set(modelRecords.map((record) => record.inference_service))
    ).map((service) => ({
        value: service,
        label: service,
    }));

    return (
        <>
            <div className="text-sm italic">
                Last updated: {lastUpdatedTimeFromNow}
                <br />
                To report an issue with a price, please{' '}
                <ReportPricingIssueModal>
                    <button className="italic text-blue-500 hover:text-blue-600 hover:underline dark:text-primary-dark-text-accent dark:hover:text-primary-dark-text-accent inline-flex items-center gap-1">
                        send us a message
                        <Send className="w-4" strokeWidth="1.5" />
                    </button>
                </ReportPricingIssueModal>
            </div>

            <div className="flex justify-between items-center gap-2 mb-4">
                <div className="relative">
                    <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 w-4 h-4 text-gray-400" />
                    <input
                        type="text"
                        placeholder="Search models..."
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        className="pl-9 pr-4 py-2 rounded-lg border border-gray-200 dark:border-gray-100/20 bg-white dark:bg-primary-dark-bg focus:outline-none focus:ring-2 focus:ring-blue-500 dark:focus:ring-primary-dark-text-accent text-sm dark:placeholder-gray-400"
                    />
                </div>
                <div className="flex gap-2">
                    <FilterDropdown
                        filters={filters}
                        handleFilterChange={handleFilterChange}
                        serviceOptions={serviceOptions}
                    />
                    <ColumnDropdown
                        columns={columns}
                        visibleColumns={visibleColumns}
                        handleColumnChange={handleColumnChange}
                        isAdmin={isAdmin}
                    />
                </div>
            </div>

            <ModelPricingTable
                sortedRecords={sortedRecords}
                visibleColumns={visibleColumns}
                handleSort={handleSort}
                sortConfig={sortConfig}
            />
        </>
    );
}

export default ModelPricingTableWrapper;
