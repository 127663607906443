import KeyValueTable from './KeyValueTable';

function Model({
    modelNameAndParams,
}: {
    modelNameAndParams: { id: number; key: string; value: any }[];
}) {
    return (
        <KeyValueTable
            title="Model parameters"
            keyColName="Parameter"
            valueColName="Value"
            data={modelNameAndParams}
        />
    );
}

export default Model;
