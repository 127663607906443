import { useState } from 'react';
import { useLoaderData, useOutletContext } from 'react-router-dom';
import {
    SandpackProvider,
    SandpackLayout,
    SandpackCodeViewer,
} from '@codesandbox/sandpack-react';
import { python } from '@codemirror/lang-python';

import * as Tabs from '@radix-ui/react-tabs';
import ActionRow from './ActionRow';
import { CopyCodeBtn } from './ActionRowButtons';
import ObjectData from './ObjectData';
import ObjectOverview from './ObjectData/ObjectOverview';
import { EDSLObject } from '../../../../types';
import CheckPasswordModal from './CheckPasswordModal';

interface ObjectData {
    user: {
        is_admin: boolean;
        is_authenticated: boolean;
        is_beta_user: boolean;
    };
    object: EDSLObject;
}

function ContentId() {
    const data = useLoaderData() as ObjectData;
    const isDarkMode: boolean = useOutletContext();
    const [activeTab, setActiveTab] = useState('content');

    const btnBaseStyles =
        'py-2.5 px-5 text-sm font-medium text-gray-900 focus:outline-none bg-white border-gray-200 hover:bg-gray-100 focus:z-10 focus:ring focus:ring-gray-100 dark:focus:ring-gray-500 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:bg-gray-700';

    const object = data.object;

    const [showPasswordModal, setShowPasswordModal] = useState<boolean>(() => {
        const hasPassword = object.password !== null && object.password !== '';
        const isPasswordProtected =
            !object.is_owned_by_user &&
            hasPassword &&
            object.visibility === 'unlisted';
        if (isPasswordProtected) {
            return true;
        } else {
            return false;
        }
    });

    function dismissPasswordModal() {
        setShowPasswordModal(false);
    }

    if (showPasswordModal) {
        return (
            <CheckPasswordModal
                objectId={object.id}
                dismissPasswordModal={dismissPasswordModal}
            />
        );
    }

    return (
        <>
            <div className="flex flex-col space-x-2 pb-2">
                <div className="w-full flex flex-col md:items-center min-h-[60vh] space-y-6">
                    <div className="w-full space-y-6">
                        <ObjectOverview object={object} />
                        <Tabs.Root
                            value={activeTab}
                            onValueChange={(value) => setActiveTab(value)}
                        >
                            <ActionRow
                                object={object}
                                activeTab={activeTab}
                                showSurveyBuilderBtn={
                                    object.object_type === 'survey' &&
                                    data.user.is_beta_user === true &&
                                    object.is_stale === false
                                }
                            >
                                <Tabs.List
                                    className="flex bg-gray-100 dark:bg-gray-800 rounded-xl"
                                    aria-label="Switch tabs"
                                >
                                    <Tabs.Trigger
                                        className="rounded-l-xl px-4 py-2.5 text-sm font-medium text-center data-[state=active]:bg-white data-[state=active]:border-2 data-[state=active]:border-gray-800 dark:data-[state=active]:border-gray-300 dark:data-[state=active]:bg-black"
                                        value="content"
                                    >
                                        View
                                    </Tabs.Trigger>
                                    <Tabs.Trigger
                                        className="px-4 py-2.5 text-sm font-medium text-center data-[state=active]:bg-white data-[state=active]:border-2 data-[state=active]:border-gray-800 dark:data-[state=active]:border-gray-300 dark:data-[state=active]:bg-black"
                                        value="pull"
                                    >
                                        Pull
                                    </Tabs.Trigger>
                                    <Tabs.Trigger
                                        className="rounded-r-xl px-4 py-2.5 text-sm font-medium text-center data-[state=active]:bg-white data-[state=active]:border-2 data-[state=active]:border-gray-800 dark:data-[state=active]:border-gray-300 dark:data-[state=active]:bg-black"
                                        value="raw"
                                    >
                                        Raw
                                    </Tabs.Trigger>
                                </Tabs.List>
                            </ActionRow>
                            <Tabs.Content value="content">
                                <div className="p-6 border-2 border-gray-200 dark:border-primary-dark-border shadow-lg rounded-b-xl relative">
                                    {object.is_stale ? (
                                        <div className="w-full space-y-6 text-center">
                                            <p className="text-2xl">
                                                Sorry, this object is stale 😔
                                            </p>
                                            <p className="text-lg px-8">
                                                Stale objects are objects that a
                                                user previously uploaded that we
                                                cannot deserialize. This means
                                                we have a backwards
                                                compatibility error!
                                            </p>
                                        </div>
                                    ) : (
                                        <ObjectData object={object} />
                                    )}
                                </div>
                            </Tabs.Content>
                            <Tabs.Content value="pull">
                                <SandpackProvider
                                    files={{
                                        'pull_code.py': {
                                            code: object.pull_code,
                                            active: true,
                                        },
                                    }}
                                    theme={isDarkMode ? 'dark' : 'light'}
                                >
                                    <SandpackLayout>
                                        <CopyCodeBtn
                                            code={object.pull_code}
                                            baseStyles={`z-10 absolute top-5 right-5 ${btnBaseStyles}`}
                                        />
                                        <SandpackCodeViewer
                                            showLineNumbers={true}
                                            showTabs={false}
                                            additionalLanguages={[
                                                {
                                                    name: 'python',
                                                    extensions: ['py'],
                                                    language: python(),
                                                },
                                            ]}
                                        ></SandpackCodeViewer>
                                    </SandpackLayout>
                                </SandpackProvider>
                            </Tabs.Content>
                            <Tabs.Content value="raw">
                                <SandpackProvider
                                    files={{
                                        'raw_code.py': {
                                            code: object.raw_code,
                                            active: true,
                                        },
                                    }}
                                    theme={isDarkMode ? 'dark' : 'light'}
                                >
                                    <SandpackLayout>
                                        <CopyCodeBtn
                                            code={object.raw_code}
                                            baseStyles={`z-10 absolute top-5 right-5 ${btnBaseStyles}`}
                                        />
                                        <SandpackCodeViewer
                                            showLineNumbers={true}
                                            showTabs={false}
                                            additionalLanguages={[
                                                {
                                                    name: 'python',
                                                    extensions: ['py'],
                                                    language: python(),
                                                },
                                            ]}
                                        />
                                    </SandpackLayout>
                                </SandpackProvider>
                            </Tabs.Content>
                        </Tabs.Root>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ContentId;
