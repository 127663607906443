import { useLoaderData } from 'react-router-dom';
import * as Tabs from '@radix-ui/react-tabs';
import DemographicForm from './DemographicForm';

interface RespondData {
    demographics: {
        age: number;
    };
}

function Respond() {
    const data = useLoaderData() as RespondData;

    const linkStyles =
        'font-semibold text-green-600 hover:text-green-700 underline dark:text-primary-dark-text-accent dark:hover:text-primary-dark-text-accent';

    return (
        <>
            <div className="flex justify-between items-center space-y-2 sm:space-y-0">
                <h1 className="font-bold text-3xl pb-2">Respond</h1>
            </div>

            <Tabs.Root defaultValue="surveys">
                <Tabs.List
                    className="flex gap-x-2 mb-4 border-b border-gray-200 dark:border-gray-700"
                    aria-label="Switch tabs"
                >
                    <Tabs.Trigger
                        className="p-4 rounded-t-lg border-b-2 text-sm font-medium text-center data-[state=active]:text-blue-600 data-[state=active]:border-blue-600 data-[state=inactive]:border-transparent dark:data-[state=active]:text-primary-dark-text-accent dark:data-[state=active]:border-primary-dark-text-accent"
                        value="surveys"
                    >
                        Surveys
                    </Tabs.Trigger>
                    <Tabs.Trigger
                        className="p-4 rounded-t-lg border-b-2 text-sm font-medium text-center data-[state=active]:text-blue-600 data-[state=active]:border-blue-600 data-[state=inactive]:border-transparent dark:data-[state=active]:text-primary-dark-text-accent dark:data-[state=active]:border-primary-dark-text-accent"
                        value="demographics"
                    >
                        Demographics
                    </Tabs.Trigger>
                </Tabs.List>
                <Tabs.Content value="surveys">
                    <br></br>
                    <i>Feature in development</i>
                </Tabs.Content>
                <Tabs.Content value="demographics">
                    {/* <DemographicForm demographics={data.demographics} /> */}
                </Tabs.Content>
            </Tabs.Root>
        </>
    );
}

export default Respond;
