import KeyValueTable from './KeyValueTable';
import DataTable from './DataTable';
import { FileStoreData } from '../../../../../types';

function Scenario({
    fileStoreData,
    keyValuePairs,
}: {
    fileStoreData: FileStoreData;
    keyValuePairs: { id: number; key: string; value: any }[];
}) {
    const { is_file_store, file_store_type, file_store_content } =
        fileStoreData;

    if (!is_file_store) {
        return (
            <KeyValueTable
                title="Scenario data"
                keyColName="Key"
                valueColName="Value"
                data={keyValuePairs}
            />
        );
    }

    // Handle supported filestore types
    switch (file_store_type) {
        case 'image':
            return (
                <div>
                    <img src={file_store_content.load_link} alt="Scenario" />
                </div>
            );

        case 'video':
            return (
                <div>
                    <video controls>
                        <source
                            src={file_store_content.load_link}
                            type="video/mp4"
                        />
                        Your browser does not support the video tag.
                    </video>
                </div>
            );

        case 'pdf':
            return (
                <div>
                    <object
                        className="w-full h-screen"
                        data={file_store_content.load_link}
                        type="application/pdf"
                    />
                </div>
            );

        case 'html':
            return (
                <div>
                    <iframe
                        src={file_store_content.load_link}
                        className="w-full"
                        sandbox=""
                    />
                </div>
            );

        case 'csv':
            return (
                <DataTable
                    columns={file_store_content.columns}
                    records={file_store_content.records}
                />
            );

        case 'json':
        case 'py':
        case 'txt':
            return (
                <pre className="text-wrap">
                    <code>{file_store_content.code_string}</code>
                </pre>
            );

        case 'custom_rendering_not_supported':
            return (
                <KeyValueTable
                    title="FileStore data"
                    keyColName="Key"
                    valueColName="Value"
                    data={keyValuePairs}
                />
            );
        default:
            return <div>Unsupported file store type</div>;
    }
}

export default Scenario;
