import { useLoaderData, useOutletContext, Link } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';
import CacheEntriesTable from '../../../../CacheEntriesTable';
import { CacheEntry } from '../../../../types';

interface RemoteCacheEntriesData {
    cache_entries: CacheEntry[];
    cache_entry_count: number;
    search_results_count: number;
    current_page: number;
    page_size: number;
    total_pages: number;
    search_query: string | null;
}

function Entries() {
    const data = useLoaderData() as RemoteCacheEntriesData;
    const isDarkMode: boolean = useOutletContext();

    return (
        <>
            <div className="space-y-2">
                <h1 className="font-bold text-3xl pb-2">Legacy Remote Cache</h1>
                <Link
                    to="/home/remote-cache/legacy"
                    className="flex items-center text-lg pb-2 text-blue-500 hover:text-blue-600 dark:text-primary-dark-text-accent dark:hover:text-primary-dark-text-accent"
                >
                    <ArrowLeft
                        className="inline w-4 h-4 ml-1"
                        strokeWidth="2"
                    />
                    <span className="ml-2">
                        Back to legacy remote cache logs
                    </span>
                </Link>
            </div>
            <div className="space-y-4 mt-8">
                <CacheEntriesTable
                    entries={data.cache_entries}
                    currentPage={data.current_page}
                    totalPages={data.total_pages}
                    pageSize={data.page_size}
                    searchQuery={data.search_query}
                    searchResultsCount={data.search_results_count}
                    cacheEntryCount={data.cache_entry_count}
                    isLegacyCacheTable={true}
                    isDarkMode={isDarkMode}
                />
            </div>
        </>
    );
}

export default Entries;
