import { useFetcher } from 'react-router-dom';
import { Trash2 } from 'lucide-react';
import * as Popover from '@radix-ui/react-popover';

function DeleteRateLimitBtn({
    keyId,
    service,
}: {
    keyId: string;
    service: string;
}) {
    const fetcher = useFetcher();

    return (
        <fetcher.Form method="post">
            <input type="hidden" name="key_uuid" value={keyId} />
            <input type="hidden" name="service" value={service} />
            <button
                type="submit"
                name="intent"
                value="delete_key_rate_limit"
                className="bg-red-600 hover:bg-red-700 border-2 border-red-600 hover:border-red-700 rounded-md px-3 py-2 text-white font-semibold text-sm"
                onClick={(event) => event.stopPropagation()}
            >
                Remove
            </button>
        </fetcher.Form>
    );
}

function DeleteRateLimitPopover({
    keyId,
    service,
}: {
    keyId: string;
    service: string;
}) {
    return (
        <Popover.Root>
            <Popover.Trigger asChild>
                <button className="text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-lg text-sm px-3 py-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">
                    <Trash2 className="w-4 h-4" />
                </button>
            </Popover.Trigger>
            <Popover.Portal>
                <Popover.Content
                    className="w-48 bg-white dark:bg-gray-700 rounded-lg shadow z-20"
                    sideOffset={5}
                >
                    <div className="flex flex-col gap-y-4 p-4 text-center text-sm">
                        Remove rate limit for this service?
                        <div className="flex justify-center gap-x-2">
                            <Popover.Close asChild>
                                <button className="hover:bg-gray-300/20 border border-gray-300 dark:border-2 dark:border-gray-500 font-semibold text-sm px-3 py-2 rounded-md">
                                    Cancel
                                </button>
                            </Popover.Close>
                            <DeleteRateLimitBtn
                                keyId={keyId}
                                service={service}
                            />
                        </div>
                    </div>
                </Popover.Content>
            </Popover.Portal>
        </Popover.Root>
    );
}

export default DeleteRateLimitPopover;
