import { Link } from 'react-router-dom';
import CodeBlock from '../../CodeBlock';

function URCStats() {
    const linkStyles =
        'text-blue-500 hover:text-blue-600 hover:underline dark:text-primary-dark-text-accent dark:hover:text-primary-dark-text-accent';

    return (
        <>
            <div className="space-y-2">
                <h1 className="font-bold text-4xl pb-2">
                    Universal Remote Cache
                </h1>
                <p className="text-lg">
                    The universal remote cache is a collection of responses to
                    all the questions that have been run at the Expected Parrot
                    server. This cache is a common repository that is available
                    to all users for free when running surveys remotely. Learn
                    more about{' '}
                    <a
                        href="https://docs.expectedparrot.com/en/latest/remote_caching.html"
                        target="_blank"
                        rel="noopener noreferrer"
                        className={linkStyles}
                    >
                        how it works
                    </a>
                    .
                </p>
            </div>

            <div className="mb-8 min-h-[80vh] overflow-x-auto rounded-lg">
                <div className="relative min-h-[80vh]">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
                        <div className="bg-white dark:bg-gray-800 rounded-lg p-6 shadow-sm">
                            <h3 className="text-lg text-gray-500 dark:text-gray-400 mb-2">
                                Contributors
                            </h3>
                            <p className="text-3xl font-bold">100</p>
                        </div>
                        <div className="bg-white dark:bg-gray-800 rounded-lg p-6 shadow-sm">
                            <h3 className="text-lg text-gray-500 dark:text-gray-400 mb-2">
                                Questions
                            </h3>
                            <p className="text-3xl font-bold">250</p>
                        </div>
                    </div>
                    <br />
                    <br />
                    <table className="min-w-full text-sm relative">
                        <thead className="bg-gray-100 dark:bg-gray-800 sticky border-b dark:border-gray-100/20 top-0 text-lg">
                            <tr className="even:bg-gray-50 dark:even:bg-gray-800/50 border-y dark:border-gray-100/20 dark:text-secondary-dark-text">
                                <th className="text-left whitespace-nowrap w-40 font-semibold text-xl">
                                    Service
                                </th>
                                <th className="text-left whitespace-nowrap w-40 font-semibold text-xl">
                                    Model
                                </th>
                                <th className="text-left whitespace-nowrap w-40 font-semibold text-xl">
                                    Questions
                                </th>
                                <th className="text-left whitespace-nowrap w-40 font-semibold text-xl">
                                    Reproduced
                                </th>
                            </tr>
                        </thead>
                        <tbody className="align-top">
                            <tr className="border-y dark:border-gray-100/20 dark:text-secondary-dark-text">
                                <td className="py-2 px-4">Google</td>
                                <td className="py-2 px-4">gemini-1.5-flash</td>
                                <td className="py-2 px-4">250</td>
                                <td className="py-2 px-4">500</td>
                            </tr>
                        </tbody>
                    </table>
                    <br />
                    <br />
                    <div className="text-sm italic">Last updated: .....</div>
                </div>
            </div>
        </>
    );
}

export default URCStats;
