import { useState } from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import { X } from 'lucide-react';
import { useFetcher } from 'react-router-dom';
import ActionAlert from '../../../../../base/design-system/ActionAlert';
import ServiceSelect, {
    KEY_TO_SERVICES,
    SERVICE_LABELS,
} from '../../ServiceSelect';
import UpdateRateLimitPopover from './UpdateRateLimitPopover';
import DeleteRateLimitPopover from './DeleteRateLimitPopover';

interface EditRateLimitsModalProps {
    children: React.ReactNode;
    keyId: string;
    keyName: string;
    rateLimits: {
        service: string;
        rpm: number | null;
        tpm: number | null;
    }[];
}

function EditRateLimitsModal({
    children,
    keyId,
    keyName,
    rateLimits,
}: EditRateLimitsModalProps) {
    const fetcher = useFetcher();
    const availableServices = KEY_TO_SERVICES[keyName] || [];

    const [service, setService] = useState(availableServices[0]);

    function handleServiceChange(service: string) {
        setService(service);
    }

    function formatRateLimit(rateLimit: number) {
        const formatter = new Intl.NumberFormat('en-US');
        return formatter.format(rateLimit);
    }

    return (
        <Dialog.Root>
            <Dialog.Trigger asChild>{children}</Dialog.Trigger>
            <Dialog.Portal>
                <Dialog.Overlay className="fixed inset-0 bg-black/60 animate-dialog-overlay-show" />
                <Dialog.Content
                    aria-describedby={undefined}
                    className="w-[90vw] max-w-[500px] max-h-[85vh] overflow-y-auto fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 p-6 bg-white dark:bg-gray-700 rounded-md focus:outline-none animate-dialog-content-show"
                >
                    <Dialog.Title asChild>
                        <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                            Add service rate limit
                        </h3>
                    </Dialog.Title>
                    <div className="text-sm py-4 md:py-5">
                        <fetcher.Form method="post">
                            <input
                                type="hidden"
                                name="key_uuid"
                                value={keyId}
                            />

                            <div className="flex flex-col gap-4 mb-3">
                                <div className="flex flex-col md:flex-row md:gap-4">
                                    <div className="h-fit flex flex-col flex-[2] mb-4 md:mb-0">
                                        <div className="block mb-3 text-base font-medium text-gray-900 dark:text-white">
                                            Service
                                        </div>
                                        <ServiceSelect
                                            name="service"
                                            value={service}
                                            onValueChange={handleServiceChange}
                                            availableServices={
                                                availableServices
                                            }
                                        />
                                    </div>
                                    <div className="flex-[1] mb-4 md:mb-0">
                                        <label
                                            htmlFor="rpm-input"
                                            className="block mb-3 text-base font-medium text-gray-900 dark:text-white"
                                        >
                                            RPM
                                        </label>
                                        <input
                                            id="rpm-input"
                                            type="number"
                                            name="rpm"
                                            className="block w-full p-2.5 bg-gray-50 focus:outline-none border rounded-lg border-gray-400 focus:ring-1 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700/20 dark:border-gray-500 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:placeholder-gray-400"
                                            placeholder="100"
                                        />
                                    </div>
                                    <div className="flex-[1]">
                                        <label
                                            htmlFor="tpm-input"
                                            className="block mb-3 text-base font-medium text-gray-900 dark:text-white"
                                        >
                                            TPM
                                        </label>
                                        <input
                                            id="tpm-input"
                                            type="number"
                                            name="tpm"
                                            className="block w-full p-2.5 bg-gray-50 focus:outline-none border rounded-lg border-gray-400 focus:ring-1 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700/20 dark:border-gray-500 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:placeholder-gray-400"
                                            placeholder="100"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="flex justify-end mb-3">
                                <button
                                    type="submit"
                                    name="intent"
                                    value="add_key_rate_limit"
                                    className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                                >
                                    Add rate limit
                                </button>
                            </div>
                            <ActionAlert response={fetcher.data} />
                            <p className="block mt-4 mb-2 text-base font-medium text-gray-900 dark:text-white">
                                Rate limits
                            </p>
                            {rateLimits.length === 0 ? (
                                <p className="border-t border-gray-200 dark:border-gray-600 py-3">
                                    No rate limits configured yet!
                                </p>
                            ) : (
                                <ul>
                                    {rateLimits.map((limit) => (
                                        <li key={limit.service}>
                                            <div className="flex justify-between border-t py-3 border-gray-200 dark:border-gray-600">
                                                <div>
                                                    <div className="font-medium">
                                                        {SERVICE_LABELS[
                                                            limit.service
                                                        ] || limit.service}
                                                    </div>
                                                    <div className="text-sm text-gray-500 dark:text-gray-400">
                                                        {limit.rpm !== null
                                                            ? `RPM: ${formatRateLimit(
                                                                  limit.rpm
                                                              )}`
                                                            : 'RPM: default'}
                                                        {' • '}
                                                        {limit.tpm !== null
                                                            ? `TPM: ${formatRateLimit(
                                                                  limit.tpm
                                                              )}`
                                                            : 'TPM: default'}
                                                    </div>
                                                </div>
                                                <div className="flex items-center gap-x-2">
                                                    <UpdateRateLimitPopover
                                                        keyId={keyId}
                                                        service={limit.service}
                                                        rpm={limit.rpm}
                                                        tpm={limit.tpm}
                                                    />
                                                    <DeleteRateLimitPopover
                                                        keyId={keyId}
                                                        service={limit.service}
                                                    />
                                                </div>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </fetcher.Form>
                    </div>
                    <Dialog.Close asChild>
                        <button
                            className="absolute top-2.5 right-2.5 h-6 w-6 justify-center align-center rounded-full"
                            aria-label="Close"
                        >
                            <X className="inline w-4 h-4" strokeWidth="2" />
                        </button>
                    </Dialog.Close>
                </Dialog.Content>
            </Dialog.Portal>
        </Dialog.Root>
    );
}

export default EditRateLimitsModal;
