import { useFetcher } from 'react-router-dom';
import Toggle from '../../../base/Toggle';

interface EDSLSettingsToggleProps {
    beforelabel: string;
    afterlabel: string;
    id: 'remote-caching' | 'remote-inference' | 'remote-logging';
    defaultValue: boolean;
    name: string;
    value: string;
}

function EDSLSettingsToggle({
    beforelabel,
    afterlabel,
    id,
    defaultValue,
    name,
    value,
}: EDSLSettingsToggleProps) {
    const fetcher = useFetcher();

    const checked = fetcher.formData
        ? fetcher.formData.get('value') === 'true'
        : defaultValue;

    function handleToggle(newValue: boolean) {
        fetcher.submit({ intent: id, value: newValue }, { method: 'post' });
    }

    return (
        <div className="flex justify-start items-center space-x-5 py-1">
            <label
                className={`w-48 transition-all duration-200 ${
                    !checked
                        ? 'font-bold text-blue-600 dark:text-blue-400'
                        : 'font-normal text-gray-500 dark:text-gray-400'
                }`}
                htmlFor={id}
            >
                {beforelabel}
            </label>
            <Toggle
                id={id}
                checked={checked}
                onCheckedChange={handleToggle}
                name={name}
                value={value}
            />
            <label
                className={`w-100 transition-all duration-200 ${
                    checked
                        ? 'font-bold text-blue-600 dark:text-blue-400'
                        : 'font-normal text-gray-500 dark:text-gray-400'
                }`}
                htmlFor={id}
            >
                {afterlabel}
            </label>
        </div>
    );
}

export default EDSLSettingsToggle;
