import * as Select from '@radix-ui/react-select';
import {
    AlignLeft,
    Award,
    Check,
    CheckSquare,
    ChevronDown,
    ChevronUp,
    ChevronsUp,
    Circle,
    DollarSign,
    Hash,
    List,
    Sliders,
    Smile,
    ThumbsUp,
} from 'lucide-react';
import { QuestionType } from './types';
import questionSchema from './questionSchema';

function QuestionIcon({ questionType }: { questionType: QuestionType }) {
    switch (questionType) {
        case 'budget':
            return (
                <DollarSign className="w-4 text-gray-600 dark:text-primary-dark-text" />
            );
        case 'checkbox':
            return (
                <CheckSquare className="w-4 text-gray-600 dark:text-primary-dark-text" />
            );
        case 'free_text':
            return (
                <AlignLeft className="w-4 text-gray-600 dark:text-primary-dark-text" />
            );
        case 'likert_five':
            return (
                <Smile className="w-4 text-gray-600 dark:text-primary-dark-text" />
            );
        case 'linear_scale':
            return (
                <Sliders className="w-4 text-gray-600 dark:text-primary-dark-text" />
            );
        case 'list':
            return (
                <List className="w-4 text-gray-600 dark:text-primary-dark-text" />
            );
        case 'multiple_choice':
            return (
                <Circle className="w-4 text-gray-600 dark:text-primary-dark-text" />
            );
        case 'numerical':
            return (
                <Hash className="w-4 text-gray-600 dark:text-primary-dark-text" />
            );
        case 'rank':
            return (
                <Award className="w-4 text-gray-600 dark:text-primary-dark-text" />
            );
        case 'top_k':
            return (
                <ChevronsUp className="w-4 text-gray-600 dark:text-primary-dark-text" />
            );
        case 'yes_no':
            return (
                <ThumbsUp className="w-4 text-gray-600 dark:text-primary-dark-text" />
            );
        default:
            return null;
    }
}

function QuestionTypeSelect({ question, changeQuestionType }) {
    return (
        <Select.Root
            value={question.type}
            onValueChange={(newType) => changeQuestionType(question, newType)}
        >
            <Select.Trigger
                className="h-full inline-flex items-center justify-center border border-gray-300 dark:border-2 dark:border-gray-500 rounded px-4 py-2 text-sm h-9 gap-2 bg-transparent hover:bg-mauve-300 focus:shadow-outline"
                aria-label="Select a question type"
            >
                <QuestionIcon questionType={question.type} />
                <Select.Value placeholder="Select a question type…" />
                <Select.Icon>
                    <ChevronDown className="ml-3 w-5" strokeWidth={1.5} />
                </Select.Icon>
            </Select.Trigger>
            <Select.Portal>
                <Select.Content className="overflow-hidden bg-white rounded-md shadow-lg">
                    <Select.ScrollUpButton className="flex items-center justify-center h-6 bg-white text-blue-900 cursor-default">
                        <ChevronUp />
                    </Select.ScrollUpButton>
                    <Select.Viewport className="p-1">
                        <Select.Group>
                            <Select.Label className="px-6 py-2 text-xs text-gray-900">
                                Question Types
                            </Select.Label>
                            {questionSchema.map((question, index) => (
                                <Select.Item
                                    key={index}
                                    value={question.type}
                                    className="text-sm text-blue-900 rounded flex items-center h-6 px-9 py-4 relative select-none data-[disabled]:text-mauve-800 data-[disabled]:pointer-events-none data-[highlighted]:outline-none data-[highlighted]:bg-blue-900 data-[highlighted]:text-blue-100"
                                >
                                    <Select.ItemText>
                                        {question.name}
                                    </Select.ItemText>
                                    <Select.ItemIndicator className="absolute left-1 w-5 inline-flex items-center justify-center">
                                        <Check />
                                    </Select.ItemIndicator>
                                </Select.Item>
                            ))}
                        </Select.Group>
                    </Select.Viewport>
                    <Select.ScrollDownButton className="flex items-center justify-center h-6 bg-white text-blue-900 cursor-default">
                        <ChevronDown />
                    </Select.ScrollDownButton>
                </Select.Content>
            </Select.Portal>
        </Select.Root>
    );
}

export default QuestionTypeSelect;
