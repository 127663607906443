import { useState } from 'react';
import { useFetcher, useLoaderData } from 'react-router-dom';
import ActionAlert from '../../../base/design-system/ActionAlert';

interface ConfigVarsData {
    config_vars: { key: string; value: string; data_type: string }[];
}

interface ConfigVar {
    id: string;
    key: string;
    value: string;
    data_type: string;
}

function ConfigVars() {
    const fetcher = useFetcher();

    const data = useLoaderData() as ConfigVarsData;
    const [configVars, setConfigVars] = useState<ConfigVar[]>(() =>
        data.config_vars.map((configVar) => ({
            ...configVar,
            id: crypto.randomUUID(),
        }))
    );
    const handleInputChange = (id: string, newValue: string) => {
        setConfigVars((prevVars) =>
            prevVars.map((configVar) =>
                configVar.id === id
                    ? { ...configVar, value: newValue }
                    : configVar
            )
        );
    };

    function handleSubmit(configVars: ConfigVar[]) {
        fetcher.submit(
            { configVars: JSON.stringify(configVars) },
            { method: 'post', encType: 'application/json' }
        );
    }

    const lightInputStyles =
        'w-64 bg-white focus:outline-none mt-1 border border-gray-400 rounded-lg focus:ring-1 focus:ring-blue-500 focus:border-blue-500 block p-2.5';
    const darkInputStyles =
        'dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-500 dark:focus:border-blue-500';
    const inputStyles = `${lightInputStyles} ${darkInputStyles}`;

    return (
        <div className="space-y-6">
            <p>Update EDSL config variables.</p>
            {configVars.map((configVar) => (
                <div key={configVar.id}>
                    <fieldset>
                        <label htmlFor={configVar.id} className="block">
                            {configVar.key}
                        </label>
                        <input
                            type="text"
                            id={configVar.id}
                            className={inputStyles}
                            value={configVar.value}
                            onChange={(e) =>
                                handleInputChange(configVar.id, e.target.value)
                            }
                        />
                    </fieldset>
                </div>
            ))}
            <button
                onClick={() => handleSubmit(configVars)}
                className="flex gap-2 px-6 py-2.5 bg-green-700 hover:bg-green-800 transition-colors rounded-md text-white font-medium"
            >
                Update
            </button>
            <ActionAlert response={fetcher.data} />
        </div>
    );
}

export default ConfigVars;
