import { Form } from 'react-router-dom';
import * as Dialog from '@radix-ui/react-dialog';
import { X } from 'lucide-react';

function DeleteAccountModal({ children }: { children: React.ReactNode }) {
    return (
        <Dialog.Root>
            <Dialog.Trigger asChild>{children}</Dialog.Trigger>
            <Dialog.Portal>
                <Dialog.Overlay className="fixed inset-0 bg-black/60 animate-dialog-overlay-show" />
                <Dialog.Content className="w-[90vw] max-w-[450px] max-h-[85vh] fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 p-6 bg-white dark:bg-gray-700 rounded-md focus:outline-none animate-dialog-content-show">
                    <div className="flex flex-col items-center text-sm text-center py-4 md:py-5">
                        <Dialog.Title asChild>
                            <h3 className="mb-5 text-lg font-normal">
                                Are you sure you want to delete your account?
                            </h3>
                        </Dialog.Title>
                        <div className="flex">
                            <Dialog.Close asChild>
                                <button
                                    type="button"
                                    className="py-2.5 px-5 me-4 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                                >
                                    No, cancel
                                </button>
                            </Dialog.Close>
                            <Form method="post">
                                <button
                                    type="submit"
                                    name="intent"
                                    value="delete_account"
                                    className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center"
                                >
                                    Yes, I'm sure
                                </button>
                            </Form>
                        </div>
                    </div>

                    <Dialog.Close asChild>
                        <button
                            className="absolute top-2.5 right-2.5 h-6 w-6 inline-flex justify-center align-center rounded-full"
                            aria-label="Close"
                        >
                            <X className="inline w-4 h-4" strokeWidth="2" />
                        </button>
                    </Dialog.Close>
                </Dialog.Content>
            </Dialog.Portal>
        </Dialog.Root>
    );
}

export default DeleteAccountModal;
