import { Link, useLoaderData } from 'react-router-dom';
import ObjectTable, { ObjectTableWrapper } from '../../../base/ObjectTable';
import { ObjectTableData } from '../../../../types';

function ContentIndex() {
    const data = useLoaderData() as ObjectTableData;

    function getTableOrMessage() {
        if (data.results_status === 'no content uploaded') {
            return <p className="pb-2">You haven't posted any content yet.</p>;
        } else if (data.results_status === 'no results found') {
            return <p className="text-lg pb-2">No results found.</p>;
        } else {
            return (
                <ObjectTable
                    pageType="home"
                    objects={data.objects}
                    currentPage={data.current_page}
                    totalPages={data.total_pages}
                />
            );
        }
    }

    return (
        <div className="flex flex-col">
            <ObjectTableWrapper pageType="home" owners={data.owners}>
                {getTableOrMessage()}
            </ObjectTableWrapper>
        </div>
    );
}

export default ContentIndex;
