import { Link } from 'react-router-dom';
import { Check, Edit2, Users } from 'lucide-react';
import DeleteKeyPopover from './DeleteKeyPopover';
import ShareKeyModal from './ShareKeyModal';
import EditRateLimitsModal from './EditRateLimitsModal';
import EPKeyInfoModal from '../EPKeyInfoModal';
import KeyStatusToggle from './KeyStatusToggle';
import { formatDate } from '../../../../../utils';
import { Key } from '../types';

function OwnedKeysTable({ owned_keys }: { owned_keys: Key[] }) {
    const linkStyles =
        'text-blue-500 hover:text-blue-600 dark:text-blue-400 dark:hover:text-blue-300 underline underline-offset-2';

    return (
        <div className="mb-8 overflow-x-auto">
            <div className="relative">
                <table className="min-w-full text-sm relative">
                    <thead className="bg-white dark:bg-primary-dark-bg sticky border-b dark:border-gray-100/20 top-0">
                        <tr>
                            <th className="py-2 px-4 text-left w-1/8 font-semibold whitespace-nowrap">
                                Created on
                            </th>
                            <th className="py-2 px-4 text-left w-3/8 font-semibold whitespace-nowrap">
                                Key
                            </th>
                            <th className="py-2 px-4 text-left w-1/8 font-semibold whitespace-nowrap">
                                Personal use
                            </th>
                            <th className="py-2 px-4 text-center w-1/8 font-semibold whitespace-nowrap">
                                Edit
                            </th>
                            <th className="py-2 px-4 text-left w-1/8 font-semibold whitespace-nowrap">
                                Grant access
                            </th>
                            <th className="py-2 px-4 text-center w-1/8 font-semibold whitespace-nowrap">
                                Delete
                            </th>
                        </tr>
                    </thead>
                    <tbody className="align-top">
                        {owned_keys.map((key, index) => {
                            return (
                                <tr
                                    key={key.id}
                                    className={`border-y dark:border-gray-100/20 dark:text-secondary-dark-text ${
                                        index % 2 === 0
                                            ? 'bg-white dark:bg-gray-800'
                                            : 'bg-gray-50 dark:bg-gray-700'
                                    }`}
                                >
                                    <td className="py-2 px-4 text-left">
                                        {formatDate(
                                            key.created_ts,
                                            'MMMM D, YYYY'
                                        )}
                                    </td>
                                    <td className="py-2 px-4 text-left">
                                        <div className="flex flex-col">
                                            <span className="font-medium flex items-center gap-2">
                                                {key.name}
                                                {key.paired_key_name &&
                                                    `, ${key.paired_key_name}`}
                                                {key.is_ep_key && (
                                                    <Check
                                                        className="w-4 h-4 text-green-600"
                                                        strokeWidth="2.5"
                                                    />
                                                )}
                                            </span>
                                            <span className="space-x-3 text-gray-500 dark:text-gray-400">
                                                {key.is_ep_key && (
                                                    <Link
                                                        to="/home/settings"
                                                        className={linkStyles}
                                                    >
                                                        Manage key
                                                    </Link>
                                                )}
                                                {key.is_ep_key && (
                                                    <span>•</span>
                                                )}
                                                {key.is_ep_key ? (
                                                    <EPKeyInfoModal>
                                                        <button className="text-blue-500 hover:text-blue-600 dark:text-blue-400 dark:hover:text-blue-300 underline underline-offset-2">
                                                            Learn more
                                                        </button>
                                                    </EPKeyInfoModal>
                                                ) : (
                                                    key.description
                                                )}
                                            </span>
                                        </div>
                                    </td>
                                    <td className="py-2 px-4 text-left flex items-center gap-4">
                                        {!key.is_ep_key && (
                                            <KeyStatusToggle
                                                keyId={key.id}
                                                defaultValue={
                                                    key.is_enabled_for_owner
                                                }
                                            />
                                        )}
                                    </td>
                                    <td className="py-2 px-4 relative text-center">
                                        {!key.is_ep_key && (
                                            <EditRateLimitsModal
                                                keyId={key.id}
                                                keyName={key.name}
                                                rateLimits={key.rate_limits}
                                            >
                                                <button className="items-center justify-center p-2 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600">
                                                    <Edit2 className="w-5 h-5" />
                                                </button>
                                            </EditRateLimitsModal>
                                        )}
                                    </td>
                                    <td className="py-2 px-4 flex relative text-center">
                                        <ShareKeyModal
                                            keyId={key.id}
                                            keyName={key.name}
                                            sharedWith={key.shared_with}
                                            isEpKey={key.is_ep_key}
                                        >
                                            <button className="flex items-center gap-2 p-2 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600">
                                                <Users className="w-5 h-5" />
                                                {key.shared_with.length > 0 && (
                                                    <span className="font-medium">
                                                        {key.shared_with.length}
                                                    </span>
                                                )}
                                            </button>
                                        </ShareKeyModal>
                                    </td>
                                    <td className="py-2 px-4 relative text-center">
                                        {!key.is_ep_key && (
                                            <DeleteKeyPopover keyId={key.id} />
                                        )}
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default OwnedKeysTable;
