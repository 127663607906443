const previewLogos = [
    {
        name: 'MIT',
        logo: new URL('../../../public/assets/mit.png', import.meta.url)
            .pathname,
    },
    {
        name: 'Harvard University',
        logo: new URL('../../../public/assets/harvard.png', import.meta.url)
            .pathname,
    },
    {
        name: 'Stanford University',
        logo: new URL('../../../public/assets/stanford.png', import.meta.url)
            .pathname,
    },
    {
        name: 'UC Berkeley',
        logo: new URL('../../../public/assets/berkeley.png', import.meta.url)
            .pathname,
    },
    {
        name: 'Columbia University',
        logo: new URL('../../../public/assets/columbia.png', import.meta.url)
            .pathname,
    },
    {
        name: 'New York University',
        logo: new URL('../../../public/assets/nyu.png', import.meta.url)
            .pathname,
    },
    {
        name: 'University of Chicago',
        logo: new URL('../../../public/assets/chicago.png', import.meta.url)
            .pathname,
    },
    {
        name: 'Yale University',
        logo: new URL('../../../public/assets/yale.png', import.meta.url)
            .pathname,
    },
    {
        name: 'Princeton University',
        logo: new URL('../../../public/assets/princeton.png', import.meta.url)
            .pathname,
    },
];

function HeroLogosGrid() {
    return (
        <div className="grid grid-cols-3 gap-4 w-full">
            {previewLogos.map((org, index) => (
                <div
                    key={index}
                    className="flex items-center justify-center p-2 bg-white dark:bg-gray-800 rounded-lg"
                >
                    <img
                        src={org.logo}
                        alt={org.name}
                        className="w-24 h-auto object-contain filter dark:brightness-90"
                        // Using the exact same styling approach as the main grid, but with fixed width
                    />
                </div>
            ))}
        </div>
    );
}

export default HeroLogosGrid;
