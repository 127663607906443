import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
    ArrowRight,
    ExternalLink,
    Copy,
    Check,
    Code,
    ChevronRight,
} from 'lucide-react';

const EDSLInstallation = () => {
    const linkStyles =
        'text-blue-500 hover:text-blue-600 hover:underline dark:text-primary-dark-text-accent dark:hover:text-primary-dark-text-accent';

    const [copiedText, setCopiedText] = useState('');

    const copyToClipboard = (text) => {
        setCopiedText(text);
        setTimeout(() => setCopiedText(''), 2000);
    };

    return (
        <div className="max-w-4xl mx-auto space-y-12 pb-16 px-4 sm:px-6">
            {/* Introduction */}
            <div className="space-y-4">
                <h1 className="font-bold text-4xl md:text-5xl pb-2 text-blue-600">
                    Installing EDSL
                </h1>
                <p className="text-xl text-gray-700 dark:text-gray-300">
                    EDSL (
                    <span className="italic">
                        Expected Parrot Domain-Specific Language
                    </span>
                    ) is an open-source Python package for simulating surveys,
                    experiments and research tasks with AI agents and large
                    language models.
                </p>
            </div>

            {/* Source Code Section */}
            <div className="border border-gray-200 dark:border-gray-800 rounded-xl p-6 shadow-sm bg-white dark:bg-gray-900">
                <div className="flex items-center gap-3 mb-4">
                    <div className="w-10 h-10 rounded-full bg-blue-100 dark:bg-blue-900 flex items-center justify-center text-blue-600 dark:text-blue-400">
                        <Code size={20} />
                    </div>
                    <h3 className="font-bold text-2xl">Source code</h3>
                </div>

                <p className="mb-4">
                    EDSL is compatible with Python 3.9-3.12. You can access the
                    source code and contribute to the project at{' '}
                    <a
                        href="https://github.com/expectedparrot/edsl"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="inline-flex items-center gap-1 text-blue-500 hover:text-blue-700"
                    >
                        GitHub <ExternalLink size={16} />
                    </a>{' '}
                    or download the package from{' '}
                    <a
                        href="https://pypi.org/project/edsl/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="inline-flex items-center gap-1 text-blue-500 hover:text-blue-700"
                    >
                        PyPI <ExternalLink size={16} />
                    </a>
                    . Please see instructions below for installing from either
                    source.
                </p>

                <p className="mb-4">
                    If you are using <b>Google Colab</b>, please see{' '}
                    <a
                        href="https://docs.expectedparrot.com/en/latest/colab_setup.html"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="inline-flex items-center gap-1 text-blue-500 hover:text-blue-700"
                    >
                        special instructions <ExternalLink size={16} />
                    </a>
                    .
                </p>

                <div className="bg-yellow-50 dark:bg-yellow-900/20 p-4 rounded-lg mb-6 border-l-4 border-yellow-500">
                    <p>
                        <b>Tip:</b> EDSL is in development! We recommend
                        continually updating your version in order to access the
                        latest features and bug fixes.
                    </p>
                </div>
            </div>

            {/* Quickstart Installation */}
            <div className="border border-gray-200 dark:border-gray-800 rounded-xl p-6 shadow-sm bg-white dark:bg-gray-900">
                <div className="flex items-center gap-3 mb-4">
                    <div className="w-10 h-10 rounded-full bg-green-100 dark:bg-green-900 flex items-center justify-center text-green-600 dark:text-green-400">
                        <Code size={20} />
                    </div>
                    <h3 className="font-bold text-2xl">
                        Quickstart Installation
                    </h3>
                </div>

                <p className="mb-4">To install EDSL using pip:</p>

                <div className="relative bg-gray-100 dark:bg-gray-800 rounded-lg p-4 font-mono text-sm mb-4">
                    <pre className="whitespace-pre-wrap break-all">
                        pip install edsl
                    </pre>
                    <button
                        onClick={() => copyToClipboard('pip install edsl')}
                        className="absolute top-2 right-2 p-1 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
                        aria-label="Copy to clipboard"
                    >
                        {copiedText === 'pip install edsl' ? (
                            <Check size={18} />
                        ) : (
                            <Copy size={18} />
                        )}
                    </button>
                </div>

                <p className="mb-4">
                    You can also use <i>uv</i> for a faster installation:
                </p>

                <div className="relative bg-gray-100 dark:bg-gray-800 rounded-lg p-4 font-mono text-sm mb-4 overflow-x-auto">
                    <pre className="whitespace-pre-wrap break-all">
                        !pip install uv && uv pip install edsl
                    </pre>
                    <button
                        onClick={() =>
                            copyToClipboard(
                                '!pip install uv && uv pip install edsl'
                            )
                        }
                        className="absolute top-2 right-2 p-1 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
                        aria-label="Copy to clipboard"
                    >
                        {copiedText ===
                        '!pip install uv && uv pip install edsl' ? (
                            <Check size={18} />
                        ) : (
                            <Copy size={18} />
                        )}
                    </button>
                </div>

                <p className="mb-4">To update to the latest version:</p>

                <div className="relative bg-gray-100 dark:bg-gray-800 rounded-lg p-4 font-mono text-sm mb-4">
                    <pre className="whitespace-pre-wrap break-all">
                        pip install --upgrade edsl
                    </pre>
                    <button
                        onClick={() =>
                            copyToClipboard('pip install --upgrade edsl')
                        }
                        className="absolute top-2 right-2 p-1 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
                        aria-label="Copy to clipboard"
                    >
                        {copiedText === 'pip install --upgrade edsl' ? (
                            <Check size={18} />
                        ) : (
                            <Copy size={18} />
                        )}
                    </button>
                </div>
            </div>

            {/* Installing from source */}
            <div className="border border-gray-200 dark:border-gray-800 rounded-xl p-6 shadow-sm bg-white dark:bg-gray-900">
                <div className="flex items-center gap-3 mb-4">
                    <div className="w-10 h-10 rounded-full bg-purple-100 dark:bg-purple-900 flex items-center justify-center text-purple-600 dark:text-purple-400">
                        <Code size={20} />
                    </div>
                    <h3 className="font-bold text-2xl">
                        Installing from source
                    </h3>
                </div>

                <p className="mb-4">
                    To install EDSL from the source code at GitHub:
                </p>

                <div className="relative bg-gray-100 dark:bg-gray-800 rounded-lg p-4 font-mono text-sm mb-4 overflow-x-auto">
                    <pre className="whitespace-pre-wrap break-all">
                        pip install
                        git+https://github.com/expectedparrot/edsl.git@main
                    </pre>
                    <button
                        onClick={() =>
                            copyToClipboard(
                                'pip install git+https://github.com/expectedparrot/edsl.git@main'
                            )
                        }
                        className="absolute top-2 right-2 p-1 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
                        aria-label="Copy to clipboard"
                    >
                        {copiedText ===
                        'pip install git+https://github.com/expectedparrot/edsl.git@main' ? (
                            <Check size={18} />
                        ) : (
                            <Copy size={18} />
                        )}
                    </button>
                </div>
            </div>

            {/* Using a virtual environment */}
            <div className="border border-gray-200 dark:border-gray-800 rounded-xl p-6 shadow-sm bg-white dark:bg-gray-900">
                <div className="flex items-center gap-3 mb-4">
                    <div className="w-10 h-10 rounded-full bg-indigo-100 dark:bg-indigo-900 flex items-center justify-center text-indigo-600 dark:text-indigo-400">
                        <Code size={20} />
                    </div>
                    <h3 className="font-bold text-2xl">
                        Using a virtual environment
                    </h3>
                </div>

                <p className="mb-4">
                    The steps above will install EDSL globally on your computer.
                    Sometimes, you may face problems with conflicts between EDSL
                    and other packages that you have installed. To avoid such
                    problems, we recommend using EDSL in a virtual environment:
                </p>

                <p className="mb-2">
                    1. Create a folder for the virtual environment (here we name
                    it{' '}
                    <code className="bg-gray-100 dark:bg-gray-800 px-1 py-0.5 rounded">
                        myenv
                    </code>
                    ):
                </p>

                <div className="relative bg-gray-100 dark:bg-gray-800 rounded-lg p-4 font-mono text-sm mb-4">
                    <pre className="whitespace-pre-wrap break-all">
                        python3 -m venv myenv
                    </pre>
                    <button
                        onClick={() => copyToClipboard('python3 -m venv myenv')}
                        className="absolute top-2 right-2 p-1 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
                        aria-label="Copy to clipboard"
                    >
                        {copiedText === 'python3 -m venv myenv' ? (
                            <Check size={18} />
                        ) : (
                            <Copy size={18} />
                        )}
                    </button>
                </div>

                <p className="mb-2">2. Activate the virtual environment.</p>

                <p className="mb-2">On MacOS and Linux:</p>

                <div className="relative bg-gray-100 dark:bg-gray-800 rounded-lg p-4 font-mono text-sm mb-4">
                    <pre className="whitespace-pre-wrap break-all">
                        source myenv/bin/activate
                    </pre>
                    <button
                        onClick={() =>
                            copyToClipboard('source myenv/bin/activate')
                        }
                        className="absolute top-2 right-2 p-1 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
                        aria-label="Copy to clipboard"
                    >
                        {copiedText === 'source myenv/bin/activate' ? (
                            <Check size={18} />
                        ) : (
                            <Copy size={18} />
                        )}
                    </button>
                </div>

                <p className="mb-2">On Windows:</p>

                <div className="relative bg-gray-100 dark:bg-gray-800 rounded-lg p-4 font-mono text-sm mb-4">
                    <pre className="whitespace-pre-wrap break-all">
                        myenv\Scripts\activate
                    </pre>
                    <button
                        onClick={() =>
                            copyToClipboard('myenv\\Scripts\\activate')
                        }
                        className="absolute top-2 right-2 p-1 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
                        aria-label="Copy to clipboard"
                    >
                        {copiedText === 'myenv\\Scripts\\activate' ? (
                            <Check size={18} />
                        ) : (
                            <Copy size={18} />
                        )}
                    </button>
                </div>

                <p className="mb-2">
                    3. Install EDSL through pip within the virtual environment:
                </p>

                <div className="relative bg-gray-100 dark:bg-gray-800 rounded-lg p-4 font-mono text-sm mb-4">
                    <pre className="whitespace-pre-wrap break-all">
                        pip install edsl
                    </pre>
                    <button
                        onClick={() => copyToClipboard('pip install edsl')}
                        className="absolute top-2 right-2 p-1 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
                        aria-label="Copy to clipboard"
                    >
                        {copiedText === 'pip install edsl' ? (
                            <Check size={18} />
                        ) : (
                            <Copy size={18} />
                        )}
                    </button>
                </div>

                <p className="mb-4">
                    You will have access to EDSL while your virtual environment
                    is activated.
                </p>

                <p className="mb-2">To deactivate the virtual environment:</p>

                <div className="relative bg-gray-100 dark:bg-gray-800 rounded-lg p-4 font-mono text-sm mb-4">
                    <pre className="whitespace-pre-wrap break-all">
                        deactivate
                    </pre>
                    <button
                        onClick={() => copyToClipboard('deactivate')}
                        className="absolute top-2 right-2 p-1 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
                        aria-label="Copy to clipboard"
                    >
                        {copiedText === 'deactivate' ? (
                            <Check size={18} />
                        ) : (
                            <Copy size={18} />
                        )}
                    </button>
                </div>

                <p className="mb-4">
                    To delete the virtual environment, simply delete the{' '}
                    <code className="bg-gray-100 dark:bg-gray-800 px-1 py-0.5 rounded">
                        myenv
                    </code>{' '}
                    folder.
                </p>
            </div>

            {/* Footer Links */}
            <div className="border border-gray-200 dark:border-gray-800 rounded-xl p-6 shadow-sm bg-blue-50 dark:bg-blue-900/20">
                <h3 className="font-bold text-2xl mb-4">Next steps</h3>

                <div className="grid md:grid-cols-2 gap-4">
                    <a
                        href="https://docs.expectedparrot.com/en/latest/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="flex items-center justify-center gap-2 px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition"
                    >
                        Read the documentation <ExternalLink size={18} />
                    </a>
                    <a
                        href="https://github.com/expectedparrot/edsl"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="flex items-center justify-center gap-2 px-6 py-3 bg-blue-200 text-blue-800 dark:bg-blue-700 dark:text-white rounded-lg hover:bg-blue-300 dark:hover:bg-blue-600 transition"
                    >
                        View source on GitHub <ExternalLink size={18} />
                    </a>
                </div>
            </div>
        </div>
    );
};

export default EDSLInstallation;
