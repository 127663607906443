export async function action({ request }) {
    const formData = await request.formData();
    let intent = formData.get('intent');

    let response: Response;

    if (intent === 'remote-inference') {
        response = await fetch('/api/edsl-settings', {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                attribute: 'remote_inference',
                value: formData.get('value'),
            }),
        });
    } else if (intent === 'remote-logging') {
        response = await fetch('/api/edsl-settings', {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                attribute: 'remote_logging',
                value: formData.get('value'),
            }),
        });
    }

    if (response.ok) {
        const successData = await response.json();
        return successData.message;
    } else if (response.status === 422) {
        return 'Pydantic error.';
    } else if (response.status === 504) {
        return 'No response from server.';
    } else {
        const errorData = await response.json();
        return errorData.detail;
    }
}
