export async function action({ request }) {
    const formData = await request.formData();
    let intent = formData.get('intent');

    let response: Response;

    if (intent === 'retry_job') {
        response = await fetch(`/api/home/remote-inference`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                job_uuid: formData.get('job_uuid'),
                status: 'queued',
            }),
        });
    } else if (intent === 'cancel_job') {
        response = await fetch(`/api/home/remote-inference`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                job_uuid: formData.get('job_uuid'),
                status: 'cancelling',
            }),
        });
    } else if (intent === 'admin_download_job') {
        response = await fetch(
            `/api/admin/download/jobs/${formData.get('job_uuid')}`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );
    } else if (intent === 'admin_mark_as_failed') {
        response = await fetch(`/api/admin/failed-jobs`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                job_uuid: formData.get('job_uuid'),
                failure_description: formData.get('failure_description'),
            }),
        });
    }
    if (response.ok && intent === 'retry_job') {
        return 'Success! Job added to queue.';
    } else if (response.ok && intent === 'cancel_job') {
        return 'Success! Cancellation request sent.';
    } else if (response.ok && intent === 'admin_download_job') {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        switch (blob.type) {
            case 'application/json':
                a.download = 'job.json';
                break;
        }
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        return 'Successfully downloaded job!';
    } else if (intent == 'admin_mark_as_failed') {
        if (response.ok) {
            return { success: true, message: 'Success! Job marked as failed.' };
        } else if (response.status === 422) {
            return { success: false, message: 'Pydantic error.' };
        } else if (response.status === 504) {
            return { success: false, message: 'No response from server.' };
        } else {
            const errorData = await response.json();
            return { success: false, message: errorData.detail };
        }
    } else if (response.status === 422) {
        return 'Pydantic error.';
    } else if (response.status === 504) {
        return 'No response from server.';
    } else {
        const errorData = await response.json();
        return errorData.detail;
    }
}
