import { useState } from 'react';
import { useCopyToClipboard } from '../../../hooks/useCopyToClipboard';
import { Copy, Check } from 'lucide-react';

interface ModelProgress {
    completed: number;
    total: number;
    percent: number;
}

interface BucketInfo {
    completed: number;
    requested: number;
    tokens_returned: number;
    current_rate: number;
    target_rate: number;
}

interface ModelQueueInfo {
    language_model_name: string;
    requests_bucket: BucketInfo;
    tokens_bucket: BucketInfo;
}

export interface JobStatus {
    job_id: string;
    overall_progress: {
        percent: number;
        completed: number;
        total: number;
    };
    status: 'idle' | 'running' | 'completed';
    language_model_progress: Record<string, ModelProgress>;
    statistics: Record<string, string | number>;
    language_model_queues: Record<string, ModelQueueInfo>;
}

function JobProgressBar({
    status,
    isRemoteJob,
}: {
    status: JobStatus;
    isRemoteJob: boolean;
}) {
    // Copy to clipboard functionality
    const [isCopied, copyToClipboard] = useCopyToClipboard();

    // Helper function to get status color
    const getStatusColor = () => {
        switch (status.status) {
            case 'running':
                return 'bg-blue-600';
            case 'completed':
                return 'bg-green-600';
            case 'idle':
                return 'bg-gray-500';
            default:
                return 'bg-green-600';
        }
    };

    // Helper function to format status text
    const getStatusDisplay = (statusText: string) => {
        return statusText.charAt(0).toUpperCase() + statusText.slice(1);
    };

    return (
        <div className="container mx-auto px-2 py-3 text-gray-700 dark:text-gray-300 max-w-4xl">
            {/* Header section with title, status badge, and UUID */}
            <div className="flex flex-wrap justify-between items-center mb-4">
                <div className="flex items-center gap-3">
                    <h1 className="text-xl font-bold">Progress Report</h1>
                    <div
                        className={`px-2 py-0.5 rounded-full text-white text-sm font-medium ${getStatusColor()}`}
                    >
                        {getStatusDisplay(status.status)}
                    </div>
                </div>

                {isRemoteJob && (
                    <div className="flex items-center mt-2 sm:mt-0">
                        <span className="text-sm text-gray-500 mr-2">
                            Job UUID
                        </span>
                        <button
                            className="font-mono text-sm bg-gray-100 dark:bg-gray-700 px-2 py-0.5 rounded cursor-pointer flex items-center group"
                            onClick={() => copyToClipboard(status.job_id)}
                            aria-label="Click to copy"
                        >
                            <span className="truncate max-w-xs">
                                {status.job_id}
                            </span>
                            <span className="ml-2 text-sm text-gray-400 group-hover:text-gray-600 dark:group-hover:text-gray-300">
                                {isCopied ? (
                                    <Check size={14} />
                                ) : (
                                    <Copy size={14} />
                                )}
                            </span>
                        </button>
                    </div>
                )}
            </div>

            {/* Main content */}
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-3">
                {/* Column 1: Overall Progress */}
                <div className="bg-white dark:bg-gray-800 rounded-lg shadow-sm border border-gray-200 dark:border-gray-700 overflow-hidden">
                    <div className="p-2 border-b border-gray-200 dark:border-gray-700 bg-gray-50 dark:bg-gray-900">
                        <h2 className="font-semibold text-sm">
                            Overall Progress
                        </h2>
                    </div>
                    <div className="p-3">
                        <div className="flex justify-between items-center mb-1 text-sm">
                            <span>{getStatusDisplay(status.status)}</span>
                            <span className="font-medium">
                                {status.overall_progress.percent}%
                            </span>
                        </div>
                        <div className="relative w-full bg-gray-200 dark:bg-gray-700 rounded h-3 overflow-hidden">
                            <div
                                className={`absolute left-0 top-0 h-3 rounded transition-all duration-300 ${getStatusColor()}`}
                                style={{
                                    width: `${status.overall_progress.percent}%`,
                                }}
                            />
                        </div>
                        <div className="mt-1 text-sm text-gray-600 dark:text-gray-400">
                            {status.overall_progress.completed}/
                            {status.overall_progress.total} interviews
                        </div>
                    </div>
                </div>

                {/* Column 2: Key Statistics */}
                <div className="bg-white dark:bg-gray-800 rounded-lg shadow-sm border border-gray-200 dark:border-gray-700 overflow-hidden">
                    <div className="p-2 border-b border-gray-200 dark:border-gray-700 bg-gray-50 dark:bg-gray-900">
                        <h2 className="font-semibold text-sm">
                            Key Statistics
                        </h2>
                    </div>
                    <div className="divide-y divide-gray-100 dark:divide-gray-700 text-sm">
                        <div className="flex justify-between p-2">
                            <span>Completed interviews</span>
                            <span className="font-medium">
                                {status.statistics['Completed interviews']}
                            </span>
                        </div>
                        <div className="flex justify-between p-2">
                            <span>Exceptions</span>
                            <span className="font-medium">
                                {status.statistics['Exceptions']}
                            </span>
                        </div>
                        <div className="flex justify-between p-2">
                            <span>Elapsed time</span>
                            <span className="font-medium">
                                {status.statistics['Elapsed time']}
                            </span>
                        </div>
                        <div className="flex justify-between p-2">
                            <span>Est. remaining</span>
                            <span className="font-medium">
                                {status.statistics['Estimated time remaining']}
                            </span>
                        </div>
                    </div>
                </div>

                {/* Column 3: Model Progress */}
                <div className="bg-white dark:bg-gray-800 rounded-lg shadow-sm border border-gray-200 dark:border-gray-700 overflow-hidden">
                    <div className="p-2 border-b border-gray-200 dark:border-gray-700 bg-gray-50 dark:bg-gray-900">
                        <h2 className="font-semibold text-sm">
                            Model Progress
                        </h2>
                    </div>
                    <div className="p-3">
                        {Object.entries(status.language_model_progress).map(
                            ([model, progress]) => (
                                <div key={model} className="mb-2">
                                    <div className="flex justify-between items-center mb-1">
                                        <span className="text-sm truncate mr-1">
                                            {model}
                                        </span>
                                        <span className="text-sm">
                                            {progress.percent}%
                                        </span>
                                    </div>
                                    <div className="relative w-full bg-gray-200 dark:bg-gray-700 rounded h-2 overflow-hidden">
                                        <div
                                            className="absolute left-0 top-0 bg-blue-500 h-2 rounded transition-all duration-300"
                                            style={{
                                                width: `${progress.percent}%`,
                                            }}
                                        />
                                    </div>
                                </div>
                            )
                        )}
                    </div>
                </div>
            </div>

            {/* Detailed Statistics in a second row */}
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-3 mt-3">
                {/* Interviews & Exceptions Card */}
                <div className="bg-white dark:bg-gray-800 rounded-lg shadow-sm border border-gray-200 dark:border-gray-700 overflow-hidden">
                    <div className="p-2 border-b border-gray-200 dark:border-gray-700 bg-gray-50 dark:bg-gray-900">
                        <h2 className="font-semibold text-sm">
                            Detailed Stats
                        </h2>
                    </div>
                    <div className="divide-y divide-gray-100 dark:divide-gray-700">
                        {[
                            [
                                'Total interviews requested',
                                status.statistics['Total interviews requested'],
                            ],
                            [
                                'Completed interviews',
                                status.statistics['Completed interviews'],
                            ],
                            [
                                'Unfixed exceptions',
                                status.statistics['Unfixed exceptions'],
                            ],
                            [
                                'Average time per interview',
                                status.statistics['Average time per interview'],
                            ],
                            ['Throughput', status.statistics['Throughput']],
                        ].map(([key, value]) => (
                            <div key={key} className="flex justify-between p-2">
                                <span className="text-sm">{key}</span>
                                <span className="text-sm font-medium">
                                    {value}
                                </span>
                            </div>
                        ))}
                    </div>
                </div>

                {/* Model Queues - Always Expanded */}
                <div className="bg-white dark:bg-gray-800 rounded-lg shadow-sm border border-gray-200 dark:border-gray-700 overflow-hidden">
                    <div className="p-2 border-b border-gray-200 dark:border-gray-700 bg-gray-50 dark:bg-gray-900">
                        <h2 className="font-semibold text-sm">Model Queues</h2>
                    </div>

                    <div className="divide-y divide-gray-200 dark:divide-gray-700 max-h-96 overflow-y-auto">
                        {Object.entries(status.language_model_queues).map(
                            ([modelName, queueInfo]) => (
                                <div key={modelName} className="p-3">
                                    <h3 className="text-sm font-medium mb-2">
                                        {modelName}
                                    </h3>

                                    <div className="overflow-x-auto">
                                        <table className="min-w-full text-sm">
                                            <thead>
                                                <tr className="bg-gray-50 dark:bg-gray-900">
                                                    <th className="px-2 py-1 text-left text-gray-500 dark:text-gray-400"></th>
                                                    <th className="px-2 py-1 text-right text-gray-500 dark:text-gray-400">
                                                        Requests
                                                    </th>
                                                    <th className="px-2 py-1 text-right text-gray-500 dark:text-gray-400">
                                                        Tokens
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td className="px-2 py-1 text-gray-600 dark:text-gray-400">
                                                        Completed/Req
                                                    </td>
                                                    <td className="px-2 py-1 text-right">
                                                        {
                                                            queueInfo
                                                                .requests_bucket
                                                                .completed
                                                        }
                                                        /
                                                        {
                                                            queueInfo
                                                                .requests_bucket
                                                                .requested
                                                        }
                                                    </td>
                                                    <td className="px-2 py-1 text-right">
                                                        {
                                                            queueInfo
                                                                .tokens_bucket
                                                                .completed
                                                        }
                                                        /
                                                        {
                                                            queueInfo
                                                                .tokens_bucket
                                                                .requested
                                                        }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="px-2 py-1 text-gray-600 dark:text-gray-400">
                                                        Tokens Returned
                                                    </td>
                                                    <td className="px-2 py-1 text-right">
                                                        {
                                                            queueInfo
                                                                .requests_bucket
                                                                .tokens_returned
                                                        }
                                                    </td>
                                                    <td className="px-2 py-1 text-right">
                                                        {
                                                            queueInfo
                                                                .tokens_bucket
                                                                .tokens_returned
                                                        }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="px-2 py-1 text-gray-600 dark:text-gray-400">
                                                        Rate (Cur/Target)
                                                    </td>
                                                    <td className="px-2 py-1 text-right">
                                                        {
                                                            queueInfo
                                                                .requests_bucket
                                                                .current_rate
                                                        }
                                                        /
                                                        {
                                                            queueInfo
                                                                .requests_bucket
                                                                .target_rate
                                                        }
                                                    </td>
                                                    <td className="px-2 py-1 text-right">
                                                        {
                                                            queueInfo
                                                                .tokens_bucket
                                                                .current_rate
                                                        }
                                                        /
                                                        {
                                                            queueInfo
                                                                .tokens_bucket
                                                                .target_rate
                                                        }
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            )
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default JobProgressBar;
