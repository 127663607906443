import { Moon, Sun } from 'lucide-react';

interface DarkModeToggleProps {
    isDarkMode: boolean;
    toggleDarkMode: (value: boolean) => void;
    styles: string;
}

function DarkModeToggle({
    isDarkMode,
    toggleDarkMode,
    styles,
}: DarkModeToggleProps) {
    if (isDarkMode) {
        return (
            <button
                onClick={() => toggleDarkMode(false)}
                type="button"
                className={styles}
                aria-label="Use light mode"
            >
                <Sun />
            </button>
        );
    } else {
        return (
            <button
                onClick={() => toggleDarkMode(true)}
                type="button"
                className={styles}
                aria-label="Use dark mode"
            >
                <Moon />
            </button>
        );
    }
}

export default DarkModeToggle;
