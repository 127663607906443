import * as Dialog from '@radix-ui/react-dialog';
import { useEffect, useState } from 'react';
import { useFetcher } from 'react-router-dom';
import { X } from 'lucide-react';
import ActionAlert from '../../../base/design-system/ActionAlert';
import { ActionResponse } from '../../../../types';
import ServiceSelect, {
    SERVICE_TO_KEY,
    SERVICE_TO_PAIRED_KEY,
} from './ServiceSelect';

interface KeyModalState {
    service: string;
    keyName: string;
    keyValue: string;
    pairedKeyName: string;
    pairedKeyValue: string;
    description: string;
}

function getPairedKeyName(service: string) {
    const pairedKeyName = SERVICE_TO_PAIRED_KEY[service];
    if (typeof pairedKeyName === 'string') {
        return pairedKeyName;
    }
    return null;
}

function getPairedKeyValue(service: string) {
    const pairedKeyName = SERVICE_TO_PAIRED_KEY[service];
    if (typeof pairedKeyName === 'string') {
        // Default value is the empty string
        return '';
    }
    return null;
}

function getDefaultKeyState(
    defaultService: string = 'anthropic'
): KeyModalState {
    return {
        service: defaultService,
        keyName: SERVICE_TO_KEY[defaultService],
        keyValue: '',
        pairedKeyName: getPairedKeyName(defaultService),
        pairedKeyValue: getPairedKeyValue(defaultService),
        description: '',
    };
}

function AddKeyModal({ children }: { children: React.ReactNode }) {
    const fetcher = useFetcher();
    const [response, setResponse] = useState<ActionResponse | null>(null);
    const [keyState, setKeyState] = useState<KeyModalState>(() =>
        getDefaultKeyState()
    );

    useEffect(() => {
        if (fetcher.state === 'idle' && fetcher.data) {
            setResponse(fetcher.data);
        }
    }, [fetcher.state, fetcher.data]);

    const availableServices = Object.keys(SERVICE_TO_KEY);

    const handleChange = (field: keyof KeyModalState, value: string) => {
        setKeyState((prev) => ({
            ...prev,
            [field]: value,
        }));
    };

    const handleServiceChange = (service: string) => {
        setKeyState({
            service,
            keyName: SERVICE_TO_KEY[service],
            keyValue: '',
            pairedKeyName: getPairedKeyName(service),
            pairedKeyValue: getPairedKeyValue(service),
            description: '',
        });
    };

    function handleSubmit() {
        const keyData: Record<string, string> = {
            keyName: keyState.keyName,
            keyValue: keyState.keyValue,
            pairedKeyName: keyState.pairedKeyName,
            pairedKeyValue: keyState.pairedKeyValue,
            description: keyState.description,
        };

        fetcher.submit(
            { intent: 'create_keys', key: JSON.stringify(keyData) },
            {
                method: 'post',
                encType: 'application/json',
            }
        );
    }

    const handleOpenChange = (isOpen: boolean) => {
        if (!isOpen) {
            const defaultKeyState = getDefaultKeyState();
            setKeyState(defaultKeyState);
            // Reset response
            setResponse(null);
        }
    };

    return (
        <Dialog.Root onOpenChange={handleOpenChange}>
            <Dialog.Trigger asChild>{children}</Dialog.Trigger>
            <Dialog.Portal>
                <Dialog.Overlay className="fixed inset-0 bg-black/60 animate-dialog-overlay-show" />
                <Dialog.Content
                    aria-describedby={undefined}
                    className="w-[90vw] max-w-[600px] max-h-[85vh] fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 space-y-4 p-8 bg-white dark:bg-gray-700 rounded-md focus:outline-none animate-dialog-content-show overflow-y-auto"
                >
                    <Dialog.Title asChild>
                        <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                            Add API key
                        </h3>
                    </Dialog.Title>

                    <div className="flex flex-col gap-4 text-sm">
                        <ServiceSelect
                            name="service"
                            availableServices={availableServices}
                            value={keyState.service}
                            onValueChange={handleServiceChange}
                        />

                        <div className="space-y-4">
                            <div>
                                <label
                                    htmlFor="key-value"
                                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                >
                                    {keyState.keyName}
                                </label>
                                <input
                                    id="key-value"
                                    name="key-value"
                                    value={keyState.keyValue}
                                    onChange={(e) =>
                                        handleChange('keyValue', e.target.value)
                                    }
                                    className="block p-2.5 w-full text-sm bg-gray-50 focus:outline-none border rounded-lg border-gray-400 focus:ring-1 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700/20 dark:border-gray-500 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:placeholder-gray-400"
                                />
                            </div>

                            {keyState.pairedKeyName && (
                                <div>
                                    <label
                                        htmlFor="paired-key-value"
                                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                    >
                                        {keyState.pairedKeyName}
                                    </label>
                                    <input
                                        id="paired-key-value"
                                        name="paired-key-value"
                                        value={keyState.pairedKeyValue}
                                        onChange={(e) =>
                                            handleChange(
                                                'pairedKeyValue',
                                                e.target.value
                                            )
                                        }
                                        className="block p-2.5 w-full text-sm bg-gray-50 focus:outline-none border rounded-lg border-gray-400 focus:ring-1 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700/20 dark:border-gray-500 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:placeholder-gray-400"
                                    />
                                </div>
                            )}

                            <div>
                                <label
                                    htmlFor="description"
                                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                >
                                    Description
                                </label>
                                <input
                                    id="description"
                                    name="description"
                                    value={keyState.description}
                                    onChange={(e) =>
                                        handleChange(
                                            'description',
                                            e.target.value
                                        )
                                    }
                                    className="block p-2.5 w-full text-sm bg-gray-50 focus:outline-none border rounded-lg border-gray-400 focus:ring-1 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700/20 dark:border-gray-500 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:placeholder-gray-400"
                                />
                            </div>
                        </div>

                        <button
                            type="submit"
                            onClick={handleSubmit}
                            className="self-end text-white px-8 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                        >
                            Add
                        </button>
                        <ActionAlert response={response} />
                    </div>

                    <Dialog.Close asChild>
                        <button
                            className="absolute top-2.5 right-2.5 h-6 w-6 inline-flex justify-center align-center rounded-full"
                            aria-label="Close"
                        >
                            <X className="inline w-4 h-4" strokeWidth="2" />
                        </button>
                    </Dialog.Close>
                </Dialog.Content>
            </Dialog.Portal>
        </Dialog.Root>
    );
}

export default AddKeyModal;
