export async function action({ request }) {
    let data;
    let intent;

    const contentType = request.headers.get('Content-Type');

    if (contentType && contentType.includes('application/json')) {
        data = await request.json();
        intent = data.intent;
    } else {
        data = await request.formData();
        intent = data.get('intent');
    }

    let response: Response;

    if (intent === 'create_keys') {
        const key = JSON.parse(data.key);
        response = await fetch('/api/keys', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                key_name: key.keyName,
                key_value: key.keyValue,
                paired_key_name: key.pairedKeyName,
                paired_key_value: key.pairedKeyValue,
                description: key.description,
            }),
        });
    } else if (intent === 'delete_key') {
        const keyId = data.get('key_uuid');
        response = await fetch(`/api/keys/${keyId}`, {
            method: 'DELETE',
        });
    } else if (intent === 'share_key') {
        const keyId = data.get('key_uuid');
        const usernameOrEmail = data.get('username_or_email');
        const monthlySpendLimitUsd = data.get('monthly_spend_limit_usd');
        const isEpKey = data.get('is_ep_key');
        const url =
            isEpKey === 'true' ? `/api/api-keys/share` : `/api/keys/share`;
        response = await fetch(url, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                key_uuid: keyId,
                username_or_email: usernameOrEmail,
                monthly_spend_limit_usd: monthlySpendLimitUsd,
            }),
        });
    } else if (intent === 'unshare_key') {
        const shareId = data.get('share_uuid');
        const isEpKey = data.get('is_ep_key');
        const url =
            isEpKey === 'true' ? `/api/api-keys/unshare` : `/api/keys/unshare`;
        response = await fetch(url, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ share_uuid: shareId }),
        });
    } else if (intent === 'update_share') {
        const shareId = data.get('share_uuid');
        const isEnabledByOwner = data.get('is_enabled_by_owner');
        const isEnabledByUser = data.get('is_enabled_by_user');
        const monthlySpendLimitUsd = data.get('monthly_spend_limit_usd');
        const isEpKey = data.get('is_ep_key');
        const url =
            isEpKey === 'true' ? `/api/api-keys/share` : `/api/keys/share`;
        response = await fetch(url, {
            method: 'PATCH',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                share_uuid: shareId,
                monthly_spend_limit_usd: monthlySpendLimitUsd,
                is_enabled_by_owner: isEnabledByOwner,
                is_enabled_by_user: isEnabledByUser,
            }),
        });
    } else if (intent === 'update_key_status') {
        const keyId = data.get('key_uuid');
        const isEnabledForOwner = data.get('is_enabled_for_owner');
        response = await fetch(`/api/keys/status/${keyId}`, {
            method: 'PATCH',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ is_enabled_for_owner: isEnabledForOwner }),
        });
    } else if (intent === 'add_key_rate_limit') {
        const keyId = data.get('key_uuid');
        const service = data.get('service');
        const rpm = data.get('rpm');
        const tpm = data.get('tpm');
        response = await fetch(`/api/keys/rate-limits/${keyId}`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ service, rpm, tpm }),
        });
    } else if (intent === 'update_key_rate_limit') {
        const keyId = data.get('key_uuid');
        const service = data.get('service');
        const rpm = data.get('rpm');
        const tpm = data.get('tpm');
        response = await fetch(`/api/keys/rate-limits/${keyId}`, {
            method: 'PATCH',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ service, rpm, tpm }),
        });
    } else if (intent === 'delete_key_rate_limit') {
        const keyId = data.get('key_uuid');
        const service = data.get('service');
        response = await fetch(`/api/keys/rate-limits/${keyId}`, {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ service }),
        });
    } else if (intent === 'reset_api_key') {
        response = await fetch('/api/reset-api-key', {
            method: 'PATCH',
        });
    }

    if (response.ok) {
        const successData = await response.json();
        return { success: true, message: successData.message };
    } else if (response.status === 422) {
        const errorData = await response.json();
        console.log(errorData);
        return { success: false, message: 'Pydantic error.' };
    } else if (response.status === 504) {
        return { success: false, message: 'No response from server.' };
    } else {
        const errorData = await response.json();
        return { success: false, message: errorData.detail };
    }
}
