import { useState } from 'react';
import {
    useActionData,
    useFetcher,
    useLoaderData,
    useOutletContext,
    useSubmit,
} from 'react-router-dom';
import * as Tabs from '@radix-ui/react-tabs';
import {
    SandpackProvider,
    SandpackLayout,
    SandpackCodeViewer,
} from '@codesandbox/sandpack-react';
import { python } from '@codemirror/lang-python';
import { Box, Plus, Save, Send, Zap } from 'lucide-react';
import * as VisuallyHidden from '@radix-ui/react-visually-hidden';
import Question from './Questions';
import QuestionControls from './QuestionControls';
import QuestionLogic from './QuestionLogic';
import {
    getNewQuestion,
    getQuestionFromData,
    getQuestionWithUpdatedType,
    getDefaultConditionValues,
} from './helpers';
import {
    Question as EDSLQuestion,
    QuestionType,
    QuestionWithOptionLabels,
    QuestionWithOptions,
    Condition,
    Rule,
} from './types';
import { ActionResponse } from '../../../../types';
import SurveyFlow from '../../../Content/routes/ContentId/ObjectData/SurveyFlow';
import { CreateSurveyModal, CreateProjectModal } from './PublishSurveyModals';

interface ExistingQuestions {
    questions: EDSLQuestion[];
}

function getExampleSurvey(): EDSLQuestion[] {
    const breakfastQuestionId = crypto.randomUUID();
    const daysAteWafflesQuestionId = crypto.randomUUID();
    const rateWafflesQuestionId = crypto.randomUUID();
    const agreeQuestionId = crypto.randomUUID();
    const disagreeQuestionId = crypto.randomUUID();

    const breakfastFoodsOptionIds = {
        waffles: crypto.randomUUID(),
        eggs: crypto.randomUUID(),
        toast: crypto.randomUUID(),
    };

    // Generate option IDs upfront
    const rateWafflesOptionIds = {
        stronglyDisagree: crypto.randomUUID(),
        disagree: crypto.randomUUID(),
        neutral: crypto.randomUUID(),
        agree: crypto.randomUUID(),
        stronglyAgree: crypto.randomUUID(),
    };

    return [
        {
            id: breakfastQuestionId,
            text: 'Which of the following have you had for breakfast in the past week?',
            rules: [
                {
                    id: crypto.randomUUID(),
                    first_word: 'if',
                    conditions: [
                        {
                            id: crypto.randomUUID(),
                            question_id: breakfastQuestionId,
                            connector: null,
                            operator: 'does_not_contain',
                            value: breakfastFoodsOptionIds.waffles,
                        },
                    ],
                    next_question_id: 'end_of_survey',
                },
            ],
            type: 'checkbox',
            structure: {
                options: [
                    {
                        id: breakfastFoodsOptionIds.waffles,
                        text: 'Waffles',
                    },
                    {
                        id: breakfastFoodsOptionIds.eggs,
                        text: 'Eggs',
                    },
                    {
                        id: breakfastFoodsOptionIds.toast,
                        text: 'Toast',
                    },
                ],
                minSelections: '',
                maxSelections: '',
            },
            constraints: {
                defaultOptions: [
                    {
                        text: 'Option 1',
                    },
                    {
                        text: 'Option 2',
                    },
                ],
                minNumOptions: 2,
                maxNumOptions: 200,
            },
        },
        {
            id: daysAteWafflesQuestionId,
            text: 'About how many waffles have you eaten this week?',
            rules: [
                {
                    id: crypto.randomUUID(),
                    first_word: 'if',
                    conditions: [
                        {
                            id: crypto.randomUUID(),
                            question_id: daysAteWafflesQuestionId,
                            connector: null,
                            operator: 'is_equal_to',
                            value: '0',
                        },
                    ],
                    next_question_id: 'end_of_survey',
                },
            ],
            type: 'numerical',
            structure: {
                minValue: '0',
                maxValue: '',
            },
            constraints: {},
        },
        {
            id: rateWafflesQuestionId,
            text: 'Rate your agreement with the following statement: I like waffles.',
            rules: [
                {
                    id: crypto.randomUUID(),
                    first_word: 'if',
                    conditions: [
                        {
                            id: crypto.randomUUID(),
                            question_id: rateWafflesQuestionId,
                            connector: null,
                            operator: 'is',
                            value: rateWafflesOptionIds.stronglyDisagree,
                        },
                        {
                            id: crypto.randomUUID(),
                            question_id: rateWafflesQuestionId,
                            connector: 'or',
                            operator: 'is',
                            value: rateWafflesOptionIds.disagree,
                        },
                    ],
                    next_question_id: disagreeQuestionId,
                },
                {
                    id: crypto.randomUUID(),
                    first_word: 'if',
                    conditions: [
                        {
                            id: crypto.randomUUID(),
                            question_id: rateWafflesQuestionId,
                            connector: null,
                            operator: 'is',
                            value: rateWafflesOptionIds.agree,
                        },
                        {
                            id: crypto.randomUUID(),
                            question_id: rateWafflesQuestionId,
                            connector: 'or',
                            operator: 'is',
                            value: rateWafflesOptionIds.stronglyAgree,
                        },
                    ],
                    next_question_id: agreeQuestionId,
                },
            ],
            type: 'likert_five',
            structure: {
                options: [
                    {
                        id: rateWafflesOptionIds.stronglyDisagree,
                        text: 'Strongly disagree',
                    },
                    {
                        id: rateWafflesOptionIds.disagree,
                        text: 'Disagree',
                    },
                    {
                        id: rateWafflesOptionIds.neutral,
                        text: 'Neutral',
                    },
                    {
                        id: rateWafflesOptionIds.agree,
                        text: 'Agree',
                    },
                    {
                        id: rateWafflesOptionIds.stronglyAgree,
                        text: 'Strongly agree',
                    },
                ],
            },
            constraints: {
                defaultOptions: [
                    {
                        text: 'Strongly disagree',
                    },
                    {
                        text: 'Disagree',
                    },
                    {
                        text: 'Neutral',
                    },
                    {
                        text: 'Agree',
                    },
                    {
                        text: 'Strongly agree',
                    },
                ],
                minNumOptions: 2,
                maxNumOptions: 200,
            },
        },
        {
            id: agreeQuestionId,
            text: 'Why do you like waffles?',
            rules: [
                {
                    id: crypto.randomUUID(),
                    first_word: 'always',
                    conditions: [],
                    next_question_id: 'end_of_survey',
                },
            ],
            type: 'free_text',
            structure: {},
            constraints: {},
        },
        {
            id: disagreeQuestionId,
            text: 'Why do you dislike waffles?',
            rules: [],
            type: 'free_text',
            structure: {},
            constraints: {},
        },
    ];
}

function Survey() {
    const isDarkMode: boolean = useOutletContext();

    const starterQuestionData = useLoaderData() as ExistingQuestions;

    const fetcher = useFetcher();

    const isInEditMode = starterQuestionData.questions !== undefined;

    const actionData = useActionData() as ActionResponse & {
        intent: string;
        code?: string;
        questions?: {
            question_name: string;
            question_options?: string[];
            question_text: string;
            question_type: string;
        }[];
        rule_collection?: {
            rules: {
                current_q: number;
                expression: string;
                next_q: number;
                priority: number;
            }[];
        };
    };

    // Create survey and create project response will be displayed as an alert in the modal
    const createSurveyResponse =
        actionData !== undefined && actionData.intent === 'create_survey'
            ? actionData
            : null;

    const createProjectResponse =
        actionData !== undefined && actionData.intent === 'create_project'
            ? actionData
            : null;

    // All other errors should be displayed in the Code tab
    const surveyData =
        fetcher.data !== undefined && fetcher.data.intent === 'get_code'
            ? fetcher.data
            : null;

    const [questions, setQuestions] = useState<EDSLQuestion[]>(() => {
        if (isInEditMode) {
            const starterQuestions = starterQuestionData.questions.map(
                (question) => getQuestionFromData(question)
            );
            return starterQuestions;
        } else {
            const starterQuestions = [getNewQuestion('multiple_choice')];
            return starterQuestions;
        }
    });
    // console.log(questions);
    const submit = useSubmit();

    function addQuestion(type: QuestionType) {
        const newQuestion = getNewQuestion(type);
        setQuestions([...questions, newQuestion]);
    }

    function changeQuestionType(question: EDSLQuestion, newType: QuestionType) {
        const updatedQuestion = getQuestionWithUpdatedType(question, newType);
        setQuestions((prevQuestions) =>
            prevQuestions.map((q) =>
                q.id === question.id ? updatedQuestion : q
            )
        );
    }

    function updateQuestion(updatedQuestion: EDSLQuestion) {
        setQuestions((prevQuestions) =>
            prevQuestions.map((q) =>
                q.id === updatedQuestion.id ? updatedQuestion : q
            )
        );
    }

    function removeQuestion(questionId: string) {
        setQuestions((prevQuestions) =>
            prevQuestions.filter((q) => q.id !== questionId)
        );
    }

    function moveQuestionUp(questionId: string) {
        setQuestions((prevQuestions) => {
            const index = prevQuestions.findIndex((q) => q.id === questionId);
            if (index <= 0) return prevQuestions;

            const newQuestions = [...prevQuestions];
            [newQuestions[index - 1], newQuestions[index]] = [
                newQuestions[index],
                newQuestions[index - 1],
            ];
            return newQuestions;
        });
    }

    function moveQuestionDown(questionId: string) {
        setQuestions((prevQuestions) => {
            const index = prevQuestions.findIndex((q) => q.id === questionId);
            if (index === -1 || index === prevQuestions.length - 1)
                return prevQuestions;

            const newQuestions = [...prevQuestions];
            [newQuestions[index], newQuestions[index + 1]] = [
                newQuestions[index + 1],
                newQuestions[index],
            ];
            return newQuestions;
        });
    }

    function copyQuestion(questionId: string) {
        setQuestions((prevQuestions) => {
            const index = prevQuestions.findIndex((q) => q.id === questionId);
            if (index === -1) return prevQuestions;

            const questionToCopy = prevQuestions[index];
            const copiedQuestion = {
                ...questionToCopy,
                id: crypto.randomUUID(),
            };

            const newQuestions = [
                ...prevQuestions.slice(0, index + 1),
                copiedQuestion,
                ...prevQuestions.slice(index + 1),
            ];
            return newQuestions;
        });
    }

    function handleOptionLabelChange(
        question: QuestionWithOptionLabels,
        optionId: string,
        optionLabel: string
    ) {
        const newOptions = question.structure.options.map((option) =>
            option.id === optionId ? { ...option, label: optionLabel } : option
        );
        updateQuestion({
            ...question,
            structure: { ...question.structure, options: newOptions },
        } as QuestionWithOptionLabels);
    }

    function handleOptionTextChange(
        question: QuestionWithOptions,
        optionId: string,
        optionText: string
    ) {
        const newOptions = question.structure.options.map((option) =>
            option.id === optionId ? { ...option, text: optionText } : option
        );
        updateQuestion({
            ...question,
            structure: { ...question.structure, options: newOptions },
        } as QuestionWithOptions);
    }

    function addOption(question: QuestionWithOptions) {
        const newOption =
            question.type === 'linear_scale'
                ? {
                      id: crypto.randomUUID(),
                      text: `${question.structure.options.length + 1}`,
                      label: '',
                  }
                : {
                      id: crypto.randomUUID(),
                      text: `Option ${question.structure.options.length + 1}`,
                  };

        updateQuestion({
            ...question,
            structure: {
                ...question.structure,
                options: [...question.structure.options, newOption],
            },
        } as QuestionWithOptions);
    }

    function removeOption(question: QuestionWithOptions, optionId: string) {
        const newOptions = question.structure.options.filter(
            (option) => option.id !== optionId
        );
        updateQuestion({
            ...question,
            structure: {
                ...question.structure,
                options: newOptions,
            },
        } as QuestionWithOptions);
    }

    // Conditional logic
    function addRule(questionId: string) {
        setQuestions((prevQuestions) =>
            prevQuestions.map((q) => {
                if (q.id === questionId) {
                    const { question_id, connector, operator, defaultValue } =
                        getDefaultConditionValues(q);
                    const newRule: Rule = {
                        id: crypto.randomUUID(),
                        first_word: 'if',
                        conditions: [
                            {
                                id: crypto.randomUUID(),
                                question_id,
                                connector,
                                operator,
                                value: defaultValue,
                            },
                        ],
                        next_question_id: 'end_of_survey',
                    };

                    return {
                        ...q,
                        rules: [...q.rules, newRule],
                    };
                }
                return q;
            })
        );
    }

    function updateRule(questionId: string, ruleId: string, updatedRule: Rule) {
        setQuestions((prevQuestions) =>
            prevQuestions.map((q) => {
                if (q.id === questionId) {
                    return {
                        ...q,
                        rules: q.rules.map((r) =>
                            r.id === ruleId ? updatedRule : r
                        ),
                    };
                }
                return q;
            })
        );
    }

    function removeRule(questionId: string, ruleId: string) {
        setQuestions((prevQuestions) =>
            prevQuestions.map((q) => {
                if (q.id === questionId) {
                    return {
                        ...q,
                        rules: q.rules.filter((r) => r.id !== ruleId),
                    };
                }
                return q;
            })
        );
    }

    function addCondition(questionId: string, ruleId: string) {
        setQuestions((prevQuestions) =>
            prevQuestions.map((q) => {
                if (q.id === questionId) {
                    return {
                        ...q,
                        rules: q.rules.map((r) => {
                            if (r.id === ruleId) {
                                const {
                                    question_id,
                                    connector,
                                    operator,
                                    defaultValue,
                                } = getDefaultConditionValues(q);
                                const newCondition: Condition = {
                                    id: crypto.randomUUID(),
                                    question_id,
                                    connector: 'and',
                                    operator,
                                    value: defaultValue,
                                };
                                return {
                                    ...r,
                                    conditions: [...r.conditions, newCondition],
                                };
                            }
                            return r;
                        }),
                    };
                }
                return q;
            })
        );
    }

    function updateCondition(
        questionId: string,
        ruleId: string,
        conditionId: string,
        updatedCondition: Condition
    ) {
        setQuestions((prevQuestions) =>
            prevQuestions.map((q) => {
                if (q.id === questionId) {
                    return {
                        ...q,
                        rules: q.rules.map((r) => {
                            if (r.id === ruleId) {
                                return {
                                    ...r,
                                    conditions: r.conditions.map((c) =>
                                        c.id === conditionId
                                            ? updatedCondition
                                            : c
                                    ),
                                };
                            }
                            return r;
                        }),
                    };
                }
                return q;
            })
        );
    }

    function removeCondition(
        questionId: string,
        ruleId: string,
        conditionId: string
    ) {
        setQuestions((prevQuestions) =>
            prevQuestions.map((q) => {
                if (q.id === questionId) {
                    return {
                        ...q,
                        rules: q.rules.map((r) => {
                            if (r.id === ruleId) {
                                return {
                                    ...r,
                                    conditions: r.conditions.filter(
                                        (c) => c.id !== conditionId
                                    ),
                                };
                            }
                            return r;
                        }),
                    };
                }
                return q;
            })
        );
    }
    // Function for generating an example survey
    function handleGenerateExampleSurvey() {
        const exampleSurvey = getExampleSurvey();
        setQuestions(exampleSurvey);
    }

    // Functions for fetching code and creating survey/project
    function handleGetCode(intent: string) {
        const serializedQuestions = JSON.stringify(questions);
        fetcher.submit(
            { questions: serializedQuestions, intent: intent },
            { method: 'post', encType: 'application/json' }
        );
    }

    function handleEditSurvey(intent: string) {
        const serializedQuestions = JSON.stringify(questions);
        submit(
            { questions: serializedQuestions, intent: intent },
            { method: 'post', encType: 'application/json' }
        );
    }

    return (
        <Tabs.Root
            defaultValue="survey"
            onValueChange={(newTab) => {
                if (newTab === 'code' || newTab === 'flow') {
                    handleGetCode('get_code');
                }
            }}
        >
            <div className="flex flex-col lg:flex-row justify-between">
                <Tabs.List
                    className="flex gap-x-2 mb-4"
                    aria-label="Switch tabs"
                >
                    <Tabs.Trigger
                        className="px-4 pb-4 border-b-2 rounded-t-lg text-sm font-medium text-center data-[state=active]:text-blue-600 data-[state=active]:border-blue-600 data-[state=inactive]:border-gray-200 dark:data-[state=active]:text-primary-dark-text-accent dark:data-[state=active]:border-primary-dark-text-accent"
                        value="survey"
                    >
                        Survey
                    </Tabs.Trigger>
                    <Tabs.Trigger
                        className="px-4 pb-4 border-b-2 rounded-t-lg text-sm font-medium text-center data-[state=active]:text-blue-600 data-[state=active]:border-blue-600 data-[state=inactive]:border-gray-200 dark:data-[state=active]:text-primary-dark-text-accent dark:data-[state=active]:border-primary-dark-text-accent"
                        value="logic"
                    >
                        Logic
                    </Tabs.Trigger>
                    <Tabs.Trigger
                        className="px-4 pb-4 border-b-2 rounded-t-lg text-sm font-medium text-center data-[state=active]:text-blue-600 data-[state=active]:border-blue-600 data-[state=inactive]:border-gray-200 dark:data-[state=active]:text-primary-dark-text-accent dark:data-[state=active]:border-primary-dark-text-accent"
                        value="code"
                    >
                        <div className="flex">
                            Code
                            {/* Alert icon for errors */}
                            {surveyData !== null && !surveyData.success && (
                                <div className="h-2 w-2 ml-1 rounded-full bg-red-400" />
                            )}
                        </div>
                    </Tabs.Trigger>
                    <Tabs.Trigger
                        className="px-4 pb-4 border-b-2 rounded-t-lg text-sm font-medium text-center data-[state=active]:text-blue-600 data-[state=active]:border-blue-600 data-[state=inactive]:border-gray-200 dark:data-[state=active]:text-primary-dark-text-accent dark:data-[state=active]:border-primary-dark-text-accent"
                        value="flow"
                    >
                        Flow
                    </Tabs.Trigger>
                </Tabs.List>
                <div className="flex flex-row gap-4 mb-4">
                    {/* <button
                        className="flex items-center gap-2 px-4 py-2.5 bg-green-600 hover:bg-green-700 hover:transition-colors rounded-md text-white font-medium text-sm"
                        onClick={handleGenerateExampleSurvey}
                    >
                        <Zap className="w-4" />
                        Example survey
                    </button> */}
                    {isInEditMode ? (
                        <button
                            onClick={() => handleEditSurvey('edit_survey')}
                            className="flex items-center gap-2 px-4 py-2.5 bg-green-600 hover:bg-green-700 hover:transition-colors rounded-md text-white font-medium text-sm"
                        >
                            <Save className="w-4" />
                            Save changes to survey
                        </button>
                    ) : (
                        <CreateProjectModal
                            questions={questions}
                            createProjectResponse={createProjectResponse}
                        >
                            <button className="flex items-center gap-2 px-4 py-2.5 bg-green-600 hover:bg-green-700 hover:transition-colors rounded-md text-white font-medium text-sm">
                                <Send className="w-4" />
                                Create project
                            </button>
                        </CreateProjectModal>
                    )}
                </div>
            </div>
            <Tabs.Content value="survey">
                {questions.map((question, index) => (
                    <div
                        key={question.id}
                        className="flex flex-col mt-4 p-12 space-y-6 border dark:border-primary-dark-border rounded-md text-sm"
                    >
                        <QuestionControls
                            question={question}
                            changeQuestionType={changeQuestionType}
                            copyQuestion={copyQuestion}
                            moveQuestionUp={moveQuestionUp}
                            moveQuestionDown={moveQuestionDown}
                            removeQuestion={removeQuestion}
                        />
                        <div className="flex flex-col md:flex-row gap-8">
                            <div className="relative flex grow">
                                <div className="flex items-center mr-2 px-3 border-gray-500 rounded-md">
                                    {index + 1}.
                                </div>
                                <VisuallyHidden.Root>
                                    <label
                                        className="block text-blue-500 dark:text-primary-dark-text-accent font-bold py-2"
                                        htmlFor={`${question.id}-text`}
                                    >
                                        Question text
                                    </label>
                                </VisuallyHidden.Root>
                                <input
                                    type="text"
                                    id={`${question.id}-text`}
                                    name={`${question.id}-text`}
                                    value={question.text}
                                    placeholder="Question text"
                                    onChange={(event) => {
                                        updateQuestion({
                                            ...question,
                                            text: event.target.value,
                                        });
                                    }}
                                    className="grow bg-slate-50 dark:bg-gray-700/30 focus:outline-none border-b-2 border-gray-400 focus:border-b-2 focus:border-b-blue-500 block p-2.5 dark:border-b-gray-500 dark:focus:border-b-blue-500 dark:placeholder-gray-400"
                                />
                            </div>
                        </div>
                        <Question
                            question={question}
                            updateQuestion={updateQuestion}
                            addOption={addOption}
                            handleOptionLabelChange={handleOptionLabelChange}
                            handleOptionTextChange={handleOptionTextChange}
                            removeOption={removeOption}
                        />
                    </div>
                ))}
                <div className="flex justify-center mt-4">
                    <button
                        onClick={() => addQuestion('free_text')}
                        className="flex px-4 py-2.5 hover:bg-gray-300/20 transition-colors border border-gray-300 dark:border-2 dark:border-gray-500 rounded-md font-medium text-sm"
                    >
                        Add question
                    </button>
                </div>
            </Tabs.Content>
            <Tabs.Content value="logic">
                {questions.map((question, index) => (
                    <div
                        key={question.id}
                        className="flex flex-col mt-4 border border-gray-300 dark:border-gray-500 rounded-lg text-sm"
                    >
                        <div className="flex items-center p-4 bg-gray-100 dark:bg-slate-700/50 rounded-t-lg border-b border-gray-300 dark:border-gray-500 font-medium">
                            <div className="flex items-center mr-2 px-3">
                                {index + 1}. {question.text}
                            </div>
                        </div>
                        <div className="p-4">
                            <QuestionLogic
                                questions={questions}
                                question={question}
                                questionIndex={index}
                                updateRule={updateRule}
                                updateCondition={updateCondition}
                                removeRule={removeRule}
                                addCondition={addCondition}
                                removeCondition={removeCondition}
                            />
                            <div>
                                {[
                                    'budget',
                                    'linear_scale',
                                    'list',
                                    'rank',
                                ].includes(question.type) ? (
                                    <p className="text-gray-600 dark:text-gray-400 italic">
                                        Conditional logic is not available for{' '}
                                        {question.type.replace('_', ' ')}{' '}
                                        questions.
                                    </p>
                                ) : (
                                    <button
                                        onClick={() => addRule(question.id)}
                                        className="flex items-center gap-2 mt-4 ml-8 px-4 py-2 hover:bg-gray-300/20 transition-colors border border-gray-300 dark:border-2 dark:border-gray-500 rounded-md font-medium text-sm"
                                    >
                                        <Plus className="w-4 h-4" />
                                        Add rule
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                ))}
            </Tabs.Content>
            <Tabs.Content value="code">
                <SandpackProvider
                    options={{ classes: { 'sp-stack': '!h-fit' } }}
                    files={{
                        'survey_code.py': {
                            code:
                                surveyData !== null
                                    ? surveyData.code
                                    : '# Nothing to see here yet!',
                            active: true,
                        },
                    }}
                    theme={isDarkMode ? 'dark' : 'light'}
                >
                    <SandpackLayout>
                        <SandpackCodeViewer
                            showLineNumbers={true}
                            showTabs={false}
                            additionalLanguages={[
                                {
                                    name: 'python',
                                    extensions: ['py'],
                                    language: python(),
                                },
                            ]}
                        ></SandpackCodeViewer>
                    </SandpackLayout>
                </SandpackProvider>
            </Tabs.Content>
            <Tabs.Content value="flow">
                {fetcher.state !== 'idle' ? (
                    'Loading visualization...'
                ) : surveyData !== null && surveyData.success ? (
                    <SurveyFlow
                        questions={surveyData.questions}
                        rule_collection={surveyData.rule_collection}
                    />
                ) : (
                    'There was an error loading the survey flow. See the Code tab for more details.'
                )}
            </Tabs.Content>
        </Tabs.Root>
    );
}

export default Survey;
