import { Link } from 'react-router-dom';
import * as Dialog from '@radix-ui/react-dialog';
import { X } from 'lucide-react';

function EPKeyInfoModal({ children }: { children: React.ReactNode }) {
    const linkStyles =
        'text-blue-500 hover:text-blue-600 dark:text-blue-400 dark:hover:text-blue-300 underline underline-offset-2';

    return (
        <Dialog.Root>
            <Dialog.Trigger asChild>{children}</Dialog.Trigger>
            <Dialog.Portal>
                <Dialog.Overlay className="fixed inset-0 bg-black/60 animate-dialog-overlay-show" />
                <Dialog.Content
                    aria-describedby={undefined}
                    className="w-[90vw] max-w-[550px] fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 p-6 bg-white dark:bg-gray-700 rounded-md focus:outline-none animate-dialog-content-show"
                >
                    <Dialog.Title asChild>
                        <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                            About your Expected Parrot API key
                        </h3>
                    </Dialog.Title>

                    <div className="mt-4 text-gray-600 dark:text-gray-300 space-y-4">
                        <p>
                            Your Expected Parrot key lets you interact with Coop
                            and run surveys with all{' '}
                            <Link to="/home/pricing" className={linkStyles}>
                                available models
                            </Link>{' '}
                            at the Expected Parrot server.
                        </p>
                        <p>
                            You can view and reset your key at your{' '}
                            <Link to="/home/settings" className={linkStyles}>
                                Settings
                            </Link>{' '}
                            and view or replenish your credit balance at your{' '}
                            <Link to="/home/credits" className={linkStyles}>
                                Credits
                            </Link>{' '}
                            page.
                        </p>
                        <p>Your Expected Parrot key:</p>
                        <ul className="list-disc pl-6 space-y-2">
                            <li>Uses our default token rate limits</li>
                            <li>
                                Can be shared with other users (using your
                                credit balance)
                            </li>{' '}
                            <li>Can be reset at any time</li>
                            <li>Cannot be deleted</li>
                        </ul>
                        <p>
                            Learn more about{' '}
                            <Link
                                to="/getting-started/edsl-api-keys"
                                className={linkStyles}
                            >
                                managing keys
                            </Link>
                            .
                        </p>
                    </div>

                    <Dialog.Close asChild>
                        <button
                            className="absolute top-2.5 right-2.5 h-6 w-6 justify-center align-center rounded-full"
                            aria-label="Close"
                        >
                            <X className="inline w-4 h-4" strokeWidth="2" />
                        </button>
                    </Dialog.Close>
                </Dialog.Content>
            </Dialog.Portal>
        </Dialog.Root>
    );
}

export default EPKeyInfoModal;
