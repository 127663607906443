import React from 'react';
import { useLoaderData } from 'react-router-dom';
import {
    Camera,
    User,
    AlertTriangle,
    Beaker,
    Mail,
    Calendar,
} from 'lucide-react';
import * as Avatar from '@radix-ui/react-avatar';
import BetaToggle from './BetaToggle';
import ChangeProfilePhotoModal from './ChangeProfilePhotoModal';
import DeleteAccountModal from './DeleteAccountModal';
import EditUsernameForm from './EditUsernameForm';
import { formatDate } from '../../../../utils';

interface ProfileData {
    is_beta_user: boolean;
    email: string;
    created_ts: number;
    username: string;
    base_64_profile_photo: string;
}

function Profile() {
    const profileData = useLoaderData() as ProfileData;
    const formattedDate = formatDate(profileData.created_ts, 'MMMM D, YYYY');

    return (
        <>
            {/* Header */}
            <div className="space-y-4">
                <h1 className="font-bold text-4xl pb-2">Profile</h1>
                <p className="text-xl text-gray-700 dark:text-gray-300">
                    Manage your account settings and preferences.
                </p>
            </div>

            {/* Profile Information Section */}
            <div className="border border-gray-200 dark:border-gray-800 rounded-xl p-6 shadow-sm bg-white dark:bg-gray-900">
                <div className="flex items-center gap-3 mb-6">
                    <div className="w-10 h-10 rounded-full bg-blue-100 dark:bg-blue-900 flex items-center justify-center text-blue-600 dark:text-blue-400">
                        <User size={20} />
                    </div>
                    <h3 className="font-bold text-2xl">Account Information</h3>
                </div>

                <div className="flex flex-col-reverse md:flex-row gap-8">
                    {/* Account Details */}
                    <div className="md:w-2/3 space-y-6">
                        <div className="grid gap-4">
                            <div className="flex items-start gap-3 p-4 bg-gray-50 dark:bg-gray-800 rounded-lg">
                                <Calendar
                                    className="mt-1 text-gray-500 dark:text-gray-400"
                                    size={20}
                                />
                                <div>
                                    <h4 className="font-semibold mb-1">
                                        Account created
                                    </h4>
                                    <p>{formattedDate}</p>
                                </div>
                            </div>

                            <div className="flex items-start gap-3 p-4 bg-gray-50 dark:bg-gray-800 rounded-lg">
                                <Mail
                                    className="mt-1 text-gray-500 dark:text-gray-400"
                                    size={20}
                                />
                                <div>
                                    <h4 className="font-semibold mb-1">
                                        Email
                                    </h4>
                                    <p>{profileData.email}</p>
                                </div>
                            </div>

                            <div className="flex items-start gap-3 p-4 bg-gray-50 dark:bg-gray-800 rounded-lg">
                                <User
                                    className="mt-1 text-gray-500 dark:text-gray-400"
                                    size={20}
                                />
                                <div className="w-full">
                                    <EditUsernameForm
                                        currentUsername={profileData.username}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Profile Photo */}
                    <div className="md:w-1/3 flex flex-col items-center">
                        <div className="relative mb-4">
                            <Avatar.Root className="inline-flex size-40 select-none items-center justify-center overflow-hidden rounded-full bg-green-100 align-middle">
                                <Avatar.Image
                                    className="size-full rounded-full object-cover"
                                    src={profileData.base_64_profile_photo}
                                    alt="Profile photo"
                                />
                                <Avatar.Fallback
                                    className="flex size-full items-center justify-center bg-green-100 text-5xl leading-1 font-medium text-green-800"
                                    delayMs={600}
                                >
                                    {profileData.username
                                        ? profileData.username
                                              .slice(0, 1)
                                              .toUpperCase()
                                        : 'AN'}
                                </Avatar.Fallback>
                            </Avatar.Root>
                            <ChangeProfilePhotoModal
                                username={profileData.username}
                                profilePhoto={profileData.base_64_profile_photo}
                            >
                                <button
                                    type="button"
                                    className="absolute -bottom-3 right-0 rounded-full p-3 bg-blue-500 hover:bg-blue-600 text-white shadow-md transition"
                                    aria-label="Change profile photo"
                                >
                                    <Camera className="w-5 h-5" />
                                </button>
                            </ChangeProfilePhotoModal>
                        </div>
                    </div>
                </div>
            </div>

            {/* Beta Features Section */}
            <div className="border border-gray-200 dark:border-gray-800 rounded-xl p-6 shadow-sm bg-white dark:bg-gray-900">
                <div className="flex items-center gap-3 mb-4">
                    <div className="w-10 h-10 rounded-full bg-purple-100 dark:bg-purple-900 flex items-center justify-center text-purple-600 dark:text-purple-400">
                        <Beaker size={20} />
                    </div>
                    <h3 className="font-bold text-2xl">Beta Features</h3>
                </div>

                <p className="mb-6 text-gray-700 dark:text-gray-300">
                    Get access to new features in development. Beta features may
                    change frequently and might not be fully stable.
                </p>

                <div className="p-4 bg-gray-50 dark:bg-gray-800 rounded-lg">
                    <BetaToggle defaultValue={profileData.is_beta_user} />
                </div>
            </div>

            {/* Delete Account Section */}
            <div className="border border-gray-200 dark:border-gray-800 rounded-xl p-6 shadow-sm bg-white dark:bg-gray-900">
                <div className="flex items-center gap-3 mb-4">
                    <div className="w-10 h-10 rounded-full bg-red-100 dark:bg-red-900/50 flex items-center justify-center text-red-600 dark:text-red-400">
                        <AlertTriangle size={20} />
                    </div>
                    <h3 className="font-bold text-2xl">Delete Account</h3>
                </div>

                <div className="bg-red-50 dark:bg-red-900/20 p-4 rounded-lg mb-6 border-l-4 border-red-500">
                    <p className="text-red-800 dark:text-red-200">
                        <b>Warning:</b> Deleting your account is permanent. All
                        of your personal data will be removed and cannot be
                        recovered.
                    </p>
                </div>

                <DeleteAccountModal>
                    <button
                        type="submit"
                        name="intent"
                        value="delete_account"
                        className="mt-2 bg-red-600 hover:bg-red-700 border-2 border-red-600 hover:border-red-700 rounded-md px-4 py-2 text-white font-semibold flex items-center gap-2"
                    >
                        <AlertTriangle size={16} />
                        Delete account
                    </button>
                </DeleteAccountModal>
            </div>
        </>
    );
}

export default Profile;
