import { useFetcher } from 'react-router-dom';
import Toggle from '../../../../base/Toggle';

function KeyStatusToggle({
    keyId,
    defaultValue,
}: {
    keyId: string;
    defaultValue: boolean;
}) {
    const fetcher = useFetcher();

    const checked = fetcher.formData
        ? fetcher.formData.get('is_enabled_for_owner') === 'true'
        : defaultValue;

    function handleToggle(newValue: boolean) {
        fetcher.submit(
            {
                intent: 'update_key_status',
                key_uuid: keyId,
                is_enabled_for_owner: newValue,
            },
            {
                method: 'post',
            }
        );
    }

    return (
        <Toggle
            id={`key-toggle-${keyId}`}
            checked={checked}
            onCheckedChange={handleToggle}
            name="is_enabled_for_owner"
        />
    );
}

export default KeyStatusToggle;
