import { Form, Link, useLoaderData } from 'react-router-dom';
import * as Popover from '@radix-ui/react-popover';
import { ArrowDown, ArrowUp, Check, Search, Trash2 } from 'lucide-react';
import DeleteUnverifiedUsersForm from './DeleteUnverifiedUsersForm';
import DeleteUserForm from './DeleteUserForm';
import UpdateCreditsModal from './UpdateCreditsModal';
import Pagination from '../../../base/Pagination';
import { formatDate } from '../../../../utils';

interface User {
    credits: number;
    email: string;
    email_verified: boolean;
    id: string;
    created_ts: number;
    username: string;
}

interface UserData {
    users: User[];
    current_page: number;
    page_size: number;
    search_results_count: number;
    total_pages: number;
    search_query: string;
}

function Home() {
    const data = useLoaderData() as UserData;

    const startIndex = (data.current_page - 1) * data.page_size + 1;
    const endIndex = Math.min(
        startIndex + data.page_size - 1,
        data.search_results_count
    );

    function getSortIcon(colName: string) {
        const searchParams = new URLSearchParams(window.location.search);
        const sortColName = searchParams.get('sort_col');
        const sortAscending = searchParams.get('sort_ascending');

        if (colName === sortColName && sortAscending === 'True') {
            return <ArrowUp className="inline w-4 h-4" strokeWidth="2" />;
        } else if (colName === sortColName && sortAscending === 'False') {
            return <ArrowDown className="inline w-4 h-4" strokeWidth="2" />;
        } else {
            return (
                <ArrowUp
                    className="inline w-4 h-4 opacity-0 group-hover:opacity-100"
                    strokeWidth="2"
                />
            );
        }
    }

    function getSortURL(colName: string) {
        const searchParams = new URLSearchParams(window.location.search);
        const sortColName = searchParams.get('sort_col');
        const sortAscending = searchParams.get('sort_ascending');

        searchParams.set('sort_col', colName);
        if (colName === sortColName && sortAscending === 'True') {
            searchParams.set('sort_ascending', 'False');
        } else {
            searchParams.set('sort_ascending', 'True');
        }

        return `?${searchParams.toString()}`;
    }

    return (
        <>
            <h1 className="font-bold text-3xl pb-2">Users</h1>
            {data.search_results_count === 0 ? (
                <p>No users found</p>
            ) : data.search_query ? (
                <p>
                    Showing {startIndex}-{endIndex} of{' '}
                    {data.search_results_count} results for "{data.search_query}
                    "
                </p>
            ) : (
                <p>
                    Showing {startIndex}-{endIndex} of{' '}
                    {data.search_results_count} users
                </p>
            )}
            <Form className="relative">
                <input
                    type="text"
                    name="search_query"
                    className="w-full px-4 py-2 text-sm border border-gray-500 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 dark:placeholder-gray-400"
                    placeholder="Search by username or email..."
                />
                <button
                    type="submit"
                    aria-label="Search"
                    className="h-full absolute top-0 end-0 px-4 py-2.5 text-sm font-medium text-white bg-blue-500 hover:bg-blue-600 rounded-r-lg border-blue-500 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-500 dark:hover:bg-blue-600 dark:focus:ring-blue-800"
                >
                    <Search className="w-4 h-4" />
                </button>
            </Form>
            <div className="flex flex-wrap-reverse gap-8 justify-between">
                <Pagination
                    style="grayscale"
                    currentPage={data.current_page}
                    totalPages={data.total_pages}
                />
                <DeleteUnverifiedUsersForm />
            </div>
            <div>
                <div className="mb-8 overflow-x-auto">
                    <div className="relative">
                        <table className="min-w-full text-sm relative">
                            <thead className="bg-white dark:bg-primary-dark-bg sticky border-b dark:border-gray-100/20 top-0">
                                <tr>
                                    <th className="text-left w-40 font-semibold">
                                        <Link
                                            className="flex items-center gap-x-1 w-full h-full py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-50/10 group"
                                            to={getSortURL('email')}
                                        >
                                            Email {getSortIcon('email')}
                                        </Link>
                                    </th>
                                    <th className="text-left w-40 font-semibold">
                                        <Link
                                            className="flex items-center gap-x-1 w-full h-full py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-50/10 group"
                                            to={getSortURL('username')}
                                        >
                                            Username {getSortIcon('username')}
                                        </Link>
                                    </th>
                                    <th className="text-left w-40 font-semibold">
                                        <Link
                                            className="flex whitespace-nowrap items-center gap-x-1 w-full h-full py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-50/10 group"
                                            to={getSortURL('created_ts')}
                                        >
                                            Registered on{' '}
                                            {getSortIcon('created_ts')}
                                        </Link>
                                    </th>
                                    <th className="text-left w-40 font-semibold">
                                        <Link
                                            className="flex items-center gap-x-1 w-full h-full py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-50/10 group"
                                            to={getSortURL('email_verified')}
                                        >
                                            Verified{' '}
                                            {getSortIcon('email_verified')}
                                        </Link>
                                    </th>
                                    <th className="text-left w-40 font-semibold">
                                        <Link
                                            className="flex items-center gap-x-1 w-full h-full py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-50/10 group"
                                            to={getSortURL('credits')}
                                        >
                                            Credits {getSortIcon('credits')}
                                        </Link>
                                    </th>
                                    <th className="py-2 px-4 text-center w-40 font-semibold">
                                        Give/taketh
                                    </th>
                                    <th className="py-2 px-4 text-center w-40 font-semibold">
                                        Delete
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="align-top">
                                {data.users.map((user, index) => (
                                    <tr
                                        key={user.id}
                                        className={`border-y dark:border-gray-100/20 dark:text-secondary-dark-text ${
                                            index % 2 === 0
                                                ? 'bg-white dark:bg-gray-800'
                                                : 'bg-gray-50 dark:bg-gray-700'
                                        }`}
                                    >
                                        <td className="py-2 px-4 text-left">
                                            {user.email}
                                        </td>
                                        <td className="py-2 px-4 text-left">
                                            {user.username !== null ? (
                                                <Link
                                                    to={`/admin/users/${user.username}`}
                                                    className="underline underline-offset-4 hover:no-underline"
                                                >
                                                    {user.username}
                                                </Link>
                                            ) : (
                                                'None'
                                            )}
                                        </td>
                                        <td className="py-2 px-4 text-left">
                                            {formatDate(
                                                user.created_ts,
                                                'MMMM D, YYYY'
                                            )}
                                        </td>
                                        <td className="py-2 px-4 text-left">
                                            {user.email_verified && (
                                                <Check
                                                    strokeWidth={3}
                                                    className="inline w-5 h-5 text-green-400"
                                                />
                                            )}
                                        </td>
                                        <td className="py-2 px-4 text-left">
                                            {user.credits}
                                        </td>
                                        <td className="py-2 px-4 text-center">
                                            <UpdateCreditsModal
                                                email={user.email}
                                            >
                                                <button className="underline underline-offset-4 hover:no-underline">
                                                    Gift...
                                                </button>
                                            </UpdateCreditsModal>
                                        </td>
                                        <td className="py-2 px-4 text-center relative">
                                            <Popover.Root>
                                                <Popover.Trigger asChild>
                                                    <button>
                                                        <Trash2
                                                            className="inline w-4 h-4 ml-1 hover:text-red-700"
                                                            strokeWidth="2"
                                                        />
                                                    </button>
                                                </Popover.Trigger>
                                                <Popover.Portal>
                                                    <Popover.Content
                                                        className="w-48 bg-white dark:bg-gray-700 rounded-lg shadow"
                                                        sideOffset={5}
                                                    >
                                                        <div className="flex flex-col gap-y-4 p-4 text-center">
                                                            Permanently delete
                                                            this user?
                                                            <div className="flex gap-x-2">
                                                                <Popover.Close
                                                                    asChild
                                                                >
                                                                    <button className="hover:bg-gray-300/20 border border-gray-300 dark:border-2 dark:border-gray-500 font-semibold text-sm px-3 py-2 rounded-md">
                                                                        Cancel
                                                                    </button>
                                                                </Popover.Close>
                                                                <DeleteUserForm
                                                                    email={
                                                                        user.email
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    </Popover.Content>
                                                </Popover.Portal>
                                            </Popover.Root>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Home;
