import { useFetcher } from 'react-router-dom';
import Toggle from '../../../base/Toggle';

function BetaToggle({ defaultValue }: { defaultValue: boolean }) {
    const fetcher = useFetcher();

    const checked = fetcher.formData
        ? fetcher.formData.get('value') === 'true'
        : defaultValue;

    function handleToggle(newValue: boolean) {
        fetcher.submit(
            { intent: 'toggle_beta', value: newValue },
            { method: 'post' }
        );
    }

    return (
        <div className="flex justify-start items-center space-x-10 py-1">
            <label className="w-40" htmlFor="beta-toggle">
                Activate beta features
            </label>
            <Toggle
                id="beta-toggle"
                checked={checked}
                onCheckedChange={handleToggle}
                name="intent"
                value="toggle_beta"
            />
        </div>
    );
}

export default BetaToggle;
