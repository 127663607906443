interface Organization {
    name: string;
    logo: string;
}

interface LogosGridProps {
    title?: string;
    second_title?: string;
    third_title?: string;
    preview?: boolean;
    uniformSize?: boolean;
}

const universities = [
    {
        name: 'MIT',
        logo: new URL('../../../public/assets/mit.png', import.meta.url)
            .pathname,
    },
    {
        name: 'Harvard University',
        logo: new URL('../../../public/assets/harvard.png', import.meta.url)
            .pathname,
    },
    {
        name: 'Stanford University',
        logo: new URL('../../../public/assets/stanford.png', import.meta.url)
            .pathname,
    },
    {
        name: 'UC Berkeley',
        logo: new URL('../../../public/assets/berkeley.png', import.meta.url)
            .pathname,
    },
    {
        name: 'Columbia University',
        logo: new URL('../../../public/assets/columbia.png', import.meta.url)
            .pathname,
    },
    {
        name: 'New York University',
        logo: new URL('../../../public/assets/nyu.png', import.meta.url)
            .pathname,
    },
    {
        name: 'University of Chicago',
        logo: new URL('../../../public/assets/chicago.png', import.meta.url)
            .pathname,
    },
    {
        name: 'Cornell University',
        logo: new URL('../../../public/assets/cornell.png', import.meta.url)
            .pathname,
    },
    {
        name: 'Yale University',
        logo: new URL('../../../public/assets/yale.png', import.meta.url)
            .pathname,
    },
    {
        name: 'Princeton University',
        logo: new URL('../../../public/assets/princeton.png', import.meta.url)
            .pathname,
    },
    {
        name: 'University of Oxford',
        logo: new URL('../../../public/assets/oxford.png', import.meta.url)
            .pathname,
    },
    {
        name: 'University of Toronto',
        logo: new URL('../../../public/assets/toronto.png', import.meta.url)
            .pathname,
    },
    {
        name: 'University of Zurich',
        logo: new URL('../../../public/assets/zurich.png', import.meta.url)
            .pathname,
    },
    {
        name: 'Carnegie Mellon University',
        logo: new URL('../../../public/assets/cmu.png', import.meta.url)
            .pathname,
    },
    {
        name: 'Indiana University',
        logo: new URL('../../../public/assets/indiana.png', import.meta.url)
            .pathname,
    },
    {
        name: 'UC Davis',
        logo: new URL('../../../public/assets/ucdavis.png', import.meta.url)
            .pathname,
    },
    {
        name: 'UC San Diego',
        logo: new URL('../../../public/assets/ucsd.png', import.meta.url)
            .pathname,
    },
    {
        name: 'George Washington University',
        logo: new URL('../../../public/assets/gw.png', import.meta.url)
            .pathname,
    },
    {
        name: 'Fordham University',
        logo: new URL('../../../public/assets/fordham.png', import.meta.url)
            .pathname,
    },
    {
        name: 'Augsburg University',
        logo: new URL('../../../public/assets/augsburg.png', import.meta.url)
            .pathname,
    },
    {
        name: 'University of Michigan',
        logo: new URL('../../../public/assets/michigan.png', import.meta.url)
            .pathname,
    },
    {
        name: 'Williams College',
        logo: new URL('../../../public/assets/williams.png', import.meta.url)
            .pathname,
    },
    // {
    //     name: 'Case Western Reserve University',
    //     logo: new URL('../../../public/assets/casewestern.png', import.meta.url)
    //         .pathname,
    // },
    // {
    //     name: 'University of Texas at Dallas',
    //     logo: new URL('../../../public/assets/utd.png', import.meta.url)
    //         .pathname,
    // },
    // {
    //     name: 'Arizona State University',
    //     logo: new URL('../../../public/assets/asu.png', import.meta.url)
    //         .pathname,
    // },
];

const companies = [
    {
        name: 'DeepMind',
        logo: new URL('../../../public/assets/deepmind.png', import.meta.url)
            .pathname,
    },
    {
        name: 'Microsoft Research',
        logo: new URL('../../../public/assets/microsoft.png', import.meta.url)
            .pathname,
    },
    {
        name: 'Federal Reserve Bank of Richmond',
        logo: new URL('../../../public/assets/frbrichmond.png', import.meta.url)
            .pathname,
    },
    {
        name: 'Simthetic AI',
        logo: new URL('../../../public/assets/simtheticai.png', import.meta.url)
            .pathname,
    },
    {
        name: 'Expected Parrot',
        logo: new URL('../../../public/assets/ep.png', import.meta.url)
            .pathname,
    },
];

const talks = [
    {
        name: 'Google',
        logo: new URL('../../../public/assets/google.png', import.meta.url)
            .pathname,
    },
    {
        name: 'Bundesbank',
        logo: new URL('../../../public/assets/bundesbank.png', import.meta.url)
            .pathname,
    },
    {
        name: 'NABE',
        logo: new URL('../../../public/assets/nabe.png', import.meta.url)
            .pathname,
    },
    {
        name: 'Wharton',
        logo: new URL('../../../public/assets/wharton.png', import.meta.url)
            .pathname,
    },
    {
        name: 'MIT',
        logo: new URL('../../../public/assets/mit.png', import.meta.url)
            .pathname,
    },
];

const sponsors = [
    {
        name: 'Bloomberg Beta',
        logo: new URL('../../../public/assets/beta.png', import.meta.url)
            .pathname,
    },
    {
        name: 'Google',
        logo: new URL('../../../public/assets/google.png', import.meta.url)
            .pathname,
    },
    {
        name: 'Anthropic',
        logo: new URL('../../../public/assets/anthropic.png', import.meta.url)
            .pathname,
    },
    // {
    //     name: '',
    //     logo: new URL('../../../public/assets/xxxxxxxx.png', import.meta.url)
    //         .pathname,
    // },
];

function LogosGrid({
    title = 'Accelerating research at leading universities and organizations',
    second_title = 'Invited talks',
    third_title = 'Grateful for support from our partners and sponsors',
    preview = false,
}: LogosGridProps) {
    // If in preview mode, just show a subset of logos with different styling
    if (preview) {
        // Get a sampling of logos for the preview
        const previewLogos = [...universities, ...companies].slice(0, 9);

        return (
            <div className="grid grid-cols-3 gap-2">
                {previewLogos.map((org, index) => (
                    <div
                        key={index}
                        className="flex items-center justify-center p-1 h-9"
                    >
                        <img
                            src={org.logo}
                            alt={org.name}
                            className="block h-auto max-h-7 max-w-full object-contain"
                        />
                    </div>
                ))}
            </div>
        );
    }

    // Full display mode
    return (
        <>
            <h1 className="text-3xl sm:text-5xl font-bold text-green-700 dark:text-primary-dark-text mt-20">
                {title}
            </h1>
            <div className="mt-20">
                <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-x-8 gap-y-4 sm:gap-x-12 sm:gap-y-6">
                    {universities.map((university, index) => (
                        <div
                            key={index}
                            className="flex items-center justify-center p-6 bg-white dark:bg-gray-800 rounded-lg"
                        >
                            <img
                                src={university.logo}
                                alt={university.name}
                                className="w-48 h-auto object-contain filter dark:brightness-90 hover:scale-110 transition-transform duration-200"
                            />
                        </div>
                    ))}
                    {companies.map((company, index) => (
                        <div
                            key={index}
                            className="flex items-center justify-center p-6 bg-white dark:bg-gray-800 rounded-lg"
                        >
                            <img
                                src={company.logo}
                                alt={company.name}
                                className="w-48 h-auto object-contain filter dark:brightness-90 hover:scale-110 transition-transform duration-200"
                            />
                        </div>
                    ))}
                </div>
            </div>
            <h1 className="text-3xl sm:text-5xl font-bold text-green-700 dark:text-primary-dark-text mt-32">
                {second_title}
            </h1>
            <div className="mt-8">
                <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-x-8 gap-y-4 sm:gap-x-12 sm:gap-y-6">
                    {talks.map((sponsor, index) => (
                        <div
                            key={index}
                            className="flex items-center justify-center p-6 bg-white dark:bg-gray-800 rounded-lg"
                        >
                            <img
                                src={sponsor.logo}
                                alt={sponsor.name}
                                className="w-48 h-auto object-contain filter dark:brightness-90 hover:scale-110 transition-transform duration-200"
                            />
                        </div>
                    ))}
                </div>
            </div>
            <h1 className="text-3xl sm:text-5xl font-bold text-green-700 dark:text-primary-dark-text mt-20">
                {third_title}
            </h1>
            <div className="mt-8">
                <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-x-8 gap-y-4 sm:gap-x-12 sm:gap-y-6">
                    {sponsors.map((sponsor, index) => (
                        <div
                            key={index}
                            className="flex items-center justify-center p-6 bg-white dark:bg-gray-800 rounded-lg"
                        >
                            <img
                                src={sponsor.logo}
                                alt={sponsor.name}
                                className="w-48 h-auto object-contain filter dark:brightness-90 hover:scale-110 transition-transform duration-200"
                            />
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
}

export default LogosGrid;
