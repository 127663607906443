import { useFetcher } from 'react-router-dom';
import Toggle from '../../../../../base/Toggle';

function SharedKeyToggle({
    shareId,
    defaultValue,
    isEpKey,
}: {
    shareId: string;
    defaultValue: boolean;
    isEpKey: boolean;
}) {
    const fetcher = useFetcher();

    const checked = fetcher.formData
        ? fetcher.formData.get('is_enabled_by_owner') === 'true'
        : defaultValue;

    function handleToggle(newValue: boolean) {
        fetcher.submit(
            {
                intent: 'update_share',
                share_uuid: shareId,
                is_enabled_by_owner: newValue,
                is_ep_key: isEpKey,
            },
            { method: 'post' }
        );
    }

    return (
        <Toggle
            id={`share-toggle-${shareId}`}
            checked={checked}
            onCheckedChange={handleToggle}
            name="is_enabled_by_owner"
        />
    );
}

export default SharedKeyToggle;
