import { Link, useLoaderData } from 'react-router-dom';
import AddProjectModal from './AddProjectModal';
import ObjectTypeChip from '../../../base/ObjectTypeChip';
import { Calendar } from 'lucide-react';
import { Info, Plus } from 'lucide-react';

interface ProjectData {
    user: {
        is_admin: boolean;
        is_authenticated: boolean;
        is_beta_user: boolean;
    };
    projects: Project[];
}

interface Project {
    id: string;
    name: string;
    owner_username: string;
    created_time_from_now: string;
    survey: {
        id: string;
        description: string;
    };
}

function ProjectCard({ project }: { project: Project }) {
    return (
        <div className="flex flex-col p-6 space-y-4 border dark:border-primary-dark-border rounded-md">
            <h2 className="text-xl font-semibold">{project.name}</h2>
            <div className="flex items-center text-sm text-gray-500 dark:text-gray-400">
                <Calendar className="w-4 h-4 mr-2" />
                <span>Created {project.created_time_from_now}</span>
            </div>
            <p className="space-x-3 text-sm">
                <ObjectTypeChip objectType="survey" />
                <span>{project.survey.description}</span>
            </p>
            <div className="flex justify-end mt-4">
                <Link
                    to={`/home/projects/${project.id}`}
                    className="mt-2 px-3 py-2 hover:bg-gray-300/20 transition-colors border border-gray-300 dark:border-2 dark:border-gray-500 rounded-md font-medium"
                >
                    View project
                </Link>
            </div>
        </div>
    );
}

function Projects() {
    const data = useLoaderData() as ProjectData;

    return (
        <>
            <div className="flex justify-between items-center space-y-2 sm:space-y-0">
                <h1 className="font-bold text-3xl pb-2">Projects</h1>
                {!data.user.is_admin && (
                    <AddProjectModal>
                        <button className="flex items-center gap-2 px-4 py-2.5 bg-green-600 hover:bg-green-700 hover:transition-colors rounded-md text-white font-medium">
                            <Plus className="w-5 h-5" />
                            Create project
                        </button>
                    </AddProjectModal>
                )}
            </div>
            <div>
                <div className="mb-8 overflow-x-auto">
                    <div className="relative min-h-[60vh]">
                        <table className="min-w-full text-sm relative">
                            <thead className="bg-gray-50 dark:bg-gray-50/5 sticky top-0">
                                <tr className="border dark:border-gray-100/20">
                                    {/* <th className="py-2 px-4 text-left font-semibold">
                                        Project
                                    </th> */}
                                </tr>
                            </thead>
                            <tbody className="align-top">
                                {data.projects.map((project) => (
                                    <tr
                                        key={project.id}
                                        className="rounded-full border-x border-b dark:border-gray-100/20 dark:text-secondary-dark-text hover:bg-gray-50 dark:hover:bg-gray-50/5"
                                    >
                                        <td className="py-3 px-4 space-y-3">
                                            <Link
                                                className="text-base font-semibold hover:text-blue-600 dark:hover:text-primary-dark-text-accent"
                                                to={
                                                    data.user.is_admin
                                                        ? `/admin/projects/${project.id}`
                                                        : `/home/projects/${project.id}`
                                                }
                                            >
                                                {project.name}
                                            </Link>
                                            <p className="flex items-center text-sm text-gray-500 dark:text-gray-400">
                                                <Calendar className="w-4 h-4 mr-2" />
                                                <span>
                                                    Created{' '}
                                                    {
                                                        project.created_time_from_now
                                                    }
                                                    {data.user.is_admin &&
                                                        ` by ${project.owner_username}`}
                                                </span>
                                            </p>
                                            <p className="flex items-center gap-3 text-sm">
                                                <ObjectTypeChip objectType="survey" />
                                                {project.survey.description}
                                            </p>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            {/* <div className="flex flex-col space-y-6 w-full max-w-2xl">
                {data.projects.map((project) => (
                    <ProjectCard key={project.id} project={project} />
                ))}
            </div> */}
        </>
    );
}

export default Projects;
