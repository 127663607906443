import { isRouteErrorResponse, useRouteError } from 'react-router-dom';
import Message from '../base/Message';

function GlobalErrorPage() {
    const error = useRouteError();

    if (isRouteErrorResponse(error)) {
        const title = `HTTP ${error.status}`;

        // More informative message when the route is not found
        if (
            typeof error.data === 'string' &&
            error.data.includes('No route matches')
        ) {
            const message = error.data;
            return <Message title={title} mainMessage={message} />;
        }

        const message = error.data.message || 'An error occurred.';
        return <Message title={title} mainMessage={message} />;
    }

    return (
        <Message
            title="Expected Parrot"
            mainMessage="This page either does not exist, or we are working on it 👩🏻‍💻👨‍💻🛠️"
        />
    );
}

export default GlobalErrorPage;
