import { useLoaderData, Link } from 'react-router-dom';
import { ArrowRight } from 'lucide-react';
import CacheLogTable from './CacheLogTable';
import { CacheLog } from './types';

interface RemoteCacheData {
    total_cache_entries: number;
    cache_logs: CacheLog[];
    cache_logs_count: number;
    current_page: number;
    page_size: number;
    total_pages: number;
    is_admin: boolean;
}

function RemoteCache() {
    const data = useLoaderData() as RemoteCacheData;

    const cacheEntriesLink = data.is_admin
        ? '/admin/remote-cache/entries'
        : '/home/remote-cache/entries';

    return (
        <>
            <div className="space-y-2">
                <h1 className="font-bold text-3xl pb-2">Remote Cache</h1>
                <p className="text-lg pb-2">
                    Manage your remote cache entries.
                </p>
                <p className="text-lg pb-2">
                    Looking for cache entries before the introduction of the
                    universal cache?{' '}
                    <Link
                        to="/home/remote-cache/legacy"
                        className="text-blue-600 dark:text-blue-400 hover:text-blue-700 dark:hover:text-blue-300"
                    >
                        View legacy cache{' '}
                        <ArrowRight
                            className="inline w-4 h-4 ml-1"
                            strokeWidth="2"
                        />
                    </Link>
                </p>
                <div>
                    {data.total_cache_entries > 0 && (
                        <div className="bg-white dark:bg-gray-800 rounded-lg border border-gray-200 dark:border-gray-500 w-fit">
                            <div className="p-6">
                                <div className="flex items-start">
                                    <div>
                                        <h3 className="font-medium text-gray-900 dark:text-gray-100">
                                            Entries in remote cache
                                        </h3>
                                        <p className="mt-2 text-3xl font-semibold">
                                            {data.total_cache_entries}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="border-t border-gray-200 dark:border-gray-500 px-6 py-3 bg-gray-50 dark:bg-gray-800 rounded-b-lg">
                                <Link
                                    to={cacheEntriesLink}
                                    className="text-sm text-blue-600 dark:text-blue-400 hover:text-blue-700 dark:hover:text-blue-300"
                                >
                                    View all
                                </Link>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className="mt-8">
                <CacheLogTable
                    cacheLogs={data.cache_logs}
                    cacheLogsCount={data.cache_logs_count}
                    currentPage={data.current_page}
                    totalPages={data.total_pages}
                    pageSize={data.page_size}
                    showOwnerEmail={data.is_admin}
                />
            </div>
        </>
    );
}

export default RemoteCache;
