import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { MoreVertical } from 'lucide-react';
import {
    DeleteModal,
    EditModal,
    ShareModal,
} from '../../Content/routes/ContentId/ActionRowButtons';
import { Object } from '../../../types';
import { useCopyToClipboard } from '../../../hooks/useCopyToClipboard';

function CopyLinkToContentItem({
    objectId,
    baseStyles,
}: {
    objectId: string;
    baseStyles: string;
}) {
    const [isCopied, copy] = useCopyToClipboard();

    const rootUrl = window.location.origin;
    const linkToContent = `${rootUrl}/content/${objectId}`;

    function handleCopy(text: string) {
        copy(text).catch((error) => {
            console.error('Failed to copy!', error);
        });
    }

    return (
        <DropdownMenu.Item
            className={`${baseStyles}`}
            onSelect={(event) => {
                event.preventDefault();
                handleCopy(linkToContent);
            }}
        >
            {isCopied ? 'Copied!' : 'Copy link'}
        </DropdownMenu.Item>
    );
}

function ObjectTableDropdown({ object }: { object: Object }) {
    const dropdownItemStyles =
        'w-32 select-none outline-none rounded-sm pl-4 pr-8 py-1 data-[highlighted]:bg-sky-600 data-[highlighted]:text-white';

    return (
        <DropdownMenu.Root>
            <DropdownMenu.Trigger asChild>
                <button aria-label="View object actions">
                    <MoreVertical className="inline w-4 h-4" strokeWidth="2" />
                </button>
            </DropdownMenu.Trigger>

            <DropdownMenu.Portal>
                <DropdownMenu.Content
                    className="bg-white dark:bg-gray-700 rounded-lg p-1.5 shadow"
                    sideOffset={5}
                >
                    <CopyLinkToContentItem
                        objectId={object.id}
                        baseStyles={dropdownItemStyles}
                    />
                    {object.is_editable_by_user && (
                        <>
                            <ShareModal
                                objectId={object.id}
                                humanReadableObjectType={
                                    object.human_readable_object_type
                                }
                                isOwnedByUser={object.is_owned_by_user}
                                sharedWith={object.shared_with}
                                tempSharedWith={object.temp_shared_with}
                            >
                                <DropdownMenu.Item
                                    className={dropdownItemStyles}
                                    onSelect={(event) => {
                                        event.preventDefault();
                                    }}
                                >
                                    Share
                                </DropdownMenu.Item>
                            </ShareModal>
                            <EditModal
                                objectId={object.id}
                                humanReadableObjectType={
                                    object.human_readable_object_type
                                }
                                description={object.description}
                            >
                                <DropdownMenu.Item
                                    className={dropdownItemStyles}
                                    onSelect={(event) => {
                                        event.preventDefault();
                                    }}
                                >
                                    Edit
                                </DropdownMenu.Item>
                            </EditModal>
                            <DeleteModal
                                objectId={object.id}
                                humanReadableObjectType={
                                    object.human_readable_object_type
                                }
                            >
                                <DropdownMenu.Item
                                    className={dropdownItemStyles}
                                    onSelect={(event) => {
                                        event.preventDefault();
                                    }}
                                >
                                    Delete
                                </DropdownMenu.Item>
                            </DeleteModal>
                        </>
                    )}
                </DropdownMenu.Content>
            </DropdownMenu.Portal>
        </DropdownMenu.Root>
    );
}

export { ObjectTableDropdown };
