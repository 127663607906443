import { TaskHistory } from './types';
import { useCopyToClipboard } from '../../../../../../../../hooks/useCopyToClipboard';
import ExceptionSummary from './ExceptionSummary';
import Interviews from './Interviews';

function JobIdContainer({ jobId }: { jobId: string }) {
    const [isCopied, copy] = useCopyToClipboard();

    // Copy Job UUID to clipboard
    function handleCopy(text: string) {
        copy(text).catch((error) => {
            console.error('Failed to copy!', error);
        });
    }

    return (
        <div className="bg-gray-50 dark:bg-gray-800 p-4 rounded-lg border border-gray-300 dark:border-gray-600">
            <div className="flex justify-between items-center">
                <span className="font-medium">Job UUID</span>
                <div className="flex items-center">
                    <span className="font-mono text-sm mr-3">{jobId}</span>
                    <button
                        onClick={() => handleCopy(jobId)}
                        className={`px-3 py-1 rounded text-white text-xs ${
                            isCopied
                                ? 'bg-green-600'
                                : 'bg-blue-600 hover:bg-blue-700'
                        }`}
                    >
                        {isCopied ? 'Copied!' : 'Copy'}
                    </button>
                </div>
            </div>
        </div>
    );
}

interface ExceptionsReportProps {
    taskHistory: TaskHistory;
    jobId: string;
}

// Main Exception Report component
function ExceptionsReport({ taskHistory, jobId }: ExceptionsReportProps) {
    return (
        <div className="container mx-auto px-4 py-6 text-gray-700 dark:text-gray-400 max-w-6xl">
            {/* Header */}
            <div className="mb-6">
                <h1 className="text-2xl font-bold mb-4">Exceptions Report</h1>
                <JobIdContainer jobId={jobId} />
            </div>

            {/* Exception Summary Table */}
            {taskHistory.exception_summary.length > 0 && (
                <ExceptionSummary taskHistory={taskHistory} />
            )}

            {/* Detailed Exception List */}
            <div className="mb-6">
                <div className="bg-white dark:bg-gray-900 rounded-lg border border-gray-300 dark:border-gray-700 overflow-hidden shadow-sm">
                    <div className="bg-gray-50 dark:bg-gray-800 p-3 border-b border-gray-300 dark:border-gray-700">
                        <h2 className="font-medium">Exception Details</h2>
                    </div>
                    <div className="p-4">
                        <Interviews taskHistory={taskHistory} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ExceptionsReport;
