import React from 'react';
import { Link } from 'react-router-dom';
import {
    ArrowRight,
    ExternalLink,
    Play,
    Box,
    Database,
    Cpu,
    MessageCircle,
    Mail,
    ChevronRight,
} from 'lucide-react';

const HomeIndex = () => {
    const linkStyles =
        'text-blue-500 hover:text-blue-600 hover:underline dark:text-primary-dark-text-accent dark:hover:text-primary-dark-text-accent';

    return (
        <div className="max-w-4xl mx-auto space-y-12 pb-16">
            {/* Introduction */}
            <div className="space-y-4">
                <h1 className="font-bold text-4xl md:text-5xl pb-2 text-blue-600">
                    Welcome to Coop
                </h1>
                <p className="text-xl text-gray-700 dark:text-gray-300">
                    Coop is a free platform for creating and sharing AI-powered
                    research.
                </p>

                <div className="bg-blue-50 dark:bg-blue-900/20 p-4 rounded-lg mb-6 border-l-4 border-blue-500">
                    <p>
                        Have a question or new feature request? Please see our{' '}
                        <Link to="/faq" className={linkStyles}>
                            FAQ
                        </Link>{' '}
                        page. You can also post a message at our{' '}
                        <a
                            href="https://discord.com/invite/mxAYkjfy9m"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="inline-flex items-center gap-1 text-blue-500 hover:text-blue-700"
                        >
                            Discord <ExternalLink size={16} />
                        </a>{' '}
                        or email us at{' '}
                        <a
                            href="mailto:info@expectedparrot.com"
                            className="inline-flex items-center gap-1 text-blue-500 hover:text-blue-700"
                        >
                            info@expectedparrot.com <Mail size={16} />
                        </a>
                        .
                    </p>
                </div>
            </div>

            {/* Tutorials Section */}
            <div className="border border-gray-200 dark:border-gray-800 rounded-xl p-6 shadow-sm bg-white dark:bg-gray-900">
                <div className="flex items-center gap-3 mb-6">
                    {/* <div className="w-10 h-10 rounded-full bg-green-100 dark:bg-green-900 flex items-center justify-center text-green-600 dark:text-green-400">
                        <Play size={20} />
                    </div> */}
                    <h3 className="font-bold text-2xl">
                        Get started with tutorials
                    </h3>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    {/* Getting Started */}
                    <div className="border border-gray-200 dark:border-gray-700 rounded-lg overflow-hidden shadow-sm hover:shadow-md transition-shadow">
                        <div className="p-5 bg-blue-50 dark:bg-blue-900/20">
                            <div className="flex items-center gap-3 mb-4">
                                <div className="w-10 h-10 rounded-full bg-blue-100 dark:bg-blue-900 flex items-center justify-center text-blue-600 dark:text-blue-400">
                                    <Play size={18} />
                                </div>
                                <h4 className="font-semibold text-xl">
                                    Getting Started
                                </h4>
                            </div>
                            <p className="text-gray-700 dark:text-gray-300 mb-4">
                                Install EDSL, our open-source Python package for
                                AI-powered research
                            </p>
                            <Link
                                to="/getting-started/edsl-installation"
                                className="inline-flex items-center gap-2 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition"
                            >
                                View guide <ArrowRight size={16} />
                            </Link>
                        </div>
                    </div>

                    {/* Content Management */}
                    <div className="border border-gray-200 dark:border-gray-700 rounded-lg overflow-hidden shadow-sm hover:shadow-md transition-shadow">
                        <div className="p-5 bg-purple-50 dark:bg-purple-900/20">
                            <div className="flex items-center gap-3 mb-4">
                                <div className="w-10 h-10 rounded-full bg-purple-100 dark:bg-purple-900 flex items-center justify-center text-purple-600 dark:text-purple-400">
                                    <Box size={18} />
                                </div>
                                <h4 className="font-semibold text-xl">
                                    Content Management
                                </h4>
                            </div>
                            <p className="text-gray-700 dark:text-gray-300 mb-4">
                                Share your research with other users and
                                collaborate on projects
                            </p>
                            <Link
                                to="/getting-started/coop-how-it-works"
                                className="inline-flex items-center gap-2 px-4 py-2 bg-purple-600 text-white rounded-lg hover:bg-purple-700 transition"
                            >
                                Learn more <ArrowRight size={16} />
                            </Link>
                        </div>
                    </div>

                    {/* Universal Remote Cache */}
                    <div className="border border-gray-200 dark:border-gray-700 rounded-lg overflow-hidden shadow-sm hover:shadow-md transition-shadow">
                        <div className="p-5 bg-amber-50 dark:bg-amber-900/20">
                            <div className="flex items-center gap-3 mb-4">
                                <div className="w-10 h-10 rounded-full bg-amber-100 dark:bg-amber-900 flex items-center justify-center text-amber-600 dark:text-amber-400">
                                    <Database size={18} />
                                </div>
                                <h4 className="font-semibold text-xl">
                                    Universal Remote Cache
                                </h4>
                            </div>
                            <p className="text-gray-700 dark:text-gray-300 mb-4">
                                Retrieve verified responses at no cost to
                                accelerate your research
                            </p>
                            <Link
                                to="/getting-started/coop-remote-cache"
                                className="inline-flex items-center gap-2 px-4 py-2 bg-amber-600 text-white rounded-lg hover:bg-amber-700 transition"
                            >
                                Learn more <ArrowRight size={16} />
                            </Link>
                        </div>
                    </div>

                    {/* Remote Inference */}
                    <div className="border border-gray-200 dark:border-gray-700 rounded-lg overflow-hidden shadow-sm hover:shadow-md transition-shadow">
                        <div className="p-5 bg-green-50 dark:bg-green-900/20">
                            <div className="flex items-center gap-3 mb-4">
                                <div className="w-10 h-10 rounded-full bg-green-100 dark:bg-green-900 flex items-center justify-center text-green-600 dark:text-green-400">
                                    <Cpu size={18} />
                                </div>
                                <h4 className="font-semibold text-xl">
                                    Remote Inference
                                </h4>
                            </div>
                            <p className="text-gray-700 dark:text-gray-300 mb-4">
                                Run large jobs on our server - your account
                                comes with $25 in API credits!
                            </p>
                            <Link
                                to="/getting-started/coop-remote-inference"
                                className="inline-flex items-center gap-2 px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 transition"
                            >
                                Learn more <ArrowRight size={16} />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

            {/* Community Section */}
            <div className="border border-gray-200 dark:border-gray-800 rounded-xl p-6 shadow-sm bg-blue-50 dark:bg-blue-900/20">
                <h3 className="font-bold text-2xl mb-4">Join our community</h3>

                <div className="grid md:grid-cols-2 gap-4">
                    <a
                        href="https://discord.com/invite/mxAYkjfy9m"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="flex items-center justify-center gap-2 px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition"
                    >
                        Discord Community <MessageCircle size={18} />
                    </a>
                    <a
                        href="https://docs.expectedparrot.com"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="flex items-center justify-center gap-2 px-6 py-3 bg-blue-200 text-blue-800 dark:bg-blue-700 dark:text-white rounded-lg hover:bg-blue-300 dark:hover:bg-blue-600 transition"
                    >
                        Documentation <ExternalLink size={18} />
                    </a>
                </div>
            </div>
        </div>
    );
};

export default HomeIndex;
