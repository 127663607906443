import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
    ArrowRight,
    ExternalLink,
    Copy,
    Check,
    Users,
    Key,
    Upload,
    Search,
    ChevronRight,
    Cpu,
    Database,
} from 'lucide-react';

const CoopObjects = () => {
    const linkStyles =
        'text-blue-500 hover:text-blue-600 hover:underline dark:text-primary-dark-text-accent dark:hover:text-primary-dark-text-accent';

    const [copiedText, setCopiedText] = useState('');

    const copyToClipboard = (text) => {
        setCopiedText(text);
        setTimeout(() => setCopiedText(''), 2000);
    };

    const coopMyContentImageURL = new URL(
        '../../../../../public/assets/getting-started/coop-my-content.png',
        import.meta.url
    ).pathname;

    return (
        <div className="max-w-4xl mx-auto space-y-12 pb-16 px-4 sm:px-6">
            {/* Introduction */}
            <div className="space-y-6">
                <div>
                    <h1 className="font-bold text-4xl md:text-5xl pb-2 text-blue-600">
                        Coop: How it Works
                    </h1>
                    <p className="text-xl text-gray-700 dark:text-gray-300 mt-4">
                        Your Expected Parrot account provides access to a
                        variety of features for conducting research with AI,
                        including:
                    </p>
                </div>

                <div className="grid md:grid-cols-3 gap-6">
                    <div className="bg-white dark:bg-gray-800 p-5 rounded-lg border border-gray-200 dark:border-gray-700 shadow-sm">
                        <div className="flex items-center gap-3 mb-3">
                            <div className="w-10 h-10 rounded-full bg-blue-100 dark:bg-blue-900 flex items-center justify-center text-blue-600 dark:text-blue-400">
                                <Users size={20} />
                            </div>
                            <h3 className="font-bold text-lg">Coop</h3>
                        </div>
                        <p className="text-gray-700 dark:text-gray-300">
                            Create, share and collaborate on research with other
                            users through our integrated platform.
                        </p>
                    </div>

                    <div className="bg-white dark:bg-gray-800 p-5 rounded-lg border border-gray-200 dark:border-gray-700 shadow-sm">
                        <div className="flex items-center gap-3 mb-3">
                            <div className="w-10 h-10 rounded-full bg-purple-100 dark:bg-purple-900 flex items-center justify-center text-purple-600 dark:text-purple-400">
                                <Cpu size={20} />
                            </div>
                            <h3 className="font-bold text-lg">
                                Remote Inference
                            </h3>
                        </div>
                        <p className="text-gray-700 dark:text-gray-300">
                            Run surveys with any available model directly on our
                            servers, without tying up your local machine.
                        </p>
                    </div>

                    <div className="bg-white dark:bg-gray-800 p-5 rounded-lg border border-gray-200 dark:border-gray-700 shadow-sm">
                        <div className="flex items-center gap-3 mb-3">
                            <div className="w-10 h-10 rounded-full bg-amber-100 dark:bg-amber-900 flex items-center justify-center text-amber-600 dark:text-amber-400">
                                <Database size={20} />
                            </div>
                            <h3 className="font-bold text-lg">Remote Cache</h3>
                        </div>
                        <p className="text-gray-700 dark:text-gray-300">
                            Store, share and replicate results to ensure
                            consistency and reduce API costs.
                        </p>
                    </div>
                </div>

                <div className="bg-blue-50 dark:bg-blue-900/20 p-6 rounded-lg border-l-4 border-blue-500">
                    <p className="text-lg text-gray-700 dark:text-gray-300">
                        This guide shows you how to use{' '}
                        <span className="font-bold">Coop</span>. For more
                        information about account features, please see our
                        guides on{' '}
                        <Link to="/getting-started" className={linkStyles}>
                            getting started with EDSL
                        </Link>
                        ,{' '}
                        <Link
                            to="/getting-started/coop-remote-inference"
                            className={linkStyles}
                        >
                            remote inference
                        </Link>
                        , and{' '}
                        <Link
                            to="/getting-started/coop-remote-cache"
                            className={linkStyles}
                        >
                            remote cache
                        </Link>
                        .
                    </p>
                </div>
            </div>

            {/* Step 1 */}
            <div className="border border-gray-200 dark:border-gray-800 rounded-xl p-6 shadow-sm bg-white dark:bg-gray-900">
                <div className="flex items-center gap-3 mb-4">
                    <div className="w-10 h-10 rounded-full bg-green-100 dark:bg-green-900 flex items-center justify-center text-green-600 dark:text-green-400">
                        <Users size={20} />
                    </div>
                    <h3 className="font-bold text-2xl">
                        Step 1: Create an account
                    </h3>
                </div>

                <p className="mb-4">
                    <Link to="/login" className={linkStyles}>
                        Log in / Sign up
                    </Link>{' '}
                    with an email address to create an account.
                </p>

                <div className="bg-green-50 dark:bg-green-900/20 p-4 rounded-lg mb-4">
                    <p className="mb-2">
                        Your account comes with an{' '}
                        <b>Expected Parrot API key</b> that allows you to:
                    </p>
                    <ul className="list-disc pl-5 space-y-1">
                        <li>Post and share content at Coop</li>
                        <li>Run surveys at the Expected Parrot server</li>
                        <li>Use any available models with your surveys</li>
                        <li>Use remote caching features</li>
                    </ul>
                    <p className="mb-2">
                        Your account also comes with an <b>$25 in credits</b>{' '}
                        for running surveys with any available models.
                    </p>
                </div>

                <p className="mb-4">
                    You can inspect your key and reset it at any time at your{' '}
                    <Link to="/home/keys" className={linkStyles}>
                        Keys
                    </Link>{' '}
                    page.
                </p>

                <Link
                    to="/login"
                    className="inline-flex items-center gap-2 px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 transition"
                >
                    Create an account <ArrowRight size={16} />
                </Link>
            </div>

            {/* Step 2 */}
            <div className="border border-gray-200 dark:border-gray-800 rounded-xl p-6 shadow-sm bg-white dark:bg-gray-900">
                <div className="flex items-center gap-3 mb-4">
                    <div className="w-10 h-10 rounded-full bg-amber-100 dark:bg-amber-900 flex items-center justify-center text-amber-600 dark:text-amber-400">
                        <Key size={20} />
                    </div>
                    <h3 className="font-bold text-2xl">
                        Step 2: Store your Expected Parrot API key
                    </h3>
                </div>

                <p className="mb-4">
                    When remote inference is activated, your survey results are
                    automatically stored at the Expected Parrot server and
                    accessible from your account.
                </p>

                <p className="mb-4">
                    You can also post content to Coop from your workspace, such
                    as surveys, agents and notebooks. To do this, ensure that
                    your Expected Parrot key is stored in a file named{' '}
                    <code className="bg-gray-100 dark:bg-gray-800 px-1 py-0.5 rounded">
                        .env
                    </code>{' '}
                    in your working directory in the following format:
                </p>

                <div className="relative bg-gray-100 dark:bg-gray-800 rounded-lg p-4 font-mono text-sm mb-4 overflow-x-auto">
                    <pre className="whitespace-pre-wrap break-all">
                        EXPECTED_PARROT_API_KEY = your_api_key
                    </pre>
                    <button
                        onClick={() =>
                            copyToClipboard(
                                'EXPECTED_PARROT_API_KEY = your_api_key'
                            )
                        }
                        className="absolute top-2 right-2 p-1 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
                        aria-label="Copy to clipboard"
                    >
                        {copiedText ===
                        'EXPECTED_PARROT_API_KEY = your_api_key' ? (
                            <Check size={18} />
                        ) : (
                            <Copy size={18} />
                        )}
                    </button>
                </div>

                <div className="bg-blue-50 dark:bg-blue-900/20 p-4 rounded-lg mb-4 border-l-4 border-blue-500">
                    <p>
                        <b>Note:</b> When you run surveys with remote inference
                        activated, your Expected Parrot key is stored
                        automatically.
                    </p>
                </div>

                <Link
                    to="/home/keys"
                    className="inline-flex items-center gap-2 px-4 py-2 bg-amber-600 text-white rounded-lg hover:bg-amber-700 transition"
                >
                    View your API key <ArrowRight size={16} />
                </Link>
            </div>

            {/* Step 3 */}
            <div className="border border-gray-200 dark:border-gray-800 rounded-xl p-6 shadow-sm bg-white dark:bg-gray-900">
                <div className="flex items-center gap-3 mb-4">
                    <div className="w-10 h-10 rounded-full bg-purple-100 dark:bg-purple-900 flex items-center justify-center text-purple-600 dark:text-purple-400">
                        <Upload size={20} />
                    </div>
                    <h3 className="font-bold text-2xl">
                        Step 3: Post objects to Coop
                    </h3>
                </div>

                <p className="mb-4">
                    There are 2 methods for posting content from your workspace
                    to Coop.
                </p>

                <h4 className="font-semibold text-lg mb-2">
                    Method 1: Use the push() method of the object
                </h4>

                <div className="relative bg-gray-100 dark:bg-gray-800 rounded-lg p-4 font-mono text-sm mb-6 overflow-x-auto">
                    <pre className="whitespace-pre-wrap break-all">
                        from edsl import QuestionFreeText
                        <br />
                        <br />
                        q = QuestionFreeText(question_name = "example", alias =
                        "example-question", question_text = "How do you feel
                        today?")
                        <br />
                        <br />
                        q.push(description="This is an example question",
                        visibility="public")
                    </pre>
                    <button
                        onClick={() =>
                            copyToClipboard(`from edsl import QuestionFreeText

q = QuestionFreeText(question_name = "example", alias = "example-question", question_text = "How do you feel today?")

q.push(description="This is an example question", visibility="public")`)
                        }
                        className="absolute top-2 right-2 p-1 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
                        aria-label="Copy to clipboard"
                    >
                        {copiedText.startsWith(
                            'from edsl import QuestionFreeText'
                        ) ? (
                            <Check size={18} />
                        ) : (
                            <Copy size={18} />
                        )}
                    </button>
                </div>

                <h4 className="font-semibold text-lg mb-2">
                    Method 2: Use the create() method of the Coop client object
                </h4>

                <div className="relative bg-gray-100 dark:bg-gray-800 rounded-lg p-4 font-mono text-sm mb-6 overflow-x-auto">
                    <pre className="whitespace-pre-wrap break-all">
                        from edsl import Coop, QuestionFreeText
                        <br />
                        <br />
                        q = QuestionFreeText(question_name = "example", alias =
                        "example-question", question_text = "How do you feel
                        today?")
                        <br />
                        <br />
                        c = Coop()
                        <br />
                        <br />
                        c.create(q, description="This is an example question",
                        alias = "example-question", visibility="public")
                    </pre>
                    <button
                        onClick={() =>
                            copyToClipboard(`from edsl import Coop, QuestionFreeText

q = QuestionFreeText(question_name = "example", alias = "example-question", question_text = "How do you feel today?")

c = Coop()

c.create(q, description="This is an example question", visibility="public")`)
                        }
                        className="absolute top-2 right-2 p-1 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
                        aria-label="Copy to clipboard"
                    >
                        {copiedText.startsWith('from edsl import Coop') ? (
                            <Check size={18} />
                        ) : (
                            <Copy size={18} />
                        )}
                    </button>
                </div>

                <p className="mb-4">
                    Calling these methods will return information about the
                    object that has been posted to Coop, including the URL and
                    UUID which you can use to access, share and modify the
                    content.
                </p>

                <p className="mb-4">
                    Passing description and visibility parameters is optional;
                    you can modify them from your workspace or account at any
                    time.
                </p>

                <div className="bg-purple-50 dark:bg-purple-900/20 p-4 rounded-lg mb-6">
                    <p className="font-semibold mb-2">
                        There are 3 visibility statuses to choose from:
                    </p>
                    <ul className="list-disc pl-5 space-y-1">
                        <li>
                            <code className="bg-gray-100 dark:bg-gray-800 px-1 py-0.5 rounded">
                                public
                            </code>
                            : share with everyone
                        </li>
                        <li>
                            <code className="bg-gray-100 dark:bg-gray-800 px-1 py-0.5 rounded">
                                private
                            </code>
                            : share with no one
                        </li>
                        <li>
                            <code className="bg-gray-100 dark:bg-gray-800 px-1 py-0.5 rounded">
                                unlisted
                            </code>
                            : share with anyone who has the link (this is the
                            default status)
                        </li>
                    </ul>
                </div>

                <h4 className="font-semibold text-lg mb-2">Updating objects</h4>

                <p className="mb-4">
                    You can use the{' '}
                    <code className="bg-gray-100 dark:bg-gray-800 px-1 py-0.5 rounded">
                        patch
                    </code>{' '}
                    method to update an object at Coop using its URL or UUID:
                </p>

                <div className="relative bg-gray-100 dark:bg-gray-800 rounded-lg p-4 font-mono text-sm mb-4 overflow-x-auto">
                    <pre className="whitespace-pre-wrap break-all">
                        q.patch("https://www.expectedparrot.com/content/my_username/example-question",
                        description = "This is a new description",
                        visibility="private", value = q)
                    </pre>
                    <button
                        onClick={() =>
                            copyToClipboard(
                                'q.patch("https://www.expectedparrot.com/content/my_username/example-question", description = "This is a new description", visibility="private", value = q)'
                            )
                        }
                        className="absolute top-2 right-2 p-1 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
                        aria-label="Copy to clipboard"
                    >
                        {copiedText.startsWith('q.patch') ? (
                            <Check size={18} />
                        ) : (
                            <Copy size={18} />
                        )}
                    </button>
                </div>

                <p className="mb-4">
                    See more details and examples of methods at the{' '}
                    <a
                        href="https://docs.expectedparrot.com/en/latest/coop.html"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="inline-flex items-center gap-1 text-blue-500 hover:text-blue-700"
                    >
                        Coop documentation page <ExternalLink size={16} />
                    </a>
                    .
                </p>
            </div>

            {/* Step 4 */}
            <div className="border border-gray-200 dark:border-gray-800 rounded-xl p-6 shadow-sm bg-white dark:bg-gray-900">
                <div className="flex items-center gap-3 mb-4">
                    <div className="w-10 h-10 rounded-full bg-blue-100 dark:bg-blue-900 flex items-center justify-center text-blue-600 dark:text-blue-400">
                        <Search size={20} />
                    </div>
                    <h3 className="font-bold text-2xl">
                        Step 4: Explore content
                    </h3>
                </div>

                <p className="mb-4">
                    Navigate to your{' '}
                    <Link to="/content" className={linkStyles}>
                        Coop content page
                    </Link>{' '}
                    to see the content that you have uploaded, together with any
                    results that were automatically stored with remote
                    inference.
                </p>

                <p className="mb-4">
                    You can search for other users' public content at the{' '}
                    <span className="font-bold">Explore</span> tab, and copy
                    code and examples to modify or rerun at your workspace:
                </p>

                <div className="mb-6 flex justify-center">
                    <img
                        src={coopMyContentImageURL}
                        alt="Screenshot of the Coop content page"
                        className="w-full max-w-3xl border-2 border-gray-200 rounded-lg shadow-lg"
                    />
                </div>

                <Link
                    to="/content"
                    className="inline-flex items-center gap-2 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition"
                >
                    Go to Coop content <ArrowRight size={16} />
                </Link>
            </div>

            {/* Additional Resources */}
            <div className="border border-gray-200 dark:border-gray-800 rounded-xl p-6 shadow-sm bg-blue-50 dark:bg-blue-900/20">
                <h3 className="font-bold text-2xl mb-4">Learn more</h3>

                <div className="grid md:grid-cols-2 gap-4">
                    <a
                        href="https://docs.expectedparrot.com/en/latest/coop.html"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="flex items-center justify-center gap-2 px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition"
                    >
                        Coop Documentation <ExternalLink size={18} />
                    </a>
                    <Link
                        to="/getting-started/coop-remote-inference"
                        className="flex items-center justify-center gap-2 px-6 py-3 bg-blue-200 text-blue-800 dark:bg-blue-700 dark:text-white rounded-lg hover:bg-blue-300 dark:hover:bg-blue-600 transition"
                    >
                        Remote Inference <ChevronRight size={18} />
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default CoopObjects;
