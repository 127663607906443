import { useLoaderData } from 'react-router-dom';
import EDSLSettingsToggle from './EDSLSettingsToggle';
import { Settings as SettingsIcon } from 'lucide-react';

interface EDSLSettings {
    remote_caching: boolean;
    remote_inference: boolean;
    remote_logging: boolean;
}

interface SettingsData {
    edsl_settings: EDSLSettings;
}

function Settings() {
    const { edsl_settings } = useLoaderData() as SettingsData;

    const linkStyles =
        'text-blue-500 hover:text-blue-600 hover:underline dark:text-primary-dark-text-accent dark:hover:text-primary-dark-text-accent';

    return (
        <>
            {/* Header */}
            <div className="space-y-4">
                <h1 className="font-bold text-4xl pb-2">Settings</h1>
                <p className="text-xl text-gray-700 dark:text-gray-300">
                    Configure your EDSL preferences to customize your
                    experience.
                </p>
            </div>

            {/* Remote Execution Section */}
            <div className="border border-gray-200 dark:border-gray-800 rounded-xl p-6 shadow-sm bg-white dark:bg-gray-900 mb-6">
                <div className="flex items-center gap-3 mb-4">
                    <div className="w-10 h-10 rounded-full bg-indigo-100 dark:bg-indigo-900 flex items-center justify-center text-indigo-600 dark:text-indigo-400">
                        <SettingsIcon size={20} />
                    </div>
                    <h3 className="font-bold text-2xl">Remote Execution</h3>
                </div>

                <p className="mb-6 text-gray-700 dark:text-gray-300">
                    Configure whether surveys are run locally or on the Expected
                    Parrot servers.
                </p>

                <div className="mb-6 p-4 bg-gray-50 dark:bg-gray-800 rounded-lg">
                    <EDSLSettingsToggle
                        beforelabel="Run surveys locally"
                        afterlabel="Run surveys remotely at the Expected Parrot server"
                        id="remote-inference"
                        defaultValue={edsl_settings.remote_inference}
                        name="intent"
                        value="remote-inference"
                    />
                </div>

                <p className="text-sm text-gray-600 dark:text-gray-400">
                    Remote execution allows you to run surveys with your
                    Expected Parrot API key, access the universal remote cache
                    and automatically store results.
                </p>

                <div className="text-sm bg-blue-50 dark:bg-blue-900/20 p-4 rounded-lg mt-6 border-l-4 border-blue-500">
                    <p>
                        <b>Note:</b> Running surveys remotely reduces the load
                        on your local machine.
                    </p>
                </div>
            </div>

            {/* Error Logging Section */}
            <div className="border border-gray-200 dark:border-gray-800 rounded-xl p-6 shadow-sm bg-white dark:bg-gray-900">
                <div className="flex items-center gap-3 mb-4">
                    <div className="w-10 h-10 rounded-full bg-indigo-100 dark:bg-indigo-900 flex items-center justify-center text-indigo-600 dark:text-indigo-400">
                        <SettingsIcon size={20} />
                    </div>
                    <h3 className="font-bold text-2xl">Remote Error Logging</h3>
                </div>

                <p className="mb-6 text-gray-700 dark:text-gray-300">
                    Configure how EDSL handles error reporting and logging.
                </p>

                <div className="mb-6 p-4 bg-gray-50 dark:bg-gray-800 rounded-lg">
                    <EDSLSettingsToggle
                        beforelabel="Suffer in silence"
                        afterlabel="Log errors and send them to Expected Parrot for help"
                        id="remote-logging"
                        defaultValue={edsl_settings.remote_logging}
                        name="intent"
                        value="remote-logging"
                    />
                </div>

                <p className="text-sm text-gray-600 dark:text-gray-400">
                    Error logging helps the Expected Parrot team identify and
                    fix issues more quickly and provide feedback on your
                    surveys.
                </p>
            </div>
        </>
    );
}

export default Settings;
