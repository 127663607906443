import { Check, ChevronDown } from 'lucide-react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

type FilterOption = {
    value: string;
    label: string;
};

const booleanFilterOptions: FilterOption[] = [
    { value: 'works', label: 'Works' },
    { value: 'doesnt-work', label: "Doesn't work" },
];

interface ServiceSectionProps {
    value: string[];
    onChange: (value: string[]) => void;
    options: FilterOption[];
}

function ServiceSection({ value, onChange, options }: ServiceSectionProps) {
    return (
        <>
            <DropdownMenu.Label className="px-2 py-1.5 text-sm font-semibold">
                Services
            </DropdownMenu.Label>
            {options.map((option) => (
                <DropdownMenu.CheckboxItem
                    key={option.value}
                    checked={value.includes(option.value)}
                    onCheckedChange={(checked) => {
                        const newValue = checked
                            ? [...value, option.value]
                            : value.filter((v) => v !== option.value);
                        onChange(newValue);
                    }}
                    onSelect={(event) => {
                        event.preventDefault();
                    }}
                    className="relative flex items-center gap-2 px-8 py-1.5 select-none outline-none cursor-default hover:bg-gray-100 dark:hover:bg-gray-100/5 rounded text-sm"
                >
                    <DropdownMenu.ItemIndicator className="absolute left-2">
                        <Check className="w-4 h-4" />
                    </DropdownMenu.ItemIndicator>
                    {option.label}
                </DropdownMenu.CheckboxItem>
            ))}
        </>
    );
}

interface SupportSectionProps {
    label: string;
    value: string[];
    onChange: (value: string[]) => void;
}

function SupportSection({ label, value, onChange }: SupportSectionProps) {
    return (
        <>
            <DropdownMenu.Label className="px-2 py-1.5 text-sm font-semibold">
                {label}
            </DropdownMenu.Label>
            {booleanFilterOptions.map((option) => (
                <DropdownMenu.CheckboxItem
                    key={option.value}
                    checked={value.includes(option.value)}
                    onCheckedChange={(checked) => {
                        const newValue = checked
                            ? [...value, option.value]
                            : value.filter((v) => v !== option.value);
                        onChange(newValue);
                    }}
                    onSelect={(event) => {
                        event.preventDefault();
                    }}
                    className="relative flex items-center gap-2 px-8 py-1.5 select-none outline-none cursor-default hover:bg-gray-100 dark:hover:bg-gray-100/5 rounded text-sm"
                >
                    <DropdownMenu.ItemIndicator className="absolute left-2">
                        <Check className="w-4 h-4" />
                    </DropdownMenu.ItemIndicator>
                    {option.label}
                </DropdownMenu.CheckboxItem>
            ))}
        </>
    );
}

interface FilterDropdownProps {
    filters: {
        textSupport: string[];
        imageSupport: string[];
        services: string[];
    };
    handleFilterChange: (filterType: string, value: string | string[]) => void;
    serviceOptions: FilterOption[];
}

function FilterDropdown({
    filters,
    handleFilterChange,
    serviceOptions,
}: FilterDropdownProps) {
    return (
        <DropdownMenu.Root modal={false}>
            <DropdownMenu.Trigger className="flex items-center gap-2 px-3 py-2 rounded-lg border border-gray-200 dark:border-gray-100/20 hover:bg-gray-50 dark:hover:bg-gray-100/5 text-sm font-semibold">
                Filters
                <ChevronDown className="w-4 h-4" />
            </DropdownMenu.Trigger>
            <DropdownMenu.Portal>
                <DropdownMenu.Content className="max-h-64 overflow-y-scroll bg-white dark:bg-primary-dark-bg rounded-lg shadow-lg border border-gray-200 dark:border-gray-100/20 p-2 min-w-[200px]">
                    <SupportSection
                        label="Text Support"
                        value={filters.textSupport}
                        onChange={(value) =>
                            handleFilterChange('textSupport', value)
                        }
                    />
                    <DropdownMenu.Separator className="h-px bg-gray-200 dark:bg-gray-100/20 my-2" />
                    <SupportSection
                        label="Image Support"
                        value={filters.imageSupport}
                        onChange={(value) =>
                            handleFilterChange('imageSupport', value)
                        }
                    />
                    <DropdownMenu.Separator className="h-px bg-gray-200 dark:bg-gray-100/20 my-2" />
                    <ServiceSection
                        value={filters.services}
                        onChange={(value) =>
                            handleFilterChange('services', value)
                        }
                        options={serviceOptions}
                    />
                </DropdownMenu.Content>
            </DropdownMenu.Portal>
        </DropdownMenu.Root>
    );
}

export default FilterDropdown;
