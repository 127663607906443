import { NavLink, useNavigate } from 'react-router-dom';
import {
    Home,
    Key,
    LogOut,
    Wrench,
    User,
    LayoutGrid,
    Folder,
    Edit3,
    Settings,
    Database,
    Cpu,
    DollarSign,
    CreditCard,
    Play,
} from 'lucide-react';
import SidebarContainer from './SidebarContainer';
import { logout } from '../../helpers/auth.helpers';
import useAuth from '../../hooks/useAuth';

function WebAppSidebar() {
    const { isLoggedIn, isBetaUser } = useAuth();
    const navigate = useNavigate();

    function handleLogout() {
        logout().then((responseMsg: string) => {
            if (responseMsg === 'Logout successful!') {
                navigate('/login');
            } else {
                console.log('Logout unsuccessful.');
            }
        });
    }

    const activeNavLinkStyles =
        'w-full inline-flex items-center p-2 text-green-600 dark:text-green-400 font-bold';
    const inactiveNavLinkStyles =
        'w-full inline-flex items-center p-2 rounded-md hover:bg-gray-100 dark:hover:bg-gray-50/10 dark:text-primary-dark-text-accent';

    return (
        <SidebarContainer>
            <nav className="space-y-2 text-green-600 text-sm">
                <div className="-space-y-2 pb-2">
                    <NavLink
                        to="/home"
                        className={({ isActive }) =>
                            isActive
                                ? `${activeNavLinkStyles}`
                                : `${inactiveNavLinkStyles}`
                        }
                        end={true}
                    >
                        <Home
                            className="inline w-4 h-4 mr-3 text-gray-500"
                            strokeWidth="2"
                        />
                        Home
                    </NavLink>
                </div>
                <div className="-space-y-2">
                    <div className="inline-flex items-center p-2 text-gray-500 dark:text-gray-300 font-semibold text-xs">
                        View
                    </div>
                    <NavLink
                        to="/content"
                        className={({ isActive }) =>
                            isActive
                                ? `block p-2 ${activeNavLinkStyles}`
                                : `block p-2 ${inactiveNavLinkStyles}`
                        }
                    >
                        <LayoutGrid
                            className="inline w-4 h-4 mr-3 text-gray-500"
                            strokeWidth="2"
                        />
                        Content
                    </NavLink>
                    <NavLink
                        to="/home/remote-inference"
                        className={({ isActive }) =>
                            isActive
                                ? `block p-2 ${activeNavLinkStyles}`
                                : `block p-2 ${inactiveNavLinkStyles}`
                        }
                    >
                        <Cpu
                            className="inline w-4 h-4 mr-3 text-gray-500"
                            strokeWidth="2"
                        />
                        Jobs
                    </NavLink>
                    <NavLink
                        to="/home/remote-cache"
                        className={({ isActive }) =>
                            isActive
                                ? `block p-2 ${activeNavLinkStyles}`
                                : `block p-2 ${inactiveNavLinkStyles}`
                        }
                    >
                        <Database
                            className="inline w-4 h-4 mr-3 text-gray-500"
                            strokeWidth="2"
                        />
                        Cache
                    </NavLink>
                </div>
                {/* Survey Builder Section - Only for Beta Users */}
                {isBetaUser && (
                    <div className="-space-y-2">
                        <div className="inline-flex items-center p-2 text-gray-500 dark:text-gray-300 font-semibold text-xs">
                            Build
                        </div>
                        {isBetaUser && (
                            <NavLink
                                to="/create"
                                className={({ isActive }) =>
                                    isActive
                                        ? `flex items-center p-2 ${activeNavLinkStyles}`
                                        : `flex items-center p-2 ${inactiveNavLinkStyles}`
                                }
                            >
                                <Wrench
                                    className="inline w-4 h-4 mr-3 text-gray-500"
                                    strokeWidth="2"
                                />
                                Create
                            </NavLink>
                        )}
                        {isBetaUser && (
                            <NavLink
                                to="/home/projects"
                                className={({ isActive }) =>
                                    isActive
                                        ? `flex p-2 ${activeNavLinkStyles}`
                                        : `flex p-2 ${inactiveNavLinkStyles}`
                                }
                            >
                                <Folder
                                    className="inline w-4 h-4 mr-3 text-gray-500"
                                    strokeWidth="2"
                                />
                                Projects
                            </NavLink>
                        )}
                        {isBetaUser && (
                            <NavLink
                                to="/home/respond"
                                className={({ isActive }) =>
                                    isActive
                                        ? `flex p-2 ${activeNavLinkStyles}`
                                        : `flex p-2 ${inactiveNavLinkStyles}`
                                }
                            >
                                <Edit3
                                    className="inline w-4 h-4 mr-3 text-gray-500"
                                    strokeWidth="2"
                                />
                                Respond
                            </NavLink>
                        )}
                    </div>
                )}
                <div className="-space-y-2">
                    <div className="inline-flex items-center p-2 text-gray-500 dark:text-gray-300 font-semibold text-xs">
                        Account
                    </div>
                    <NavLink
                        to="/home/keys"
                        className={({ isActive }) =>
                            isActive
                                ? `block p-2 ${activeNavLinkStyles}`
                                : `block p-2 ${inactiveNavLinkStyles}`
                        }
                    >
                        <Key
                            className="inline w-4 h-4 mr-3 text-gray-500"
                            strokeWidth="2"
                        />
                        Keys
                    </NavLink>
                    <NavLink
                        to="/home/settings"
                        className={({ isActive }) =>
                            isActive
                                ? `block p-2 ${activeNavLinkStyles}`
                                : `block p-2 ${inactiveNavLinkStyles}`
                        }
                    >
                        <Settings
                            className="inline w-4 h-4 mr-3 text-gray-500"
                            strokeWidth="2"
                        />
                        Settings
                    </NavLink>
                    <NavLink
                        to="/home/credits"
                        className={({ isActive }) =>
                            isActive
                                ? `block p-2 ${activeNavLinkStyles}`
                                : `block p-2 ${inactiveNavLinkStyles}`
                        }
                    >
                        <CreditCard
                            className="inline w-4 h-4 mr-3 text-gray-500"
                            strokeWidth="2"
                        />
                        Credits
                    </NavLink>
                    <NavLink
                        to="/home/profile"
                        className={({ isActive }) =>
                            isActive
                                ? `block p-2 ${activeNavLinkStyles}`
                                : `block p-2 ${inactiveNavLinkStyles}`
                        }
                    >
                        <User
                            className="inline w-4 h-4 mr-3 text-gray-500"
                            strokeWidth="2"
                        />
                        Profile
                    </NavLink>
                </div>
                <div className="-space-y-2">
                    <div className="inline-flex items-center p-2 text-gray-500 dark:text-gray-300 font-semibold text-xs">
                        Models
                    </div>
                    <NavLink
                        to="/home/pricing"
                        className={({ isActive }) =>
                            isActive
                                ? `block p-2 ${activeNavLinkStyles}`
                                : `block p-2 ${inactiveNavLinkStyles}`
                        }
                    >
                        <DollarSign
                            className="inline w-4 h-4 mr-3 text-gray-500"
                            strokeWidth="2"
                        />
                        Pricing
                    </NavLink>
                </div>
                {isLoggedIn && (
                    <div className="-space-y-2">
                        <button
                            onClick={handleLogout}
                            type="button"
                            className="w-full inline-flex items-center rounded-md p-2 text-red-700 hover:bg-gray-100 dark:hover:bg-gray-50/10 dark:text-amber-400 dark:hover:text-amber-500"
                        >
                            <LogOut
                                className="inline w-4 h-4 mr-3"
                                strokeWidth="2.5"
                            />
                            Log Out
                        </button>
                    </div>
                )}
            </nav>
        </SidebarContainer>
    );
}

export default WebAppSidebar;
