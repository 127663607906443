import { redirect } from 'react-router-dom';

export async function action({ request, params }) {
    const formData = await request.formData();
    let intent = formData.get('intent');

    const objectId = formData.get('object_uuid');

    let response: Response;

    if (intent === 'share') {
        response = await fetch('/api/content/share', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                username_or_email: formData.get('username_or_email'),
                object_uuid: formData.get('object_uuid'),
            }),
        });
    } else if (intent === 'unshare') {
        response = await fetch('/api/content/unshare', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email: formData.get('email'),
                object_uuid: formData.get('object_uuid'),
            }),
        });
    } else if (intent === 'edit') {
        response = await fetch(`/api/content/${objectId}`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                description: formData.get('description'),
            }),
        });
    } else if (intent === 'update_alias') {
        response = await fetch(`/api/content/${objectId}`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                alias: formData.get('alias'),
            }),
        });
    } else if (intent === 'update_password') {
        response = await fetch(`/api/content/${objectId}`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                password: formData.get('password'),
            }),
        });
    } else if (intent === 'check_password') {
        response = await fetch(`/api/content/check-password/${objectId}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                password: formData.get('password'),
            }),
        });
    } else if (intent === 'download') {
        const origin = window.location.origin;

        let api_endpoint = '';
        if (
            origin === 'http://localhost' ||
            origin === 'http://localhost:1234'
        ) {
            api_endpoint = 'http://localhost:8000';
        } else if (origin === 'https://chick.expectedparrot.com') {
            api_endpoint = 'https://chickapi.expectedparrot.com';
        } else if (origin === 'https://www.expectedparrot.com') {
            api_endpoint = 'https://api.expectedparrot.com';
        } else {
            api_endpoint = origin; // Fallback to the current origin if none of the conditions match
        }

        response = await fetch(`${api_endpoint}/download/${objectId}`, {
            method: 'GET',
            credentials: 'include',
        });
    } else if (intent === 'delete') {
        response = await fetch(`/api/content/${objectId}`, {
            method: 'DELETE',
        });
    } else if (intent === 'change_visibility') {
        response = await fetch(`/api/content/${objectId}`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                visibility: formData.get('visibility'),
            }),
        });
    } else if (intent === 'toggle_star') {
        response = await fetch(`/api/content/toggle-star/${objectId}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
        });
    }

    if (response.ok && intent === 'download') {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        switch (blob.type) {
            case 'application/json':
                a.download = 'object.json';
                break;
            case 'application/x-ipynb+json':
                a.download = 'notebook.ipynb';
                break;
        }
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        return 'Successfully downloaded object!';
    } else if (response.ok && intent === 'delete') {
        return redirect('/content');
    } else if (response.ok && intent === 'update_alias') {
        const paramNames = Object.keys(params);
        const isOnAliasPage =
            paramNames.includes('username') &&
            paramNames.includes('contentAlias');
        if (isOnAliasPage) {
            const successData = await response.json();
            return redirect(`/content/${params.username}/${successData.alias}`);
        } else {
            const successData = await response.json();
            return { success: true, message: successData.message };
        }
    } else if (response.ok) {
        const successData = await response.json();
        return { success: true, message: successData.message };
    } else if (response.status === 422) {
        return { success: false, message: 'Pydantic error.' };
    } else if (response.status === 504) {
        return { success: false, message: 'No response from server.' };
    } else {
        const errorData = await response.json();
        return { success: false, message: errorData.detail };
    }
}
