import { Link, useFetcher } from 'react-router-dom';
import * as Popover from '@radix-ui/react-popover';
import * as Tooltip from '@radix-ui/react-tooltip';
import {
    Activity,
    BookmarkX,
    Check,
    Download,
    RotateCw,
    XOctagon,
    X,
} from 'lucide-react';
import * as Dialog from '@radix-ui/react-dialog';
import ActionAlert from '../design-system/ActionAlert';

function RetryBtn({ jobId }: { jobId: string }) {
    const fetcher = useFetcher();

    return (
        <Popover.Root>
            <Tooltip.Provider>
                <Tooltip.Root delayDuration={0}>
                    <Tooltip.Trigger asChild>
                        <Popover.Trigger asChild>
                            <button
                                onClick={(event) => event.stopPropagation()}
                            >
                                <RotateCw
                                    className="inline w-4 h-4 hover:text-blue-400"
                                    strokeWidth="2"
                                />
                            </button>
                        </Popover.Trigger>
                    </Tooltip.Trigger>
                    <Tooltip.Portal>
                        <Tooltip.Content
                            className="px-3 py-2 select-none text-sm font-medium text-white bg-gray-900 dark:bg-gray-700 rounded-lg shadow-sm"
                            sideOffset={5}
                        >
                            Retry job
                            <Tooltip.Arrow className="fill-gray-900 dark:fill-gray-700" />
                        </Tooltip.Content>
                    </Tooltip.Portal>
                </Tooltip.Root>
            </Tooltip.Provider>
            <Popover.Portal>
                <Popover.Content
                    className="bg-white dark:bg-gray-700 rounded-lg shadow"
                    sideOffset={5}
                >
                    {fetcher.data ? (
                        <div className="flex flex-col gap-y-4 p-4 text-center">
                            {fetcher.data}
                            <div className="flex justify-center gap-x-4">
                                <Popover.Close asChild>
                                    <button
                                        onClick={(event) =>
                                            event.stopPropagation()
                                        }
                                        className="flex gap-x-2 hover:bg-gray-100 dark:hover:bg-gray-300/10 border border-gray-200 dark:border-gray-500 py-2 px-4 rounded focus:shadow-outline"
                                    >
                                        <X strokeWidth="1.5" />
                                        Close
                                    </button>
                                </Popover.Close>
                            </div>
                        </div>
                    ) : (
                        <div className="flex flex-col gap-y-4 p-4 text-center">
                            Do you want to retry this job?
                            <div className="flex gap-x-4">
                                <Popover.Close asChild>
                                    <button
                                        onClick={(event) =>
                                            event.stopPropagation()
                                        }
                                        className="flex gap-x-2 hover:bg-gray-100 dark:hover:bg-gray-300/10 border border-gray-200 dark:border-gray-500 py-2 px-4 rounded focus:shadow-outline"
                                    >
                                        <X strokeWidth="1.5" />
                                        Close
                                    </button>
                                </Popover.Close>
                                <fetcher.Form method="post">
                                    <input
                                        type="hidden"
                                        name="job_uuid"
                                        value={jobId}
                                    />
                                    <button
                                        name="intent"
                                        value="retry_job"
                                        onClick={(event) =>
                                            event.stopPropagation()
                                        }
                                        className="flex gap-x-2 bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded focus:shadow-outline"
                                    >
                                        <Check strokeWidth="1.5" />
                                        Retry job
                                    </button>
                                </fetcher.Form>
                            </div>
                        </div>
                    )}
                </Popover.Content>
            </Popover.Portal>
        </Popover.Root>
    );
}

function CancelBtn({ jobId }: { jobId: string }) {
    const fetcher = useFetcher();

    return (
        <Popover.Root>
            <Tooltip.Provider>
                <Tooltip.Root delayDuration={0}>
                    <Tooltip.Trigger asChild>
                        <Popover.Trigger asChild>
                            <button
                                aria-label="Cancel job"
                                onClick={(event) => event.stopPropagation()}
                            >
                                <XOctagon
                                    className="inline w-4 h-4 text-red-500 hover:text-red-600"
                                    strokeWidth="2"
                                />
                            </button>
                        </Popover.Trigger>
                    </Tooltip.Trigger>
                    <Tooltip.Portal>
                        <Tooltip.Content
                            className="px-3 py-2 select-none text-sm font-medium text-white bg-gray-900 dark:bg-gray-700 rounded-lg shadow-sm"
                            sideOffset={5}
                        >
                            Cancel job
                            <Tooltip.Arrow className="fill-gray-900 dark:fill-gray-700" />
                        </Tooltip.Content>
                    </Tooltip.Portal>
                </Tooltip.Root>
            </Tooltip.Provider>

            <Popover.Portal>
                <Popover.Content
                    className="bg-white dark:bg-gray-700 rounded-lg shadow"
                    sideOffset={5}
                >
                    {fetcher.data ? (
                        <div className="flex flex-col gap-y-4 p-4 text-center">
                            {fetcher.data}
                            <div className="flex justify-center gap-x-4">
                                <Popover.Close asChild>
                                    <button
                                        onClick={(event) =>
                                            event.stopPropagation()
                                        }
                                        className="flex gap-x-2 hover:bg-gray-100 dark:hover:bg-gray-300/10 border border-gray-200 dark:border-gray-500 py-2 px-4 rounded focus:shadow-outline"
                                    >
                                        <X strokeWidth="1.5" />
                                        Close
                                    </button>
                                </Popover.Close>
                            </div>
                        </div>
                    ) : (
                        <div className="flex flex-col gap-y-4 p-4 text-center">
                            Do you want to cancel this job?
                            <div className="flex gap-x-4">
                                <Popover.Close asChild>
                                    <button
                                        onClick={(event) =>
                                            event.stopPropagation()
                                        }
                                        className="flex gap-x-2 hover:bg-gray-100 dark:hover:bg-gray-300/10 border border-gray-200 dark:border-gray-500 py-2 px-4 rounded focus:shadow-outline"
                                    >
                                        <X strokeWidth="1.5" />
                                        Close
                                    </button>
                                </Popover.Close>
                                <fetcher.Form method="post">
                                    <input
                                        type="hidden"
                                        name="job_uuid"
                                        value={jobId}
                                    />
                                    <button
                                        name="intent"
                                        value="cancel_job"
                                        onClick={(event) =>
                                            event.stopPropagation()
                                        }
                                        className="flex gap-x-2 bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded focus:shadow-outline"
                                    >
                                        <Check strokeWidth="1.5" />
                                        Cancel job
                                    </button>
                                </fetcher.Form>
                            </div>
                        </div>
                    )}
                </Popover.Content>
            </Popover.Portal>
        </Popover.Root>
    );
}

function ProgressLink({ jobId, isAdmin }: { jobId: string; isAdmin: boolean }) {
    const progressLink = isAdmin
        ? `/admin/remote-job-progress/${jobId}`
        : `/home/remote-job-progress/${jobId}`;

    return (
        <Tooltip.Provider>
            <Tooltip.Root delayDuration={0}>
                <Tooltip.Trigger asChild>
                    <Link to={progressLink}>
                        <Activity
                            className="inline w-4 h-4 hover:text-blue-400"
                            strokeWidth="2"
                        />
                    </Link>
                </Tooltip.Trigger>
                <Tooltip.Portal>
                    <Tooltip.Content
                        className="px-3 py-2 select-none text-sm font-medium text-white bg-gray-900 dark:bg-gray-700 rounded-lg shadow-sm"
                        sideOffset={5}
                    >
                        Track progress
                        <Tooltip.Arrow className="fill-gray-900 dark:fill-gray-700" />
                    </Tooltip.Content>
                </Tooltip.Portal>
            </Tooltip.Root>
        </Tooltip.Provider>
    );
}

function DownloadBtn({ jobId }: { jobId: string }) {
    const fetcher = useFetcher();

    return (
        <fetcher.Form className="inline" method="post">
            <input type="hidden" name="job_uuid" value={jobId} />
            <Tooltip.Provider>
                <Tooltip.Root delayDuration={0}>
                    <Tooltip.Trigger asChild>
                        <button
                            name="intent"
                            value="admin_download_job"
                            className="inline w-4 h-4 hover:text-blue-400"
                            onClick={(event) => event.stopPropagation()}
                        >
                            <Download
                                className="inline w-4 h-4"
                                strokeWidth="2"
                            />
                        </button>
                    </Tooltip.Trigger>
                    <Tooltip.Portal>
                        <Tooltip.Content
                            className="px-3 py-2 select-none text-sm font-medium text-white bg-gray-900 dark:bg-gray-700 rounded-lg shadow-sm"
                            sideOffset={5}
                        >
                            Download JSON
                            <Tooltip.Arrow className="fill-gray-900 dark:fill-gray-700" />
                        </Tooltip.Content>
                    </Tooltip.Portal>
                </Tooltip.Root>
            </Tooltip.Provider>
        </fetcher.Form>
    );
}

function MarkAsFailedBtn({ jobId }: { jobId: string }) {
    const fetcher = useFetcher();

    return (
        <Dialog.Root>
            <Tooltip.Provider>
                <Tooltip.Root delayDuration={0}>
                    <Tooltip.Trigger asChild>
                        <Dialog.Trigger asChild>
                            <button
                                onClick={(event) => event.stopPropagation()}
                            >
                                <BookmarkX
                                    className="inline w-4 h-4 hover:text-red-600"
                                    strokeWidth="2"
                                />
                            </button>
                        </Dialog.Trigger>
                    </Tooltip.Trigger>
                    <Tooltip.Portal>
                        <Tooltip.Content
                            className="px-3 py-2 select-none text-sm font-medium text-white bg-gray-900 dark:bg-gray-700 rounded-lg shadow-sm"
                            sideOffset={5}
                        >
                            Mark as failed
                            <Tooltip.Arrow className="fill-gray-900 dark:fill-gray-700" />
                        </Tooltip.Content>
                    </Tooltip.Portal>
                </Tooltip.Root>
            </Tooltip.Provider>

            <Dialog.Portal>
                <Dialog.Overlay className="fixed inset-0 bg-black/60 animate-dialog-overlay-show" />
                <Dialog.Content
                    className="w-[90vw] max-w-[450px] max-h-[85vh] fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 p-6 bg-white dark:bg-gray-700 rounded-md focus:outline-none animate-dialog-content-show"
                    aria-describedby={undefined}
                >
                    <Dialog.Title asChild>
                        <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                            Mark job as failed
                        </h3>
                    </Dialog.Title>
                    <div className="py-4 md:py-5 space-y-5">
                        <div>
                            <p className="mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                Job UUID
                            </p>
                            <div className="max-w-fit bg-blue-50 dark:bg-blue-900/20 border border-blue-200 dark:border-blue-800 rounded-lg px-4 py-2">
                                <p className="text-sm font-mono text-blue-600 dark:text-blue-400">
                                    {jobId}
                                </p>
                            </div>
                        </div>
                        <fetcher.Form method="post">
                            <div className="mb-4">
                                <label
                                    htmlFor="failure_description"
                                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                >
                                    Failure description
                                </label>
                                <textarea
                                    id="failure_description"
                                    name="failure_description"
                                    rows={4}
                                    className="block w-full resize-none p-2.5 text-sm bg-gray-50 focus:outline-none border rounded-lg border-gray-400 focus:ring-1 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700/20 dark:border-gray-500 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:placeholder-gray-400"
                                    defaultValue=""
                                    required
                                />
                                <input
                                    type="hidden"
                                    name="job_uuid"
                                    value={jobId}
                                />
                            </div>
                            <button
                                type="submit"
                                name="intent"
                                value="admin_mark_as_failed"
                                className="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                            >
                                <div className="flex items-center justify-center gap-x-2">
                                    <XOctagon strokeWidth="1.5" />
                                    Mark as failed
                                </div>
                            </button>
                        </fetcher.Form>
                        <ActionAlert response={fetcher.data} />
                    </div>
                    <Dialog.Close asChild>
                        <button
                            className="absolute top-2.5 right-2.5 h-6 w-6 inline-flex justify-center align-center rounded-full"
                            aria-label="Close"
                        >
                            <X className="inline w-4 h-4" strokeWidth="2" />
                        </button>
                    </Dialog.Close>
                </Dialog.Content>
            </Dialog.Portal>
        </Dialog.Root>
    );
}

export { CancelBtn, DownloadBtn, MarkAsFailedBtn, ProgressLink, RetryBtn };
