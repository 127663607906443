import { useState, useEffect } from 'react';
import { Link, NavLink, useNavigate, useLocation } from 'react-router-dom';
import { LogOut, Menu, X, CircleHelp, MessageSquare } from 'lucide-react';
import DarkModeToggle from './DarkModeToggle';
import EPLogo from './EPLogo';
import useAuth from '../../hooks/useAuth';
import { logout } from '../../helpers/auth.helpers';

interface HeaderProps {
    isDarkMode: boolean;
    toggleDarkMode: (value: boolean) => void;
}

function GitHubIcon() {
    return (
        <svg
            className="h-5 w-5 fill-current"
            role="img"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
        >
            <title>GitHub</title>
            <path d="M12 .297c-6.63 0-12 5.373-12 12 0 5.303 3.438 9.8 8.205 11.385.6.113.82-.258.82-.577 0-.285-.01-1.04-.015-2.04-3.338.724-4.042-1.61-4.042-1.61C4.422 18.07 3.633 17.7 3.633 17.7c-1.087-.744.084-.729.084-.729 1.205.084 1.838 1.236 1.236 1.236 1.07 1.835 2.809 1.305 3.495.998.108-.776.417-1.305.76-1.605-2.665-.3-5.466-1.332-5.466-5.93 0-1.31.465-2.38 1.235-3.22-.135-.303-.54-1.523.105-3.176 0 0 1.005-.322 3.3 1.23.96-.267 1.98-.399 3-.405 1.02.006 2.04.138 3 .405 2.28-1.552 3.285-1.23 3.285-1.23.645 1.653.24 2.873.12 3.176.765.84 1.23 1.91 1.23 3.22 0 4.61-2.805 5.625-5.475 5.92.42.36.81 1.096.81 2.22 0 1.606-.015 2.896-.015 3.286 0 .315.21.69.825.57C20.565 22.092 24 17.592 24 12.297c0-6.627-5.373-12-12-12" />
        </svg>
    );
}

function DiscordIcon() {
    return (
        <svg
            className="h-5 w-5 fill-current"
            role="img"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
        >
            <title>Discord</title>
            <path d="M20.317 4.3698a19.7913 19.7913 0 00-4.8851-1.5152.0741.0741 0 00-.0785.0371c-.211.3753-.4447.8648-.6083 1.2495-1.8447-.2762-3.68-.2762-5.4868 0-.1636-.3933-.4058-.8742-.6177-1.2495a.077.077 0 00-.0785-.037 19.7363 19.7363 0 00-4.8852 1.515.0699.0699 0 00-.0321.0277C.5334 9.0458-.319 13.5799.0992 18.0578a.0824.0824 0 00.0312.0561c2.0528 1.5076 4.0413 2.4228 5.9929 3.0294a.0777.0777 0 00.0842-.0276c.4616-.6304.8731-1.2952 1.226-1.9942a.076.076 0 00-.0416-.1057c-.6528-.2476-1.2743-.5495-1.8722-.8923a.077.077 0 01-.0076-.1277c.1258-.0943.2517-.1923.3718-.2914a.0743.0743 0 01.0776-.0105c3.9278 1.7933 8.18 1.7933 12.0614 0a.0739.0739 0 01.0785.0095c.1202.099.246.1981.3728.2924a.077.077 0 01-.0066.1276 12.2986 12.2986 0 01-1.873.8914.0766.0766 0 00-.0407.1067c.3604.698.7719 1.3628 1.225 1.9932a.076.076 0 00.0842.0286c1.961-.6067 3.9495-1.5219 6.0023-3.0294a.077.077 0 00.0313-.0552c.5004-5.177-.8382-9.6739-3.5485-13.6604a.061.061 0 00-.0312-.0286zM8.02 15.3312c-1.1825 0-2.1569-1.0857-2.1569-2.419 0-1.3332.9555-2.4189 2.157-2.4189 1.2108 0 2.1757 1.0952 2.1568 2.419 0 1.3332-.9555 2.4189-2.1569 2.4189zm7.9748 0c-1.1825 0-2.1569-1.0857-2.1569-2.419 0-1.3332.9554-2.4189 2.1569-2.4189 1.2108 0 2.1757 1.0952 2.1568 2.419 0 1.3332-.946 2.4189-2.1568 2.4189Z" />
        </svg>
    );
}

function Header({ isDarkMode, toggleDarkMode }: HeaderProps) {
    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
    const { isLoggedIn } = useAuth();
    const navigate = useNavigate();

    const location = useLocation(); // Get current location

    const isHomePage = location.pathname === '/';

    // Unmount hamburger menu at medium breakpoints
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth >= 768 && isMenuOpen) {
                setIsMenuOpen(false);
            }
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [isMenuOpen]);

    // Restore body scroll when hamburger menu is unmounted
    useEffect(() => {
        document.body.style.overflow = isMenuOpen ? 'hidden' : 'auto';

        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [isMenuOpen]);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    function handleLogout() {
        toggleMenu();
        logout().then((responseMsg: string) => {
            if (responseMsg === 'Logout successful!') {
                navigate('/login');
            } else {
                console.log('Logout unsuccessful.');
            }
        });
    }

    const baseNavItemStyles = 'flex items-center px-3 py-2 font-semibold';
    const activeNavItemStyles = `underline underline-offset-8 text-green-700 dark:text-primary-dark-text-accent`;
    const inactiveNavItemStyles =
        'text-gray-500 hover:text-green-700 dark:text-primary-dark-text dark:hover:text-primary-dark-text-accent';

    // For items that don't need to differentiate between active and inactive states (e.g., Discord link)
    const standardNavItemStyles = `${baseNavItemStyles} ${inactiveNavItemStyles}`;

    return (
        <div
            className={
                isMenuOpen
                    ? 'h-screen flex flex-col sticky top-0 z-40'
                    : 'w-100 shrink-0'
            }
        >
            <header className="h-16 shrink-0 flex justify-between items-center bg-white border-b border-gray-300 px-5 dark:bg-primary-dark-bg dark:border-slate-700">
                <div className="flex items-center">
                    {/* Hamburger menu */}
                    <button
                        onClick={toggleMenu}
                        type="button"
                        className={`block md:hidden ${standardNavItemStyles}`}
                        aria-label="Navigation"
                    >
                        {isMenuOpen ? <X /> : <Menu />}
                    </button>
                    <Link to="/" className={standardNavItemStyles}>
                        <EPLogo isDarkMode={isDarkMode} className="w-14 mr-3" />
                        <span className="hidden lg:block">Expected Parrot</span>
                    </Link>
                </div>
                <div className="flex items-center">
                    <DarkModeToggle
                        isDarkMode={isDarkMode}
                        toggleDarkMode={toggleDarkMode}
                        styles={standardNavItemStyles}
                    />
                    <nav className="hidden md:flex md:flex-row gap-2">
                        <NavLink
                            to="/getting-started"
                            className={({ isActive }) =>
                                isActive
                                    ? `${baseNavItemStyles} ${activeNavItemStyles}`
                                    : `${baseNavItemStyles} ${inactiveNavItemStyles}`
                            }
                        >
                            Get started
                        </NavLink>
                        <a
                            href="https://docs.expectedparrot.com/en/latest/index.html"
                            target="_blank"
                            className={standardNavItemStyles}
                        >
                            Docs
                        </a>
                        <NavLink
                            to="/solutions"
                            className={({ isActive }) =>
                                isActive
                                    ? `${baseNavItemStyles} ${activeNavItemStyles}`
                                    : `${baseNavItemStyles} ${inactiveNavItemStyles}`
                            }
                        >
                            Solutions
                        </NavLink>
                        <NavLink
                            to="/models"
                            className={({ isActive }) =>
                                isActive
                                    ? `${baseNavItemStyles} ${activeNavItemStyles}`
                                    : `${baseNavItemStyles} ${inactiveNavItemStyles}`
                            }
                        >
                            Models
                        </NavLink>
                        <a
                            href="https://github.com/expectedparrot/edsl"
                            target="_blank"
                            rel="noopener noreferrer"
                            className={standardNavItemStyles}
                        >
                            <GitHubIcon />
                        </a>
                        <a
                            href="https://discord.com/invite/mxAYkjfy9m"
                            target="_blank"
                            rel="noopener noreferrer"
                            className={standardNavItemStyles}
                        >
                            <DiscordIcon />
                        </a>
                        <NavLink to="/faq" className={standardNavItemStyles}>
                            <CircleHelp aria-label="FAQ" />
                        </NavLink>
                        <NavLink
                            to={isLoggedIn === true ? '/home' : '/login'}
                            end={true}
                            className={({ isActive }) =>
                                isLoggedIn
                                    ? `ml-4 px-4 py-2 rounded-md font-semibold text-green-600 hover:text-green-700 border-2 border-green-600 hover:border-green-700`
                                    : `${baseNavItemStyles} ${
                                          isActive
                                              ? `${baseNavItemStyles} ${activeNavItemStyles}`
                                              : `${baseNavItemStyles} ${inactiveNavItemStyles}`
                                      }`
                            }
                        >
                            {isLoggedIn === true
                                ? 'My account'
                                : 'Log in / Sign up'}
                        </NavLink>
                    </nav>
                </div>
            </header>
            {/* Menu for smaller screens */}
            {isMenuOpen && (
                <div className="overflow-y-auto isolate grow bg-white px-11 py-5 dark:bg-primary-dark-bg dark:border-slate-700 dark:text-primary-dark-text">
                    <nav className="flex flex-col gap-5">
                        <div className="flex gap-2">
                            <NavLink
                                to={isLoggedIn === true ? '/home' : '/login'}
                                onClick={toggleMenu}
                                className="flex-1 bg-green-700 hover:bg-green-800 border-2 border-green-700 hover:border-green-800 rounded-md px-3 py-2 text-white font-semibold text-center"
                            >
                                {isLoggedIn === true
                                    ? 'My account'
                                    : 'Login / Sign up'}
                            </NavLink>
                            {isLoggedIn && (
                                <button
                                    onClick={handleLogout}
                                    className="flex-1 border-2 border-green-700 hover:border-green-800 text-green-700 hover:text-green-800 rounded-md px-3 py-2 font-semibold flex items-center justify-center"
                                >
                                    <LogOut
                                        className="inline w-4 h-4 mr-2"
                                        strokeWidth="2.5"
                                    />
                                    Log out
                                </button>
                            )}
                        </div>
                        <div>
                            <p className="text-gray-400 text-sm font-semibold">
                                EDSL
                            </p>
                            <NavLink
                                to="/getting-started"
                                onClick={toggleMenu}
                                className={standardNavItemStyles}
                            >
                                Getting started
                            </NavLink>
                            <NavLink
                                to="/getting-started/edsl-installation"
                                onClick={toggleMenu}
                                className={standardNavItemStyles}
                            >
                                Installation
                            </NavLink>
                            <NavLink
                                to="/getting-started/edsl-api-keys"
                                onClick={toggleMenu}
                                className={standardNavItemStyles}
                            >
                                Managing keys
                            </NavLink>
                            <NavLink
                                to="/getting-started/edsl-use-cases"
                                onClick={toggleMenu}
                                className={standardNavItemStyles}
                            >
                                Use cases
                            </NavLink>
                            <a
                                href="https://docs.expectedparrot.com/en/latest/starter_tutorial.html"
                                target="_blank"
                                onClick={toggleMenu}
                                className={standardNavItemStyles}
                            >
                                Starter tutorial
                            </a>
                        </div>
                        <div>
                            <p className="text-gray-400 text-sm font-semibold">
                                Coop
                            </p>
                            <NavLink
                                to="/login"
                                onClick={toggleMenu}
                                className={standardNavItemStyles}
                            >
                                Create an account
                            </NavLink>
                            <NavLink
                                to="/getting-started/coop-how-it-works"
                                onClick={toggleMenu}
                                className={standardNavItemStyles}
                            >
                                How it works
                            </NavLink>
                            <NavLink
                                to="/getting-started/coop-remote-inference"
                                onClick={toggleMenu}
                                className={standardNavItemStyles}
                            >
                                Use remote inference
                            </NavLink>
                            <NavLink
                                to="/getting-started/coop-remote-cache"
                                onClick={toggleMenu}
                                className={standardNavItemStyles}
                            >
                                Use remote cache
                            </NavLink>
                            <NavLink
                                to="/models"
                                onClick={toggleMenu}
                                className={standardNavItemStyles}
                            >
                                Model pricing
                            </NavLink>
                        </div>
                        <div>
                            <p className="text-gray-400 text-sm font-semibold">
                                View
                            </p>
                            <NavLink
                                to="/home"
                                onClick={toggleMenu}
                                className={standardNavItemStyles}
                            >
                                Home
                            </NavLink>
                            <NavLink
                                to="/content"
                                onClick={toggleMenu}
                                className={standardNavItemStyles}
                            >
                                Content
                            </NavLink>
                            <NavLink
                                to="/home/remote-inference"
                                onClick={toggleMenu}
                                className={standardNavItemStyles}
                            >
                                Jobs
                            </NavLink>
                            <NavLink
                                to="/home/remote-cache"
                                onClick={toggleMenu}
                                className={standardNavItemStyles}
                            >
                                Cache
                            </NavLink>
                        </div>
                        <div>
                            <p className="text-gray-400 text-sm font-semibold">
                                Build
                            </p>
                            <NavLink
                                to="/create"
                                onClick={toggleMenu}
                                className={standardNavItemStyles}
                            >
                                Create
                            </NavLink>
                            <NavLink
                                to="/home/projects"
                                onClick={toggleMenu}
                                className={standardNavItemStyles}
                            >
                                Projects
                            </NavLink>
                            <NavLink
                                to="/home/respond"
                                onClick={toggleMenu}
                                className={standardNavItemStyles}
                            >
                                Respond
                            </NavLink>
                        </div>
                        <div>
                            <p className="text-gray-400 text-sm font-semibold">
                                Account
                            </p>
                            <NavLink
                                to="/home/keys"
                                onClick={toggleMenu}
                                className={standardNavItemStyles}
                            >
                                Keys
                            </NavLink>
                            <NavLink
                                to="/home/settings"
                                onClick={toggleMenu}
                                className={standardNavItemStyles}
                            >
                                Settings
                            </NavLink>
                            <NavLink
                                to="/home/credits"
                                onClick={toggleMenu}
                                className={standardNavItemStyles}
                            >
                                Credits
                            </NavLink>
                            <NavLink
                                to="/home/profile"
                                onClick={toggleMenu}
                                className={standardNavItemStyles}
                            >
                                Profile
                            </NavLink>
                        </div>
                        <div>
                            <p className="text-gray-400 text-sm font-semibold">
                                Documentation
                            </p>
                            <a
                                href="https://docs.expectedparrot.com/en/latest/index.html"
                                target="_blank"
                                onClick={toggleMenu}
                                className={standardNavItemStyles}
                            >
                                Docs
                            </a>
                            <a
                                href="https://github.com/expectedparrot/edsl"
                                target="_blank"
                                onClick={toggleMenu}
                                className={standardNavItemStyles}
                            >
                                GitHub
                            </a>
                            <a
                                href="https://pypi.org/project/edsl/"
                                target="_blank"
                                onClick={toggleMenu}
                                className={standardNavItemStyles}
                            >
                                PyPI
                            </a>
                        </div>
                        <div>
                            <p className="text-gray-400 text-sm font-semibold">
                                Community
                            </p>
                            <a
                                href="https://discord.com/invite/mxAYkjfy9m"
                                target="_blank"
                                onClick={toggleMenu}
                                className={standardNavItemStyles}
                            >
                                Discord
                            </a>
                            <NavLink
                                to="/consulting"
                                onClick={toggleMenu}
                                className={standardNavItemStyles}
                            >
                                Consulting
                            </NavLink>
                            <NavLink
                                to="/about"
                                onClick={toggleMenu}
                                className={standardNavItemStyles}
                            >
                                About
                            </NavLink>
                        </div>
                        <div>
                            <p className="text-gray-400 text-sm font-semibold">
                                Contact
                            </p>
                            <a
                                href="mailto:info@expectedparrot.com"
                                target="_blank"
                                onClick={toggleMenu}
                                className={standardNavItemStyles}
                            >
                                info@expectedparrot.com
                            </a>
                        </div>
                    </nav>
                </div>
            )}
        </div>
    );
}

export default Header;
