import React from 'react';
import { Link } from 'react-router-dom';
import {
    ArrowRight,
    ExternalLink,
    Database,
    Cpu,
    FileSearch,
    Download,
    ChevronRight,
} from 'lucide-react';

const CoopRemoteCache = () => {
    const linkStyles =
        'text-blue-500 hover:text-blue-600 hover:underline dark:text-primary-dark-text-accent dark:hover:text-primary-dark-text-accent';

    const homeRemoteCacheLogsImageURL = new URL(
        '../../../../../public/assets/getting-started/home-remote-cache-logs.png',
        import.meta.url
    ).pathname;

    return (
        <div className="max-w-4xl mx-auto space-y-12 pb-16">
            {/* Introduction */}
            <div className="space-y-4">
                <h1 className="font-bold text-4xl md:text-5xl pb-2 text-blue-600">
                    Use Remote Cache
                </h1>
                <p className="text-xl text-gray-700 dark:text-gray-300">
                    Remote caching allows you to store responses from language
                    models at the Expected Parrot server, and retrieve responses
                    to questions that have already been run. It is automatically
                    activated with remote inference.
                </p>

                <div className="bg-blue-50 dark:bg-blue-900/20 p-4 rounded-lg mb-6 border-l-4 border-blue-500">
                    <p>
                        This page shows how to inspect and manage your remote
                        cache entries from your account. For more information on
                        features of the <b>universal remote cache</b>, please
                        see the{' '}
                        <a
                            href="https://docs.expectedparrot.com/en/latest/remote_caching.html"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="inline-flex items-center gap-1 text-blue-500 hover:text-blue-700"
                        >
                            remote caching <ExternalLink size={16} />
                        </a>{' '}
                        section of the documentation.
                    </p>
                </div>
            </div>

            {/* Step 1 */}
            <div className="border border-gray-200 dark:border-gray-800 rounded-xl p-6 shadow-sm bg-white dark:bg-gray-900">
                <div className="flex items-center gap-3 mb-4">
                    <div className="w-10 h-10 rounded-full bg-purple-100 dark:bg-purple-900 flex items-center justify-center text-purple-600 dark:text-purple-400">
                        <Cpu size={20} />
                    </div>
                    <h3 className="font-bold text-2xl">
                        Step 1: Run a survey with remote inference
                    </h3>
                </div>

                <p className="mb-4">
                    Follow the instructions for{' '}
                    <Link
                        to="/getting-started/coop-remote-inference"
                        className={linkStyles}
                    >
                        activating remote inference
                    </Link>{' '}
                    and running a survey.
                </p>

                <div className="bg-purple-50 dark:bg-purple-900/20 p-4 rounded-lg mb-4 border-l-4 border-purple-500">
                    <p>
                        <b>Note:</b> Remote caching is automatically enabled
                        when you use remote inference, providing seamless
                        storage and retrieval of model responses.
                    </p>
                </div>

                <Link
                    to="/getting-started/coop-remote-inference"
                    className="inline-flex items-center gap-2 px-4 py-2 bg-purple-600 text-white rounded-lg hover:bg-purple-700 transition"
                >
                    Setup remote inference <ArrowRight size={16} />
                </Link>
            </div>

            {/* Step 2 */}
            <div className="border border-gray-200 dark:border-gray-800 rounded-xl p-6 shadow-sm bg-white dark:bg-gray-900">
                <div className="flex items-center gap-3 mb-4">
                    <div className="w-10 h-10 rounded-full bg-blue-100 dark:bg-blue-900 flex items-center justify-center text-blue-600 dark:text-blue-400">
                        <FileSearch size={20} />
                    </div>
                    <h3 className="font-bold text-2xl">
                        Step 2: Inspect your cache
                    </h3>
                </div>

                <p className="mb-4">
                    Navigate to your{' '}
                    <Link to="/home/remote-cache" className={linkStyles}>
                        Cache
                    </Link>{' '}
                    page to see the entries that have been added to your cache:
                </p>

                <div className="mb-6 flex justify-center">
                    <img
                        src={homeRemoteCacheLogsImageURL}
                        alt="Screenshot of the remote cache page"
                        className="w-full max-w-3xl border-2 border-gray-200 rounded-lg shadow-lg"
                    />
                </div>

                <div className="bg-blue-50 dark:bg-blue-900/20 p-4 rounded-lg mb-4 border-l-4 border-blue-500">
                    <p>
                        <b>Benefits of remote cache inspection:</b>
                    </p>
                    <ul className="list-disc pl-5 space-y-1 mt-2">
                        <li>
                            View all your cached model responses in one place
                        </li>
                        <li>Inspect the details of each cache entry</li>
                        <li>
                            See when model responses were generated and by which
                            models
                        </li>
                        <li>
                            Track which prompts are being reused effectively
                        </li>
                    </ul>
                </div>

                <Link
                    to="/home/remote-cache"
                    className="inline-flex items-center gap-2 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition"
                >
                    View your cache <ArrowRight size={16} />
                </Link>
            </div>

            {/* Step 3 */}
            <div className="border border-gray-200 dark:border-gray-800 rounded-xl p-6 shadow-sm bg-white dark:bg-gray-900">
                <div className="flex items-center gap-3 mb-4">
                    <div className="w-10 h-10 rounded-full bg-green-100 dark:bg-green-900 flex items-center justify-center text-green-600 dark:text-green-400">
                        <Download size={20} />
                    </div>
                    <h3 className="font-bold text-2xl">
                        Step 3: Manage your cache entries
                    </h3>
                </div>

                <p className="mb-4">
                    You can pull or download these entries to add them to your
                    local cache. This allows you to reuse model responses
                    without making additional API calls.
                </p>

                <div className="bg-green-50 dark:bg-green-900/20 p-4 rounded-lg mb-4 border-l-4 border-green-500">
                    <p>
                        <b>Cache management features:</b>
                    </p>
                    <ul className="list-disc pl-5 space-y-1 mt-2">
                        <li>
                            Download cache entries to your local environment
                        </li>
                        <li>Pull entries directly in your code using EDSL</li>
                        <li>View response details and prompts</li>
                    </ul>
                </div>

                <p className="mb-4">
                    Learn more about features of remote caching at our{' '}
                    <a
                        href="https://docs.expectedparrot.com/en/latest/remote_caching.html"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="inline-flex items-center gap-1 text-blue-500 hover:text-blue-700"
                    >
                        documentation <ExternalLink size={16} />
                    </a>{' '}
                    page.
                </p>
            </div>

            {/* Benefits Section */}
            <div className="border border-gray-200 dark:border-gray-800 rounded-xl p-6 shadow-sm bg-amber-50 dark:bg-amber-900/20">
                <div className="flex items-center gap-3 mb-4">
                    <div className="w-10 h-10 rounded-full bg-amber-100 dark:bg-amber-900 flex items-center justify-center text-amber-600 dark:text-amber-400">
                        <Database size={20} />
                    </div>
                    <h3 className="font-bold text-2xl">
                        Universal Remote Cache Benefits
                    </h3>
                </div>

                <div className="grid md:grid-cols-2 gap-6 mt-4">
                    <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow-sm">
                        <h4 className="font-semibold text-lg mb-2">
                            Cost Efficiency
                        </h4>
                        <p className="text-gray-700 dark:text-gray-300">
                            Replicate results without paying for new API calls,
                            significantly reducing your costs.
                        </p>
                    </div>

                    <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow-sm">
                        <h4 className="font-semibold text-lg mb-2">
                            Faster Results
                        </h4>
                        <p className="text-gray-700 dark:text-gray-300">
                            Get instant access to previously generated responses
                            without waiting for API calls to complete.
                        </p>
                    </div>

                    <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow-sm">
                        <h4 className="font-semibold text-lg mb-2">
                            Research Reproducibility
                        </h4>
                        <p className="text-gray-700 dark:text-gray-300">
                            Ensure consistent results across multiple runs of
                            surveys and experiments.
                        </p>
                    </div>

                    <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow-sm">
                        <h4 className="font-semibold text-lg mb-2">
                            Collaborative Sharing
                        </h4>
                        <p className="text-gray-700 dark:text-gray-300">
                            Share cached responses with team members to enable
                            efficient collaboration on research projects.
                        </p>
                    </div>
                </div>
            </div>

            {/* Additional Resources */}
            <div className="border border-gray-200 dark:border-gray-800 rounded-xl p-6 shadow-sm bg-blue-50 dark:bg-blue-900/20">
                <h3 className="font-bold text-2xl mb-4">Learn more</h3>

                <div className="grid md:grid-cols-2 gap-4">
                    <a
                        href="https://docs.expectedparrot.com/en/latest/remote_caching.html"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="flex items-center justify-center gap-2 px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition"
                    >
                        Remote Cache Documentation <ExternalLink size={18} />
                    </a>
                    <Link
                        to="/getting-started/coop-how-it-works"
                        className="flex items-center justify-center gap-2 px-6 py-3 bg-blue-200 text-blue-800 dark:bg-blue-700 dark:text-white rounded-lg hover:bg-blue-300 dark:hover:bg-blue-600 transition"
                    >
                        How Coop Works <ChevronRight size={18} />
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default CoopRemoteCache;
