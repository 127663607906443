import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
    ArrowRight,
    ExternalLink,
    Copy,
    Check,
    ToggleRight,
    Code,
    FileText,
    CreditCard,
    ChevronRight,
    Cpu,
} from 'lucide-react';

const CoopRemoteInference = () => {
    const linkStyles =
        'text-blue-500 hover:text-blue-600 hover:underline dark:text-primary-dark-text-accent dark:hover:text-primary-dark-text-accent';

    const [copiedText, setCopiedText] = useState('');

    const copyToClipboard = (text) => {
        setCopiedText(text);
        setTimeout(() => setCopiedText(''), 2000);
    };

    const homeSettingsImageURL = new URL(
        '../../../../../public/assets/getting-started/settings-view.png',
        import.meta.url
    ).pathname;

    const homeCreditsImageURL = new URL(
        '../../../../../public/assets/getting-started/home-credits.png',
        import.meta.url
    ).pathname;

    const homeRemoteInferenceJobCompletedImageURL = new URL(
        '../../../../../public/assets/getting-started/home-remote-inference-job-completed.png',
        import.meta.url
    ).pathname;

    const coopContentResultsViewImageURL = new URL(
        '../../../../../public/assets/getting-started/coop-content-results-view.png',
        import.meta.url
    ).pathname;

    return (
        <div className="max-w-4xl mx-auto space-y-12 pb-16">
            {/* Introduction */}
            <div className="space-y-4">
                <h1 className="font-bold text-4xl md:text-5xl pb-2 text-blue-600">
                    Use Remote Inference
                </h1>
                <p className="text-xl text-gray-700 dark:text-gray-300">
                    Remote inference allows you to run surveys at the Expected
                    Parrot server instead of your own machine, and to use remote
                    caching to automatically store your survey results and logs.
                </p>

                <div className="bg-blue-50 dark:bg-blue-900/20 p-4 rounded-lg mb-6 border-l-4 border-blue-500">
                    <p className="text-lg">
                        <b>Benefits of remote inference:</b>
                    </p>
                    <ul className="list-disc pl-5 space-y-1 mt-2">
                        <li>
                            Run large-scale surveys without tying up your local
                            machine
                        </li>
                        <li>
                            Access all available models with a single API key
                        </li>
                        <li>
                            Automatically store and retrieve results with the
                            Universal Remote Cache
                        </li>
                        <li>
                            Share your research easily through the Coop platform
                        </li>
                    </ul>
                </div>
            </div>

            {/* Step 1 */}
            <div className="border border-gray-200 dark:border-gray-800 rounded-xl p-6 shadow-sm bg-white dark:bg-gray-900">
                <div className="flex items-center gap-3 mb-4">
                    <div className="w-10 h-10 rounded-full bg-green-100 dark:bg-green-900 flex items-center justify-center text-green-600 dark:text-green-400">
                        <ToggleRight size={20} />
                    </div>
                    <h3 className="font-bold text-2xl">
                        Step 1: Activate remote inference
                    </h3>
                </div>

                <p className="mb-4">
                    Navigate to the{' '}
                    <Link to="/home/settings" className={linkStyles}>
                        Settings
                    </Link>{' '}
                    page of your account and turn on remote inference:
                </p>

                <div className="mb-6 flex justify-center">
                    <img
                        src={homeSettingsImageURL}
                        alt="Screenshot of settings page showing remote inference toggle and Expected Parrot API key"
                        className="w-full max-w-3xl border-2 border-gray-200 rounded-lg shadow-lg"
                    />
                </div>

                <Link
                    to="/home/settings"
                    className="inline-flex items-center gap-2 px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 transition"
                >
                    Go to Settings <ArrowRight size={16} />
                </Link>
            </div>

            {/* Step 2 */}
            <div className="border border-gray-200 dark:border-gray-800 rounded-xl p-6 shadow-sm bg-white dark:bg-gray-900">
                <div className="flex items-center gap-3 mb-4">
                    <div className="w-10 h-10 rounded-full bg-blue-100 dark:bg-blue-900 flex items-center justify-center text-blue-600 dark:text-blue-400">
                        <Code size={20} />
                    </div>
                    <h3 className="font-bold text-2xl">
                        Step 2: Run a job with EDSL
                    </h3>
                </div>

                <p className="mb-4">
                    With remote inference activated, calling the{' '}
                    <code className="bg-gray-100 dark:bg-gray-800 px-1 py-0.5 rounded">
                        run()
                    </code>{' '}
                    method will automatically send your job to the Expected
                    Parrot server. You can optionally pass a visibility status
                    (default is unlisted) and description to identify the job at
                    your account:
                </p>

                <div className="relative bg-gray-100 dark:bg-gray-800 rounded-lg p-4 font-mono text-sm mb-4">
                    from edsl import Model, QuestionFreeText, Survey
                    <br />
                    <br />
                    m = Model("gemini-1.5-flash")
                    <br />
                    <br />
                    q = QuestionFreeText(question_name = "prime", question_text
                    = "Is 2 a prime number?" )
                    <br />
                    <br />
                    survey = Survey(questions = [q])
                    <br />
                    <br />
                    results = survey.by(m).run()
                    <button
                        onClick={() =>
                            copyToClipboard(`from edsl import Model, QuestionFreeText, Survey

m = Model("gemini-1.5-flash")

q = QuestionFreeText(question_name = "prime", question_text = "Is 2 a prime number?" )

survey = Survey(questions = [q])

results = survey.by(m).run(remote_inference_description = "Example survey", remote_inference_visibility = "public")`)
                        }
                        className="absolute top-2 right-2 p-1 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
                        aria-label="Copy to clipboard"
                    >
                        {copiedText.startsWith('from edsl import Model') ? (
                            <Check size={18} />
                        ) : (
                            <Copy size={18} />
                        )}
                    </button>
                </div>

                <div className="bg-yellow-50 dark:bg-yellow-900/20 p-4 rounded-lg mb-4 border-l-4 border-yellow-500">
                    <p>
                        <b>Note:</b> When remote inference is active, your
                        results are automatically stored and can be accessed
                        from your Coop content page.
                    </p>
                </div>

                <a
                    href="https://docs.expectedparrot.com/en/latest/remote_inference.html"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="inline-flex items-center gap-1 text-blue-600 hover:text-blue-700 dark:text-blue-400 dark:hover:text-blue-500"
                >
                    Learn more about remote inference options{' '}
                    <ChevronRight size={16} />
                </a>
            </div>

            {/* Step 3 */}
            <div className="border border-gray-200 dark:border-gray-800 rounded-xl p-6 shadow-sm bg-white dark:bg-gray-900">
                <div className="flex items-center gap-3 mb-4">
                    <div className="w-10 h-10 rounded-full bg-purple-100 dark:bg-purple-900 flex items-center justify-center text-purple-600 dark:text-purple-400">
                        <FileText size={20} />
                    </div>
                    <h3 className="font-bold text-2xl">
                        Step 3: View job details
                    </h3>
                </div>

                <p className="mb-4">
                    When your job has finished running, it will appear as
                    "Completed", and you can view the job details and results:
                </p>

                <div className="mb-6 flex justify-center">
                    <img
                        src={homeRemoteInferenceJobCompletedImageURL}
                        alt="Screenshot of the Remote inference page showing a job is completed"
                        className="w-full max-w-3xl border-2 border-gray-200 rounded-lg shadow-lg"
                    />
                </div>

                <p className="mb-4">
                    You can access the results from your{' '}
                    <Link to="/content" className={linkStyles}>
                        Coop content page
                    </Link>
                    :
                </p>

                <div className="mb-6 flex justify-center">
                    <img
                        src={coopContentResultsViewImageURL}
                        alt="Screenshot of the object view page"
                        className="w-full max-w-3xl border-2 border-gray-200 rounded-lg shadow-lg"
                    />
                </div>

                <Link
                    to="/content"
                    className="inline-flex items-center gap-2 px-4 py-2 bg-purple-600 text-white rounded-lg hover:bg-purple-700 transition"
                >
                    View your content <ArrowRight size={16} />
                </Link>
            </div>

            {/* Step 4 */}
            <div className="border border-gray-200 dark:border-gray-800 rounded-xl p-6 shadow-sm bg-white dark:bg-gray-900">
                <div className="flex items-center gap-3 mb-4">
                    <div className="w-10 h-10 rounded-full bg-amber-100 dark:bg-amber-900 flex items-center justify-center text-amber-600 dark:text-amber-400">
                        <CreditCard size={20} />
                    </div>
                    <h3 className="font-bold text-2xl">
                        Step 4: Manage credits
                    </h3>
                </div>

                <p className="mb-4">
                    Using remote inference consumes credits. You can view your
                    balance and purchase credits at the{' '}
                    <Link to="/home/credits" className={linkStyles}>
                        Credits
                    </Link>{' '}
                    page of your account:
                </p>

                <div className="mb-6 flex justify-center">
                    <img
                        src={homeCreditsImageURL}
                        alt="Screenshot of the Coop credits page. The user has a balance of 100 credits. There is a link to purchase credits with Stripe"
                        className="w-full max-w-3xl border-2 border-gray-200 rounded-lg shadow-lg"
                    />
                </div>

                <div className="bg-green-50 dark:bg-green-900/20 p-4 rounded-lg mb-4 border-l-4 border-green-500">
                    <p>
                        <b>New accounts:</b> Your account comes with $25 in free
                        credits to get you started with remote inference!
                    </p>
                </div>

                <Link
                    to="/home/credits"
                    className="inline-flex items-center gap-2 px-4 py-2 bg-amber-600 text-white rounded-lg hover:bg-amber-700 transition"
                >
                    Manage credits <ArrowRight size={16} />
                </Link>
            </div>

            {/* Additional Resources */}
            <div className="border border-gray-200 dark:border-gray-800 rounded-xl p-6 shadow-sm bg-blue-50 dark:bg-blue-900/20">
                <h3 className="font-bold text-2xl mb-4">Learn more</h3>

                <div className="grid md:grid-cols-2 gap-4">
                    <a
                        href="https://docs.expectedparrot.com/en/latest/remote_inference.html"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="flex items-center justify-center gap-2 px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition"
                    >
                        Remote Inference Documentation{' '}
                        <ExternalLink size={18} />
                    </a>
                    <Link
                        to="/models"
                        className="flex items-center justify-center gap-2 px-6 py-3 bg-blue-200 text-blue-800 dark:bg-blue-700 dark:text-white rounded-lg hover:bg-blue-300 dark:hover:bg-blue-600 transition"
                    >
                        Pricing Information <ChevronRight size={18} />
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default CoopRemoteInference;
