import { Link, useLoaderData } from 'react-router-dom';
import { ModelPricingTableWrapper } from '../../../base/ModelPricingTable';
import { ModelRecord } from '../../../../types';

export interface ModelPricingData {
    user: {
        is_admin: boolean;
        is_authenticated: boolean;
        is_beta_user: boolean;
    };
    last_updated_time_from_now: string;
    records: ModelRecord[];
}

function Pricing() {
    const data = useLoaderData() as ModelPricingData;

    const linkStyles =
        'text-blue-500 hover:text-blue-600 hover:underline dark:text-primary-dark-text-accent dark:hover:text-primary-dark-text-accent';

    return (
        <>
            <div className="space-y-2">
                <h1 className="font-bold text-4xl pb-2">
                    Model pricing & performance
                </h1>
                <p className="text-lg pb-2">
                    This table is updated daily to provide current model prices
                    when using your Expected Parrot API key to access language
                    models, together with information about model performance
                    answering test questions with text and images. Prices are
                    based on token rates published by service providers which
                    may be updated at any time and may vary from the rates that
                    you receive from service providers using your own API keys.
                    Please see our docs to{' '}
                    <Link
                        to="https://docs.expectedparrot.com/en/latest/credits.html"
                        target="_blank"
                        rel="noopener noreferrer"
                        className={linkStyles}
                    >
                        learn more
                    </Link>{' '}
                    about calculating costs and purchasing credits to run
                    surveys at the Expected Parrot server.
                </p>
            </div>

            <ModelPricingTableWrapper
                modelRecords={data.records}
                lastUpdatedTimeFromNow={data.last_updated_time_from_now}
                isAdmin={data.user.is_admin}
            />

            <div className="text-sm italic">
                An asterisk (*) indicates that a current price from a service
                provider was not found and a default price is being used to
                calculate costs instead. The current default prices are $1.00/1M
                tokens for input and $1.00/1M tokens for output. Please notify
                us of any missing or out of date prices.
            </div>
        </>
    );
}

export default Pricing;
