import { useState } from 'react';
import { ChevronDownIcon, ChevronUpIcon } from 'lucide-react';
import { AnswerValidationData, QuestionException, TaskHistory } from './types';
import { useCopyToClipboard } from '../../../../../../../../hooks/useCopyToClipboard';

function CopyCodeToReproduceBtn({ code }: { code: string }) {
    const [isCopied, copy] = useCopyToClipboard();

    function handleCopy(text: string) {
        copy(text).catch((error) => {
            console.error('Failed to copy!', error);
        });
    }

    return (
        <button
            type="button"
            onClick={() => handleCopy(code)}
            className={`px-3 py-1 rounded text-white text-sm ${
                isCopied ? 'bg-green-600' : 'bg-blue-600 hover:bg-blue-700'
            }`}
        >
            {isCopied ? 'Copied!' : 'Copy'}
        </button>
    );
}

function AnswerValidationTable({
    answer_validation_data,
}: {
    answer_validation_data: AnswerValidationData;
}) {
    return (
        <table className="w-full border-collapse text-sm">
            <tbody>
                <DataRow
                    label="Exception type"
                    value="QuestionAnswerValidationError"
                />
                <DataRow
                    label="Explanation"
                    value="This can occur when the answer coming from the Language Model does not conform to the expectations for the question type. For example, if the question is a multiple choice question, the answer should be drawn from the list of options provided."
                />
                <DataRow
                    label="EDSL response"
                    value={answer_validation_data.edsl_response}
                    type="code"
                />
                <DataRow
                    label="Validating model"
                    value={answer_validation_data.validating_model}
                    type="code"
                />
                <DataRow
                    label="Error message"
                    value={answer_validation_data.error_message}
                />
                <DataRow
                    label="Documentation"
                    value="https://docs.expectedparrot.com/en/latest/exceptions.html"
                    type="link"
                />
            </tbody>
        </table>
    );
}

function DataRow({
    label,
    value,
    type = 'text',
}: {
    label: string;
    value: string;
    type?: 'text' | 'code' | 'link';
}) {
    return (
        <tr className="border-b border-gray-200 dark:border-gray-700">
            <th className="p-3 text-left font-medium text-gray-700 dark:text-gray-300 align-top bg-gray-50 dark:bg-gray-800 w-1/4">
                {label}
            </th>
            <td className="p-3 text-gray-700 dark:text-gray-300">
                {type === 'code' ? (
                    <pre className="text-xs overflow-auto p-2 bg-gray-100 dark:bg-gray-800 rounded max-h-60">
                        <code className="text-wrap">{value}</code>
                    </pre>
                ) : type === 'link' ? (
                    <a
                        href={value}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-600 hover:text-blue-800 dark:text-blue-400 dark:hover:text-blue-200 underline"
                    >
                        {value}
                    </a>
                ) : (
                    value
                )}
            </td>
        </tr>
    );
}

function ExceptionDetails({ exception }: { exception: QuestionException }) {
    return (
        <div className="space-y-8">
            {/* Error Summary Section */}
            <div>
                <div className="border-b-2 border-red-500 pb-1 mb-4">
                    <h3 className="text-lg font-medium text-red-600 dark:text-red-400">
                        Error Summary
                    </h3>
                </div>

                <table className="w-full border-collapse text-sm mb-6">
                    <tbody>
                        <DataRow
                            label="Exception"
                            value={exception.exception_message}
                        />
                        <DataRow label="Model" value={exception.model_name} />
                        <DataRow
                            label="Question"
                            value={`${exception.question_name} (${
                                exception.question_type || 'Unknown type'
                            })`}
                        />
                        <DataRow label="Time" value={exception.time} />
                    </tbody>
                </table>
            </div>

            {/* Details Section */}
            <div>
                <div className="border-b-2 border-blue-500 pb-1 mb-4">
                    <h3 className="text-lg font-medium text-blue-600 dark:text-blue-400">
                        Details
                    </h3>
                </div>
                <table className="w-full border-collapse text-sm">
                    <tbody>
                        <DataRow
                            label="Interview ID"
                            value={exception.interview_id.toString()}
                        />
                        <DataRow
                            label="Question name"
                            value={exception.question_name}
                        />
                        <DataRow
                            label="Question type"
                            value={exception.question_type || 'Unknown'}
                        />
                        <DataRow
                            label="User prompt"
                            value={exception.user_prompt}
                            type="code"
                        />
                        <DataRow
                            label="Scenario"
                            value={exception.scenario_repr}
                        />
                        <DataRow label="Agent" value={exception.agent_repr} />
                        <DataRow
                            label="System prompt"
                            value={exception.system_prompt}
                            type="code"
                        />
                        <DataRow
                            label="Inference service"
                            value={exception.inference_service}
                        />
                        <DataRow
                            label="Model name"
                            value={exception.model_name}
                        />
                        <DataRow label="Model" value={exception.model_repr} />
                        <DataRow
                            label="Raw model response"
                            value={exception.raw_model_response}
                            type="code"
                        />
                        <DataRow
                            label={`Generated token string (at ${exception.key_sequence})`}
                            value={exception.generated_token_string}
                            type="code"
                        />
                    </tbody>
                </table>
            </div>

            {/* Answer validation data section */}
            {exception.answer_validation_data && (
                <div>
                    <div className="border-b-2 border-blue-500 pb-1 mb-4">
                        <h3 className="text-lg font-medium text-blue-600 dark:text-blue-400">
                            Answer Validation Details
                        </h3>
                    </div>
                    <AnswerValidationTable
                        answer_validation_data={
                            exception.answer_validation_data
                        }
                    />
                </div>
            )}

            {/* Code to reproduce section */}
            <div>
                <div className="border-b-2 border-blue-500 pb-1 mb-4 flex justify-between items-center">
                    <h3 className="text-lg font-medium text-blue-600 dark:text-blue-400">
                        Code to reproduce error
                    </h3>
                    <CopyCodeToReproduceBtn
                        code={exception.code_to_reproduce}
                    />
                </div>
                <pre className="text-xs bg-gray-100 dark:bg-gray-900 border border-gray-200 dark:border-gray-700 p-3 rounded overflow-auto max-h-60">
                    <code>{exception.code_to_reproduce}</code>
                </pre>
            </div>

            {/* Traceback section */}
            <div>
                <div className="border-b-2 border-blue-500 pb-1 mb-4">
                    <h3 className="text-lg font-medium text-blue-600 dark:text-blue-400">
                        Traceback
                    </h3>
                </div>
                <pre className="text-xs bg-gray-100 dark:bg-gray-900 border border-gray-200 dark:border-gray-700 p-3 rounded overflow-auto max-h-60 text-wrap italic">
                    <code>{exception.traceback}</code>
                </pre>
            </div>
        </div>
    );
}

function Exception({ exception }: { exception: QuestionException }) {
    const [isExpanded, setIsExpanded] = useState<boolean>(false);

    const toggleExpanded = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <div className="border border-t-0 border-red-200 dark:border-red-800 mb-4 last:mb-0 last:rounded-b-lg overflow-hidden">
            <div
                className="bg-red-50 dark:bg-red-900/30 p-4 flex justify-between items-center cursor-pointer"
                onClick={toggleExpanded}
            >
                <div className="text-red-700 dark:text-red-300">
                    <span className="font-medium">
                        {exception.exception_type}
                    </span>
                </div>
                <button className="text-gray-700 dark:text-gray-300">
                    {isExpanded ? (
                        <ChevronUpIcon className="w-5 h-5" />
                    ) : (
                        <ChevronDownIcon className="w-5 h-5" />
                    )}
                </button>
            </div>

            {isExpanded && (
                <div className="p-4 bg-white dark:bg-gray-900">
                    <ExceptionDetails exception={exception} />
                </div>
            )}
        </div>
    );
}

function Interviews({ taskHistory }: { taskHistory: TaskHistory }) {
    return (
        <div className="p-4">
            {taskHistory.interviews.map((interview, interviewIndex) =>
                interview.interview_exceptions.map(
                    (question, questionIndex) => (
                        <div
                            key={`${interviewIndex}-${questionIndex}`}
                            className="mb-8 last:mb-0"
                        >
                            <div className="w-full mt-2 px-2 py-2 bg-yellow-100 dark:bg-yellow-800 dark:border-transparent text-medium">
                                Question name:{' '}
                                <span className="font-bold">
                                    {question.question_name}
                                </span>
                            </div>
                            {question.question_exceptions.map(
                                (exception, exceptionIndex) => (
                                    <Exception
                                        key={exceptionIndex}
                                        exception={exception}
                                    />
                                )
                            )}
                        </div>
                    )
                )
            )}
        </div>
    );
}

export default Interviews;
