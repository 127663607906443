import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { ExternalLink, Play, ChevronDown, ChevronRight } from 'lucide-react';
import SidebarContainer from '../base/SidebarContainer';

interface CollapsibleSectionProps {
    title: string;
    defaultOpen?: boolean;
    children: React.ReactNode;
}

function CollapsibleSection({
    title,
    defaultOpen = false,
    children,
}: CollapsibleSectionProps) {
    const [isOpen, setIsOpen] = useState(defaultOpen);

    const toggleOpen = () => {
        setIsOpen(!isOpen);
    };

    return (
        <>
            <p
                className="-space-y-2 block px-2 pt-2 text-gray-500 dark:text-gray-300 font-semibold cursor-pointer flex items-center"
                onClick={toggleOpen}
            >
                {isOpen ? (
                    <ChevronDown className="w-4 h-4 mr-1" />
                ) : (
                    <ChevronRight className="w-4 h-4 mr-1" />
                )}
                {title}
            </p>
            <div className={`-space-y-2 ml-3 ${isOpen ? 'block' : 'hidden'}`}>
                <div className="-space-y-2 ml-3">{children}</div>
            </div>
        </>
    );
}

function Sidebar() {
    const activeNavLinkStyles =
        'text-blue-600 dark:text-primary-dark-text-accent font-bold';
    const inactiveNavLinkStyles =
        'rounded-md hover:bg-gray-100 dark:hover:bg-gray-50/10 dark:text-primary-dark-text-accent';

    return (
        <SidebarContainer>
            <nav className="space-y-2 text-blue-400 text-sm pb-8">
                <div className="-space-y-2 pb-2">
                    <NavLink
                        to="/getting-started"
                        className={({ isActive }) =>
                            isActive
                                ? `w-full inline-flex items-center p-2 ${activeNavLinkStyles}`
                                : `w-full inline-flex items-center p-2 ${inactiveNavLinkStyles}`
                        }
                        end={true}
                    >
                        <Play className="w-4 mr-2" />
                        Get started
                    </NavLink>
                </div>

                <CollapsibleSection title="EDSL" defaultOpen={true}>
                    <NavLink
                        to="/getting-started/edsl-installation"
                        className={({ isActive }) =>
                            isActive
                                ? `block p-2 ${activeNavLinkStyles}`
                                : `block p-2 ${inactiveNavLinkStyles}`
                        }
                    >
                        Installation
                    </NavLink>
                    <NavLink
                        to="/getting-started/edsl-api-keys"
                        className={({ isActive }) =>
                            isActive
                                ? `block p-2 ${activeNavLinkStyles}`
                                : `block p-2 ${inactiveNavLinkStyles}`
                        }
                    >
                        Managing keys
                    </NavLink>
                    <a
                        href="https://docs.expectedparrot.com/en/latest/starter_tutorial.html"
                        target="_blank"
                        className={`w-full inline-flex items-center p-2 ${inactiveNavLinkStyles}`}
                    >
                        Starter tutorial{' '}
                        <ExternalLink
                            className="inline w-4 h-4 ml-1"
                            strokeWidth="1.5"
                        />
                    </a>
                    <NavLink
                        to="/getting-started/edsl-use-cases"
                        className={({ isActive }) =>
                            isActive
                                ? `block p-2 ${activeNavLinkStyles}`
                                : `block p-2 ${inactiveNavLinkStyles}`
                        }
                    >
                        Use cases
                    </NavLink>
                    <a
                        href="https://github.com/expectedparrot/edsl"
                        target="_blank"
                        className={`w-full inline-flex items-center p-2 ${inactiveNavLinkStyles}`}
                    >
                        GitHub{' '}
                        <ExternalLink
                            className="inline w-4 h-4 ml-1"
                            strokeWidth="1.5"
                        />
                    </a>
                </CollapsibleSection>

                <CollapsibleSection title="Coop" defaultOpen={true}>
                    <NavLink
                        to="/login"
                        className={({ isActive }) =>
                            isActive
                                ? `block p-2 ${activeNavLinkStyles}`
                                : `block p-2 ${inactiveNavLinkStyles}`
                        }
                    >
                        Create an account
                    </NavLink>
                    <NavLink
                        to="/getting-started/coop-how-it-works"
                        className={({ isActive }) =>
                            isActive
                                ? `block p-2 ${activeNavLinkStyles}`
                                : `block p-2 ${inactiveNavLinkStyles}`
                        }
                    >
                        How it works
                    </NavLink>
                    <NavLink
                        to="/getting-started/coop-remote-inference"
                        className={({ isActive }) =>
                            isActive
                                ? `block p-2 ${activeNavLinkStyles}`
                                : `block p-2 ${inactiveNavLinkStyles}`
                        }
                    >
                        Use remote inference
                    </NavLink>
                    <NavLink
                        to="/getting-started/coop-remote-cache"
                        className={({ isActive }) =>
                            isActive
                                ? `block p-2 ${activeNavLinkStyles}`
                                : `block p-2 ${inactiveNavLinkStyles}`
                        }
                    >
                        Use remote cache
                    </NavLink>
                </CollapsibleSection>

                <CollapsibleSection title="Docs" defaultOpen={false}>
                    <a
                        href="https://docs.expectedparrot.com/en/latest/"
                        target="_blank"
                        className={`w-full inline-flex items-center p-2 ${inactiveNavLinkStyles}`}
                    >
                        Read our docs{' '}
                        <ExternalLink
                            className="inline w-4 h-4 ml-1"
                            strokeWidth="1.5"
                        />
                    </a>
                </CollapsibleSection>

                <CollapsibleSection title="Support" defaultOpen={false}>
                    <a
                        href="https://discord.com/invite/mxAYkjfy9m"
                        target="_blank"
                        className={`w-full inline-flex items-center p-2 ${inactiveNavLinkStyles}`}
                    >
                        Join our Discord{' '}
                        <ExternalLink
                            className="inline w-4 h-4 ml-1"
                            strokeWidth="1.5"
                        />
                    </a>
                    <NavLink
                        to="/consulting"
                        className={({ isActive }) =>
                            isActive
                                ? `block p-2 ${activeNavLinkStyles}`
                                : `block p-2 ${inactiveNavLinkStyles}`
                        }
                    >
                        Consulting
                    </NavLink>
                </CollapsibleSection>
            </nav>
        </SidebarContainer>
    );
}

export default Sidebar;
