import { Info, Check, AlertTriangle } from 'lucide-react';
import { PrioritizedKey } from './types';

function KeyPriorityCallout() {
    return (
        <div className="mb-6 bg-blue-50 dark:bg-blue-900/20 p-4 rounded-lg border-l-4 border-blue-500 text-sm">
            {/* <div className="p-4 mb-6 rounded-lg border border-blue-200 bg-blue-50 dark:border-blue-900 dark:bg-blue-900/50"> */}
            <div className="flex items-center gap-2 mb-4">
                <Info className="w-4 h-4" />
                <h3 className="font-semibold">How are keys prioritized?</h3>
            </div>
            <div className="ml-6">
                <p className="mb-2">
                    Keys are prioritized in the following order:
                </p>
                <ol className="list-decimal list-inside">
                    <li>Your personal keys (most recently created first)</li>
                    <li>
                        Keys that have been shared with you (most recently
                        shared first)
                    </li>
                    <li>
                        Expected Parrot API keys that have been shared with you
                        (most recently shared first)
                    </li>
                    <li>Your Expected Parrot API key (uses credits)</li>
                </ol>
            </div>
        </div>
    );
}

function KeyTable({ keys }: { keys: PrioritizedKey[] }) {
    return (
        <div className="overflow-x-auto">
            <div className="relative">
                <table className="min-w-full text-sm relative">
                    <thead className="bg-white dark:bg-primary-dark-bg sticky border-b dark:border-gray-100/20 top-0">
                        <tr>
                            <th className="py-2 px-4 text-left w-2/12 font-semibold whitespace-nowrap text-center">
                                Used for Jobs
                            </th>
                            <th className="py-2 px-4 text-left w-1/12 font-semibold whitespace-nowrap">
                                Priority
                            </th>
                            <th className="py-2 px-4 text-left w-1/12 font-semibold whitespace-nowrap">
                                Source
                            </th>
                            <th className="py-2 px-4 text-left w-1/12 font-semibold whitespace-nowrap">
                                Type
                            </th>
                            <th className="py-2 px-4 text-left w-7/12 font-semibold whitespace-nowrap">
                                Description
                            </th>
                        </tr>
                    </thead>
                    <tbody className="align-top">
                        {keys
                            .sort((a, b) => {
                                if (a.priority === null) return 1;
                                if (b.priority === null) return -1;
                                return a.priority - b.priority;
                            })
                            .map((key, index) => (
                                <tr
                                    key={`${key.type}-${key.source}-${index}`}
                                    className={`border-y dark:border-gray-100/20 dark:text-secondary-dark-text ${
                                        index % 2 === 0
                                            ? 'bg-white dark:bg-gray-800'
                                            : 'bg-gray-50 dark:bg-gray-700'
                                    }`}
                                >
                                    <td className="py-2 px-4 text-center">
                                        {key.used_for_jobs && (
                                            <Check className="w-5 h-5 inline-block text-green-600 dark:text-green-500" />
                                        )}
                                    </td>
                                    <td className="py-2 px-4 text-left font-medium">
                                        {key.priority}
                                    </td>
                                    <td className="py-2 px-4 text-left capitalize whitespace-nowrap">
                                        {key.source}
                                    </td>
                                    <td className="py-2 px-4 text-left capitalize whitespace-nowrap">
                                        {key.type === 'api'
                                            ? 'Expected Parrot'
                                            : key.type === 'custodial'
                                            ? 'Custodial'
                                            : key.type}
                                    </td>
                                    <td className="py-2 px-4 text-left">
                                        <div>{key.description}</div>
                                        {key.key_issue && (
                                            <div className="flex items-center gap-1 text-red-600 dark:text-red-400 mt-1">
                                                <AlertTriangle className="w-4 h-4" />
                                                <span>{key.key_issue}</span>
                                            </div>
                                        )}
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

function PrioritizedKeys({
    prioritized_keys,
}: {
    prioritized_keys: {
        name: string;
        keys: PrioritizedKey[];
    }[];
}) {
    return (
        <>
            <KeyPriorityCallout />
            {prioritized_keys.map(({ name, keys }) => (
                <div key={name} className="mb-8">
                    <h3 className="font-semibold text-lg mb-4">{name}</h3>
                    <KeyTable keys={keys} />
                </div>
            ))}
        </>
    );
}

export default PrioritizedKeys;
