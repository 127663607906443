import { useFetcher } from 'react-router-dom';
import * as Popover from '@radix-ui/react-popover';
import { Trash2 } from 'lucide-react';

function DeleteKeyBtn({ keyId }: { keyId: string }) {
    const fetcher = useFetcher();

    return (
        <fetcher.Form method="post">
            <input type="hidden" name="key_uuid" value={keyId} />
            <button
                type="submit"
                name="intent"
                value="delete_key"
                className="bg-red-600 hover:bg-red-700 border-2 border-red-600 hover:border-red-700 rounded-md px-3 py-2 text-white font-semibold text-sm"
                onClick={(event) => event.stopPropagation()}
            >
                Delete
            </button>
        </fetcher.Form>
    );
}

function DeleteKeyPopover({ keyId }: { keyId: string }) {
    return (
        <Popover.Root>
            <Popover.Trigger asChild>
                <button className="items-center justify-center p-2 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600">
                    <Trash2 className="w-5 h-5" />
                </button>
            </Popover.Trigger>
            <Popover.Portal>
                <Popover.Content
                    className="w-48 bg-white dark:bg-gray-700 rounded-lg shadow z-20"
                    sideOffset={5}
                >
                    <div className="flex flex-col gap-y-4 p-4 text-center">
                        Permanently delete this key?
                        <div className="flex justify-center gap-x-2">
                            <Popover.Close asChild>
                                <button className="hover:bg-gray-300/20 border border-gray-300 dark:border-2 dark:border-gray-500 font-semibold text-sm px-3 py-2 rounded-md">
                                    Cancel
                                </button>
                            </Popover.Close>
                            <DeleteKeyBtn keyId={keyId} />
                        </div>
                    </div>
                </Popover.Content>
            </Popover.Portal>
        </Popover.Root>
    );
}

export default DeleteKeyPopover;
