import { useState } from 'react';
import { useCopyToClipboard } from '../../../../hooks/useCopyToClipboard';
import { Copy, Check, Eye, EyeOff } from 'lucide-react';

function APIKey({ apiKey }: { apiKey: string }) {
    const [isCopied, copy] = useCopyToClipboard();
    const [isVisible, setIsVisible] = useState(false);

    function handleCopy(text: string) {
        copy(text).catch((error) => {
            console.error('Failed to copy!', error);
        });
    }

    return (
        <div className="w-full md:w-2/3">
            <div className="flex items-stretch w-full">
                <div className="relative flex-grow">
                    <input
                        type={isVisible ? 'text' : 'password'}
                        id="api-key"
                        name="api-key"
                        value={apiKey}
                        readOnly
                        className="w-full bg-white focus:outline-none border border-gray-300 rounded-l-md p-2.5 pr-10 dark:bg-gray-800 dark:border-gray-600 dark:text-gray-200"
                    />
                    <button
                        type="button"
                        onClick={() => setIsVisible(!isVisible)}
                        className="absolute inset-y-0 right-0 px-2.5 rounded-r-none text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
                        aria-label={isVisible ? 'Hide API key' : 'Show API key'}
                    >
                        {isVisible ? <EyeOff size={18} /> : <Eye size={18} />}
                    </button>
                </div>

                <button
                    type="button"
                    onClick={() => handleCopy(apiKey)}
                    className="flex items-center gap-2 px-4 py-2.5 border-l-0 bg-blue-500 hover:bg-blue-600 transition-colors duration-200 rounded-r-md text-white font-medium min-w-[120px] justify-center"
                >
                    {isCopied ? (
                        <>
                            <Check className="w-4 h-4" />
                            <span>Copied</span>
                        </>
                    ) : (
                        <>
                            <Copy className="w-4 h-4" />
                            <span>Copy</span>
                        </>
                    )}
                </button>
            </div>
        </div>
    );
}

export default APIKey;
