import { useEffect } from 'react';
import { useLoaderData, useRevalidator } from 'react-router-dom';
import JobProgressBar, {
    JobStatus,
} from '../../../../../../components/base/JobProgressBar/JobProgressBar';

interface StatusData {
    status: JobStatus;
}

function RemoteJobId() {
    const data = useLoaderData() as StatusData;
    const status = data.status;

    let revalidator = useRevalidator();

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (revalidator.state === 'idle' && status.status === 'running') {
                revalidator.revalidate();
            } else if (
                status.status === 'completed' ||
                status.status === 'idle'
            ) {
                // Clear interval if job is completed
                clearInterval(intervalId);
            }
            // Revalidate every 1000 ms (1 second)
        }, 1000);

        // Cleanup function to clear the interval when component unmounts
        return () => clearInterval(intervalId);
    }, [revalidator, status.status]);

    return <JobProgressBar status={data.status} isRemoteJob={true} />;
}

export default RemoteJobId;
