import { Link, useNavigate } from 'react-router-dom';
import ContentContainer from './ContentContainer';

function Message({
    title,
    mainMessage,
    redirectUrl,
    showRedirectMessage = true,
}: {
    title: string;
    mainMessage: string;
    redirectUrl?: string;
    showRedirectMessage?: boolean;
}) {
    const navigate = useNavigate();

    return (
        <ContentContainer>
            <div className="m-auto p-8 rounded-lg border-2 border-gray-300 shadow-md">
                <div className="flex flex-col items-center text-center py-10 mb-2 space-y-16">
                    <div className="text-3xl font-bold">{title}</div>
                    <div className="flex flex-col items-center text-center text-xl font-bold space-y-4">
                        <div className="whitespace-pre-line">{mainMessage}</div>
                    </div>
                    {showRedirectMessage && (
                        <div className="flex flex-col items-center text-center space-y-4">
                            <div>
                                {redirectUrl ? (
                                    <Link
                                        to={redirectUrl}
                                        className="text-blue-600 dark:text-primary-dark-text-accent hover:underline"
                                    >
                                        Click here
                                    </Link>
                                ) : (
                                    <button
                                        onClick={() => navigate(-1)}
                                        type="button"
                                        className="text-blue-600 dark:text-primary-dark-text-accent hover:underline"
                                    >
                                        Click here
                                    </button>
                                )}{' '}
                                if you are not automatically redirected
                            </div>
                            <div>
                                If you need more help, please contact us at{' '}
                                <a
                                    href="mailto:info@expectedparrot.com"
                                    className="text-blue-600 dark:text-primary-dark-text-accent hover:underline"
                                >
                                    info@expectedparrot.com
                                </a>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </ContentContainer>
    );
}

export default Message;
