import { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import * as Popover from '@radix-ui/react-popover';
import { Filter } from 'lucide-react';
import { objectTypeOptions } from './filterOptions';

function TypeDropdown() {
    const [searchParams, setSearchParams] = useSearchParams();

    const [typeFilters, setTypeFilters] = useState(() => {
        return searchParams.getAll('type');
    });

    useEffect(() => {
        setTypeFilters(searchParams.getAll('type'));
    }, [searchParams]);

    function handleTypeChange(event: React.ChangeEvent<HTMLInputElement>) {
        const prevFilters = [...typeFilters];
        const nextFilters = event.target.checked
            ? // If checkbox is checked, add value to list
              [...prevFilters, event.target.value]
            : // Otherwise, remove value from list
              prevFilters.filter(
                  (objectType) => objectType !== event.target.value
              );
        setTypeFilters(nextFilters);
    }

    function getFilterURL() {
        const searchParams = new URLSearchParams(window.location.search);

        const paramsToReset = ['page', 'type'];
        for (const param of paramsToReset) {
            searchParams.delete(param);
        }

        // Set new params
        for (const value of typeFilters) {
            searchParams.append('type', value);
        }

        return `?${searchParams.toString()}`;
    }

    return (
        <Popover.Root>
            <Popover.Trigger asChild>
                <button
                    type="button"
                    className="inline-flex items-center px-4 py-2 gap-x-2 text-sm font-medium border border-gray-200 dark:border-gray-500 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-300/10 focus:z-10 focus:ring-4 focus:outline-none focus:ring-gray-100 dark:focus:ring-gray-500"
                >
                    <Filter className="inline w-4 h-4" strokeWidth="2" />
                    Type
                </button>
            </Popover.Trigger>

            <Popover.Portal>
                <Popover.Content
                    className="flex flex-col w-48 bg-white dark:bg-gray-700 rounded-lg shadow"
                    sideOffset={5}
                >
                    <div className="max-h-[40vh] overflow-y-auto flex-grow">
                        <ul className="p-3 space-y-1 text-sm text-gray-700 dark:text-gray-200">
                            {objectTypeOptions.map(({ name, value, id }) => (
                                <li
                                    key={id}
                                    className="flex items-center p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600"
                                >
                                    <input
                                        id={`type-checkbox-${id}`}
                                        type="checkbox"
                                        checked={typeFilters.includes(value)}
                                        onChange={handleTypeChange}
                                        value={value}
                                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 dark:bg-gray-600 dark:border-gray-500"
                                    />
                                    <label
                                        htmlFor={`type-checkbox-${id}`}
                                        className="w-full ms-2 text-sm font-medium text-gray-900 rounded dark:text-gray-300"
                                    >
                                        {name}
                                    </label>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="flex border-t border-gray-200 dark:border-primary-dark-border">
                        <Popover.Close asChild>
                            <Link
                                to={window.location.pathname}
                                className="flex-1 p-3 text-sm font-medium text-blue-600 dark:text-primary-dark-text hover:underline text-center border-r border-gray-200 dark:border-primary-dark-border"
                            >
                                Clear
                            </Link>
                        </Popover.Close>
                        <Popover.Close asChild>
                            <Link
                                to={getFilterURL()}
                                className="flex-1 p-3 text-sm font-medium text-blue-600 dark:text-primary-dark-text hover:underline text-center"
                            >
                                Apply
                            </Link>
                        </Popover.Close>
                    </div>
                </Popover.Content>
            </Popover.Portal>
        </Popover.Root>
    );
}

export default TypeDropdown;
