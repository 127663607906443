import { useState, useRef } from 'react';
import { useActionData, useFetcher, useOutletContext } from 'react-router-dom';
import * as Tabs from '@radix-ui/react-tabs';
import * as VisuallyHidden from '@radix-ui/react-visually-hidden';
import {
    SandpackProvider,
    SandpackLayout,
    SandpackCodeViewer,
} from '@codesandbox/sandpack-react';
import { python } from '@codemirror/lang-python';
import { Box, Plus, Trash2, Upload } from 'lucide-react';
import { CreateAgentListModal } from './PublishAgentListModals';
import { ActionResponse } from '../../../../types';
import { Agent, Trait } from './types';

export interface AgentListState {
    method: 'manual' | 'file';
    traits: Trait[];
    agents: Agent[];
    agentFile: File | null;
    hasDataChanged: boolean;
}

function AgentPreview({
    previewData,
    totalAgents,
}: {
    previewData?: {
        traits: string[];
        agents: Array<Record<string, string>>;
    } | null;
    totalAgents?: number;
}) {
    if (!previewData) {
        return null;
    }

    const displayTraits = previewData.traits.map((name) => ({
        id: name,
        name,
    }));
    const displayAgents = previewData.agents.map((agent) => ({
        id: crypto.randomUUID(),
        traits: agent,
    }));

    return (
        <div className="space-y-4">
            <div>
                <div className="text-lg font-medium text-gray-900 dark:text-gray-100">
                    Total agents: {totalAgents?.toLocaleString() ?? '0'}
                </div>
                <div className="text-sm text-gray-500 dark:text-gray-400">
                    (Preview is limited to the first 100 agents)
                </div>
            </div>
            <div className="overflow-x-auto">
                <table className="w-full text-sm text-left text-gray-900 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                            <th scope="col" className="px-6 py-3">
                                Agent
                            </th>
                            {displayTraits.map((trait) => (
                                <th
                                    key={trait.id}
                                    scope="col"
                                    className="px-6 py-3"
                                >
                                    {trait.name}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {displayAgents.map((agent, index) => (
                            <tr
                                key={agent.id}
                                className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                            >
                                <th
                                    scope="row"
                                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                >
                                    Agent #{index + 1}
                                </th>
                                {displayTraits.map((trait) => (
                                    <td key={trait.id} className="px-6 py-4">
                                        {agent.traits[trait.name]}
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

function ConfigureAgentsForm({
    agentList,
    addAgent,
    removeAgent,
    updateAgentTrait,
}: {
    agentList: AgentListState;
    addAgent: () => void;
    removeAgent: (id: string) => void;
    updateAgentTrait: (id: string, traitId: string, value: string) => void;
}) {
    return (
        <div className="space-y-4">
            {agentList.agents.map((agent, index) => (
                <div
                    key={agent.id}
                    className="p-4 border rounded-lg dark:border-gray-600"
                >
                    <div className="flex justify-between items-center mb-2">
                        <h4 className="font-medium">Agent #{index + 1}</h4>
                        <button
                            onClick={() => removeAgent(agent.id)}
                            className="p-1 text-red-600 hover:text-red-800 dark:text-red-400 dark:hover:text-red-300"
                            aria-label="Delete agent"
                        >
                            <Trash2 className="w-4 h-4" />
                        </button>
                    </div>
                    {Object.entries(agent.traits).map(
                        ([traitId, traitValue]) => {
                            const trait = agentList.traits.find(
                                (t) => t.id === traitId
                            );
                            return (
                                <div key={traitId} className="mb-2">
                                    <label
                                        htmlFor={`${agent.id}-${trait.id}`}
                                        className="block text-sm font-medium mb-1"
                                    >
                                        {trait.name || 'unnamed trait'}
                                    </label>
                                    <input
                                        id={`${agent.id}-${trait.id}`}
                                        type="text"
                                        value={traitValue}
                                        onChange={(e) =>
                                            updateAgentTrait(
                                                agent.id,
                                                traitId,
                                                e.target.value
                                            )
                                        }
                                        className="w-full p-2 text-sm bg-gray-50 focus:outline-none border rounded-lg border-gray-400 focus:ring-1 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700/20 dark:border-gray-500 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:placeholder-gray-400"
                                    />
                                </div>
                            );
                        }
                    )}
                </div>
            ))}
            <div className="flex items-center space-x-2">
                <button
                    onClick={addAgent}
                    className="flex items-center px-4 py-2.5 hover:bg-gray-300/20 hover:transition-colors border border-gray-300 dark:border-2 dark:border-gray-500 rounded-md font-medium text-sm"
                >
                    <Plus className="w-4 h-4 mr-1" />
                    Add new agent
                </button>
            </div>
        </div>
    );
}

interface DataTabProps {
    agentList: AgentListState;
    addTrait: () => void;
    removeTrait: (id: string) => void;
    updateTrait: (id: string, name: string) => void;
    addAgent: () => void;
    removeAgent: (id: string) => void;
    updateAgentTrait: (id: string, traitId: string, value: string) => void;
    handleFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onMethodChange: (method: 'manual' | 'file') => void;
}

function DataTab({
    agentList,
    addTrait,
    removeTrait,
    updateTrait,
    addAgent,
    removeAgent,
    updateAgentTrait,
    handleFileChange,
    onMethodChange,
}: DataTabProps) {
    return (
        <Tabs.Root
            defaultValue={agentList.method}
            onValueChange={(value) => {
                if (value === 'manual' || value === 'file') {
                    onMethodChange(value);
                }
            }}
        >
            <Tabs.List
                className="inline-flex p-1 space-x-1 bg-gray-100 rounded-lg dark:bg-gray-700/50 mb-4"
                aria-label="Switch tabs"
            >
                <Tabs.Trigger
                    className="px-3 py-2 text-sm font-medium rounded-md text-center transition-colors data-[state=active]:bg-white data-[state=active]:text-blue-600 data-[state=inactive]:hover:bg-gray-50 dark:data-[state=active]:bg-gray-600 dark:data-[state=active]:text-primary-dark-text-accent dark:data-[state=inactive]:hover:bg-gray-600/50"
                    value="manual"
                >
                    Enter manually
                </Tabs.Trigger>
                <Tabs.Trigger
                    className="px-3 py-2 text-sm font-medium rounded-md text-center transition-colors data-[state=active]:bg-white data-[state=active]:text-blue-600 data-[state=inactive]:hover:bg-gray-50 dark:data-[state=active]:bg-gray-600 dark:data-[state=active]:text-primary-dark-text-accent dark:data-[state=inactive]:hover:bg-gray-600/50"
                    value="file"
                >
                    Upload from file
                </Tabs.Trigger>
            </Tabs.List>

            <Tabs.Content value="manual">
                <div className="space-y-6">
                    {/* Traits section */}
                    <div>
                        <h3 className="text-lg font-medium mb-4">
                            Define traits
                        </h3>
                        <div className="space-y-2">
                            {agentList.traits.map((trait) => (
                                <div
                                    key={trait.id}
                                    className="flex items-center"
                                >
                                    <VisuallyHidden.Root>
                                        <label htmlFor={`${trait.id}-name`}>
                                            Trait name
                                        </label>
                                    </VisuallyHidden.Root>
                                    <input
                                        id={`${trait.id}-name`}
                                        type="text"
                                        value={trait.name}
                                        onChange={(e) =>
                                            updateTrait(
                                                trait.id,
                                                e.target.value
                                            )
                                        }
                                        className="flex-grow p-2 text-sm bg-gray-50 focus:outline-none border rounded-lg border-gray-400 focus:ring-1 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700/20 dark:border-gray-500 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:placeholder-gray-400"
                                    />
                                    <button
                                        onClick={() => removeTrait(trait.id)}
                                        className="p-2 text-red-600 hover:text-red-800 dark:text-red-400 dark:hover:text-red-300"
                                        aria-label="Delete trait"
                                    >
                                        <Trash2 className="w-4 h-4" />
                                    </button>
                                </div>
                            ))}
                            <button
                                onClick={addTrait}
                                className="flex items-center px-4 py-2.5 hover:bg-gray-300/20 hover:transition-colors border border-gray-300 dark:border-2 dark:border-gray-500 rounded-md font-medium text-sm"
                            >
                                <Plus className="w-4 h-4 mr-1" />
                                Add new trait
                            </button>
                        </div>
                    </div>

                    {/* Agents section */}
                    <div>
                        <h3 className="text-lg font-medium mb-4">
                            Configure agents
                        </h3>
                        <ConfigureAgentsForm
                            agentList={agentList}
                            addAgent={addAgent}
                            removeAgent={removeAgent}
                            updateAgentTrait={updateAgentTrait}
                        />
                    </div>
                </div>
            </Tabs.Content>

            <Tabs.Content value="file">
                <FileUpload
                    onFileChange={handleFileChange}
                    selectedFile={agentList.agentFile}
                />
            </Tabs.Content>
        </Tabs.Root>
    );
}

interface FileUploadProps {
    onFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    selectedFile: File | null;
}

function FileUpload({ onFileChange, selectedFile }: FileUploadProps) {
    const fileInputRef = useRef<HTMLInputElement>(null);

    return (
        <div>
            <input
                ref={fileInputRef}
                id="agent-file"
                name="file"
                type="file"
                accept=".csv"
                onChange={onFileChange}
                className="hidden"
            />
            <div className="space-y-2">
                <button
                    onClick={() => fileInputRef.current?.click()}
                    className="flex items-center px-4 py-2.5 hover:bg-gray-300/20 hover:transition-colors border border-gray-300 dark:border-2 dark:border-gray-500 rounded-md font-medium text-sm"
                >
                    <Upload className="w-4 h-4 mr-1" />
                    Upload CSV file
                </button>
                <p className="text-sm text-gray-600 dark:text-gray-400">
                    Maximum file size: 250MB
                </p>
            </div>
            {selectedFile && (
                <div className="mt-2 p-3 bg-gray-50 dark:bg-gray-700/20 rounded-lg">
                    <p className="text-sm">
                        Selected file:{' '}
                        <span className="font-medium">{selectedFile.name}</span>
                    </p>
                </div>
            )}
        </div>
    );
}

function PreviewTab({
    isLoading,
    agentListData,
}: {
    isLoading: boolean;
    agentListData: {
        success?: boolean;
        traits: string[];
        agents: Record<string, string>[];
        num_rows: number;
    } | null;
}) {
    if (isLoading) {
        return 'Loading preview...';
    }

    if (!agentListData?.success) {
        return 'There was an error processing the agent list. See the Code tab for more details.';
    }

    return (
        <AgentPreview
            previewData={{
                traits: agentListData.traits,
                agents: agentListData.agents,
            }}
            totalAgents={agentListData.num_rows}
        />
    );
}

function AgentList() {
    const isDarkMode: boolean = useOutletContext();

    const fetcher = useFetcher();

    const actionData = useActionData() as ActionResponse & {
        intent: string;
        message: string;
        code: string;
        num_rows: number;
        traits: string[];
        agents: Record<string, string>[];
    };

    const createAgentListResponse =
        actionData !== undefined &&
        (actionData.intent === 'create_agent_list' ||
            actionData.intent === 'create_agent_list_from_file')
            ? actionData
            : null;

    // Display errors in the code tab

    const agentListData =
        fetcher.data !== undefined &&
        (fetcher.data.intent === 'get_code' ||
            fetcher.data.intent === 'get_code_from_file')
            ? fetcher.data
            : null;

    const [agentList, setAgentList] = useState<AgentListState>(() => {
        const traits = [
            { id: crypto.randomUUID(), name: 'age' },
            { id: crypto.randomUUID(), name: 'gender' },
            { id: crypto.randomUUID(), name: 'country' },
        ];

        // const ages = [20, 30, 40, 50, 60];
        // const genders = ['male', 'female'];
        // const agents = [];

        const ages = [];
        const genders = [];
        const agents = [];

        for (let age of ages) {
            for (let gender of genders) {
                agents.push({
                    id: crypto.randomUUID(),
                    traits: {
                        [traits[0].id]: age.toString(),
                        [traits[1].id]: gender,
                        [traits[2].id]: 'USA',
                    },
                });
            }
        }

        return {
            method: 'manual',
            traits: traits,
            agents: agents,
            agentFile: null,
            hasDataChanged: true,
        };
    });

    const addTrait = () => {
        const newTrait = { id: crypto.randomUUID(), name: '' };
        setAgentList((prevState) => ({
            ...prevState,
            hasDataChanged: true,
            traits: [...prevState.traits, newTrait],
            agents: prevState.agents.map((agent) => ({
                ...agent,
                traits: { ...agent.traits, [newTrait.id]: '' },
            })),
        }));
    };

    const removeTrait = (id: string) => {
        setAgentList((prevState) => ({
            ...prevState,
            hasDataChanged: true,
            traits: prevState.traits.filter((trait) => trait.id !== id),
            agents: prevState.agents.map((agent) => ({
                ...agent,
                traits: Object.fromEntries(
                    Object.entries(agent.traits).filter(
                        ([traitId]) => traitId !== id
                    )
                ),
            })),
        }));
    };

    const updateTrait = (id: string, name: string) => {
        setAgentList((prevState) => ({
            ...prevState,
            hasDataChanged: true,
            traits: prevState.traits.map((trait) =>
                trait.id === id ? { ...trait, name } : trait
            ),
        }));
    };

    const addAgent = () => {
        const newAgent: Agent = {
            id: crypto.randomUUID(),
            traits: agentList.traits.reduce(
                (acc, trait) => ({ ...acc, [trait.id]: '' }),
                {}
            ),
        };
        setAgentList((prevState) => ({
            ...prevState,
            hasDataChanged: true,
            agents: [...prevState.agents, newAgent],
        }));
    };

    const updateAgentTrait = (
        agentId: string,
        traitId: string,
        value: string
    ) => {
        setAgentList((prevState) => ({
            ...prevState,
            hasDataChanged: true,
            agents: prevState.agents.map((agent) =>
                agent.id === agentId
                    ? {
                          ...agent,
                          traits: { ...agent.traits, [traitId]: value },
                      }
                    : agent
            ),
        }));
    };

    const removeAgent = (id: string) => {
        setAgentList((prevState) => ({
            ...prevState,
            hasDataChanged: true,
            agents: prevState.agents.filter((agent) => agent.id !== id),
        }));
    };

    function handleFileChange(event: React.ChangeEvent<HTMLInputElement>) {
        const file = event.target.files?.[0] || null;

        if (file) {
            const fileExtension = file.name.split('.').pop()?.toLowerCase();
            const isCSV = fileExtension === 'csv' && file.type === 'text/csv';

            if (!isCSV) {
                alert('Please upload a CSV file.');
                event.target.value = ''; // Clear the file input
                return;
            }

            // 250MB file size limit
            const fileSizeLimitMB = 250;

            if (file.size > fileSizeLimitMB * 1024 * 1024) {
                alert('File size exceeds 250MB limit.');
                event.target.value = ''; // Clear the file input
                return;
            }
        }

        setAgentList((prevState) => ({
            ...prevState,
            hasDataChanged: true,
            method: 'file',
            agentFile: file,
        }));
    }

    const handleMethodChange = (method: 'manual' | 'file') => {
        setAgentList((prevState) => ({
            ...prevState,
            hasDataChanged: true,
            method: method,
        }));
    };

    function handleGetCode(intent: string) {
        // Reset the change tracker
        setAgentList((prevState) => ({
            ...prevState,
            hasDataChanged: false,
        }));
        const serializedTraits = JSON.stringify(agentList.traits);
        const serializedAgents = JSON.stringify(agentList.agents);
        fetcher.submit(
            {
                traits: serializedTraits,
                agents: serializedAgents,
                intent: intent,
            },
            { method: 'post', encType: 'application/json' }
        );
    }

    function handleGetCodeFromFile(intent: string) {
        // Reset the change tracker
        setAgentList((prevState) => ({
            ...prevState,
            hasDataChanged: false,
        }));

        const formData = new FormData();
        formData.append('file', agentList.agentFile);
        formData.append('intent', intent);
        fetcher.submit(formData, {
            method: 'post',
            encType: 'multipart/form-data',
        });
    }

    return (
        <Tabs.Root
            defaultValue="data"
            onValueChange={(newTab) => {
                if (
                    (newTab === 'code' || newTab === 'preview') &&
                    agentList.hasDataChanged
                ) {
                    // console.log('Data changed. Re-requesting code...');
                    if (agentList.method === 'manual') {
                        handleGetCode('get_code');
                    } else {
                        handleGetCodeFromFile('get_code_from_file');
                    }
                } else if (newTab === 'code' || newTab === 'preview') {
                    // console.log('Data unchanged. Not re-requesting code.');
                }
            }}
        >
            <div className="flex flex-col lg:flex-row justify-between">
                <Tabs.List
                    className="flex gap-x-2 mb-4"
                    aria-label="Switch tabs"
                >
                    <Tabs.Trigger
                        className="px-4 pb-4 border-b-2 rounded-t-lg text-sm font-medium text-center data-[state=active]:text-blue-600 data-[state=active]:border-blue-600 data-[state=inactive]:border-gray-200 dark:data-[state=active]:text-primary-dark-text-accent dark:data-[state=active]:border-primary-dark-text-accent"
                        value="data"
                    >
                        Data
                    </Tabs.Trigger>
                    <Tabs.Trigger
                        className="px-4 pb-4 border-b-2 rounded-t-lg text-sm font-medium text-center data-[state=active]:text-blue-600 data-[state=active]:border-blue-600 data-[state=inactive]:border-gray-200 dark:data-[state=active]:text-primary-dark-text-accent dark:data-[state=active]:border-primary-dark-text-accent"
                        value="code"
                    >
                        <div className="flex">
                            Code
                            {/* Alert icon for errors */}
                            {agentListData !== null &&
                                !agentListData.success && (
                                    <div className="h-2 w-2 ml-1 rounded-full bg-red-400" />
                                )}
                        </div>
                    </Tabs.Trigger>
                    <Tabs.Trigger
                        className="px-4 pb-4 border-b-2 rounded-t-lg text-sm font-medium text-center data-[state=active]:text-blue-600 data-[state=active]:border-blue-600 data-[state=inactive]:border-gray-200 dark:data-[state=active]:text-primary-dark-text-accent dark:data-[state=active]:border-primary-dark-text-accent"
                        value="preview"
                    >
                        Preview
                    </Tabs.Trigger>
                </Tabs.List>
                <div className="flex flex-row gap-4 mb-4">
                    <CreateAgentListModal
                        traits={agentList.traits}
                        agents={agentList.agents}
                        createAgentListResponse={createAgentListResponse}
                        method={agentList.method}
                        agentFile={agentList.agentFile}
                    >
                        <button className="flex items-center gap-2 px-4 py-2.5 bg-green-600 hover:bg-green-700 hover:transition-colors rounded-md text-white font-medium text-sm">
                            <Box className="w-4" />
                            Create agent list
                        </button>
                    </CreateAgentListModal>
                </div>
            </div>
            <Tabs.Content value="data">
                <DataTab
                    agentList={agentList}
                    addTrait={addTrait}
                    removeTrait={removeTrait}
                    updateTrait={updateTrait}
                    addAgent={addAgent}
                    removeAgent={removeAgent}
                    updateAgentTrait={updateAgentTrait}
                    handleFileChange={handleFileChange}
                    onMethodChange={handleMethodChange}
                />
            </Tabs.Content>
            <Tabs.Content value="code">
                <SandpackProvider
                    options={{ classes: { 'sp-stack': '!h-fit' } }}
                    files={{
                        'survey_code.py': {
                            code:
                                agentListData !== null
                                    ? agentListData.code
                                    : '# Nothing to see here yet!',
                            active: true,
                        },
                    }}
                    theme={isDarkMode ? 'dark' : 'light'}
                >
                    <SandpackLayout>
                        <SandpackCodeViewer
                            showLineNumbers={true}
                            showTabs={false}
                            additionalLanguages={[
                                {
                                    name: 'python',
                                    extensions: ['py'],
                                    language: python(),
                                },
                            ]}
                        ></SandpackCodeViewer>
                    </SandpackLayout>
                </SandpackProvider>
            </Tabs.Content>
            <Tabs.Content value="preview">
                <PreviewTab
                    isLoading={fetcher.state !== 'idle'}
                    agentListData={agentListData}
                />
            </Tabs.Content>
        </Tabs.Root>
    );
}

export default AgentList;
