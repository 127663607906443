import { useMatches } from 'react-router-dom';

export default function useAuth() {
    const matches = useMatches();

    const authenticatorRoute = matches.find(
        (match) => match.data !== undefined && match.data.hasOwnProperty('user')
    );

    let isLoggedIn: boolean;
    let isBetaUser: boolean;
    if (authenticatorRoute) {
        isLoggedIn = authenticatorRoute.data['user']['is_authenticated'];
        isBetaUser = authenticatorRoute.data['user']['is_beta_user'];
    } else {
        isLoggedIn = false;
        isBetaUser = false;
    }

    return { isBetaUser, isLoggedIn };
}
