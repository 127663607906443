import { TaskHistory } from './types';

function ExceptionSummary({ taskHistory }: { taskHistory: TaskHistory }) {
    const tableStyles = 'border-collapse border border-gray-300';
    const tableCellStyles = 'p-2 border border-slate-300';

    return (
        <div className="mb-8">
            <div className="bg-white dark:bg-gray-900 rounded-lg border border-gray-300 dark:border-gray-700 overflow-hidden shadow-sm">
                <div className="bg-gray-50 dark:bg-gray-800 p-3 border-b border-gray-300 dark:border-gray-700">
                    <h2 className="font-medium">Exceptions Summary</h2>
                </div>
                <div className="p-4">
                    <div className="overflow-x-auto">
                        <table className="w-full border-collapse text-sm">
                            <thead>
                                <tr className="bg-gray-50 dark:bg-gray-800">
                                    <th className="p-3 text-left font-medium border-b border-gray-200 dark:border-gray-700">
                                        Exception Type
                                    </th>
                                    <th className="p-3 text-left font-medium border-b border-gray-200 dark:border-gray-700">
                                        Service
                                    </th>
                                    <th className="p-3 text-left font-medium border-b border-gray-200 dark:border-gray-700">
                                        Model
                                    </th>
                                    <th className="p-3 text-left font-medium border-b border-gray-200 dark:border-gray-700">
                                        Question Name
                                    </th>
                                    <th className="p-3 text-left font-medium border-b border-gray-200 dark:border-gray-700 w-16">
                                        Count
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {taskHistory.exception_summary.map(
                                    (exception, index) => (
                                        <tr
                                            key={index}
                                            className="border-b border-gray-200 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-800"
                                        >
                                            <td className="p-3">
                                                {exception.exception_type}
                                            </td>
                                            <td className="p-3">
                                                {exception.service}
                                            </td>
                                            <td className="p-3">
                                                {exception.model}
                                            </td>
                                            <td className="p-3">
                                                {exception.question_name}
                                            </td>
                                            <td className="p-3 text-center">
                                                {exception.num_exceptions}
                                            </td>
                                        </tr>
                                    )
                                )}
                            </tbody>
                        </table>
                    </div>
                    <p className="text-sm mt-4">
                        Note: Each unique exception is counted only once. You
                        may encounter repeated exceptions where retries were
                        attempted.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default ExceptionSummary;
