import { useFetcher } from 'react-router-dom';
import { RefreshCw } from 'lucide-react';

function ResetAPIKeyForm() {
    const fetcher = useFetcher();
    const isSubmitting = fetcher.state === 'submitting';

    return (
        <fetcher.Form method="post">
            <button
                name="intent"
                value="reset_api_key"
                disabled={isSubmitting}
                className="flex items-center gap-2 px-4 py-2.5 transition-colors duration-200 bg-amber-500 hover:bg-amber-600 disabled:bg-amber-400 text-white font-medium rounded-lg shadow-sm"
            >
                <RefreshCw
                    size={16}
                    className={isSubmitting ? 'animate-spin' : ''}
                />
                {isSubmitting ? 'Resetting...' : 'Reset Key'}
            </button>
        </fetcher.Form>
    );
}

export default ResetAPIKeyForm;
