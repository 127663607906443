import * as Tooltip from '@radix-ui/react-tooltip';
import { ArrowDown, ArrowUp, Copy, Trash2 } from 'lucide-react';
import { Question as EDSLQuestion } from './types';
import QuestionTypeSelect from './QuestionTypeSelect';
import { QuestionType } from './types';

interface QuestionControlsProps {
    question: EDSLQuestion;
    changeQuestionType: (question: EDSLQuestion, newType: QuestionType) => void;
    copyQuestion: (questionId: string) => void;
    moveQuestionUp: (questionId: string) => void;
    moveQuestionDown: (questionId: string) => void;
    removeQuestion: (questionId: string) => void;
}

function QuestionControls({
    question,
    changeQuestionType,
    copyQuestion,
    moveQuestionUp,
    moveQuestionDown,
    removeQuestion,
}: QuestionControlsProps) {
    return (
        <div className="flex flex-col md:flex-row justify-between">
            <QuestionTypeSelect
                question={question}
                changeQuestionType={changeQuestionType}
            />
            <div className="flex">
                <Tooltip.Provider>
                    <Tooltip.Root delayDuration={0}>
                        <Tooltip.Trigger asChild>
                            <button
                                onClick={() => copyQuestion(question.id)}
                                className="grow-0 p-3 hover:bg-gray-300/20 transition-colors rounded-md text-gray-500"
                            >
                                <Copy className="h-5" />
                            </button>
                        </Tooltip.Trigger>
                        <Tooltip.Portal>
                            <Tooltip.Content
                                className="px-3 py-2 select-none text-sm font-medium text-white bg-gray-900 dark:bg-gray-700 rounded-lg shadow-sm"
                                sideOffset={5}
                            >
                                Copy question
                                <Tooltip.Arrow className="fill-gray-900 dark:fill-gray-700" />
                            </Tooltip.Content>
                        </Tooltip.Portal>
                    </Tooltip.Root>
                </Tooltip.Provider>
                <Tooltip.Provider>
                    <Tooltip.Root delayDuration={0}>
                        <Tooltip.Trigger asChild>
                            <button
                                onClick={() => moveQuestionUp(question.id)}
                                className="grow-0 p-3 hover:bg-gray-300/20 transition-colors rounded-md text-gray-500"
                            >
                                <ArrowUp className="h-5" />
                            </button>
                        </Tooltip.Trigger>
                        <Tooltip.Portal>
                            <Tooltip.Content
                                className="px-3 py-2 select-none text-sm font-medium text-white bg-gray-900 dark:bg-gray-700 rounded-lg shadow-sm"
                                sideOffset={5}
                            >
                                Move question up
                                <Tooltip.Arrow className="fill-gray-900 dark:fill-gray-700" />
                            </Tooltip.Content>
                        </Tooltip.Portal>
                    </Tooltip.Root>
                </Tooltip.Provider>
                <Tooltip.Provider>
                    <Tooltip.Root delayDuration={0}>
                        <Tooltip.Trigger asChild>
                            <button
                                onClick={() => moveQuestionDown(question.id)}
                                className="grow-0 p-3 hover:bg-gray-300/20 transition-colors rounded-md text-gray-500"
                            >
                                <ArrowDown className="h-5" />
                            </button>
                        </Tooltip.Trigger>
                        <Tooltip.Portal>
                            <Tooltip.Content
                                className="px-3 py-2 select-none text-sm font-medium text-white bg-gray-900 dark:bg-gray-700 rounded-lg shadow-sm"
                                sideOffset={5}
                            >
                                Move question down
                                <Tooltip.Arrow className="fill-gray-900 dark:fill-gray-700" />
                            </Tooltip.Content>
                        </Tooltip.Portal>
                    </Tooltip.Root>
                </Tooltip.Provider>
                <Tooltip.Provider>
                    <Tooltip.Root delayDuration={0}>
                        <Tooltip.Trigger asChild>
                            <button
                                onClick={() => removeQuestion(question.id)}
                                className="grow-0 p-3 hover:bg-gray-300/20 transition-colors rounded-md text-gray-500"
                            >
                                <Trash2 className="h-5" />
                            </button>
                        </Tooltip.Trigger>
                        <Tooltip.Portal>
                            <Tooltip.Content
                                className="px-3 py-2 select-none text-sm font-medium text-white bg-gray-900 dark:bg-gray-700 rounded-lg shadow-sm"
                                sideOffset={5}
                            >
                                Delete question
                                <Tooltip.Arrow className="fill-gray-900 dark:fill-gray-700" />
                            </Tooltip.Content>
                        </Tooltip.Portal>
                    </Tooltip.Root>
                </Tooltip.Provider>
            </div>
        </div>
    );
}

export default QuestionControls;
