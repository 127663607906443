import {
    Mail,
    Copy,
    Check,
    Code,
    Settings,
    BarChart4,
    ShieldCheck,
} from 'lucide-react';
import { useCopyToClipboard } from '../../hooks/useCopyToClipboard';

function Consulting() {
    const [isCopied, copyToClipboard] = useCopyToClipboard();
    const email = 'info@expectedparrot.com';

    return (
        <div className="relative w-full overflow-auto">
            <div className="max-w-7xl mx-auto px-4 sm:px-10 pt-20">
                <div className="sm:ml-20">
                    <h1 className="text-3xl sm:text-5xl font-bold text-green-700 dark:text-primary-dark-text">
                        We offer a variety of services to support your AI use
                        cases and needs.
                    </h1>
                    <p className="text-lg sm:text-xl text-gray-500 dark:text-primary-dark-text mt-8">
                        Whether you need technical help, ongoing support, advice
                        on building agent sets and setting up complex tasks, or
                        general strategy consulting on AI tools, we can help.
                    </p>

                    {/* Services Section */}
                    <div className="mt-12 grid grid-cols-1 md:grid-cols-2 gap-6">
                        <div className="p-5 bg-gray-50 dark:bg-gray-800 rounded-lg border border-gray-200 dark:border-gray-700">
                            <div className="flex items-center mb-3">
                                <Code className="h-6 w-6 text-green-600 dark:text-green-400 mr-2" />
                                <h3 className="text-xl font-semibold text-gray-600 dark:text-gray-100">
                                    Technical Implementation
                                </h3>
                            </div>
                            <p className="text-lg text-gray-600 dark:text-gray-300">
                                Support with integration, custom development,
                                and troubleshooting.
                            </p>
                        </div>

                        <div className="p-5 bg-gray-50 dark:bg-gray-800 rounded-lg border border-gray-200 dark:border-gray-700">
                            <div className="flex items-center mb-3">
                                <Settings className="h-6 w-6 text-green-600 dark:text-green-400 mr-2" />
                                <h3 className="text-xl font-semibold text-gray-600 dark:text-gray-100">
                                    Agent Configuration
                                </h3>
                            </div>
                            <p className="text-lg text-gray-600 dark:text-gray-300">
                                Guidance on designing agent systems and creating
                                effective workflows.
                            </p>
                        </div>

                        <div className="p-5 bg-gray-50 dark:bg-gray-800 rounded-lg border border-gray-200 dark:border-gray-700">
                            <div className="flex items-center mb-3">
                                <BarChart4 className="h-6 w-6 text-green-600 dark:text-green-400 mr-2" />
                                <h3 className="text-xl font-semibold text-gray-600 dark:text-gray-100">
                                    Strategy Development
                                </h3>
                            </div>
                            <p className="text-lg text-gray-600 dark:text-gray-300">
                                Advice on model selection, use case evaluation,
                                and implementation planning.
                            </p>
                        </div>

                        <div className="p-5 bg-gray-50 dark:bg-gray-800 rounded-lg border border-gray-200 dark:border-gray-700">
                            <div className="flex items-center mb-3">
                                <ShieldCheck className="h-6 w-6 text-green-600 dark:text-green-400 mr-2" />
                                <h3 className="text-xl font-semibold text-gray-600 dark:text-gray-100">
                                    Ongoing Support
                                </h3>
                            </div>
                            <p className="text-lg text-gray-600 dark:text-gray-300">
                                Regular check-ins and performance optimization
                                assistance.
                            </p>
                        </div>
                    </div>
                    {/* Contact section */}
                    <div className="mt-12 mb-12 mx-auto">
                        <div className="bg-green-50 dark:bg-gray-800 rounded-lg p-8 shadow-md">
                            <div className="flex flex-col items-center justify-center">
                                <h3 className="text-2xl sm:text-3xl font-bold text-green-700 dark:text-green-500 mb-4 text-center">
                                    Get in touch
                                </h3>
                                <p className="text-lg text-gray-600 dark:text-gray-300 mb-6 text-center max-w-3xl">
                                    Send us an email to discuss your consulting
                                    needs.
                                </p>
                                <div className="flex items-stretch">
                                    <a
                                        href="mailto:info@expectedparrot.com"
                                        className="text-xl inline-flex items-center px-4 py-4 bg-green-600 text-white rounded-l-md transition-colors"
                                    >
                                        <Mail className="h-7 w-7 mr-3" />
                                        {email}
                                    </a>
                                    <button
                                        onClick={() => copyToClipboard(email)}
                                        className="inline-flex items-center px-4 py-4 bg-gray-400 hover:bg-gray-600 text-white rounded-r-md transition-colors"
                                        title="Copy email address"
                                    >
                                        {isCopied ? (
                                            <Check className="h-7 w-7" />
                                        ) : (
                                            <Copy className="h-7 w-7" />
                                        )}
                                    </button>
                                </div>
                                <div className="h-6 mt-2">
                                    {isCopied && (
                                        <span className="text-green-600 dark:text-green-400 text-sm">
                                            Email copied to clipboard!
                                        </span>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Add more content to test scrolling */}
                    <div className="h-12 mt-8">
                        {/* Spacer to ensure we have enough content to scroll */}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Consulting;
