import { Link } from 'react-router-dom';
import ContentContainer from '../base/ContentContainer';

function Terms() {
    const linkStyles =
        'text-blue-500 hover:text-blue-600 hover:underline dark:text-primary-dark-text-accent dark:hover:text-primary-dark-text-accent';

    return (
        <ContentContainer>
            <div className="relative w-full overflow-auto">
                <div className="max-w-7xl mx-auto px-4 sm:px-10 pt-20">
                    <div className="space-y-2">
                        <h1 className="font-bold text-4xl pb-2">
                            Terms of Use
                        </h1>
                        <p>Last updated: March 1, 2025</p>
                        <br />
                    </div>

                    <div className="space-y-8">
                        <section>
                            <p>
                                These Terms of Use ("Terms") govern the use of
                                applications of Expected Parrot, Inc.
                                ("Company") that allow users to create, share,
                                access, and analyze surveys using large language
                                models ("LLMs") (collectively, the
                                "Application").
                            </p>
                            <p className="mt-4">
                                Please read these Terms carefully before using
                                the Application. By downloading, accessing, or
                                using the Application or any of the content
                                provided therein, you agree to be bound by these
                                Terms. If you do not agree to these Terms, do
                                not use the Application.
                            </p>
                        </section>

                        <section>
                            <h2 className="font-bold text-2xl mb-4">
                                Use of the Application
                            </h2>
                            <p>
                                You may only use the Application for lawful
                                purposes and in accordance with these Terms.
                            </p>
                        </section>

                        <section>
                            <h2 className="font-bold text-2xl mb-4">
                                API Key Usage
                            </h2>
                            <p>
                                The Application allows users to access LLMs via
                                APIs which may require API keys. You have the
                                option to use your own API keys to access LLMs
                                using the Application. Any charges and costs
                                incurred through the use of your API keys are
                                your responsibility. You also have the option to
                                request that the Company make API requests to
                                LLM providers on your behalf by sending your
                                requests to the Company. Whether you use your
                                own API keys or request that Company make API
                                requests on your behalf, you agree to comply
                                with the terms and conditions of the respective
                                LLM providers. By requesting that the Company
                                make API requests on your behalf you agree that
                                the Company may accept any terms of use of LLM
                                providers on your behalf, and that the Company
                                may enforce any terms of use.
                            </p>
                        </section>

                        <section>
                            <h2 className="font-bold text-2xl mb-4">
                                Purchase of Credits
                            </h2>
                            <p>
                                As an alternative to using your own API keys to
                                access LLMs, you have the option to purchase
                                credits from the Company which may be used to
                                access LLMs and other features and
                                functionalities of the Application. Purchased
                                credits are generally refundable upon request to
                                info@expectedparrot.com. However, the Company
                                reserves the right to refuse a refund request if
                                there is evidence of fraudulent or abusive
                                behavior associated with the purchase or use of
                                the credits. Refunds, if applicable, will be
                                processed in a timely manner to the payment
                                method used for purchasing the credits or such
                                other method that the Company deems appropriate.
                                Credits may only be used within the Application
                                and cannot be transferred or resold. Details
                                regarding the use and limitations of credits
                                will be provided at the time of purchase.
                            </p>
                            <p className="mt-4">
                                Users will be charged credits based on the
                                Pricing page (available{' '}
                                <Link to="/models" className={linkStyles}>
                                    here
                                </Link>
                                ) which may be updated from time to time. Users
                                should check the Pricing page to verify current
                                prices. Prices are subject to change, based on
                                changes in pricing by LLM providers or
                                otherwise. Users may also be charged payment
                                processing fees when purchasing credits.
                            </p>
                        </section>

                        <section>
                            <h2 className="font-bold text-2xl mb-4">
                                User Conduct
                            </h2>
                            <p>
                                You agree not to use the Application in any way
                                that violates any applicable federal, state,
                                local, or international law or regulation. The
                                unauthorized use of the Application, including
                                but not limited to unauthorized entry into the
                                Company's systems, misuse of passwords, or
                                misuse of any information posted to the
                                Application, is strictly prohibited.
                            </p>
                        </section>

                        <section>
                            <h2 className="font-bold text-2xl mb-4">
                                User Responsibilities for Content
                            </h2>
                            <p>
                                As a user of the Application, you are
                                responsible for ensuring that any content you
                                create, submit, post, display or otherwise make
                                available through the Application ("User
                                Content") does not infringe upon the
                                intellectual property rights of others. You
                                represent and warrant that you have all
                                necessary rights and permissions for the User
                                Content you provide or create and that such
                                content does not violate any laws or the rights
                                of any third party, including but not limited to
                                intellectual property rights and privacy rights.
                                You agree to indemnify and hold harmless the
                                Company and its affiliates, officers, agents,
                                and employees from any claim, demand, loss,
                                liability, or expense, including attorneys'
                                fees, arising out of or in connection with your
                                User Content, including but not limited to
                                claims of copyright infringement, trademark
                                infringement or violation of third-party rights.
                            </p>
                        </section>

                        <section>
                            <h2 className="font-bold text-2xl mb-4">
                                Data Collection and Use
                            </h2>
                            <p>
                                The Company collects and uses personal and
                                non-personal information as described in its
                                Privacy Policy. This includes information you
                                provide directly, as well as data collected
                                automatically when you use the Application.
                            </p>
                            <p className="mt-4">
                                When you use our services to make API calls to
                                language models, we store the responses on our
                                servers. The Company reserves the right to
                                preserve these responses indefinitely and to
                                inspect and analyze these responses as needed
                                for service improvement, quality control,
                                compliance purposes, and other purposes outlined
                                in our Privacy Policy.
                            </p>
                            <p className="mt-4">
                                The information collected may be used to provide
                                and improve the Application, for customer
                                support, and for other purposes as outlined in
                                the Privacy Policy.
                            </p>
                        </section>

                        <section>
                            <h2 className="font-bold text-2xl mb-4">
                                Data Security
                            </h2>
                            <p>
                                The Company takes the security of your data
                                seriously and implements appropriate technical
                                and organizational measures to protect your
                                information from unauthorized access,
                                alteration, disclosure, or destruction. However,
                                no method of transmission over the Internet or
                                method of electronic storage is 100% secure.
                                Therefore, while the Company strives to use
                                commercially acceptable means to protect your
                                data, absolute security cannot be guaranteed.
                            </p>
                        </section>

                        <section>
                            <h2 className="font-bold text-2xl mb-4">
                                User Responsibilities
                            </h2>
                            <p>
                                You are responsible for maintaining the
                                confidentiality of your account information,
                                including any credentials, passwords and API
                                keys, and for all activities that occur in your
                                account. You agree to immediately notify the
                                Company of any unauthorized use of your account
                                or any other breach of security.
                            </p>
                        </section>

                        <section>
                            <h2 className="font-bold text-2xl mb-4">
                                Data Sharing and Disclosure
                            </h2>
                            <p>
                                The Company may share your data with third
                                parties in accordance with its Privacy Policy.
                                This may include service providers who assist
                                the Company in operating the Application,
                                conducting business, or serving users, so long
                                as those parties agree to keep this information
                                confidential. The Company may also release
                                information when such release is appropriate to
                                comply with the law, enforce site policies, or
                                protect its or others' rights, property, or
                                safety.
                            </p>
                        </section>

                        <section>
                            <h2 className="font-bold text-2xl mb-4">
                                Third-Party Services
                            </h2>
                            <p>
                                The Application may contain links to third-party
                                websites or services. The Company is not
                                responsible for the privacy practices of these
                                third-party sites or services. The Company
                                encourages you to be aware when you leave the
                                Application and to read the privacy statements
                                of any other site that collects personally
                                identifiable information.
                            </p>
                        </section>

                        <section>
                            <h2 className="font-bold text-2xl mb-4">
                                Changes to Privacy Practices
                            </h2>
                            <p>
                                The Company reserves the right to update and
                                change its privacy practices from time to time.
                                For more detailed information about how the
                                Company collects, uses, and protects your data,
                                please review the Privacy Policy.
                            </p>
                        </section>

                        <section>
                            <h2 className="font-bold text-2xl mb-4">
                                Intellectual Property Rights
                            </h2>
                            <p>
                                The Application and its entire contents,
                                features, and functionality (including but not
                                limited to all information, software, text,
                                displays, images and design) are owned by the
                                Company, its licensors, or other providers of
                                such material and are protected by copyright,
                                trademark, patent, trade secret and other
                                intellectual property or proprietary rights
                                laws.
                            </p>
                            <p className="mt-4">
                                As a user of the Application, you retain
                                ownership of User Content that you independently
                                create, submit, post, display or otherwise make
                                available through the Application.
                            </p>
                            <p className="mt-4">
                                The Company grants you a non-exclusive,
                                non-transferable, revocable license to use the
                                Application and any User Content content created
                                through its use. The Company reserves the right
                                to use any User Content created in the
                                Application for internal purposes including but
                                not limited to improving the Application.
                            </p>
                            <p className="mt-4">
                                You agree not to reproduce, distribute, modify,
                                create derivative works of, publicly display,
                                publicly perform, republish, download, store or
                                transmit any of the material on the Application,
                                except as necessary to create and store content
                                for your own use.
                            </p>
                            <p className="mt-4">
                                Any use of the Application not expressly
                                permitted by these Terms of Use is a breach of
                                these Terms and may violate copyright, trademark
                                and other laws.
                            </p>
                        </section>

                        <section>
                            <h2 className="font-bold text-2xl mb-4">
                                Disclaimer of Warranties
                            </h2>
                            <p>
                                The Application is provided "as is" and "as
                                available" without any warranties of any kind,
                                either express or implied. The Company does not
                                guarantee that the Application or any of its
                                functions will be uninterrupted or error-free.
                            </p>
                        </section>

                        <section>
                            <h2 className="font-bold text-2xl mb-4">
                                Limitation of Liability
                            </h2>
                            <p>
                                To the maximum extent permitted by law, the
                                Company, its affiliates, officers, employees,
                                agents, suppliers and licensors shall not be
                                liable for any indirect, incidental, special,
                                consequential or punitive damages, or any loss
                                of profits or revenues, whether incurred
                                directly or indirectly, or any loss of data,
                                use, goodwill, or other intangible losses,
                                resulting from (a) your access to, use of, or
                                inability to access or use the Application; (b)
                                any conduct or content of any third party on the
                                Application, including without limitation, any
                                defamatory, offensive, or illegal conduct of
                                other users or third parties; (c) any content
                                obtained from the Application; or (d)
                                unauthorized access, use or alteration of your
                                transmissions or content.
                            </p>
                            <p className="mt-4">
                                In no event shall the Company's aggregate
                                liability for all claims related to the
                                Application exceed the greater of one hundred
                                U.S. dollars (U.S. $100) or the amount you paid
                                the Company, if any, in the past six months for
                                the Application.
                            </p>
                            <p className="mt-4">
                                This limitation of liability section shall apply
                                to the fullest extent permitted by law in the
                                State of Massachusetts and shall survive any
                                termination or expiration of these Terms or your
                                use of the Application.
                            </p>
                        </section>

                        <section>
                            <h2 className="font-bold text-2xl mb-4">
                                Modifications and Discontinuation
                            </h2>
                            <p>
                                The Company reserves the right, at its sole
                                discretion, to modify, suspend, or discontinue,
                                temporarily or permanently, the Application (or
                                any part thereof) at any time and without
                                notice. You agree that the Company shall not be
                                liable to you or to any third party for any
                                modification, suspension, or discontinuance of
                                the Application or any part thereof. It is
                                recommended that you periodically check for
                                updates or changes to the Application. Continued
                                use of the Application after any such changes
                                shall constitute your consent to such changes.
                            </p>
                        </section>

                        <section>
                            <h2 className="font-bold text-2xl mb-4">
                                Termination of Use
                            </h2>
                            <p>
                                The Company reserves the right to terminate or
                                suspend your access to and use of the
                                Application at any time, without notice, for
                                conduct that it believes violates these Terms
                                and/or is harmful to other users of the
                                Application, to the Company, or to other
                                information providers. Common reasons for
                                termination include, but are not limited to,
                                breach of these Terms, fraudulent or illegal
                                activities, and actions that may create legal
                                liability or financial loss to users or to the
                                Company. Upon termination, your right to use the
                                Application will immediately cease, and the
                                Company may remove or delete your information
                                and content. If your use of the Application is
                                terminated, you must not re-register for, or
                                re-access, the Application without prior consent
                                of the Company. The disclaimers, limitations of
                                liability, and indemnities within these Terms
                                will survive termination of your use or access
                                to the Application.
                            </p>
                        </section>

                        <section>
                            <h2 className="font-bold text-2xl mb-4">
                                Changes to the Terms
                            </h2>
                            <p>
                                The Company reserves the right to update and
                                change these Terms from time to time. We will
                                notify you of any changes by updating the "Last
                                Updated" date of these Terms.
                            </p>
                        </section>

                        <section>
                            <h2 className="font-bold text-2xl mb-4">
                                Governing Law
                            </h2>
                            <p>
                                These Terms shall be governed by and construed
                                in accordance with the laws of the State of
                                Massachusetts, without giving effect to any
                                choice or conflict of law provision or rule.
                            </p>
                        </section>

                        <section>
                            <h2 className="font-bold text-2xl mb-4">
                                Contact Information
                            </h2>
                            <p>
                                If you have any questions about these Terms,
                                please contact us at{' '}
                                <a
                                    href="mailto:info@expectedparrot.com"
                                    className={linkStyles}
                                >
                                    info@expectedparrot.com
                                </a>
                                .
                            </p>
                        </section>

                        <section>
                            <p className="italic">
                                By using the Application, you acknowledge that
                                you have read and understand these Terms of Use
                                and agree to be bound by them.
                            </p>
                        </section>
                    </div>
                </div>
            </div>
        </ContentContainer>
    );
}

export default Terms;
