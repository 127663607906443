import { Link, useLoaderData } from 'react-router-dom';
import {
    Info,
    Plus,
    Key as KeyIcon,
    Share,
    ListFilter,
    HelpCircle,
    Copy,
    Check,
    ShieldAlert,
} from 'lucide-react';
import * as Tabs from '@radix-ui/react-tabs';
import AddKeyModal from './AddKeyModal';
import OwnedKeysTable from './OwnedKeysTable';
import SharedKeysTable from './SharedKeysTable';
import PrioritizedKeys from './PrioritizedKeys';
import APIKey from './APIKey';
import ResetAPIKeyForm from './ResetAPIKeyForm';
import { Key, SharedKey, PrioritizedKey } from './types';
import { useState } from 'react';
import { useCopyToClipboard } from '../../../../hooks/useCopyToClipboard';

// This is the structure used by Keys.tsx
interface KeysData {
    api_key: string;
    owned_keys: Key[];
    shared_keys: SharedKey[];
    prioritized_keys: { name: string; keys: PrioritizedKey[] }[];
}

function Keys() {
    const { api_key, owned_keys, shared_keys, prioritized_keys } =
        useLoaderData() as KeysData;

    const [isCopied, copyToClipboard] = useCopyToClipboard(2000);

    const linkStyles =
        'text-blue-500 hover:text-blue-600 hover:underline dark:text-primary-dark-text-accent dark:hover:text-primary-dark-text-accent';

    return (
        <>
            {/* Header */}
            <div className="space-y-4">
                <h1 className="font-bold text-4xl pb-2">API Keys</h1>
                <p className="text-xl text-gray-700 dark:text-gray-300">
                    Manage your API keys, see keys shared with you, and set key
                    priorities for your applications.
                </p>
            </div>

            {/* Expected Parrot API Key Section */}
            <div className="border border-gray-200 dark:border-gray-800 rounded-xl p-6 shadow-sm bg-white dark:bg-gray-900">
                <div className="flex items-center gap-3 mb-4">
                    <div className="w-10 h-10 rounded-full bg-green-100 dark:bg-green-900 flex items-center justify-center text-green-600 dark:text-green-400">
                        <KeyIcon size={20} />
                    </div>
                    <h3 className="font-bold text-2xl">
                        Expected Parrot API Key
                    </h3>
                </div>

                <p className="text-gray-700 dark:text-gray-300">
                    Your Expected Parrot API key grants access to{' '}
                    <Link
                        to="/getting-started/coop-how-it-works"
                        className={linkStyles}
                    >
                        Coop
                    </Link>{' '}
                    and enables remote survey execution.
                </p>

                <div className="text-sm bg-red-50 dark:bg-red-900/20 p-4 rounded-lg mt-6 border-l-4 border-red-500">
                    <p className="text-red-800 dark:text-red-200">
                        <b>Warning:</b> Treat your API key like a password.
                        Never share it publicly or include it in
                        version-controlled code.
                    </p>
                </div>

                <div className="flex flex-col mb-6">
                    <div className="w-full">
                        <div className="mt-6 mb-4">
                            <h4 className="font-semibold text-lg mb-3">
                                Your API Key:
                            </h4>
                            <div className="bg-gray-50 dark:bg-gray-800 rounded-lg p-4 border border-gray-200 dark:border-gray-700">
                                <div className="flex flex-col sm:flex-row items-start sm:items-center gap-4">
                                    <APIKey apiKey={api_key} />
                                    <div className="flex-shrink-0">
                                        <ResetAPIKeyForm />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="w-full mt-6">
                        <h4 className="font-semibold text-lg mb-3">
                            Local Configuration:
                        </h4>
                        <div className="bg-gray-50 dark:bg-gray-800 p-4 rounded-lg border border-gray-200 dark:border-gray-700">
                            <p className="mb-4 text-sm">
                                To use this key locally, add it to your{' '}
                                <code className="bg-gray-200 dark:bg-gray-700 px-1 py-0.5 rounded">
                                    .env
                                </code>{' '}
                                file:
                            </p>
                            <div className="relative bg-white dark:bg-gray-900 rounded-md p-3 font-mono text-sm border border-gray-200 dark:border-gray-700">
                                <pre className="whitespace-pre-wrap break-all text-xs pr-8">
                                    {`EXPECTED_PARROT_API_KEY = '${
                                        api_key
                                            ? api_key.substring(0, 10) + '...'
                                            : 'your_api_key_here'
                                    }'`}
                                </pre>
                                <button
                                    onClick={() =>
                                        copyToClipboard(
                                            `EXPECTED_PARROT_API_KEY = '${api_key}'`
                                        )
                                    }
                                    className="absolute top-2 right-2 p-1 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
                                    aria-label="Copy to clipboard"
                                >
                                    {isCopied ? (
                                        <Check
                                            size={16}
                                            className="text-green-500"
                                        />
                                    ) : (
                                        <Copy size={16} />
                                    )}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Tabs Section */}
            <div className="rounded-xl bg-white dark:bg-gray-900">
                <Tabs.Root defaultValue="my_keys" className="w-full">
                    <div className="flex justify-between items-center mb-6">
                        <Tabs.List
                            className="flex gap-x-2 border-b border-gray-200 dark:border-gray-700"
                            aria-label="Manage API keys"
                        >
                            <Tabs.Trigger
                                className="px-4 py-3 font-medium rounded-t-lg text-gray-600 dark:text-gray-300 hover:text-blue-600 dark:hover:text-blue-400 data-[state=active]:bg-white dark:data-[state=active]:bg-gray-800 data-[state=active]:border-t data-[state=active]:border-l data-[state=active]:border-r data-[state=active]:border-gray-200 dark:data-[state=active]:border-gray-700 data-[state=active]:text-blue-600 dark:data-[state=active]:text-blue-400"
                                value="my_keys"
                            >
                                <div className="flex items-center gap-2">
                                    <KeyIcon size={18} />
                                    My keys
                                </div>
                            </Tabs.Trigger>
                            <Tabs.Trigger
                                className="px-4 py-3 font-medium rounded-t-lg text-gray-600 dark:text-gray-300 hover:text-blue-600 dark:hover:text-blue-400 data-[state=active]:bg-white dark:data-[state=active]:bg-gray-800 data-[state=active]:border-t data-[state=active]:border-l data-[state=active]:border-r data-[state=active]:border-gray-200 dark:data-[state=active]:border-gray-700 data-[state=active]:text-blue-600 dark:data-[state=active]:text-blue-400"
                                value="shared_with_me"
                            >
                                <div className="flex items-center gap-2">
                                    <Share size={18} />
                                    Shared with me
                                </div>
                            </Tabs.Trigger>
                            <Tabs.Trigger
                                className="px-4 py-3 font-medium rounded-t-lg text-gray-600 dark:text-gray-300 hover:text-blue-600 dark:hover:text-blue-400 data-[state=active]:bg-white dark:data-[state=active]:bg-gray-800 data-[state=active]:border-t data-[state=active]:border-l data-[state=active]:border-r data-[state=active]:border-gray-200 dark:data-[state=active]:border-gray-700 data-[state=active]:text-blue-600 dark:data-[state=active]:text-blue-400"
                                value="key_priority"
                            >
                                <div className="flex items-center gap-2">
                                    <ListFilter size={18} />
                                    Key priority
                                </div>
                            </Tabs.Trigger>
                        </Tabs.List>

                        {/* Add Key Button */}
                        <AddKeyModal>
                            <button className="flex items-center gap-2 px-4 py-2.5 bg-green-600 hover:bg-green-700 transition-colors rounded-md text-white font-medium">
                                <Plus className="w-5 h-5" />
                                Add key
                            </button>
                        </AddKeyModal>
                    </div>

                    {/* Tab Content Container */}
                    <div className="bg-white dark:bg-gray-800 rounded-lg border border-gray-200 dark:border-gray-700 p-6">
                        {/* My Keys Tab */}
                        <Tabs.Content value="my_keys" className="w-full">
                            <div className="mb-4 w-full">
                                <div className="flex items-center gap-3 mb-4">
                                    <div className="w-10 h-10 rounded-full bg-blue-100 dark:bg-blue-900 flex items-center justify-center text-blue-600 dark:text-blue-400">
                                        <KeyIcon size={20} />
                                    </div>
                                    <h3 className="font-bold text-2xl">
                                        My API Keys
                                    </h3>
                                </div>
                                <p className="text-gray-700 dark:text-gray-300 mb-6">
                                    These are the API keys you own and manage.
                                    You can add, edit, delete, and share these
                                    keys.
                                </p>
                                <div className="w-full overflow-x-auto">
                                    <OwnedKeysTable owned_keys={owned_keys} />
                                </div>
                            </div>
                        </Tabs.Content>

                        {/* Shared with Me Tab */}
                        <Tabs.Content value="shared_with_me" className="w-full">
                            <div className="mb-4 w-full">
                                <div className="flex items-center gap-3 mb-4">
                                    <div className="w-10 h-10 rounded-full bg-green-100 dark:bg-green-900 flex items-center justify-center text-green-600 dark:text-green-400">
                                        <Share size={20} />
                                    </div>
                                    <h3 className="font-bold text-2xl">
                                        Keys Shared With Me
                                    </h3>
                                </div>
                                <p className="text-gray-700 dark:text-gray-300 mb-6">
                                    These are API keys that other users have
                                    shared with you. You can use these keys in
                                    your applications.
                                </p>
                                <div className="w-full overflow-x-auto">
                                    <SharedKeysTable
                                        shared_keys={shared_keys}
                                    />
                                </div>
                            </div>
                        </Tabs.Content>

                        {/* Key Priority Tab */}
                        <Tabs.Content value="key_priority" className="w-full">
                            <div className="mb-4 w-full">
                                <div className="flex items-center gap-3 mb-4">
                                    <div className="w-10 h-10 rounded-full bg-purple-100 dark:bg-purple-900 flex items-center justify-center text-purple-600 dark:text-purple-400">
                                        <ListFilter size={20} />
                                    </div>
                                    <h3 className="font-bold text-2xl">
                                        Key Priority Settings
                                    </h3>
                                </div>
                                <p className="text-gray-700 dark:text-gray-300 mb-6">
                                    Set the priority order for using API keys in
                                    your applications. Keys at the top will be
                                    used first.
                                </p>
                                <div className="w-full overflow-x-auto">
                                    <PrioritizedKeys
                                        prioritized_keys={prioritized_keys}
                                    />
                                </div>
                            </div>
                        </Tabs.Content>
                    </div>
                </Tabs.Root>
            </div>
        </>
    );
}

export default Keys;
