import { useFetcher } from 'react-router-dom';
import * as Dialog from '@radix-ui/react-dialog';
import { X } from 'lucide-react';
import ActionAlert from '../design-system/ActionAlert';

function ReportPricingIssueModal({ children }: { children: React.ReactNode }) {
    const fetcher = useFetcher();

    return (
        <Dialog.Root>
            <Dialog.Trigger asChild>{children}</Dialog.Trigger>
            <Dialog.Portal>
                <Dialog.Overlay className="fixed inset-0 bg-black/60 animate-dialog-overlay-show" />
                <Dialog.Content className="w-[90vw] max-w-[450px] max-h-[85vh] fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 p-6 bg-white dark:bg-gray-700 rounded-md focus:outline-none animate-dialog-content-show">
                    <Dialog.Title asChild>
                        <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                            Report pricing issue
                        </h3>
                    </Dialog.Title>
                    <Dialog.Description className="text-sm mt-5">
                        Notice an issue with the pricing for a model? Please
                        describe the issue below, and we'll get to work on
                        fixing it.
                        <br />
                        <br />
                        Feel free to include your email if you'd like a direct
                        response from us!
                    </Dialog.Description>
                    <div className="text-sm py-4 md:py-5">
                        <fetcher.Form method="post">
                            <div>
                                <label
                                    htmlFor="report-description-input"
                                    className="block mb-3 text-sm font-medium text-gray-900 dark:text-white"
                                >
                                    Description
                                </label>
                                <textarea
                                    id="report-description-input"
                                    name="description"
                                    rows={4}
                                    className="block resize-none p-2.5 w-full text-sm bg-gray-50 focus:outline-none border rounded-lg border-gray-400 focus:ring-1 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700/20 dark:border-gray-500 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:placeholder-gray-400"
                                    placeholder="Please describe the pricing issue..."
                                    defaultValue=""
                                    required
                                />
                            </div>
                            <button
                                type="submit"
                                name="intent"
                                value="report_pricing_issue"
                                className="w-full mt-3 mb-3 text-sm text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                            >
                                Send
                            </button>
                        </fetcher.Form>
                        <ActionAlert response={fetcher.data} />
                    </div>

                    <Dialog.Close asChild>
                        <button
                            className="absolute top-2.5 right-2.5 h-6 w-6 inline-flex justify-center align-center rounded-full"
                            aria-label="Close"
                        >
                            <X className="inline w-4 h-4" strokeWidth="2" />
                        </button>
                    </Dialog.Close>
                </Dialog.Content>
            </Dialog.Portal>
        </Dialog.Root>
    );
}

export default ReportPricingIssueModal;
