import { useState } from 'react';
import { Link } from 'react-router-dom';
import {
    MessageCircle,
    ChevronDown,
    ChevronUp,
    CheckCircle,
    Key,
    Database,
    Globe,
    Layout,
    MousePointer,
    HelpCircleIcon,
    Mail,
    ArrowRight,
} from 'lucide-react';
import { useCopyToClipboard } from '../../hooks/useCopyToClipboard';

function FAQ() {
    const linkStyles =
        'text-blue-500 hover:text-blue-600 hover:underline dark:text-primary-dark-text-accent dark:hover:text-primary-dark-text-accent';

    const [openItems, setOpenItems] = useState({
        'managing-keys': false,
        'universal-cache': false,
        'local-remote': false,
        'no-code': false,
        'why-not': false,
    });

    const [isDiscordHovered, setIsDiscordHovered] = useState(false);
    const [isCopied, copy] = useCopyToClipboard(2000);

    const toggleItem = (id) => {
        setOpenItems((prev) => ({
            ...prev,
            [id]: !prev[id],
        }));
    };

    function DiscordIcon() {
        return (
            <svg
                className="h-5 w-5 mr-2 fill-current"
                role="img"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
            >
                <title>Discord</title>
                <path d="M20.317 4.3698a19.7913 19.7913 0 00-4.8851-1.5152.0741.0741 0 00-.0785.0371c-.211.3753-.4447.8648-.6083 1.2495-1.8447-.2762-3.68-.2762-5.4868 0-.1636-.3933-.4058-.8742-.6177-1.2495a.077.077 0 00-.0785-.037 19.7363 19.7363 0 00-4.8852 1.515.0699.0699 0 00-.0321.0277C.5334 9.0458-.319 13.5799.0992 18.0578a.0824.0824 0 00.0312.0561c2.0528 1.5076 4.0413 2.4228 5.9929 3.0294a.0777.0777 0 00.0842-.0276c.4616-.6304.8731-1.2952 1.226-1.9942a.076.076 0 00-.0416-.1057c-.6528-.2476-1.2743-.5495-1.8722-.8923a.077.077 0 01-.0076-.1277c.1258-.0943.2517-.1923.3718-.2914a.0743.0743 0 01.0776-.0105c3.9278 1.7933 8.18 1.7933 12.0614 0a.0739.0739 0 01.0785.0095c.1202.099.246.1981.3728.2924a.077.077 0 01-.0066.1276 12.2986 12.2986 0 01-1.873.8914.0766.0766 0 00-.0407.1067c.3604.698.7719 1.3628 1.225 1.9932a.076.076 0 00.0842.0286c1.961-.6067 3.9495-1.5219 6.0023-3.0294a.077.077 0 00.0313-.0552c.5004-5.177-.8382-9.6739-3.5485-13.6604a.061.061 0 00-.0312-.0286zM8.02 15.3312c-1.1825 0-2.1569-1.0857-2.1569-2.419 0-1.3332.9555-2.4189 2.157-2.4189 1.2108 0 2.1757 1.0952 2.1568 2.419 0 1.3332-.9555 2.4189-2.1569 2.4189zm7.9748 0c-1.1825 0-2.1569-1.0857-2.1569-2.419 0-1.3332.9554-2.4189 2.1569-2.4189 1.2108 0 2.1757 1.0952 2.1568 2.419 0 1.3332-.946 2.4189-2.1568 2.4189Z" />
            </svg>
        );
    }

    // FAQ data structure with custom icons
    const faqSections = [
        {
            id: 'why-not',
            title: 'Why not just...',
            icon: HelpCircleIcon,
            items: [
                {
                    question: 'Why not just use the OpenAI API directly?',
                    answer: (
                        <>
                            You can, but our tools eliminate the need to write
                            lots of code for connecting to individual AI
                            services. Our tools work with many LLM providers
                            (OpenAI, Anthropic, etc.), and our platform provides
                            helpful infrastructure for conducting research,
                            including a robust caching system for validating,
                            retrieving and sharing stored responses to reproduce
                            results at no cost.
                        </>
                    ),
                },
            ],
        },
        {
            id: 'managing-keys',
            title: 'Managing API keys for LLMs',
            icon: Key,
            items: [
                {
                    question: 'Can I use my own API keys?',
                    answer: (
                        <>
                            Yes. Add them to the{' '}
                            <Link to="/home/keys" className={linkStyles}>
                                Keys
                            </Link>{' '}
                            page of your account to use them with surveys that
                            you run remotely or store them in a <em>.env</em>{' '}
                            file on your computer to use with surveys that you
                            run locally. Learn more about options for{' '}
                            <a
                                href="https://docs.expectedparrot.com/en/latest/api_keys.html"
                                target="_blank"
                                rel="noopener noreferrer"
                                className={linkStyles}
                            >
                                managing keys
                            </a>
                            .
                        </>
                    ),
                },
                {
                    question: 'Where can I find my Expected Parrot key?',
                    answer: (
                        <>
                            Your key is stored at the{' '}
                            <Link to="/home/keys" className={linkStyles}>
                                Settings
                            </Link>{' '}
                            page of your account where you can inspect, copy and
                            reset it at any time.
                        </>
                    ),
                },
            ],
        },
        {
            id: 'universal-cache',
            title: 'Universal Remote Cache',
            icon: Database,
            items: [
                {
                    question: 'What is the universal remote cache?',
                    answer: (
                        <>
                            A collection of questions and responses that have
                            been generated at the Expected Parrot server. Learn
                            more about{' '}
                            <a
                                href="https://docs.expectedparrot.com/en/latest/remote_caching.html"
                                target="_blank"
                                rel="noopener noreferrer"
                                className={linkStyles}
                            >
                                how it works
                            </a>
                            .
                        </>
                    ),
                },
                {
                    question: 'Is it free to use?',
                    answer: 'Yes, it is free to all users with an Expected Parrot (Coop) account.',
                },
                {
                    question:
                        'How do I add responses to the universal remote cache?',
                    answer: 'This happens automatically when you run a survey remotely.',
                },
                {
                    question: 'How do I get a stored response?',
                    answer: 'When you run a question you will retrieve a stored response by default if it exists. If you want to generate a fresh response you can use run(fresh=True).',
                },
                {
                    question:
                        'How do I know whether I will retrieve a stored response?',
                    answer: 'Results that were generated at the Expected Parrot server will show a verified checkmark ✓ at Coop. If you rerun a survey with verified results you will retrieve stored responses.',
                },
                {
                    question:
                        'Is the universal remote cache queryable? Can I check whether there is a stored response for a question?',
                    answer: 'No, the universal remote cache is not queryable. You will only know that a response is available by rerunning verified results.',
                },
                {
                    question:
                        'Can I see which user generated a stored response in the universal remote cache?',
                    answer: 'No, there is no user information in the universal remote cache.',
                },
                {
                    question:
                        'Is my legacy remote cache in the universal remote cache?',
                    answer: 'No, your legacy remote cache is only available to you. If you want your existing stored responses (local or remote) to be added to the universal remote cache you need to rerun the questions to regenerate them. Please let us know if you would like free credits to rerun your surveys.',
                },
                {
                    question: 'Can I still access my legacy remote cache?',
                    answer: 'Yes, your legacy remote cache will be available at your Cache page for 30 days. During this time you can pull the entries at any time. After 30 days the entries will be removed.',
                },
                {
                    question:
                        "Why can't I add my existing caches to the universal remote cache?",
                    answer: 'The purpose of the universal remote cache is to provide a canonical, verified collection of responses to allow researchers to be confident in results and easily reproduce them at no cost. By only allowing responses generated at the Expected Parrot server we can verify the results that are reproduced.',
                },
                {
                    question:
                        'What if I want to run a survey remotely but do not want my responses added to the universal remote cache?',
                    answer: 'This is not allowed. Any new or fresh responses generated at the Expected Parrot server are automatically added to the universal remote cache. If you do not want to add responses to the universal remote cache you must run your surveys locally.',
                },
                {
                    question:
                        'Can I access the universal remote cache when I run a survey locally?',
                    answer: 'No, the universal remote cache is only available when running a survey remotely. However, you can pull entries from your Cache to use them locally at any time (responses that you generated or retrieved from the universal remote cache).',
                },
                {
                    question:
                        'Can I delete a response in the universal remote cache?',
                    answer: 'No, this is not allowed.',
                },
                {
                    question: 'What happens if I delete my account?',
                    answer: 'All of your account information is deleted. Any remote cache entries that you generated will remain in the universal remote cache but information about your account will be deleted.',
                },
            ],
        },
        {
            id: 'local-remote',
            title: 'Running surveys locally vs. remotely',
            icon: Globe,
            items: [
                {
                    question: 'What does "remote inference" mean?',
                    answer: 'Remote inference is the process of running a survey on the Expected Parrot server.',
                },
                {
                    question: 'What does "local inference" mean?',
                    answer: 'Local inference is the process of running a survey on your own computer.',
                },
                {
                    question:
                        'Are my results (still) cached when I use local inference?',
                    answer: 'Yes, they are stored in a local cache on your computer.',
                },
            ],
        },
        {
            id: 'no-code',
            title: 'No-code options',
            icon: MousePointer,
            items: [
                {
                    question:
                        'Are there any tools for running EDSL surveys without writing code?',
                    answer: (
                        <>
                            {' '}
                            Yes, our survey builder tool lets you create surveys
                            and agents at a no-code interface. You can find it
                            at the{' '}
                            <Link to="/create" className={linkStyles}>
                                Create
                            </Link>{' '}
                            page of your Expected Parrot (Coop) account. Learn
                            more about{' '}
                            <a
                                href="https://docs.expectedparrot.com/en/latest/survey_builder.html"
                                target="_blank"
                                rel="noopener noreferrer"
                                className={linkStyles}
                            >
                                how to use it
                            </a>
                            .
                        </>
                    ),
                },
                {
                    question: 'Are there tools for helping me write EDSL code?',
                    answer: (
                        <>
                            Yes, you can use our{' '}
                            <a
                                href="https://docs.expectedparrot.com/en/latest/survey_builder.html"
                                target="_blank"
                                rel="noopener noreferrer"
                                className={linkStyles}
                            >
                                free GPT for generating EDSL code
                            </a>
                            .
                        </>
                    ),
                },
            ],
        },
    ];

    return (
        <div className="relative w-full overflow-auto">
            <div className="max-w-4xl py-8 px-4 mx-auto">
                <div className="space-y-4 mb-8">
                    <h1 className="font-bold text-4xl md:text-5xl pb-2 text-gray-800 dark:text-gray-100">
                        Support & FAQ
                    </h1>
                    <p className="text-gray-600 dark:text-gray-300">
                        Get help and find answers to common questions about
                        using our tools and services for AI research.
                    </p>
                </div>

                {/* Support Options Section */}
                <div className="mb-12">
                    <h2 className="font-bold text-2xl pb-4 text-gray-800 dark:text-gray-100">
                        Support Options
                    </h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
                        <div className="block p-6 bg-white dark:bg-gray-800 rounded-lg border border-gray-200 dark:border-gray-700 shadow-sm">
                            <div className="flex items-center mb-4">
                                <MessageCircle
                                    className="w-5 h-5 mr-2 text-blue-500"
                                    strokeWidth="1.5"
                                />
                                <h3 className="text-lg font-semibold text-gray-900 dark:text-gray-100">
                                    Join our Discord Community
                                </h3>
                            </div>
                            <p className="text-gray-600 dark:text-gray-400 mb-4">
                                Get immediate help from our team and community
                                members. Share your experiences, ask questions,
                                and connect with other researchers.
                            </p>
                            <a
                                href="https://discord.com/invite/mxAYkjfy9m"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="inline-flex items-center px-4 py-2 text-white rounded-md transition-colors"
                                style={{
                                    backgroundColor: isDiscordHovered
                                        ? '#5f73bc'
                                        : '#7289da',
                                }}
                                onMouseEnter={() => setIsDiscordHovered(true)}
                                onMouseLeave={() => setIsDiscordHovered(false)}
                            >
                                <DiscordIcon />
                                Join Discord
                                <ArrowRight className="ml-2 w-4 h-4" />
                            </a>
                        </div>

                        <div className="block p-6 bg-white dark:bg-gray-800 rounded-lg border border-gray-200 dark:border-gray-700 shadow-sm">
                            <div className="flex items-center mb-4">
                                <Mail
                                    className="w-5 h-5 mr-2 text-blue-500"
                                    strokeWidth="1.5"
                                />
                                <h3 className="text-lg font-semibold text-gray-900 dark:text-gray-100">
                                    Email Support
                                </h3>
                            </div>
                            <p className="text-gray-600 dark:text-gray-400 mb-4">
                                Have a specific question or issue? Send us an
                                email and our support team will get back to you
                                as soon as possible.
                            </p>
                            <button
                                onClick={() => copy('info@expectedparrot.com')}
                                className={`inline-flex items-center px-4 py-2 ${
                                    isCopied
                                        ? 'bg-green-600'
                                        : 'bg-blue-600 hover:bg-blue-700'
                                } text-white rounded-md transition-colors`}
                            >
                                {isCopied
                                    ? 'Email Copied! ✓'
                                    : 'Contact Support'}
                                <ArrowRight className="ml-2 w-4 h-4" />
                            </button>
                        </div>
                    </div>
                </div>

                {/* FAQ Accordion Section */}
                <div className="space-y-4 mb-6">
                    <h2 className="font-bold text-2xl text-gray-800 dark:text-gray-100">
                        Frequently Asked Questions
                    </h2>
                    <p className="italic text-gray-600 dark:text-gray-300 flex items-start gap-2">
                        <MessageCircle className="h-5 w-5 mt-0.5 flex-shrink-0 text-blue-500" />
                        <span>
                            Have a question that you don't see answered here?
                            Please send us a message on{' '}
                            <a
                                href="https://discord.com/invite/mxAYkjfy9m"
                                target="_blank"
                                rel="noopener noreferrer"
                                className={`inline-flex items-center ${linkStyles}`}
                            >
                                Discord
                            </a>{' '}
                            or email us at info@expectedparrot.com.
                        </span>
                    </p>
                </div>

                <div className="space-y-6">
                    {faqSections.map((section) => {
                        const SectionIcon = section.icon;
                        return (
                            <div
                                key={section.id}
                                className="rounded-lg border border-gray-200 dark:border-gray-700 overflow-hidden"
                            >
                                <button
                                    onClick={() => toggleItem(section.id)}
                                    className="flex justify-between items-center w-full p-4 bg-gray-50 dark:bg-gray-800 text-left"
                                >
                                    <div className="flex items-center">
                                        <SectionIcon className="h-5 w-5 text-blue-500 mr-2" />
                                        <h2 className="font-bold text-xl text-gray-800 dark:text-gray-100">
                                            {section.title}
                                        </h2>
                                    </div>
                                    {openItems[section.id] ? (
                                        <ChevronUp className="h-5 w-5 text-gray-500" />
                                    ) : (
                                        <ChevronDown className="h-5 w-5 text-gray-500" />
                                    )}
                                </button>

                                {openItems[section.id] &&
                                    section.items.length > 0 && (
                                        <div className="p-4 bg-white dark:bg-gray-900 divide-y divide-gray-100 dark:divide-gray-800">
                                            {section.items.map(
                                                (item, index) => (
                                                    <div
                                                        key={index}
                                                        className="py-4 first:pt-0 last:pb-0"
                                                    >
                                                        <h3 className="text-lg font-medium text-gray-900 dark:text-gray-100 mb-2 flex items-start">
                                                            <SectionIcon className="h-5 w-5 text-gray-500 mr-2 mt-1 flex-shrink-0" />
                                                            {item.question}
                                                        </h3>
                                                        <p className="text-gray-600 dark:text-gray-300 ml-7">
                                                            {item.answer}
                                                        </p>
                                                    </div>
                                                )
                                            )}
                                        </div>
                                    )}

                                {openItems[section.id] &&
                                    section.items.length === 0 && (
                                        <div className="p-6 bg-white dark:bg-gray-900 text-center text-gray-500 dark:text-gray-400 italic">
                                            Content coming soon...
                                        </div>
                                    )}
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}

export default FAQ;
