import { Eye, Download, Star } from 'lucide-react';
import ObjectTypeChip from '../../../../base/ObjectTypeChip';
import { EDSLObject } from '../../../../../types';

function ObjectOverview({ object }: { object: EDSLObject }) {
    return (
        <div className="flex justify-between">
            <div className="flex flex-col justify-between grow me-4">
                <div>
                    <p className="mb-2 py-2 font-medium text-sm">About</p>
                    <div className="mb-2">
                        {object.description ? (
                            <p>{object.description}</p>
                        ) : (
                            <p className="italic">No description provided.</p>
                        )}
                    </div>
                </div>
                <div className="flex space-x-2 mt-1 text-md italic text-gray-500 mb-0">
                    Uploaded {object.created_time_from_now} by{' '}
                    {object.owner_username || 'an anonymous user'}
                    <span className="flex items-center gap-x-1 ml-2">
                        <Eye className="inline w-3 h-3" strokeWidth="2" />
                        {object.view_count}
                    </span>
                    <span className="flex items-center gap-x-1">
                        <Download className="inline w-3 h-3" strokeWidth="2" />
                        {object.download_count}
                    </span>
                    <span className="flex items-center gap-x-1">
                        <Star className="inline w-3 h-3" strokeWidth="2" />
                        {object.star_count}
                    </span>
                </div>
            </div>
            {object.overview.length === 0 && (
                <ul className="w-56 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:text-white">
                    <li className="flex justify-between px-4 py-2">
                        <span>Object</span>
                        <span>
                            <ObjectTypeChip objectType={object.object_type} />
                        </span>
                    </li>
                </ul>
            )}
            {object.overview.length === 1 && (
                <ul className="w-56 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:text-white">
                    <li className="flex justify-between px-4 py-2 border-b border-gray-200 rounded-t-lg dark:border-gray-600">
                        <span>Object</span>
                        <span>
                            <ObjectTypeChip objectType={object.object_type} />
                        </span>
                    </li>
                    {object.overview.map(({ key, value }, index) => (
                        <li
                            key={index}
                            className="flex justify-between px-4 py-2"
                        >
                            <span>{key}</span>
                            <span>{value}</span>
                        </li>
                    ))}
                </ul>
            )}
            {object.overview.length > 1 && (
                <ul className="w-56 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:text-white">
                    <li className="flex justify-between px-4 py-2 border-b border-gray-200 rounded-t-lg dark:border-gray-600">
                        <span>Object</span>
                        <span>
                            <ObjectTypeChip objectType={object.object_type} />
                        </span>
                    </li>
                    {object.overview
                        .slice(0, -1)
                        .map(({ key, value }, index) => (
                            <li
                                key={index}
                                className="flex justify-between px-4 py-2 border-b border-gray-200 rounded-t-lg dark:border-gray-600"
                            >
                                <span>{key}</span>
                                <span>{value}</span>
                            </li>
                        ))}
                    <li className="flex justify-between px-4 py-2">
                        <span>
                            {object.overview[object.overview.length - 1].key}
                        </span>
                        <span>
                            {object.overview[object.overview.length - 1].value}
                        </span>
                    </li>
                </ul>
            )}
        </div>
    );
}

export default ObjectOverview;
