import { ArrowDown, ArrowUp, Check, X } from 'lucide-react';
import { ModelRecord } from '../../../types';

function formatPrice(price: number | null) {
    if (price === null) {
        return;
    } else if (price === 0) {
        return `< $0.01`;
    } else {
        return `$${price.toFixed(2)}`;
    }
}

function ModelBadge({ operational }: { operational: boolean }) {
    switch (operational) {
        case true:
            return (
                <div className="flex items-center gap-2 px-2 py-1 rounded-md text-sm font-semibold rounded-lg bg-green-100 text-green-800 dark:bg-green-400/15 dark:text-green-400">
                    <Check className="shrink-0 w-4 h-4" />
                    Works
                </div>
            );
        case false:
            return (
                <div className="flex items-center gap-2 px-2 py-1 rounded-md text-sm font-semibold rounded-lg bg-red-100 text-red-800 dark:bg-red-400/15 dark:text-red-300">
                    <X className="shrink-0 w-4 h-4" />
                    Doesn't work
                </div>
            );
        default:
            return null;
    }
}

function PricingTable({
    sortedRecords,
    visibleColumns,
    handleSort,
    sortConfig,
}: {
    sortedRecords: ModelRecord[];
    visibleColumns: Record<string, boolean>;
    handleSort: (key: string) => void;
    sortConfig: {
        key: string | null;
        direction: 'asc' | 'desc';
    };
}) {
    const getSortIcon = (columnKey: string) => {
        if (sortConfig.key !== columnKey) {
            return (
                <ArrowUp
                    className="inline w-4 h-4 opacity-0 group-hover:opacity-100"
                    strokeWidth="2"
                />
            );
        }
        return sortConfig.direction === 'asc' ? (
            <ArrowUp className="inline w-4 h-4" strokeWidth="2" />
        ) : (
            <ArrowDown className="inline w-4 h-4" strokeWidth="2" />
        );
    };

    const linkStyles =
        'text-blue-500 hover:text-blue-600 hover:underline dark:text-primary-dark-text-accent dark:hover:text-primary-dark-text-accent';

    return (
        <div className="mb-8 min-h-[80vh] overflow-x-auto rounded-lg">
            <div className="relative min-h-[80vh]">
                <table className="min-w-full text-sm relative">
                    <thead className="bg-white dark:bg-primary-dark-bg sticky border-b dark:border-gray-100/20 top-0">
                        <tr>
                            <th className="py-2 px-4 text-left whitespace-nowrap w-4 font-semibold">
                                #
                            </th>
                            {visibleColumns.service && (
                                <th className="text-left whitespace-nowrap w-40 font-semibold">
                                    <button
                                        onClick={() => handleSort('service')}
                                        className="flex items-center gap-x-1 w-full py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-50/10 group"
                                    >
                                        Service {getSortIcon('service')}
                                    </button>
                                </th>
                            )}
                            {visibleColumns.model && (
                                <th className="text-left whitespace-nowrap w-40 font-semibold">
                                    <button
                                        onClick={() => handleSort('model')}
                                        className="flex items-center gap-x-1 w-full py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-50/10 group"
                                    >
                                        Model {getSortIcon('model')}
                                    </button>
                                </th>
                            )}
                            {visibleColumns.textSupport && (
                                <th className="text-left whitespace-nowrap w-32 font-semibold">
                                    <button
                                        onClick={() =>
                                            handleSort('textSupport')
                                        }
                                        className="flex items-center gap-x-1 w-full py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-50/10 group"
                                    >
                                        Text Support{' '}
                                        {getSortIcon('textSupport')}
                                    </button>
                                </th>
                            )}
                            {visibleColumns.imageSupport && (
                                <th className="text-left whitespace-nowrap w-32 font-semibold">
                                    <button
                                        onClick={() =>
                                            handleSort('imageSupport')
                                        }
                                        className="flex items-center gap-x-1 w-full py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-50/10 group"
                                    >
                                        Image Support{' '}
                                        {getSortIcon('imageSupport')}
                                    </button>
                                </th>
                            )}
                            {visibleColumns.inputPrice && (
                                <th className="text-left w-40 whitespace-nowrap font-semibold">
                                    <button
                                        onClick={() => handleSort('inputPrice')}
                                        className="flex items-center gap-x-1 w-full py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-50/10 group"
                                    >
                                        Input (Price per 1M tokens){' '}
                                        {getSortIcon('inputPrice')}
                                    </button>
                                </th>
                            )}
                            {visibleColumns.outputPrice && (
                                <th className="text-left w-40 whitespace-nowrap font-semibold">
                                    <button
                                        onClick={() =>
                                            handleSort('outputPrice')
                                        }
                                        className="flex items-center gap-x-1 w-full py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-50/10 group"
                                    >
                                        Output (Price per 1M tokens){' '}
                                        {getSortIcon('outputPrice')}
                                    </button>
                                </th>
                            )}
                            {visibleColumns.pricingDataUrl && (
                                <th className="py-2 px-4 text-left w-40 whitespace-nowrap font-semibold">
                                    Pricing Data URL
                                </th>
                            )}
                            {visibleColumns.capitalOfFrance && (
                                <th className="py-2 px-4 text-left whitespace-nowrap w-40 font-semibold">
                                    Capital of France (MC)
                                </th>
                            )}
                            {visibleColumns.imageDescription && (
                                <th className="py-2 px-4 text-left whitespace-nowrap min-w-[32rem] font-semibold">
                                    Expected Parrot Logo Description (Free Text)
                                </th>
                            )}
                            {visibleColumns.capitalOfFranceExceptions && (
                                <th className="py-2 px-4 text-left whitespace-nowrap w-40 font-semibold">
                                    Capital of France (MC) - Exceptions
                                </th>
                            )}
                            {visibleColumns.imageDescriptionExceptions && (
                                <th className="py-2 px-4 text-left whitespace-nowrap min-w-[32rem] font-semibold">
                                    Expected Parrot Logo Description (Free Text)
                                    - Exceptions
                                </th>
                            )}
                        </tr>
                    </thead>
                    <tbody className="align-top">
                        {sortedRecords.map((record, index) => (
                            <tr
                                key={index}
                                className="border-y dark:border-gray-100/20 dark:text-secondary-dark-text"
                            >
                                <td className="py-2 px-4">{index + 1}</td>
                                {visibleColumns.service && (
                                    <td className="py-2 px-4">
                                        {record.inference_service}
                                    </td>
                                )}
                                {visibleColumns.model && (
                                    <td className="py-2 px-4 whitespace-nowrap">
                                        {record.model}
                                    </td>
                                )}
                                {visibleColumns.textSupport && (
                                    <td className="py-2 px-4 whitespace-nowrap">
                                        <ModelBadge
                                            operational={record.works_with_text}
                                        />
                                    </td>
                                )}
                                {visibleColumns.imageSupport && (
                                    <td className="py-2 px-4 whitespace-nowrap">
                                        <ModelBadge
                                            operational={
                                                record.works_with_images
                                            }
                                        />
                                    </td>
                                )}
                                {visibleColumns.inputPrice && (
                                    <td className="py-2 pl-4 pr-10 text-right">
                                        {formatPrice(
                                            record.input_price_per_1M_tokens
                                        )}
                                        {record.using_fallback_input_price &&
                                            ' *'}
                                    </td>
                                )}
                                {visibleColumns.outputPrice && (
                                    <td className="py-2 pl-4 pr-10 text-right">
                                        {formatPrice(
                                            record.output_price_per_1M_tokens
                                        )}
                                        {record.using_fallback_output_price &&
                                            ' *'}
                                    </td>
                                )}
                                {visibleColumns.pricingDataUrl && (
                                    <td className="py-2 px-4">
                                        <a
                                            href={record.pricing_data_url}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className={`${linkStyles} underline hover:no-underline`}
                                        >
                                            {record.pricing_data_url}
                                        </a>
                                    </td>
                                )}
                                {visibleColumns.capitalOfFrance && (
                                    <td className="py-2 px-4">
                                        {record.answer_capital_of_france}
                                    </td>
                                )}
                                {visibleColumns.imageDescription && (
                                    <td
                                        className={`py-2 px-4 ${
                                            record.answer_image_description &&
                                            !record.answer_image_description.includes(
                                                ' '
                                            )
                                                ? 'break-all'
                                                : ''
                                        }`}
                                    >
                                        {record.answer_image_description}
                                    </td>
                                )}
                                {visibleColumns.capitalOfFranceExceptions && (
                                    <td className="py-2 px-4 whitespace-pre-line">
                                        {record.exceptions_capital_of_france}
                                    </td>
                                )}
                                {visibleColumns.imageDescriptionExceptions && (
                                    <td className="py-2 px-4 whitespace-pre-line">
                                        {record.exceptions_image_description}
                                    </td>
                                )}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default PricingTable;
