import ContentContainer from '../base/ContentContainer';

function Privacy() {
    const linkStyles =
        'text-blue-500 hover:text-blue-600 hover:underline dark:text-primary-dark-text-accent dark:hover:text-primary-dark-text-accent';

    return (
        <ContentContainer>
            <div className="relative w-full overflow-auto">
                <div className="max-w-7xl mx-auto px-4 sm:px-10 pt-20">
                    <div className="space-y-2">
                        <h1 className="font-bold text-4xl pb-2">
                            Privacy Policy
                        </h1>
                        <p>Last updated March 3, 2025</p>
                        <br />
                    </div>

                    <div className="space-y-8">
                        <section>
                            <h2 className="font-bold text-2xl mb-4">
                                Introduction
                            </h2>
                            <p className="mb-4">
                                We at Expected Parrot respect your privacy and
                                are committed to protecting your personal data.
                                This Privacy Policy explains how we collect,
                                use, and safeguard your information when you use
                                our services.
                            </p>
                        </section>

                        <section>
                            <h2 className="font-bold text-2xl mb-4">
                                Information We Collect
                            </h2>

                            <h3 className="font-bold text-xl mb-2">
                                Account Information
                            </h3>
                            <ul className="list-disc ml-6 mb-4">
                                <li>Email address</li>
                                <li>Username (chosen by you)</li>
                            </ul>
                            <p className="mb-4">
                                We do not collect additional personal
                                information beyond what is necessary to create
                                and maintain your account.
                            </p>

                            <h3 className="font-bold text-xl mb-2">
                                Usage Data
                            </h3>
                            <ul className="list-disc ml-6 mb-4">
                                <li>API calls to language models</li>
                                <li>Responses generated by language models</li>
                            </ul>
                        </section>

                        <section>
                            <h2 className="font-bold text-2xl mb-4">
                                How We Use Your Information
                            </h2>
                            <p className="mb-2">We use your information to:</p>
                            <ul className="list-disc ml-6 mb-4">
                                <li>Provide and maintain our services</li>
                                <li>
                                    Authenticate your account and secure access
                                    to your data
                                </li>
                                <li>Improve and optimize our platform</li>
                                <li>
                                    Communicate with you about service updates
                                    or changes
                                </li>
                                <li>Comply with legal obligations</li>
                            </ul>
                        </section>

                        <section>
                            <h2 className="font-bold text-2xl mb-4">
                                Data Storage and Retention
                            </h2>

                            <h3 className="font-bold text-xl mb-2">
                                Language model responses
                            </h3>
                            <p className="mb-2">
                                When you use our service to make API calls to
                                large language models, we store the contents of
                                the API calls and the responses on our servers,
                                including any exceptions that are generated. We
                                reserve the right to:
                            </p>
                            <ul className="list-disc ml-6 mb-4">
                                <li>
                                    Preserve the contents of API calls and
                                    responses indefinitely
                                </li>
                                <li>
                                    Allow other users to view stored responses
                                    when they make API calls with the same
                                    prompts that you used to generate the
                                    responses (i.e., when another user reruns
                                    your prompts)
                                </li>
                                <li>
                                    Inspect and analyze these responses as
                                    needed for service improvement, quality
                                    control, and compliance purposes, including
                                    reviews for compliance in connection with
                                    the terms of use of any inference service
                                    providers
                                </li>
                            </ul>

                            <h3 className="font-bold text-xl mb-2">
                                Account Information
                            </h3>
                            <p className="mb-4">
                                We retain your account information for as long
                                as your account is active or as needed to
                                provide services to you. You may request
                                deletion of your account at any time.
                            </p>
                        </section>

                        <section>
                            <h2 className="font-bold text-2xl mb-4">
                                Data Sharing and Disclosure
                            </h2>
                            <p className="mb-2">
                                We do not sell your personal information to
                                third parties. We may share your information in
                                the following circumstances:
                            </p>
                            <ul className="list-disc ml-6 mb-4">
                                <li>
                                    With service providers who assist us in
                                    operating our platform
                                </li>
                                <li>To comply with legal obligations</li>
                                <li>
                                    To protect our rights, privacy, safety, or
                                    property
                                </li>
                                <li>
                                    In connection with a business transfer,
                                    merger, or acquisition
                                </li>
                            </ul>
                        </section>

                        <section>
                            <h2 className="font-bold text-2xl mb-4">
                                Your Rights
                            </h2>
                            <p className="mb-2">
                                Depending on your location, you may have rights
                                concerning your personal data, including:
                            </p>
                            <ul className="list-disc ml-6 mb-4">
                                <li>Accessing your personal information</li>
                                <li>Correcting inaccurate data</li>
                                <li>Requesting deletion of your data</li>
                                <li>
                                    Restricting or objecting to certain
                                    processing activities
                                </li>
                                <li>Data portability</li>
                            </ul>
                            <p className="mb-4">
                                To exercise these rights, please contact us
                                using the information provided below.
                            </p>
                        </section>

                        <section>
                            <h2 className="font-bold text-2xl mb-4">
                                Security
                            </h2>
                            <p className="mb-4">
                                We implement appropriate technical and
                                organizational measures to protect your personal
                                information. However, no method of transmission
                                over the Internet or electronic storage is 100%
                                secure, and we cannot guarantee absolute
                                security.
                            </p>
                        </section>

                        <section>
                            <h2 className="font-bold text-2xl mb-4">
                                Third-Party Services
                            </h2>
                            <p className="mb-4">
                                Our service may include links to third-party
                                websites or services. We are not responsible for
                                the privacy practices of these third parties,
                                and we encourage you to review their privacy
                                policies.
                            </p>
                        </section>

                        <section>
                            <h2 className="font-bold text-2xl mb-4">
                                Children's Privacy
                            </h2>
                            <p className="mb-4">
                                Our services are not intended for use by
                                children under 13 years of age. We do not
                                knowingly collect personal information from
                                children under 13.
                            </p>
                        </section>

                        <section>
                            <h2 className="font-bold text-2xl mb-4">
                                Changes to this Privacy Policy
                            </h2>
                            <p className="mb-4">
                                We may update this Privacy Policy from time to
                                time. We will notify you of any changes by
                                posting the new Privacy Policy on this page and
                                updating the effective date.
                            </p>
                        </section>

                        <section>
                            <h2 className="font-bold text-2xl mb-4">
                                Contact Us
                            </h2>
                            <p className="mb-4">
                                If you have any questions about this Privacy
                                Policy, please contact us at
                                info@expectedparrot.com.
                            </p>
                        </section>
                    </div>
                </div>
            </div>
        </ContentContainer>
    );
}

export default Privacy;
