import { useLoaderData } from 'react-router-dom';
import ExceptionsReport from './ExceptionsReport';
import { TaskHistory } from './types';

interface ErrorData {
    task_history: TaskHistory;
    job_id: string;
}

function ErrorId() {
    const data = useLoaderData() as ErrorData;
    return (
        <ExceptionsReport taskHistory={data.task_history} jobId={data.job_id} />
    );
}

export default ErrorId;
