import * as Select from '@radix-ui/react-select';
import { ChevronDown, ChevronUp, Check } from 'lucide-react';

interface ServiceToKey {
    [key: string]: string;
}

interface KeyToServices {
    [key: string]: string[];
}

export const SERVICE_LABELS: Record<string, string> = {
    anthropic: 'Anthropic',
    azure: 'Azure',
    bedrock: 'AWS Bedrock',
    deep_infra: 'Deep Infra',
    deepseek: 'DeepSeek',
    google: 'Google',
    groq: 'Groq',
    mistral: 'Mistral',
    openai: 'OpenAI',
    perplexity: 'Perplexity',
    together: 'Together',
    xai: 'XAI',
};

export const SERVICE_TO_KEY: ServiceToKey = {
    anthropic: 'ANTHROPIC_API_KEY',
    azure: 'AZURE_ENDPOINT_URL_AND_KEY',
    bedrock: 'AWS_SECRET_ACCESS_KEY',
    deep_infra: 'DEEP_INFRA_API_KEY',
    deepseek: 'DEEPSEEK_API_KEY',
    google: 'GOOGLE_API_KEY',
    groq: 'GROQ_API_KEY',
    mistral: 'MISTRAL_API_KEY',
    openai: 'OPENAI_API_KEY',
    perplexity: 'PERPLEXITY_API_KEY',
    together: 'TOGETHER_API_KEY',
    xai: 'XAI_API_KEY',
};

export const SERVICE_TO_PAIRED_KEY: ServiceToKey = {
    bedrock: 'AWS_ACCESS_KEY_ID',
};

export const KEY_TO_SERVICES: KeyToServices = {
    ANTHROPIC_API_KEY: ['anthropic'],
    AWS_ACCESS_KEY_ID: ['bedrock'],
    AWS_SECRET_ACCESS_KEY: ['bedrock'],
    AZURE_ENDPOINT_URL_AND_KEY: ['azure'],
    DEEP_INFRA_API_KEY: ['deep_infra'],
    DEEPSEEK_API_KEY: ['deepseek'],
    GOOGLE_API_KEY: ['google'],
    GROQ_API_KEY: ['groq'],
    MISTRAL_API_KEY: ['mistral'],
    OPENAI_API_KEY: ['openai'],
    PERPLEXITY_API_KEY: ['perplexity'],
    TOGETHER_API_KEY: ['together'],
    XAI_API_KEY: ['xai'],
};

function ServiceSelect({
    name,
    value,
    onValueChange,
    availableServices,
}: {
    name: string;
    value: string;
    onValueChange: (service: string) => void;
    availableServices: string[];
}) {
    return (
        <Select.Root name={name} value={value} onValueChange={onValueChange}>
            <Select.Trigger
                className="h-full inline-flex items-center justify-between border border-gray-300 dark:border-2 dark:border-gray-500 rounded px-4 py-2 text-sm h-9 gap-2 bg-transparent hover:bg-mauve-300 focus:shadow-outline"
                aria-label="Select a service"
            >
                <Select.Value placeholder="Select a service…" />
                <Select.Icon>
                    <ChevronDown className="ml-3 w-5" strokeWidth={1.5} />
                </Select.Icon>
            </Select.Trigger>
            <Select.Portal>
                <Select.Content className="overflow-hidden bg-white rounded-md shadow-lg">
                    <Select.ScrollUpButton className="flex items-center justify-center h-6 bg-white text-blue-900 cursor-default">
                        <ChevronUp />
                    </Select.ScrollUpButton>
                    <Select.Viewport className="p-1">
                        <Select.Group>
                            <Select.Label className="px-6 py-2 text-xs text-gray-900">
                                Services
                            </Select.Label>
                            {availableServices.map((service) => (
                                <Select.Item
                                    key={service}
                                    value={service}
                                    className="text-sm text-blue-900 rounded flex items-center h-6 px-9 py-4 relative select-none data-[disabled]:text-mauve-800 data-[disabled]:pointer-events-none data-[highlighted]:outline-none data-[highlighted]:bg-blue-900 data-[highlighted]:text-blue-100"
                                >
                                    <Select.ItemText>
                                        {SERVICE_LABELS[service]}
                                    </Select.ItemText>
                                    <Select.ItemIndicator className="absolute left-1 w-5 inline-flex items-center justify-center">
                                        <Check />
                                    </Select.ItemIndicator>
                                </Select.Item>
                            ))}
                        </Select.Group>
                    </Select.Viewport>
                </Select.Content>
            </Select.Portal>
        </Select.Root>
    );
}

export default ServiceSelect;
