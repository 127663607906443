import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
    MessageCircle,
    Gift,
    Server,
    Repeat,
    Share2,
    CreditCard,
    DollarSign,
    Zap,
    BarChart,
    FileText,
    Users,
    Layout,
    Mail,
    Copy,
    Check,
    Code,
    Settings,
    BarChart4,
    ShieldCheck,
    Building,
    Lock,
    Database,
    Globe,
    Shield,
} from 'lucide-react';
import * as Tabs from '@radix-ui/react-tabs';
import { useCopyToClipboard } from '../../hooks/useCopyToClipboard';

function Solutions() {
    const [activeTab, setActiveTab] = useState('cloud');
    const email = 'info@expectedparrot.com';
    const [emailCopied, copyEmail] = useCopyToClipboard(2000);

    const handleCopyEmail = () => {
        copyEmail(email);
    };

    const linkStyles =
        'text-blue-500 hover:text-blue-600 hover:underline dark:text-primary-dark-text-accent dark:hover:text-primary-dark-text-accent';

    return (
        <div className="relative w-full overflow-auto">
            <div className="max-w-7xl mx-auto px-4 sm:px-10 py-8">
                {/* Header Section */}
                <div className="space-y-4 mb-8">
                    <h1 className="font-bold text-4xl md:text-5xl pb-2 text-gray-800 dark:text-gray-100">
                        Solutions
                    </h1>

                    {/* Value Proposition Card */}
                    <div className="bg-blue-50 dark:bg-blue-900/30 p-6 rounded-lg border border-blue-100 dark:border-blue-800 mb-8">
                        <h2 className="font-bold text-xl text-blue-800 dark:text-blue-300 mb-3">
                            Flexible solutions for organizations
                        </h2>
                        <p className="text-gray-700 dark:text-gray-300">
                            Whether you use our free cloud-based service,
                            require a closed-source option, or need specialized
                            consulting services, we have solutions to meet your
                            specific requirements.
                        </p>
                    </div>
                </div>

                {/* Convert to Radix Tabs */}
                <Tabs.Root
                    value={activeTab}
                    onValueChange={setActiveTab}
                    className="w-full"
                >
                    <Tabs.List className="flex flex-wrap gap-2 border-b border-gray-200 dark:border-gray-700 mb-6">
                        <Tabs.Trigger
                            value="cloud"
                            className="px-4 py-3 font-medium rounded-t-lg text-gray-600 dark:text-gray-300 hover:text-blue-600 dark:hover:text-blue-400 data-[state=active]:bg-white dark:data-[state=active]:bg-gray-800 data-[state=active]:border-t data-[state=active]:border-l data-[state=active]:border-r data-[state=active]:border-gray-200 dark:data-[state=active]:border-gray-700 data-[state=active]:text-blue-600 dark:data-[state=active]:text-blue-400"
                        >
                            <div className="flex items-center">
                                <Globe className="h-5 w-5 mr-2" />
                                Open Source
                            </div>
                        </Tabs.Trigger>

                        <Tabs.Trigger
                            value="enterprise"
                            className="px-4 py-3 font-medium rounded-t-lg text-gray-600 dark:text-gray-300 hover:text-blue-600 dark:hover:text-blue-400 data-[state=active]:bg-white dark:data-[state=active]:bg-gray-800 data-[state=active]:border-t data-[state=active]:border-l data-[state=active]:border-r data-[state=active]:border-gray-200 dark:data-[state=active]:border-gray-700 data-[state=active]:text-blue-600 dark:data-[state=active]:text-blue-400"
                        >
                            <div className="flex items-center">
                                <Building className="h-5 w-5 mr-2" />
                                Closed source
                            </div>
                        </Tabs.Trigger>

                        <Tabs.Trigger
                            value="consulting"
                            className="px-4 py-3 font-medium rounded-t-lg text-gray-600 dark:text-gray-300 hover:text-blue-600 dark:hover:text-blue-400 data-[state=active]:bg-white dark:data-[state=active]:bg-gray-800 data-[state=active]:border-t data-[state=active]:border-l data-[state=active]:border-r data-[state=active]:border-gray-200 dark:data-[state=active]:border-gray-700 data-[state=active]:text-blue-600 dark:data-[state=active]:text-blue-400"
                        >
                            <div className="flex items-center">
                                <Settings className="h-5 w-5 mr-2" />
                                Consulting services
                            </div>
                        </Tabs.Trigger>
                    </Tabs.List>

                    {/* Tab Content */}
                    <div className="bg-white dark:bg-gray-800 rounded-lg border border-gray-200 dark:border-gray-700 p-6 shadow-sm">
                        <Tabs.Content value="cloud">
                            <div>
                                <div className="mb-8">
                                    <h2 className="font-bold text-2xl text-gray-800 dark:text-gray-100 mb-4">
                                        Open Source
                                    </h2>
                                    <p className="text-gray-600 dark:text-gray-300">
                                        Our open-source solution provides access
                                        to our integrated platform for
                                        transparent, cost-effective AI research.
                                    </p>
                                </div>

                                {/* Features Grid - 2x2 */}
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
                                    {/* Run Surveys Card */}
                                    <div className="bg-white dark:bg-gray-800 p-6 rounded-lg border border-gray-200 dark:border-gray-700 shadow-sm">
                                        <div className="flex items-start">
                                            <Server className="h-6 w-6 mr-3 text-purple-500 flex-shrink-0 mt-1" />
                                            <div>
                                                <h2 className="font-bold text-lg text-gray-800 dark:text-gray-100 mb-2">
                                                    Run surveys at our server
                                                </h2>
                                                <p className="text-gray-600 dark:text-gray-300">
                                                    You can run surveys at our
                                                    server or your own machine.
                                                    There is no cost for running
                                                    at our server—you only pay
                                                    for LLM API calls.
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Reproduce Results Card */}
                                    <div className="bg-white dark:bg-gray-800 p-6 rounded-lg border border-gray-200 dark:border-gray-700 shadow-sm">
                                        <div className="flex items-start">
                                            <Repeat className="h-6 w-6 mr-3 text-green-500 flex-shrink-0 mt-1" />
                                            <div>
                                                <h2 className="font-bold text-lg text-gray-800 dark:text-gray-100 mb-2">
                                                    Reproduce results for free
                                                </h2>
                                                <p className="text-gray-600 dark:text-gray-300">
                                                    Team members can retrieve
                                                    and validate your survey
                                                    results at no cost, enabling
                                                    reproducible research.
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Share Research Card */}
                                    <div className="bg-white dark:bg-gray-800 p-6 rounded-lg border border-gray-200 dark:border-gray-700 shadow-sm">
                                        <div className="flex items-start">
                                            <Share2 className="h-6 w-6 mr-3 text-yellow-500 flex-shrink-0 mt-1" />
                                            <div>
                                                <h2 className="font-bold text-lg text-gray-800 dark:text-gray-100 mb-2">
                                                    Share research
                                                </h2>
                                                <p className="text-gray-600 dark:text-gray-300">
                                                    Post and share content at
                                                    our platform for free,
                                                    whether you run surveys
                                                    remotely or locally.
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Free Credits Card */}
                                    <div className="bg-white dark:bg-gray-800 p-6 rounded-lg border border-gray-200 dark:border-gray-700 shadow-sm">
                                        <div className="flex items-start">
                                            <Gift className="h-6 w-6 mr-3 text-red-500 flex-shrink-0 mt-1" />
                                            <div>
                                                <h2 className="font-bold text-lg text-gray-800 dark:text-gray-100 mb-2">
                                                    Free credits included
                                                </h2>
                                                <p className="text-gray-600 dark:text-gray-300">
                                                    Your account comes with $25
                                                    in credits for API calls.
                                                    Additional free credits are
                                                    available for students and
                                                    researchers.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Credits and Pricing Information */}
                                <div className="bg-white dark:bg-gray-800 p-6 rounded-lg border border-gray-200 dark:border-gray-700 mb-6 shadow-sm">
                                    <div className="flex items-start">
                                        <CreditCard className="h-6 w-6 mr-3 text-indigo-500 flex-shrink-0 mt-1" />
                                        <div>
                                            <h2 className="font-bold text-xl text-gray-800 dark:text-gray-100 mb-3">
                                                Credits & pricing
                                            </h2>
                                            <p className="text-gray-600 dark:text-gray-300 mb-4">
                                                You can choose whether to use
                                                your own API keys from service
                                                providers or your Expected
                                                Parrot API key. When you run
                                                surveys using your Expected
                                                Parrot API key, credits are used
                                                to cover the cost of API calls
                                                to LLM service providers. You
                                                only pay for what you use.
                                            </p>

                                            <div className="flex items-start mt-6 mb-4">
                                                <DollarSign className="h-5 w-5 mr-3 text-green-500 flex-shrink-0 mt-0.5" />
                                                <div>
                                                    <h3 className="font-semibold text-gray-800 dark:text-gray-200">
                                                        Purchase credits
                                                    </h3>
                                                    <p className="text-gray-600 dark:text-gray-300">
                                                        Credits are deducted
                                                        based on input/output
                                                        token rates set by
                                                        service providers. Learn
                                                        more about how{' '}
                                                        <a
                                                            href="https://docs.expectedparrot.com/en/latest/credits.html"
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            className="text-blue-600 dark:text-gray-200 hover:text-blue-600 dark:hover:text-blue-400"
                                                        >
                                                            credits
                                                        </a>{' '}
                                                        work and view current
                                                        token rates on our{' '}
                                                        <Link
                                                            to="/models"
                                                            className={
                                                                linkStyles
                                                            }
                                                        >
                                                            Models
                                                        </Link>{' '}
                                                        page.
                                                    </p>
                                                </div>
                                            </div>

                                            <div className="flex items-start">
                                                <BarChart className="h-5 w-5 mr-3 text-blue-500 flex-shrink-0 mt-0.5" />
                                                <div>
                                                    <h3 className="font-semibold text-gray-800 dark:text-gray-200">
                                                        Compare model
                                                        performance
                                                    </h3>
                                                    <p className="text-gray-600 dark:text-gray-300">
                                                        You can also use our{' '}
                                                        <Link
                                                            to="/models"
                                                            className={
                                                                linkStyles
                                                            }
                                                        >
                                                            Models
                                                        </Link>{' '}
                                                        page to check details on
                                                        available models and
                                                        performance information
                                                        to help you choose the
                                                        best model for your
                                                        needs.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* CTA */}
                                <div className="mt-8 text-center">
                                    <Link
                                        to="/login"
                                        className="inline-flex items-center px-6 py-3 bg-green-600 hover:bg-green-700 text-white rounded-md transition-colors font-medium"
                                    >
                                        <Gift className="h-5 w-5 mr-2" />
                                        Get started with $25 in free credits
                                    </Link>
                                </div>
                            </div>
                        </Tabs.Content>

                        <Tabs.Content value="enterprise">
                            <div>
                                <div className="mb-8">
                                    <h2 className="font-bold text-2xl text-gray-800 dark:text-gray-100 mb-4">
                                        Closed Source
                                    </h2>
                                    <p className="text-gray-600 dark:text-gray-300">
                                        If your organization operates in a
                                        sensitive environment we can deliver a
                                        custom closed source solution that
                                        brings the full power of our open source
                                        platform directly to your
                                        infrastructure.
                                    </p>
                                </div>

                                {/* Enterprise Value Props */}
                                <div className="bg-blue-50 dark:bg-blue-900/30 p-6 rounded-lg border border-blue-100 dark:border-blue-800 mb-8">
                                    <h3 className="font-bold text-xl text-blue-800 dark:text-blue-300 mb-3">
                                        Why choose our closed source solution?
                                    </h3>
                                    <p className="text-gray-700 dark:text-gray-300 mb-4">
                                        Our closed source solution is ideal for
                                        organizations with high security
                                        requirements, compliance needs, or data
                                        sovereignty concerns.
                                    </p>

                                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
                                        <div className="flex items-start">
                                            <Lock className="h-5 w-5 text-blue-600 dark:text-blue-400 mr-2 mt-1 flex-shrink-0" />
                                            <div>
                                                <h4 className="font-semibold text-gray-800 dark:text-gray-200">
                                                    Complete Control
                                                </h4>
                                                <p className="text-gray-600 dark:text-gray-300">
                                                    Deploy behind your firewall
                                                    with full control over your
                                                    data
                                                </p>
                                            </div>
                                        </div>
                                        <div className="flex items-start">
                                            <Shield className="h-5 w-5 text-blue-600 dark:text-blue-400 mr-2 mt-1 flex-shrink-0" />
                                            <div>
                                                <h4 className="font-semibold text-gray-800 dark:text-gray-200">
                                                    Enhanced Security
                                                </h4>
                                                <p className="text-gray-600 dark:text-gray-300">
                                                    Integrate with your existing
                                                    security protocols
                                                </p>
                                            </div>
                                        </div>
                                        <div className="flex items-start">
                                            <Database className="h-5 w-5 text-blue-600 dark:text-blue-400 mr-2 mt-1 flex-shrink-0" />
                                            <div>
                                                <h4 className="font-semibold text-gray-800 dark:text-gray-200">
                                                    Data Sovereignty
                                                </h4>
                                                <p className="text-gray-600 dark:text-gray-300">
                                                    Keep sensitive data within
                                                    your environment
                                                </p>
                                            </div>
                                        </div>
                                        <div className="flex items-start">
                                            <Server className="h-5 w-5 text-blue-600 dark:text-blue-400 mr-2 mt-1 flex-shrink-0" />
                                            <div>
                                                <h4 className="font-semibold text-gray-800 dark:text-gray-200">
                                                    Offline Capability
                                                </h4>
                                                <p className="text-gray-600 dark:text-gray-300">
                                                    Function in restricted
                                                    environments
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Enterprise Features */}
                                {/* <div className="bg-white dark:bg-gray-800 p-6 rounded-lg border border-gray-200 dark:border-gray-700 mb-6 shadow-sm">
                                    <h3 className="font-bold text-xl text-gray-800 dark:text-gray-100 mb-4">
                                        Enterprise Self-Hosted Features
                                    </h3>

                                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-4">
                                        <div className="flex items-start">
                                            <div className="rounded-full bg-green-100 dark:bg-green-900 p-1 mr-3 mt-0.5">
                                                <Server className="h-4 w-4 text-green-600 dark:text-green-400" />
                                            </div>
                                            <div>
                                                <h4 className="font-semibold text-gray-800 dark:text-gray-200">
                                                    Flexible Deployment
                                                </h4>
                                                <p className="text-gray-600 dark:text-gray-300">
                                                    Deploy on your hardware,
                                                    private cloud, or
                                                    containerized environment
                                                </p>
                                            </div>
                                        </div>

                                        <div className="flex items-start">
                                            <div className="rounded-full bg-green-100 dark:bg-green-900 p-1 mr-3 mt-0.5">
                                                <Shield className="h-4 w-4 text-green-600 dark:text-green-400" />
                                            </div>
                                            <div>
                                                <h4 className="font-semibold text-gray-800 dark:text-gray-200">
                                                    Enterprise Support
                                                </h4>
                                                <p className="text-gray-600 dark:text-gray-300">
                                                    SLA-backed support with
                                                    guaranteed response times
                                                </p>
                                            </div>
                                        </div>

                                        <div className="flex items-start">
                                            <div className="rounded-full bg-green-100 dark:bg-green-900 p-1 mr-3 mt-0.5">
                                                <Settings className="h-4 w-4 text-green-600 dark:text-green-400" />
                                            </div>
                                            <div>
                                                <h4 className="font-semibold text-gray-800 dark:text-gray-200">
                                                    Implementation Assistance
                                                </h4>
                                                <p className="text-gray-600 dark:text-gray-300">
                                                    Expert guidance for smooth
                                                    deployment and integration
                                                </p>
                                            </div>
                                        </div>

                                        <div className="flex items-start">
                                            <div className="rounded-full bg-green-100 dark:bg-green-900 p-1 mr-3 mt-0.5">
                                                <FileText className="h-4 w-4 text-green-600 dark:text-green-400" />
                                            </div>
                                            <div>
                                                <h4 className="font-semibold text-gray-800 dark:text-gray-200">
                                                    Compliance Documentation
                                                </h4>
                                                <p className="text-gray-600 dark:text-gray-300">
                                                    Security assessments and
                                                    documentation for regulated
                                                    industries
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}

                                {/* Enterprise Testimonial */}
                                {/* <div className="bg-gray-50 dark:bg-gray-700 p-6 rounded-lg border border-gray-200 dark:border-gray-600 mb-6 shadow-sm">
                                    <blockquote className="text-gray-600 dark:text-gray-300 italic">
                                        "The Enterprise Edition allowed us to
                                        maintain the highest levels of data security
                                        while still benefiting from the innovation
                                        of open source technology. Implementation
                                        was smooth, and the support has been
                                        exceptional."
                                    </blockquote>
                                    <div className="mt-4 font-semibold text-gray-800 dark:text-gray-200">
                                        — Security Officer, Government Agency
                                    </div>
                                </div> */}

                                {/* Enterprise CTA */}
                                <div className="mt-8 text-center">
                                    <button
                                        onClick={() =>
                                            setActiveTab('consulting')
                                        }
                                        className="inline-flex items-center px-6 py-3 bg-blue-600 hover:bg-blue-700 text-white rounded-md transition-colors font-medium mr-4"
                                    >
                                        <Mail className="h-5 w-5 mr-2" />
                                        Request a demo
                                    </button>
                                </div>
                            </div>
                        </Tabs.Content>

                        <Tabs.Content value="consulting">
                            <div>
                                <div className="mb-8">
                                    <h2 className="font-bold text-2xl text-gray-800 dark:text-gray-100 mb-4">
                                        Consulting Services
                                    </h2>
                                    <p className="text-gray-600 dark:text-gray-300">
                                        We offer a variety of services to
                                        support your AI use cases and needs,
                                        whether with our free open source
                                        service or our closed source solution.
                                    </p>
                                </div>
                                {/* Services Grid */}
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
                                    <div className="p-5 bg-gray-50 dark:bg-gray-700 rounded-lg border border-gray-200 dark:border-gray-600">
                                        <div className="flex items-center mb-3">
                                            <Code className="h-6 w-6 text-green-600 dark:text-green-400 mr-2" />
                                            <h3 className="text-xl font-semibold text-gray-600 dark:text-gray-100">
                                                Technical Implementation
                                            </h3>
                                        </div>
                                        <p className="text-lg text-gray-600 dark:text-gray-300">
                                            Support with integration, custom
                                            development, and troubleshooting.
                                        </p>
                                    </div>

                                    <div className="p-5 bg-gray-50 dark:bg-gray-700 rounded-lg border border-gray-200 dark:border-gray-600">
                                        <div className="flex items-center mb-3">
                                            <Settings className="h-6 w-6 text-green-600 dark:text-green-400 mr-2" />
                                            <h3 className="text-xl font-semibold text-gray-600 dark:text-gray-100">
                                                Agent Configuration
                                            </h3>
                                        </div>
                                        <p className="text-lg text-gray-600 dark:text-gray-300">
                                            Guidance on designing agent systems
                                            and creating effective workflows.
                                        </p>
                                    </div>

                                    <div className="p-5 bg-gray-50 dark:bg-gray-700 rounded-lg border border-gray-200 dark:border-gray-600">
                                        <div className="flex items-center mb-3">
                                            <BarChart4 className="h-6 w-6 text-green-600 dark:text-green-400 mr-2" />
                                            <h3 className="text-xl font-semibold text-gray-600 dark:text-gray-100">
                                                Strategy Development
                                            </h3>
                                        </div>
                                        <p className="text-lg text-gray-600 dark:text-gray-300">
                                            Advice on model selection, use case
                                            evaluation, and implementation
                                            planning.
                                        </p>
                                    </div>

                                    <div className="p-5 bg-gray-50 dark:bg-gray-700 rounded-lg border border-gray-200 dark:border-gray-600">
                                        <div className="flex items-center mb-3">
                                            <ShieldCheck className="h-6 w-6 text-green-600 dark:text-green-400 mr-2" />
                                            <h3 className="text-xl font-semibold text-gray-600 dark:text-gray-100">
                                                Ongoing Support
                                            </h3>
                                        </div>
                                        <p className="text-lg text-gray-600 dark:text-gray-300">
                                            Regular check-ins and performance
                                            optimization assistance.
                                        </p>
                                    </div>
                                </div>
                                {/* Contact section */}
                                <div className="mx-auto mb-6">
                                    <div className="bg-green-50 dark:bg-gray-700 rounded-lg p-8 shadow-md">
                                        <div className="flex flex-col items-center justify-center">
                                            <h3 className="text-2xl sm:text-3xl font-bold text-green-700 dark:text-green-500 mb-4 text-center">
                                                Get in touch
                                            </h3>
                                            <p className="text-lg text-gray-600 dark:text-gray-300 mb-6 text-center max-w-3xl">
                                                Send us an email to discuss your
                                                consulting needs or enterprise
                                                self-hosted implementation.
                                            </p>
                                            <div className="flex flex-col sm:flex-row items-center gap-4">
                                                <button
                                                    onClick={handleCopyEmail}
                                                    className="inline-flex items-center px-6 py-3 bg-green-600 hover:bg-green-700 text-white rounded-md transition-colors font-medium"
                                                >
                                                    {emailCopied ? (
                                                        <>
                                                            <svg
                                                                className="h-5 w-5 mr-2"
                                                                viewBox="0 0 24 24"
                                                                fill="none"
                                                                stroke="currentColor"
                                                                strokeWidth="2"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                            >
                                                                <polyline points="20 6 9 17 4 12"></polyline>
                                                            </svg>
                                                            Copied!
                                                        </>
                                                    ) : (
                                                        <>
                                                            <svg
                                                                className="h-5 w-5 mr-2"
                                                                viewBox="0 0 24 24"
                                                                fill="none"
                                                                stroke="currentColor"
                                                                strokeWidth="2"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                            >
                                                                <rect
                                                                    x="9"
                                                                    y="9"
                                                                    width="13"
                                                                    height="13"
                                                                    rx="2"
                                                                    ry="2"
                                                                ></rect>
                                                                <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path>
                                                            </svg>
                                                            info@expectedparrot.com
                                                        </>
                                                    )}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>{' '}
                            </div>
                        </Tabs.Content>
                    </div>
                </Tabs.Root>
            </div>
        </div>
    );
}

export default Solutions;
