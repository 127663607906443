export async function action({ request }) {
    const formData = await request.formData();
    let intent = formData.get('intent');

    let response: Response;

    if (intent === 'report_pricing_issue') {
        const description = formData.get('description');
        response = await fetch('/api/report-pricing-issue', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ description }),
        });
    }

    if (response.ok) {
        const successData = await response.json();
        return { success: true, message: successData.message };
    } else if (response.status === 422) {
        const errorData = await response.json();
        console.log(errorData);
        return { success: false, message: 'Pydantic error.' };
    } else if (response.status === 504) {
        return { success: false, message: 'No response from server.' };
    } else {
        const errorData = await response.json();
        return { success: false, message: errorData.detail };
    }
}
